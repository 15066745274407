import styled from "styled-components";
import { COLORS } from '../../../constants/colors';

export const LoginPageContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 0 auto;
   background-color: ${COLORS.urbanBaGLight};
   height: 100vh;
`;

export const LoginLeftSide = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   width: ${({ isMobile }) => isMobile ? '100%' : '40%'};
   height: 100vh;
   background-color: ${COLORS.urbanLoginDark};
   padding: 40px;
   overflow-y: auto;

   .logo {
      max-width: 200px;
      margin-top: 10px;
      margin-bottom: ${({ isMobile }) => isMobile ? '50px' : '120px'};;
   }

`;

export const LoginRightSide = styled.div`
   display: flex;
   flex-direction: column;
   width: 60%;

   img {
      height: 100vh;
   }
`;

export const LoginTitle = styled.h1<{ isMobile: boolean }>`
   font-size: ${({isMobile}) => isMobile ? '20px' :'40px'} !important;
   color: ${COLORS.white};
   margin-bottom: 20px;
`;

export const HolderForm = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   button {
      width: 100% !important;

   }

   input {
      margin-bottom: 20px;
   }

`;

export const InputTitle = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   color: ${COLORS.white};
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;

export const InputSubtitle = styled.div`
   margin-bottom: 50px;
   color: ${COLORS.white};
   opacity: 0.5;
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   text-decoration: underline;
   max-width: 150px;

   &:hover {
      cursor: pointer;
   }
`;

export const RegisterSubtitle = styled.div`
   color: ${COLORS.white};
   font-weight: 400;
   font-size: 14px;
   max-width: 450px;

   &:hover {
      cursor: pointer;
   }
`;

export const VerticalSeparator = styled.br`
   height: 15px;
`;

export const LogoRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   .close {
      width: 10px;
      height: 10px;
      opacity: 0.5;
      margin-top: 30px;

      &:hover {
         cursor: pointer;
         opacity: 1;
      }
   }
`;

export const MessageWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   text-align: left;
   margin-top: 10%;
   margin-left: 10%;

   position: absolute;
   top: 0;
`;

export const MessageTitle = styled.div`
   font-size: 16px;
   color: ${COLORS.white};
   margin-right: 10%;
`;

export const MessageSubtitle = styled.h2`
   font-size: 12px;
   color: ${COLORS.grey};

   opacity: 0.5;

   &:hover {
      text-decoration: underline;
      cursor: pointer;
   }
`;