import { PopularCoursesWrapper } from './styled';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import Courses from '../../../Courses/Courses';
import { getPopularCourses } from '../../../../store/actions/appActions';
import {selectPopularCourses, selectPopularStatus} from '../../../../store/selectors/courses';
import PopularCourseLoader from './PopularCourseLoader';
import PopularCoursesError from './PopularCoursesError';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { selectUserProfile } from '../../../../store/selectors/user';
import { selectCurrentLanguage } from '../../../../store/selectors/translation';

const PopularCourses = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const currentLanguage = useSelector(selectCurrentLanguage);
   const languageToFilter = userProfile?.language || currentLanguage;
   const popularCourses = useSelector(selectPopularCourses).filter(course => course.language?.includes(languageToFilter));
   const popularCoursesStatus = useSelector(selectPopularStatus);
   const isLoading = popularCoursesStatus === 'loading';
   const isError = popularCoursesStatus === 'error';
   const { isMobile } = useMediaQuery();

   useEffect(() => {
      dispatch(getPopularCourses() as any);
   }, [])

   return (
      <PopularCoursesWrapper isMobile={isMobile}>
         <h1>{t('popularCourses.title')}</h1>
         {isLoading ? <PopularCourseLoader /> : <Courses coursesConfig={popularCourses} />}
         {isError ? <PopularCoursesError errorTitle={'course.popularCourseError'} /> : null}
      </PopularCoursesWrapper>
   );
}

export default PopularCourses;