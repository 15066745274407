export type TranslateType = 'en' | 'ru' | 'ro';

export enum TRANSLATE_ACTION_TYPES {
  CHANGE_TRANSLATE = 'TRANSLATE::CHANGE_TRANSLATE'
}

export type TranslateActions =
  | { type: TRANSLATE_ACTION_TYPES.CHANGE_TRANSLATE; translate: TranslateType }

export const changeTranslation = ({ newLanguage }: { newLanguage: TranslateType, }): TranslateActions => {
  return { type: TRANSLATE_ACTION_TYPES.CHANGE_TRANSLATE, translate: newLanguage };
};