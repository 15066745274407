import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from "react-dom/client";
import App from './App';
import { store } from './store/configureStore';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
