import { useSelector } from 'react-redux';
import { selectCurrentVideo } from '../../../../store/selectors/courses';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import { CourseVideoWrapper } from './styled';

type Props = {
   courseIFrameURL: string | string[];
}

const CabinetVideoPreview = ({ courseIFrameURL }: Props) => {
   const currentVideo = useSelector(selectCurrentVideo);
   const { isMobile, width } = useMediaQuery();

   const getCourseParams = () => {
      if (Array.isArray(courseIFrameURL)) {
         return courseIFrameURL[currentVideo]
      } else {
         return courseIFrameURL
      }
   }

   return (
      <CourseVideoWrapper isMobile={isMobile}> 
         <div>
            <iframe
               src={getCourseParams()}
               width={isMobile ? width : width - 700}
               height={isMobile ? "200px" : "470px"}
               title="YouTube video player"
               frameBorder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowFullScreen={true}
            ></iframe>
         </div>
      </CourseVideoWrapper>

   );
};

export default CabinetVideoPreview;