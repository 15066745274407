import { useTranslation } from "react-i18next";
import { SECTIONS } from "../../../../constants/courses";
import useMediaQuery from "../../../../utils/breakpoints/useMediaQuery";
import { CursesSections } from "types/common";

import { CalendarEventsWrapper, CalendarEventWrapper, DescriptionWrapper, EventTitle, EventDescription } from "./styled";
import Tag from "../../../Tags/Tag";


const CalendarEvents = ({ currentDayOfWeek }: { currentDayOfWeek: number }) => {
   const { isMobile } = useMediaQuery();
   const { t } = useTranslation(); 

   const events = [
      {
         id: 1,
         title: 'course.parentsSchool.title',
         weekDay: 3,
         description: 'course.parentsSchool.description',
         section: SECTIONS.artTherapy,
      },
      {
         id: 2,
         title: 'course.urbanTop.title',
         weekDay: 0,
         description: 'course.urbanTop.description',
         section: SECTIONS.teens,
      },
      {
         id: 3,
         title: 'course.urbanHigh.title',
         weekDay: 0,
         description: 'course.urbanHigh.description',
         section: SECTIONS.teens,
      },
      {
         id: 6,
         title: 'course.urbanUp.title',
         weekDay: 0,
         description: 'course.urbanUp.description',
         section: SECTIONS.teens,
      },
      {
         id: 4,
         title: 'course.mentalMath.calendarTitle16',
         weekDay: 3,
         description: 'course.mentalMath.description',
         section: SECTIONS.kids,
      },
      {
         id: 5,
         title: 'course.mentalMath.calendarTitle11',
         weekDay: 6,
         description: 'course.mentalMath.description' ,
         section: SECTIONS.kids,
      },
   ];

   const currentEvents = events.filter((event) => {
      const eventDayOfWeek = event.weekDay;
      return eventDayOfWeek === currentDayOfWeek;
   });

   const renderNoEvents = () => {
      return <CalendarEventWrapper isMobile={isMobile}>---</CalendarEventWrapper>
   }

   return <CalendarEventsWrapper isMobile={isMobile}>
      {currentEvents.length > 0 ? currentEvents.map((event) => (
         <CalendarEventWrapper isMobile={isMobile} key={event.id}>
            <div>
               <div><Tag name={event.section as CursesSections} /></div>
            </div>
            <DescriptionWrapper>
               <EventTitle>{t(event.title)}</EventTitle>
               <EventDescription>{t(event.description)}</EventDescription>
            </DescriptionWrapper>
         </CalendarEventWrapper>
      )) : <div>{renderNoEvents()}</div>}
   </CalendarEventsWrapper>
}

export default CalendarEvents;