import SelectedCard from '../SelectedCard';
import { AssociativeCardObj } from '../AssociativeCard';
import { Button } from '../../UI/Button';
import { COLORS } from '../../../constants/colors';
import { AnnotationText, GlobalStepWrapper, BottomButtonsWrapper } from '../styled';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

type Props = {
   selectedCard?: AssociativeCardObj;
   setPreviousStep: () => void;
   setNextStep: () => void;
}

const StepSelectedNegativeCard = ({ selectedCard, setPreviousStep, setNextStep }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()

   return (
      <GlobalStepWrapper isMobile={isMobile}>
         <SelectedCardWrapper isMobile={isMobile}>
            <StepWrapper>
               <SelectedCard selectedCard={selectedCard} width={isMobile ? "250px" : "450px"} height={isMobile ? "150px" : "300px"} >
                  <SelectedCardTitle isMobile={isMobile}>
                     Вы выбрали карту из набора “негатив”:
                  </SelectedCardTitle>
               </SelectedCard>
               <AnnotationText isMobile={isMobile}>
                  Если вы согласны с выбором продолжайте дальше, если нет, то вернитесь на предыдущий шаг.
               </AnnotationText>
            </StepWrapper>
         </SelectedCardWrapper>
         <BottomButtonsWrapper isMobile={isMobile}>
            <Button modifier='primaryOutlined' onClick={setPreviousStep}>Назад</Button>
            <Button modifier='primaryOutlined' onClick={setNextStep}>{t("navigation.next")}</Button>
         </BottomButtonsWrapper>
      </GlobalStepWrapper>
   );
};

export default StepSelectedNegativeCard;

const StepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   text-align: left;
   align-items: center;
   justify-content: center;
`;

const SelectedCardWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   padding: ${props => props.isMobile ? "0.4rem" : "2rem"};
`;

const SelectedCardTitle = styled.h3<{ isMobile: boolean }>`
   font-size: ${props => props.isMobile ? "0.8rem" : "1.5rem"};
   margin: 0;
   padding-bottom: ${props => props.isMobile ? "0.5rem" : "1rem"};
   color: ${COLORS.white};
   text-align: center;
`;

