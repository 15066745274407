import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';

import { CoursesButtonTypes, CoursesModifications } from '../.../../../../types/courses/courses';
import { setCurrentCourse, setCurrentVideo } from '../../../store/actions/appActions';
import { setCurrentPage } from '../../../store/actions/bookActions';
import { COURSES } from '../../../constants/courses';
import { Button } from '../../UI/Button';
import { getCourseVideo } from '../../../utils/courses/getCourseVideo';
import { selectBookmarkedCoursesStatus, selectUserProfile } from '../../../store/selectors/user';

import { CourseActionsBtn, CourseActionsWrapper } from './styled';
import { updateUserBookmarkedCourses } from '../../../store/actions/userActions';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
   button: string;
   courseModification: CoursesModifications;
}

const CourseActions = ({
   button,
   courseModification,
}: Props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const isUserLoggedIn = !!userProfile?.id;
   const userBookmarks = userProfile?.bookmarked_courses || [];
   const isBookmarked = userBookmarks.includes(courseModification);
   const bookmarkedCoursesStatus = useSelector(selectBookmarkedCoursesStatus);
   const isLoadingBookmarkedCourses = bookmarkedCoursesStatus === 'loading';
   const [coursesToLoad, setCoursesToLoad] = useState<CoursesModifications>();

   const setCurrentCourseModification = (courseModification: CoursesModifications) => {
      dispatch(setCurrentCourse({ courseModification }));
   }

   const handleButtonClick = () => {
      if (button === CoursesButtonTypes.PINTEREST) {
         location.href = getToLink(button)
      } else {
         setCurrentCourseModification(courseModification)
         dispatch(setCurrentPage({ currentPage: 0 }));
         dispatch(setCurrentVideo({ courseVideo: 0 }))
      }
   }

   const handleBookmarkClick = (course: CoursesModifications) => {
      const updatedBookmarks = [...userBookmarks];
      setCoursesToLoad(course);
      if (updatedBookmarks?.includes(courseModification)) {
         const index = updatedBookmarks.indexOf(courseModification);
         if (index > -1) {
            updatedBookmarks.splice(index, 1);
         }
      } else {
         updatedBookmarks?.push(courseModification)
      }
      isUserLoggedIn && dispatch(updateUserBookmarkedCourses({
         userId: userProfile.id,
         bookmarked_courses: updatedBookmarks as any
      }) as any);
   }

   const renderDownloadButton = () => {
      if (isLoadingBookmarkedCourses && coursesToLoad === courseModification) {
         return <CircularProgress size={20} color='secondary' />
      }
      if (isBookmarked) {
         return <TurnedInIcon className='icon-bookmark' />
      } else {
         return <TurnedInNotIcon className='icon-bookmark' />
      }
   }

   const getToLink = (button: string): string => {
      switch (button) {
         case CoursesButtonTypes.DESCRIPTION:
            if (courseModification === COURSES.urbanHigh || courseModification === COURSES.urbanTop) {
               return `/Teens`;
            }
            return `/cabinet/${courseModification}`;
         case CoursesButtonTypes.PINTEREST:
            return getCourseVideo(courseModification) as string;
         case CoursesButtonTypes.SEE:
            return "/cabinet"
         case CoursesButtonTypes.SOON:
            return '';
         case CoursesButtonTypes.READ: {
            return `/cabinet/${courseModification}/reader`;
         }
         default:
            return "/cabinet"
      }
   }

   useEffect(() => {
      if (bookmarkedCoursesStatus === 'idle' && coursesToLoad === courseModification) {
         setCoursesToLoad(undefined);
      }
   } , [bookmarkedCoursesStatus, courseModification, coursesToLoad])

   return (
      <CourseActionsWrapper isUserLoggedIn={isUserLoggedIn}>
         {button === CoursesButtonTypes.PINTEREST ?
            <Button
               modifier={'primaryOutlined'}
               onClick={() => location.href = getToLink(button)}>
               {t(button)}
            </Button> : <CourseActionsBtn isUserLoggedIn={isUserLoggedIn}>
               <Link to={getToLink(button)}>
                  <Button
                     disabled={button === CoursesButtonTypes.SOON}
                     modifier={button === CoursesButtonTypes.SOON ? 'primary' : 'primaryOutlined'}
                     onClick={handleButtonClick}
                     className={isUserLoggedIn ? 'actionButton' : ''}
                  >
                     {t(button)}
                  </Button>
               </Link>
               {isUserLoggedIn && <Button
                  modifier={'primaryOutlined'}
                  onClick={(e: any) => handleBookmarkClick(e.currentTarget.value as CoursesModifications)}
                  className='bookmark'
                  value={courseModification}
                  disabled={isLoadingBookmarkedCourses}
               >
                  {renderDownloadButton()}
               </Button>}
            </CourseActionsBtn>

         }

      </CourseActionsWrapper>
   );
};

export default CourseActions;