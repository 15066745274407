import { COLORS } from "../../../../constants/colors";
import styled from "styled-components";

export const UserInfoEditorWrapper = styled.div`

`
export const UserImage = styled.img`
   width: 150xp;
   height: 150px;
   border-radius: 150px;
`

export const UserTopRow = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 40px;
`

export const UserNameWrapper = styled.div`
   margin-right: 30px;

   h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 21%;
   }

   span {
      font-weight: 500;
      font-size: 15px;
      opacity: 0.5;
      margin-bottom: 10px;
   }

   button {
      margin-top: 45px;
   }
`;

export const Divider = styled.div`
   width: 90%;
   margin: 16px;
   height: 1px;
   background: ${COLORS.whiteLight}
   opacity: 0.2;
`;

export const UserBottomRow = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 40px;
`;


export const HolderForm = styled.form<{isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   button {
      width: 100% !important;

   }

   input {
      margin-bottom: 20px;
   }

`;

export const InputTitle = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   color: ${COLORS.white};
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;

export const InputSubtitle = styled.div`
   margin-bottom: 50px;
   color: ${COLORS.white};
   opacity: 0.5;
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   text-decoration: underline;
   max-width: 150px;

   &:hover {
      cursor: pointer;
   }
`;

export const RegisterSubtitle = styled.div`
   margin-top: 10vh;
   color: ${COLORS.white};
   font-weight: 400;
   font-size: 14px;
   max-width: 450px;

   &:hover {
      cursor: pointer;
   }
`;

export const VerticalSeparator = styled.br`
   height: 15px;
`;

export const LogoRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   .close {
      width: 10px;
      height: 10px;
      opacity: 0.5;
      margin-top: 30px;

      &:hover {
         cursor: pointer;
         opacity: 1;
      }
   }
`;