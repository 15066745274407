import { XXLG_SCREEN } from '../../../utils/breakpoints/constants';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { MiniGamesWrapper, OneMiniGamesWrapper } from './styled';
import gameIcon from '../../../assets/games/gameIcon.png';
import associativeIcon from '../../../assets/games/associativeIcon.png';
import {useDispatch, useSelector} from 'react-redux';
import { setAssociationsGameStatus, setMemoryGameStatus } from '../../../store/actions/gameActions';
import {selectActiveTab} from '../../../store/selectors/navigation';

const MiniGames = () => {
   const dispatch = useDispatch();
   const { width } = useMediaQuery();
   const activeTab = useSelector(selectActiveTab)
   const isGamesDisplayed = width < XXLG_SCREEN || activeTab !== 'main';
   const setIsPlayingMemoryGame = () => dispatch(setMemoryGameStatus({ status: 'playing' }));
   const setIsPlayingAssociationGame = () => dispatch(setAssociationsGameStatus({ status: 'playing' }));

   return (
      <>
         {isGamesDisplayed && <MiniGamesWrapper>
            <OneMiniGamesWrapper onClick={setIsPlayingMemoryGame}>
               <img src={gameIcon} alt="" />
            </OneMiniGamesWrapper>
            <OneMiniGamesWrapper onClick={setIsPlayingAssociationGame}>
               <img className='associations' src={associativeIcon} alt="" />
            </OneMiniGamesWrapper>
         </MiniGamesWrapper>}
      </>
   );
};

export default MiniGames;