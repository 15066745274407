type ValidationType = {
   callback: any,
   card_number: number,
   date: number,
   cvv: number,
   user_name: string,
   setValidationErrorFields: (value: string[]) => void
}

export const validationFields = ({
   callback,
   card_number,
   date,
   cvv,
   user_name,
   setValidationErrorFields
}: ValidationType): boolean => {
   const russianSymbolsRegex = /[а-яА-ЯЁё]/;

   if (!card_number || !date || !cvv || !user_name) {
      callback("Please fill in all the required fields.", { variant: "warning" });
      setValidationErrorFields(['card_number', 'date', 'cvv', 'user_name'])
      return false;
   }

   if (card_number.toString().length < 16) {
      callback("Card number should be at least 16 characters long.", { variant: "warning" });
      setValidationErrorFields(['card_number'])
      return false;
   }

   if (date.toString().length < 4) {
      callback("Date should be at least 4 characters long.", { variant: "warning" });
      setValidationErrorFields(['date'])
      return false;
   }

   if (cvv.toString().length < 3) {
      callback("CVV should be at least 3 characters long.", { variant: "warning" });
      setValidationErrorFields(['cvv'])
      return false;
   }

   if (user_name.toString().length < 3) {
      callback("User name should be at least 3 characters long.", { variant: "warning" });
      setValidationErrorFields(['user_name'])
      return false;
   }

   if (russianSymbolsRegex.test(user_name)) {
      callback("User name should be in eng.", { variant: "warning" });
      setValidationErrorFields(['user_name'])
      return false;
   }

   return true
}