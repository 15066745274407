
import { Filter } from './Filter';

import { FiltersWrapper } from './styled';

export type OneFilter = {
   label: string,
   onClick: () => void,
   active?: boolean
}

type Props = {
   filters: OneFilter[]
}

const Filters = ({ filters }: Props) => {

   return (
      <FiltersWrapper>
         {filters.map(({ label, onClick, active }, index) => {
            return <Filter active={active} key={index} text={label} onClick={onClick} />
         })}
      </FiltersWrapper>
   );
};

export default Filters;