import { GameStatus } from 'types/courses/game';

export enum GAME_ACTION_TYPES {
  SET_MEMORY_GAME_STATUS = 'GAME::SET_MEMORY_GAME_STATUS',
  SET_ASSOCIATION_GAME_STATUS = 'GAME::SET_ASSOCIATION_GAME_STATUS',
}

export type GameAction =
  | { type: GAME_ACTION_TYPES.SET_MEMORY_GAME_STATUS; memoryGameStatus: GameStatus }
  | { type: GAME_ACTION_TYPES.SET_ASSOCIATION_GAME_STATUS; associationGameStatus: GameStatus };

export const setMemoryGameStatus = ({ status }: { status: GameStatus }): GameAction => {
  return { type: GAME_ACTION_TYPES.SET_MEMORY_GAME_STATUS, memoryGameStatus: status };
};

export const setAssociationsGameStatus = ({ status }: { status: GameStatus }): GameAction => {
  return { type: GAME_ACTION_TYPES.SET_ASSOCIATION_GAME_STATUS, associationGameStatus: status };
};
