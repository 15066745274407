import { COLORS } from '../../constants/colors';
import { NAVIGATION_WIDTH } from '../../constants/dimensions';
import styled from 'styled-components';

export const NavigationMenuWrapper = styled.div`
   position: fixed;
   top: 0px;
   margin: 10px 10px 10px 10px;
   width: ${NAVIGATION_WIDTH}px;
   background-color: ${COLORS.urbanBlack};
   display: flex;
   height: calc(100vh - 20px);
   border-radius: 10px;
   flex-direction: column;
`;

export const NavigationBGStyledImg = styled.img`
   position: fixed;
   top: 500px;
   width: ${NAVIGATION_WIDTH}px;
   pointer-events: none;
   user-select: none;
`;