import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const MobileWrapper = styled.div<{ width: number }>`
   position: fixed;
   bottom: 0;
   width: ${({ width}) => width - 20}px;
   z-index: 2;
   display: flex;
   flex-direction: row;
   background-color: ${COLORS.urbanBlack};
   margin: 10px;
   border-radius: 10px;
   box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
`;

export const MobileItemWrapper = styled.div`
   width: 100%;
`;

export const MobileButtonsWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
   border-radius: 10px;

   button {
      border-radius: 10px;
   }
`;