import { createSelector } from 'reselect';
import { RootState } from '../configureStore';

const selectAppReducer = (state: RootState) => state.app;

const selectLoginPage = createSelector(selectAppReducer, (data) => data.isLoginPageOpen);
const selectAdminPage = createSelector(selectAppReducer, (data) => data.isAdminPageOpen);
const selectAdminSubscriptionSelectedUser = createSelector(selectAppReducer, (data) => data.currentAdminSelectedSubscription);

export {
   selectLoginPage,
   selectAdminPage,
   selectAdminSubscriptionSelectedUser
};
