type ValidationType = {
   callback: any,
   first_name: string,
   last_name: string,
   email: string,
   setValidationErrorFields: (value: string[]) => void
}

export const validationFields = ({
   callback,
   first_name,
   last_name,
   email,
   setValidationErrorFields,
}: ValidationType): boolean => {
   const russianSymbolsRegex = /[а-яА-ЯЁё]/;
   
   if (!email && !first_name && !last_name) {
      callback("Please fill fields.", { variant: "warning" });
      setValidationErrorFields(['username', 'password', 'password2', 'email', 'first_name', 'last_name'])
      return false;
   }

   if(first_name?.includes(" ")) {
      callback("Please provide name without spaces", { variant: "warning" });
      setValidationErrorFields(['first_name'])
      return false;
   }


   if(last_name?.includes(" ")) {
      callback("Please provide name without spaces", { variant: "warning" });
      setValidationErrorFields(['last_name'])
      return false;
   }


   if(email?.includes(" ")) {
      callback("Please provide name without spaces", { variant: "warning" });
      setValidationErrorFields(['email'])
      return false;
   }


   if (russianSymbolsRegex.test(email)) {
      callback("Email should not contain Russian characters.", { variant: "warning" });
      setValidationErrorFields(['email'])
      return false;
    }

   return true
}