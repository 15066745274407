import { useTranslation } from 'react-i18next';
import { Button } from '../../UI/Button/index';
import { SECTIONS } from '../../../constants/courses';
import gameFirsStep from '../../../assets/courses/mentalMath/gameFirsStep.png'

import { StepTitle, StepWrapper, StepSubtitle } from './style';
import { CursesSections } from 'types/common';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

type Props = {
   setNextStep: () => void
}

const StartStep = ({ setNextStep }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <StepWrapper isMobile={isMobile}>
         <StepTitle>
            {t('course.mentalMath.gameFirstStepTitle')}
         </StepTitle>
         <img src={gameFirsStep} alt="" />
         <StepSubtitle isMobile={isMobile}>
            {t('course.mentalMath.gameFirstStepSubtitle')}
         </StepSubtitle>
         <Button modifier='primaryOutlined' color={SECTIONS.kids as CursesSections} onClick={setNextStep}>
            {t('course.mentalMath.button')}
         </Button>
      </StepWrapper>
   );
};

export default StartStep;