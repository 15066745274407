import { useSelector } from 'react-redux';

import { selectPremiumCourses } from '../../../../store/selectors/courses';
import { PROD_API } from '../../../../constants/api';

import PremiumCourses from './PremiumCourses';
import { selectAdminSubscriptionSelectedUser } from '../../../../store/selectors/app';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   SubscriptionsUsersWrapper,
   UserSectionTitle,
   SubscriptionsUserSectionWrapper,
   ControlWrapper,
   UserControlTitle,
   UserInfoRow,
   UserImage,
   UserInfoSubWrapper,
   ControlSubtitle
} from './styled'
import EmptyPlaceholder from './EmptyPlaceholder';

export const UsersInfo = () => {
   const allPremiumCourses = useSelector(selectPremiumCourses);
   const selectedAdminSubscriptionUser = useSelector(selectAdminSubscriptionSelectedUser)
   const { isMobile } = useMediaQuery();

   return <SubscriptionsUserSectionWrapper isMobile={isMobile}>
      <UserSectionTitle>Управление подпиской</UserSectionTitle>
      {selectedAdminSubscriptionUser ? <SubscriptionsUsersWrapper>
         <UserInfoRow>
            <UserInfoSubWrapper>
               <div>
                  <h1>{selectedAdminSubscriptionUser?.name}</h1>
               </div>
               <span>
                  <p>@{selectedAdminSubscriptionUser?.username} | {selectedAdminSubscriptionUser?.email}</p>
               </span>
            </UserInfoSubWrapper>
            <UserImage src={`${PROD_API}/${selectedAdminSubscriptionUser?.profile_image}`} alt="user" />
         </UserInfoRow>
         <ControlWrapper>
            <UserControlTitle>Текущие курсы пользователя</UserControlTitle>
            <ControlSubtitle>Курсы выделенные галочкой являются активными для пользователя</ControlSubtitle>
            <PremiumCourses premiumCourses={allPremiumCourses} />
         </ControlWrapper>
      </SubscriptionsUsersWrapper> : <EmptyPlaceholder />}
   </SubscriptionsUserSectionWrapper>
}