
import styled from 'styled-components';

import { COLORS } from '../../../../constants/colors';

export const RestoreWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const CourseSubtitle = styled.div`
   color: ${COLORS.urbanBlack};
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   max-width: 500px;
`

export const CourseFooter = styled.div<{ isMobile: boolean }>`
   display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
   justify-content: space-between;
   gap: 4rem;
`

export const CoursePlanWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   margin: ${({ isMobile }) => isMobile ? '20px 0' : '50px'};
   align-items: ${({ isMobile }) => isMobile ? 'flex-start' : 'center'};
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   gap: ${({ isMobile }) => isMobile ? '2rem' : '10rem'};
   text-align: left;

   p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 400px;
   }

   img {
      filter: ${({ isMobile }) => isMobile ? 'none' : 'drop-shadow(12px 12px 27px rgba(0, 0, 0, 0.25))'};
      width: ${({ isMobile }) => isMobile ? '100%' : 'auto'};
      max-width: ${({ isMobile }) => isMobile ? 'auto' : '700px'};
   }
`


export const ChatDecorationWrapper = styled.div<{ isMobile: boolean, isSmallScreen: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '20px' : ''};
   background: ${({ isSmallScreen }) => isSmallScreen ? COLORS.urbanBlack : ''};
   padding: ${({ isMobile }) => isMobile ? '10px' : '10px 0px'};
   margin: ${({ isMobile }) => isMobile ? '20px 0px' : '20px 0px'};
   border-radius: ${({ isMobile }) => isMobile ? '10px' : '0'};

   img {
      margin-top: -100px;
      width: 100%;
      display: ${({ isSmallScreen }) => isSmallScreen ? 'none' : 'block'};
   }
`


export const ChatTitle = styled.h1<{ isMobile: boolean, isSmallScreen: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: ${({ isSmallScreen }) => isSmallScreen ? '0px' : '110px'};
   left: 30px;

 
`

export const ChatSubtitle = styled.p<{ isMobile: boolean, isSmallScreen: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: ${({ isSmallScreen }) => isSmallScreen ? '0px' : '110px'};
   left: 30px;
   div{
      font-size: ${({ isMobile }) => isMobile ? '15px' : '20px'};
   }
`

export const ButtonWrapper = styled.div<{ isMobile: boolean, isSmallScreen: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: ${({ isSmallScreen }) => isSmallScreen ? '' : '-110px'};
   left: 30px;
   display: flex;
   gap: 20px;
`


export const PuzzleContainer = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
      props.isMobile ? 'repeat(2, 1fr)' : 'repeat(1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 1fr)'};
  grid-template-rows: ${(props) => (props.isMobile ? 'repeat(0.1fr 1fr 1fr 1fr 1fr)' : '1fr 1fr')};
  grid-template-areas: ${(props) =>
      props.isMobile
         ? '"descr descr" "puzl1 one" "two puzl2" " puzl3 three" "four puzl4"'
         : '"descr descr puzl2 puzl2 three three three puzl4" "puzl1 one one two two puzl3 puzl3 four"'};
  gap: 10px;
`;

export const GridItem1 = styled.div<{ isMobile: boolean }>`
  font-size: 20px;
  margin:${(props) => (props.isMobile ? '0 0 20px 0' : '0')};
  grid-area: descr;
  align-self: center;

`;
export const GridItem2 = styled.div`
  grid-area: puzl2;
  justify-self: center;
  align-self: center;
`;

export const GridItemPuzzle = styled.div<{ decoration: string }>`
  grid-area: ${({ decoration }) => decoration};
`;

export const GridItem4 = styled.div<{ isMobile: boolean }>`
  transform: ${(props) => (props.isMobile ? 'rotate(60deg)' : 'rotate(0)')} !important;
  grid-area: puzl4;
  justify-self: center;
  align-self: center;
`;
export const GridItem5 = styled.div`
  grid-area: puzl1;
  justify-self: center;
  align-self: center;
`;

export const GridItem8 = styled.div`
  grid-area: puzl3;
  justify-self: start;
  align-self: center;
`;

export const PuzzleImg = styled.img<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '130px' : '180px')};
`;

export const PuzzleText = styled.div`
  font-size: 14px;
`;

export const CourseParagraphTitle = styled.h2`
  color: ${COLORS.urbanBlack};
  margin: 0 0 0.8em 0 !important;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
  max-width: 500px;
`;