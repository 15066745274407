import { useTranslation } from 'react-i18next';

import gameWrongBG from '../../../assets/courses/mentalMath/gameWrongBG.png';
import gameCorrectBG from '../../../assets/courses/mentalMath/gameCorrectBG.png';

import { FinalNumber, FinalStepWrapper } from './style';
import { Button } from '../../UI/Button/index';
import { SECTIONS } from '../../../constants/courses';
import { CursesSections } from 'types/common';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

type Props = {
   correctSum: number,
   isUserAnswerCorrect: boolean,
   closeModal: () => void
}

const FinalStep = ({ correctSum, isUserAnswerCorrect, closeModal }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <FinalStepWrapper isMobile={isMobile}>
         <h1>
            {isUserAnswerCorrect ? t('course.mentalMath.success') : t('course.mentalMath.wrong')}
         </h1>
         <img src={isUserAnswerCorrect ? gameCorrectBG : gameWrongBG} alt="" />
         <FinalNumber isUserAnswerCorrect={isUserAnswerCorrect} isMobile={isMobile}>
            {correctSum}
         </FinalNumber>
         <p>
            {t('course.mentalMath.finalStepDescription')}
         </p>
         <Button
            modifier='primaryOutlined'
            color={SECTIONS.kids as CursesSections}
            onClick={closeModal}>
            {t('course.mentalMath.button')}
         </Button>
      </FinalStepWrapper>
   );
};

export default FinalStep;