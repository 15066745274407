
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NewsTypes } from 'types/courses/news';

import Pagination from '../../../Pagination/Pagination';
import gradientOverlay from '../../../../assets/global/gradienOverlay.png';
import Tag from '../../../Tags/Tag';
import { newsImageGetter } from '../../../../utils/news/newsImageGetter';
import { VerticalSeparator } from '../../../../components/Header/Changer/styled';
import { getAllNews } from '../../../../store/actions/newsActions';
import { selectNews, selectNewsStatus } from '../../../../store/selectors/news';
import NewsLoading from './NewsLoading';
import NewsError from './NewsError';
import ShortInfoBlock from './ShortInfoBlock';
import { newsActionGetter } from '../../../../utils/news/newsActionGetter';

import {
   NewsWrappers,
   NewsWrapper,
   OneNewsOverlay,
   OneSlideNewsWrapper,
   NewsTitleWrapper,
   NewsTopRow,
   NewsBottomRow,
   NewsFooter
} from './styled';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

const News = () => {
   const { t } = useTranslation();
   const [currentPage, setCurrentPage] = useState(0);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const news = useSelector(selectNews)
   const newsStatus = useSelector(selectNewsStatus);
   const totalCount = news.length;
   const hasNext = currentPage < totalCount - 1;
   const hasPrev = currentPage > 0;
   const isLoading = newsStatus === 'loading';
   const isError = newsStatus === 'error';

   const { isMobile } = useMediaQuery();

   useEffect(() => {
      dispatch(getAllNews() as any);
   }, []);

   const renderOneNews = ({ modificationNews, section }: NewsTypes) => {
      return !isError && <OneSlideNewsWrapper onClick={() => newsActionGetter({ newsModification: modificationNews, dispatch, navigate })}>
         <OneNewsOverlay src={newsImageGetter(modificationNews)} />
         <OneNewsOverlay src={gradientOverlay} />
         <div className='wrapper-news-content'>
            <NewsTopRow>
               <Tag name={section} />
            </NewsTopRow>
            <NewsBottomRow>
               <NewsTitleWrapper>{t(`news.${modificationNews}.title`)}</NewsTitleWrapper>
               <p>{t(`news.${modificationNews}.description`)}</p>
               <NewsFooter>
                  <div>{t(`news.${modificationNews}.date`)}</div>
                  <VerticalSeparator />
                  <div>{t(`news.${modificationNews}.actions`)}</div>
               </NewsFooter>
            </NewsBottomRow>
         </div>
      </OneSlideNewsWrapper>
   }

   return (
      <NewsWrappers isMobile={isMobile}>
         <NewsWrapper  isMobile={isMobile}>
            <h1>{t('news.title')}</h1>
            <Pagination
               onNext={() => {
                  setCurrentPage(currentPage + 1);
               }}
               onPrev={() => {
                  setCurrentPage(currentPage - 1);
               }}
               pageSize={1}
               totalCount={totalCount}
               currentPage={currentPage}
               hasNext={hasNext}
               hasPrev={hasPrev}
               withText={false}
            />
         </NewsWrapper>
         {isLoading ? <NewsLoading /> : renderOneNews({
            id: news[currentPage]?.id,
            modificationNews: news[currentPage]?.modificationNews,
            section: news[currentPage]?.section
         })}
         {isError ? <NewsError errorTitle='Something went wrong with data pls try again later' /> : null}
         <ShortInfoBlock />
      </NewsWrappers>
   );
}

export default News;