import { NEWS } from '../../constants/news';
import {labelCourses, filterCourses} from '../../store/actions/appActions';

const newsBookActions = ({ dispatch, navigate }: { dispatch: any, navigate: any}) => {
  dispatch(filterCourses({ filter: 'all' }))
  dispatch(labelCourses({ label: "Youth" }));
  navigate('/courses');
}

const newDirectionShowActions = ({ dispatch, navigate }: { dispatch: any, navigate: any}) => {
  dispatch(filterCourses({ filter: 'all' }));
  dispatch(labelCourses({ label: "Show" }));
  navigate('/courses');
}

export const newsActionGetter = ({newsModification, dispatch, navigate} :{newsModification: string, dispatch: any, navigate: any}) => {
  switch (newsModification) {
    case NEWS.newBooks:
      return newsBookActions({ dispatch, navigate });
    case NEWS.newDirectionShow:
      return newDirectionShowActions({ dispatch, navigate });
    default:
      // eslint-disable-next-line no-console
      return console.log('No action');
  }
}
