import {RecommendedWrapper, RecommendedSection} from './styled';
import BuckleUpProgressio from './Recommended/BuckleUpProgressio';
import EnterAltitudeProgressio from './Recommended/EnterAltitudeProgressio';
import MoveUpwardsProgressio from './Recommended/MoveUpwardsProgressio';
import DirectionProgressio from './Recommended/DirectionProgressio';
import BallanceProgressio from './Recommended/BallanceProgressio';

const RecommendedCourses = () => {
  return (
    <RecommendedSection>
      <h1>Progressio</h1>
      <RecommendedWrapper>
        <BuckleUpProgressio />
        <EnterAltitudeProgressio />
        <MoveUpwardsProgressio />
        <DirectionProgressio />
        <BallanceProgressio />
      </RecommendedWrapper>
    </RecommendedSection>
  );
};

export default RecommendedCourses;
