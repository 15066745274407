
import styled from 'styled-components';

export const PopularCoursesWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow-x: ${({ isMobile }) => isMobile ? 'scroll' : 'unset'};
   width: ${({ isMobile }) => isMobile ? 'calc(100% - 10px)' : '100%'};
   margin-right: 20px;
   margin-top: ${({ isMobile }) => isMobile ? '10px' : '0px'};
`;