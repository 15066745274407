import React from 'react';
import noCoursesInCabinet from '../../../../assets/navigation/cabinet/noCoursesInCabinet.png';
import noCoursesInCabinetMobile from '../../../../assets/navigation/cabinet/noCoursesInCabinetMobile.png';
import { NoCoursesInCabinetWrapper } from './styled';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

const MyEducationNoCourses = () => {
   const { isMobile } = useMediaQuery();
   return (
      <NoCoursesInCabinetWrapper>
         {isMobile ? <img src={noCoursesInCabinetMobile} alt="empty-courses" /> : <img src={noCoursesInCabinet} alt="empty-courses" />}
      </NoCoursesInCabinetWrapper>
   );
};

export default MyEducationNoCourses;