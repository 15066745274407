import styled from 'styled-components';

import { COLORS } from '../../../../../constants/colors';

export const AllWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${props => props.isMobile ? 'column' : 'row'};
   justify-content: space-between;
   gap: 20px;
   color: ${COLORS.white};
`;

export const StrengthsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
   width: 100%;
`;

export const StrengthsItem = styled.div`
   justify-content: space-between;
   align-items: center;
   border-radius: 10px;
   background-color: ${COLORS.eventLab};
   margin: 10px 0;
   padding: 0px 15px;
   font-style: italic;
   font-weight: 500;
   line-height: 1.5;
   padding: 20px 20px;
`;

export const WeaknessesItem = styled(StrengthsItem)`
   background-color: ${COLORS.magenta};
`;

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`