import styled from "styled-components";

import userBorder from '../../../assets/global/userBorder.png'
import { COLORS } from '../../../constants/colors';

export const UserInfoWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`;

export const UserName = styled.div`
   font-size: 14px;
   line-height: 19px;
   font-weight: 700;
   color: ${COLORS.white};
   opacity: 0.5;

   &:hover {
      cursor: pointer;
      opacity: 0.8;
   }
`;

export const DropDownWrapper = styled.div<{isMobile: boolean}>`
   position: absolute;
   margin-top: ${({ isMobile }) => isMobile ? '190px' : '150px'};
   right: 5px;
   display: flex;
   flex-direction: column;
   width: 150px;
   height: ${({ isMobile }) => isMobile ? '130px' : '95px'};
   z-index: 10;
   background-color: ${COLORS.urbanBlack};
   border-radius: 10px;
   border: 1px solid ${COLORS.urbanBlack};
   box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.55);
`;

export const ButtonUserWrapper = styled.div`
   width: auto;
   height: 30px;
   text-align: left;
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 130%;
   text-transform: uppercase;
   align-items: left;
   background: transparent;
   border: 2px solid transparent;
   border-radius: 100px;
   color: ${COLORS.white};
   padding: 5px 10px;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   opacity: 0.5;
   border-color: ${COLORS.white};
   margin: 10px 10px 0 10px;

   &.logout {
      border-color: ${COLORS.magenta};
      color: ${COLORS.magenta};
      opacity: 1;
   }

   &:hover {
     border-color: ${COLORS.white};
     background-color: ${COLORS.white};
     color: ${COLORS.blackLabel};
     opacity: 1;
   }
`;

export const ButtonWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-right: 10px;
   button {
      max-width: ${({ isMobile }) => isMobile ? '40px' : '100px'} ;
      margin-left: 10px;
   }
`;

export const UserWrapper = styled.div`
   width: 44px;
   height: 44px;
   margin-top: 20px;

   &:before {
      background-image: url(${userBorder});
      content: "";
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      right: 5px;
      margin-top: -10px;
      width: 44px;
      height: 44px;
   }
`

export const UserImage = styled.img`
   width: 33px !important;
   height: 33px !important;
   border-radius: 100%;
   margin-top: -5px;
`;
