import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import errorPageNews from '../../../../assets/global/errorPageNews.png';

const NewsError = ({ errorTitle }: { errorTitle: string }) => {
   const { t } = useTranslation();

   return <ErrorPageWrapper>
      <img src={errorPageNews} alt="" />
      <div>
         <h2>{t(errorTitle)}</h2>
         <div className='subtitle'>
            {t('errors.subtitle.news')}
         </div>
      </div>
   </ErrorPageWrapper>
}

export default NewsError;


const ErrorPageWrapper = styled.section`
   text-align: center;

   img {
      width: 400px;
   }

   h2 {
      margin-top: -5px;
   }

   .subtitle {
      margin-top: -10px;
   }
`;

