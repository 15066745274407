import { useTranslation } from 'react-i18next';
import { LectorsTypes } from 'types/courses/courses';

import { LECTORS } from '../../../../constants/courses';
import { getLectorImage } from '../../../../utils/courses/getLectorImage';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   TeamWrapper,
   TeamContainer,
   TeamSubtitle,
   TeamMembersWrapper
} from './styled';

const Team = () => {
   const { t } = useTranslation();
   const lectorsArray = Object.values(LECTORS).filter(
      (lector) => lector !== 'innaCiriac'
         && lector !== 'annaVasiltsova'
         && lector !== 'sergheiMihailov'
         && lector !== 'polinaSozonova'
         && lector !== 'ekaterinaVoronova'
         && lector !== 'vitaliiGrecov'
         && lector !== 'urbanUser'
   );
   const { isMobile } = useMediaQuery();

   return (
      <TeamWrapper isMobile={isMobile}>
         <h1>{t('team.title')}</h1>
         <TeamContainer >
            <TeamSubtitle >
               <h2>{t('team.subtitle')} <span> {t('team.subtitleEndText')} </span> </h2>
            </TeamSubtitle>
            <TeamMembersWrapper>
               {lectorsArray.map((lector) => (
                  <div key={lector} className='member'>
                     <img src={getLectorImage(lector as LectorsTypes)} alt={''} />
                     <div className='memberInfo'>
                        <h3>{t(`lectors.${lector}.name`)}</h3>
                        <p>{t(`lectors.${lector}.position`)}</p>
                     </div>
                  </div>
               ))}
            </TeamMembersWrapper>
         </TeamContainer>
      </TeamWrapper>
   );
};

export default Team;