import { useTranslation } from 'react-i18next';

import { Button } from '../../UI/Button';
import { AssociativeCardObj } from '../AssociativeCard';
import SelectedCard from '../SelectedCard';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   FinishContainer,
   IntroText,
   DescriptionText,
   AnnotationText,
   StepOneWrapper,
   CardsWrapper,
   FinishCardWrapper,
   GlobalStepWrapper,
   BottomOneButtonWrapper
} from "../styled";

type Props = {
   endGame: () => void;
   negativeSelectedCard?: AssociativeCardObj;
   positiveSelectedCard: AssociativeCardObj[];
}

const FinishComponent = ({ endGame, negativeSelectedCard, positiveSelectedCard }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()

   return (
      <GlobalStepWrapper isMobile={isMobile}>
         <FinishContainer isMobile={isMobile}>
            <IntroText isMobile={isMobile}>{t('games.responseFromMind.title')}</IntroText>
            <DescriptionText isMobile={isMobile}>“{t('games.responseFromMind.finish.title')}”</DescriptionText>
            <StepOneWrapper isMobile={isMobile}>{t('games.responseFromMind.finish.negativeCard')}</StepOneWrapper>
            <FinishCardWrapper>
               <SelectedCard
                  width={isMobile ? '230px' : ' 450px'}
                  height={isMobile ? '130px' : ' 250px'}
                  selectedCard={negativeSelectedCard}
               />
            </FinishCardWrapper>
            <StepOneWrapper isMobile={isMobile}>{t('games.responseFromMind.finish.positiveCard')}</StepOneWrapper>
            <CardsWrapper isMobile={isMobile}>
               <SelectedCard width='230px' height='130px' selectedCard={positiveSelectedCard[0]} />
               <SelectedCard width='230px' height='130px' selectedCard={positiveSelectedCard[1]} />
            </CardsWrapper>
            <AnnotationText isMobile={isMobile}>{t('games.responseFromMind.finish.solutionCard')}</AnnotationText>
            <CardsWrapper isMobile={isMobile}>
               <SelectedCard width='230px' height='130px' selectedCard={positiveSelectedCard[2]} />
               <SelectedCard width='230px' height='130px' selectedCard={positiveSelectedCard[3]} />
            </CardsWrapper>
         </FinishContainer>
         <BottomOneButtonWrapper isMobile={isMobile}>
            <Button modifier="primaryOutlined" onClick={endGame}>Выйти</Button>
         </BottomOneButtonWrapper>
      </GlobalStepWrapper>
   );
};

export default FinishComponent;