import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 00px;
  width: 100%;
  flex-wrap: wrap;
`;

export const FilterWrapper = styled.div`
   width: auto;
   height: 30px;
   text-align: left;
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 130%;
   text-transform: uppercase;
   align-items: left;
   background: transparent;
   border: 2px solid transparent;
   border-radius: 100px;
   color: ${COLORS.blackLabel};
   padding: 5px 10px;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   opacity: 0.5;

   &.active {
     background: ${COLORS.blackLabel};
     border-color: ${COLORS.blackLabel};
     color: ${COLORS.white};
     opacity: 1;
   }

   &:hover {
     border-color: ${COLORS.blackLabel};
   }
`;
