import newDirectionShow from '../../assets/news/newDirectionShow.png'
import newBooks from '../../assets/news/newBooks.png'
import { NEWS } from '../../constants/news';

export const newsImageGetter = (newsModification: string): string => {
  switch (newsModification) {
    case NEWS.newBooks:
      return newBooks;
    case NEWS.newDirectionShow:
      return newDirectionShow;
    default:
      return newDirectionShow;
  }
}
