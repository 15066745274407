
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const GamesWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 20px;

   h1 {
      margin: 0;
      padding: ${({ isMobile }) => isMobile ? '10px 0px' : '20px 0'};
      position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
      top: 0px;
      width: 100%;
      background-color: ${COLORS.urbanBaGLight};
      z-index: 2;
   }
`;

export const GameOptions = styled.div`
   display: flex;
   flex-direction: column;

`;

export const OneCardGame = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 10px 20px;
   cursor: pointer;
   background: linear-gradient(180deg, rgba(107, 110, 114, 0.8) 0%, rgba(26, 29, 34, 0.8) 100%);
   border-radius: 8px;
   margin: 10px 0;

   .associative-icon {
      width: 50px;
      height: 40px;
   }

   img {
         width: 40px;
         height: 40px;
      }
       
   .game-wrapper {
      margin: 0px 20px;
      h3 {
            line-height: 20px;
            word-wrap: break-word;
            color: ${COLORS.white};
            overflow: clip;
            margin: 0;
         }

      p {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: ${COLORS.white};
            opacity: 0.5;
            max-width: 200px;
         }

   }
`;