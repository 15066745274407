import { Link } from 'react-router-dom';
import { DirectionsType } from './Directions';
import {
   OneDirectionWrapper,
   DirectionContentCustomBottom,
   DirectionContentBig
} from './styled';
import { Button } from '../../../../components/UI/Button';
import Tag from '../../../../components/Tags/Tag';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { useTranslation } from 'react-i18next';


const OneDirection = ({ title, description, image, modification }: DirectionsType) => {
   const { isMobile, width } = useMediaQuery();
   const { t } = useTranslation();

   return (
      <OneDirectionWrapper isMobile={isMobile}>
         <DirectionContentBig image={image} isMobile={isMobile} width={width}>
            <Tag name={modification} />
            <DirectionContentCustomBottom isMobile={isMobile}>
               <div className='description'>
                  <h1>{title}</h1>
                  <p>{description}</p>
               </div>
               <Link to={`/${modification}`}>
                  <Button modifier='primaryOutlined' color={modification}>{t('directions.about')}</Button>
               </Link>
            </DirectionContentCustomBottom>
         </DirectionContentBig>
      </OneDirectionWrapper>
   );
}

export default OneDirection;