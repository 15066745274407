import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

import logo from '../../../../assets/header/logo-urban.svg';
import closeButtonWhite from '../../../../assets/courses/personalities/closeButtonWhite.png';

const AdminHeader = () => {
   const navigate = useNavigate();

   const handleReturn = () => {
      navigate('/')
   }

   return <HeaderWrapper>
      <img src={logo} alt="" />
      <HeaderLeft>
         <img onClick={handleReturn} className='close' src={closeButtonWhite} alt="" />
      </HeaderLeft>
   </HeaderWrapper>
}

export default AdminHeader;

const HeaderWrapper = styled.div`
   display: flex;
   position: absolute;
   top: 0;
   justify-content: space-between;
   align-items: center;
   padding: 0 20px;
   height: 60px;
   width: 100%;
   background: radial-gradient(100% 57600% at 0% 0%, #121212 0%, #282828 100%);

   img {
      width: 185px;
      height: 42px;
   }

   .close {
      width: 10px;
      height: 10px;
      opacity: 0.5;

      &:hover {
         opacity: 1;
         cursor: pointer;
      }
   }
`;

const HeaderLeft = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 60px;
   color: #fff;
`;

