import { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Card from 'react-animated-3d-card';

import cardCustomArtOne from '../../../assets/directions/cardCustomArtOne.png';
import cardCustomArt from '../../../assets/directions/cardCustomArt.png';
import cardCustomArtTwo from '../../../assets/directions/cardCustomArtTwo.png';
import cardCustomArtThree from '../../../assets/directions/cardCustomArtThree.png';
import cardCustomArtFour from '../../../assets/directions/cardCustomArtFour.png';

import {
   OneCardWrapper,
   WrapperCards
} from './styles';

type OneCardProps = {
   image: string;
}

const Techniques = () => {


   const renderOneCard = ({ image }: OneCardProps) => {
      const [isHidden, setIsHidden] = useState(true);

      return (
         <OneCardWrapper isHidden={isHidden} onMouseEnter={() => setIsHidden(false)} onMouseLeave={() => setIsHidden(true)}>
            <Card
               style={{
                  width: "100%",
                  height: "100%",
                  background: "transparent !important",
                  cursor: "pointer !important",
               }}
            >
               <img className='card_background' src={cardCustomArt} alt="" />
               <img className='card_front' src={image} alt="" />
            </Card>
         </OneCardWrapper >
      )
   }


   return (
      <WrapperCards>
         {[cardCustomArtOne, cardCustomArtTwo, cardCustomArtThree, cardCustomArtFour].map((image) => {
            return renderOneCard({ image })
         })}
      </WrapperCards>
   );
};

export default Techniques;