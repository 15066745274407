import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/UI/Button';
import associativeStepOne from '../../../assets/games/associativeStepOne.png';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   StartContainer,
   IntroText,
   DescriptionText,
   AnnotationText,
   StepOneWrapper,
   AssociativeStepOneImg,
   BottomOneButtonWrapper,
   StepWrapper
} from "../styled";

type Props = {
   startGame: () => void;
}

const StartComponent = ({ startGame }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <StepWrapper isMobile={isMobile}>
         <StartContainer>
            <IntroText isMobile={isMobile}>{t('games.responseFromMind.title')}</IntroText>
            <DescriptionText isMobile={isMobile}>“{t('games.responseFromMind.description')}”</DescriptionText>
            <StepOneWrapper isMobile={isMobile}>{t('games.responseFromMind.steps.stepOne')}</StepOneWrapper>
            <AssociativeStepOneImg isMobile={isMobile} src={associativeStepOne} alt="step one" />
            <AnnotationText isMobile={isMobile}>{t('games.responseFromMind.annotation')}</AnnotationText>
         </StartContainer>
         <BottomOneButtonWrapper isMobile={isMobile}>
            <Button modifier="primaryOutlined" onClick={startGame}>Начать</Button>
         </BottomOneButtonWrapper>
      </StepWrapper>
   );
};

export default StartComponent;