import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {selectCurrentCourse, selectAllNotFilteredCourses, selectPremiumCourses} from '../../../../store/selectors/courses';
import Course from '../../../Courses/Course/Course';
import { OneCourseWrapper } from '../../../Tabs/Courses/styled';
import Pagination from '../../../Pagination/Pagination';
import { COURSES } from '../../../../constants/courses';

import {
   CourseRecommendedWrapper,
   CoursesRecommendedHolder,
   TopRowWrapperRecommended
} from './styled';

const CourseRecommended = () => {
   const { t } = useTranslation();
   const currentCourse = useSelector(selectCurrentCourse);
   const courses = useSelector(selectAllNotFilteredCourses);
   const premiumCourses = useSelector(selectPremiumCourses);
   const course = courses.find((i) => i?.courseModification === currentCourse)
   const premiumCourse = premiumCourses.find((i) => i?.courseModification === currentCourse)
   const currentCourseSection = course?.section || premiumCourse?.section;
   const recommendedCourses = courses.filter((i) => i.section === currentCourseSection && i?.courseModification !== currentCourse)
   const [currentPage, setCurrentPage] = useState(0);
   const totalCount = recommendedCourses.length;
   const hasNext = currentPage < totalCount - 1;
   const hasPrev = currentPage > 0;

   if (currentCourse === COURSES.thematicActivity) {
      recommendedCourses.sort((a, b) => b.id - a.id)
   }

   return (
      <CourseRecommendedWrapper>
         <TopRowWrapperRecommended>
            <h1>
               {t('cabinet.recommendedCourses')}
            </h1>
            <Pagination
               onNext={() => {
                  setCurrentPage(currentPage + 1);
               }}
               onPrev={() => {
                  setCurrentPage(currentPage - 1);
               }}
               pageSize={1}
               totalCount={totalCount}
               currentPage={currentPage}
               hasNext={hasNext}
               hasPrev={hasPrev}
               withText={false}
            />
         </TopRowWrapperRecommended>
         <CoursesRecommendedHolder>
            <OneCourseWrapper key={recommendedCourses[currentPage]?.courseModification}>
               <Course
                  rating={recommendedCourses[currentPage]?.rating}
                  section={recommendedCourses[currentPage]?.section}
                  button={recommendedCourses[currentPage]?.button}
                  courseModification={recommendedCourses[currentPage]?.courseModification}
               />
            </OneCourseWrapper>
         </CoursesRecommendedHolder>
      </CourseRecommendedWrapper>
   );
};

export default CourseRecommended;