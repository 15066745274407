import createMuiTheme from '@material-ui/core/styles/createTheme';
import { DefaultTheme } from 'styled-components';

import {
  createTheme,
  PaletteOptions,
} from '@mui/material';

import { COLORS } from '../constants/colors';


export const breakPointsSettings = {
  xs: 0,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1440,
  xxxl: 1900,
};

export const screenSize = Object.entries(breakPointsSettings).reduce<
  Partial<{ [key in any]: string }>
>((result, [key, value]) => {
  result[key] = `${value}px`;
  return result;
}, {});

export type ColorType = keyof typeof COLORS;

export const widgetCOLORS = {
  darkBlue: '#26292b',
  ligthGray: '#c4c4c4',
  ligthPink: '#f02e60',
  darkGreen: '#22a180',
};

export const theme: DefaultTheme = {
  breakpoints: breakPointsSettings,
  colors: COLORS,
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    inherit: {
      main: '#fff',
    },
  } as PaletteOptions,
};

export const materialTheme = createMuiTheme({
  breakpoints: {
    values: {
      ...breakPointsSettings,
    },
  },
  spacing: 5,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: 'Ubuntu',
  },
  palette: {
    info: {
      main: '#fff',
    },
  } as PaletteOptions,
});




declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    anger: true;
    apple: true;
    steelBlue: true;
    violet: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });


export const themeBtn = createTheme({
  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3'),
  } as PaletteOptions,
});

export const colors = {
  darkGreen: '#1E3A33',
  green: '#30836D',
  lightGreen: '#3DBB9A',
  krayola: '#b73556',
  magenta: '#ED3262',
  yellow: '#B58246',
  lightYellow: '#F8B464',
  darkBlue: '#15191E',
  arsenic: '#383D46',
  marengo: '#6B6E71',
  lightBlue: '#F7F8F9',
  white: '#FFFFFF',
  grey: '#F2F2F2',
  darkGrey: '#1C2025',
  charcoal: '#181C22',
  black: '#000000',
  brightBlue: '#E7EAEC',
  lightGrey: '#F0F3F4',
  midGrey: '#D0D3D4',
  bgBlock: '#1A1E22',
  shark: '#1A1D22',
  balticSea: '#282B30',
  woodSmoke: '#13161B',
};