import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import prepareToSuccesBg from '../../../../assets/courses/previewCourses/prepareToSuccesBg.png';
import { CursesSections } from '../../../../types/common';
import coursePlan from '../../../../assets/courses/prepareToSuccess/coursePlan.png';
import oneLesson from '../../../../assets/courses/prepareToSuccess/oneLesson.png';
import chatDecoration from '../../../../assets/courses/prepareToSuccess/chatDecoration.png';
import olgaStoyan from '../../../../assets/courses/courseUsers/olgaStoyan.png';
import vladislavPoyanschii from '../../../../assets/courses/courseUsers/vladislavPoyanschii.png';
import urbanUser from '../../../../assets/courses/courseUsers/urbanUser.png';
import ContactsMap from '../CommonComponents/ContactsMap';
import CourseFeedback from '../CommonComponents/CourseFeedback';
import { Button } from '../../../UI/Button';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   RestoreWrapper,
   CourseTitle,
   CourseSubtitle,
   CourseFooter,
   CoursePlanWrapper,
   ChatDecorationWrapper,
   ChatTitle,
   ChatSubtitle,
   ButtonWrapper
} from './styled';
import VideoPrepare from './VideoPrepare';

const PrepareToSuccessPartTwo = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   const courseFeedbackMessages = [{
      userImage: urbanUser,
      message: 'Как вам курс, что нового смогли взять для себя?',
      isLector: true
   },
   {
      userImage: olgaStoyan,
      // eslint-disable-next-line max-len
      message: 'Я набралась опыта в наставничестве, в поддержке и помощи людям, хоть у меня не совсем получилось то, что хотелось бы, но все равно это очень ценный опыт. Я очень счастлива, что нашла Урбан, и Урбан нашел меня. ',
   },
   {
      userImage: urbanUser,
      message: 'Как вам организация?',
      isLector: true
   },
   {
      userImage: olgaStoyan,
      // eslint-disable-next-line max-len
      message: 'Это очень хорошая и полезная организация, которая помогает понять себя и стать тем, кем ты являешься. Когда-то я была гадким утенком, а Урбан сделал из меня прекрасного лебедя. Спасибо всем людям в Урбане за мероприятия, за то, что придумывают эти темы и замечательные уроки. Я люблю Урбан и не хочу с ним расставаться. Огромное вам спасибо за то, что вы есть.'
   }]

   const courseFeedbackMessagesVitalii = [{
      userImage: urbanUser,
      message: 'Как вам курс, что нового смогли взять для себя?',
      isLector: true
   },
   {
      userImage: vladislavPoyanschii,
      // eslint-disable-next-line max-len
      message: 'Период кардинального изменения моей жизни начался именно с тех пор, как я впервые переступил порог Урбана. Уже, самый первый урок, дал мне понять всю важность этих курсов. Я стал серьезнее относиться к настоящему, и к своему будущему.',
   },
   {
      userImage: urbanUser,
      message: 'Как вам организация курса, презентации, структура?',
      isLector: true
   },
   {
      userImage: vladislavPoyanschii,
      // eslint-disable-next-line max-len
      message: ' Я по-другому взглянул на окружающий меня мир и изменил свое отношение к нему. Я вырос в своих глазах, и все благодаря еженедельной встрече на курсах. Если первый год это начало роста хороших корней и ствола, а второй год- это разветвление интересов и мыслей, то третий год- это уже появление конкретных плодов, которые радуют не только меня. А ведь хорошей почвой для роста такого дерева является как раз-таки Урбан и "Подготовка к успеху", за что и благодарен всем сотрудникам, которые стали уже близкими людьми.'
   }]

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   const handleCourseRead = () => {
      return `/cabinet/${COURSES.prepareToSuccessPartTwo}/reader`
   }

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={prepareToSuccesBg}
            sectionName={SECTIONS.youth as CursesSections}
            modification={COURSES.prepareToSuccessPartTwo as CoursesModifications}
            button={CoursesButtonTypes.READ}
            buttonModification={'primaryOutlined'}
            customTag={'Free'}
            handleButtonClick={handleCourseRead}
         />
         <VideoPrepare />
         <CourseTitle>
            {t('course.howCourse')}
         </CourseTitle>
         <CoursePlanWrapper isMobile={isMobile}>
            <img src={coursePlan} alt="course plan" />
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.prepareToSuccessPartTwo}.lessonTitle`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.prepareToSuccessPartTwo}.lessonSubtitle`)}
               </p>
            </div>
         </CoursePlanWrapper>
         <CoursePlanWrapper isMobile={isMobile}>
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.prepareToSuccessPartTwo}.lessonTitleTwo`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.prepareToSuccessPartTwo}.lessonSubtitleTwo`)}
               </p>
            </div>
            <img src={oneLesson} alt="course plan" />
         </CoursePlanWrapper>
         <ChatDecorationWrapper isMobile={isMobile}>
            <ChatTitle isMobile={isMobile}>
               {t(`course.${COURSES.prepareToSuccessPartTwo}.itIsFree`)}
            </ChatTitle>
            <ChatSubtitle isMobile={isMobile}>
               {t(`course.${COURSES.prepareToSuccessPartTwo}.itIsFreeSubtitle`)}
            </ChatSubtitle>
            <img src={chatDecoration} alt="" />
            <ButtonWrapper isMobile={isMobile}>
               <Link to={`/cabinet/${COURSES.prepareToSuccessPartTwo}/reader`}>
                  <Button onClick={handleCourseRead} modifier='primaryOutlined'>{t(`course.read`)}</Button>
               </Link>
            </ButtonWrapper>
         </ChatDecorationWrapper>
         <CourseTitle>
            {t('course.feedback')}
         </CourseTitle>
         <CourseSubtitle>
            {t('course.feedbackDescription')}
         </CourseSubtitle>
         <CourseFooter isMobile={isMobile}>
            <CourseFeedback
               userName={'Ольга Стоян'}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessages}
            />
            <CourseFeedback
               userName={'Владислав Поянский'}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessagesVitalii}
            />
         </CourseFooter>
         <ContactsMap handleButtonClick={handleCourseRead} />
      </RestoreWrapper>
   );
};

export default PrepareToSuccessPartTwo;