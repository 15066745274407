import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../UI/Button';
import mainIcon from '../../../assets/navigation/home.svg';
import coursesIcon from '../../../assets/navigation/courses.svg';
import cabinetIcon from '../../../assets/navigation/cabinet.svg';
import backToCabinetIcon from '../../../assets/navigation/back-to-cabinet.svg';

import { changeActiveTab, setCurrentCourse } from '../../../store/actions/appActions';
import { ActiveTabs } from '../../../store/reducers/types';
import { selectActiveTab } from '../../../store/selectors/navigation';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { selectCurrentCourse } from '../../../store/selectors/courses';
import { getCourseVideo } from '../../../utils/courses/getCourseVideo';
import { COURSES } from '../../../constants/courses';
import {
   MobileWrapper,
   MobileItemWrapper,
   MobileButtonsWrapper
} from './styled';


const MobileNavigation = () => {
   const dispatch = useDispatch();
   const activeTab = useSelector(selectActiveTab)
   const { width } = useMediaQuery()
   const currentCourse = useSelector(selectCurrentCourse);
   const courseVideoUrl = getCourseVideo(currentCourse);
   const courseNotSelected = currentCourse === COURSES.prepareToSuccess ||
   currentCourse === COURSES.prepareToSuccessPartTwo ||
   currentCourse === COURSES.fiveDirections ||
   currentCourse === COURSES.relationshipGod ||
   currentCourse === COURSES.familyWithGod ||
   currentCourse === COURSES.creativeRestoration ||
   currentCourse === COURSES.bibleKidsLessons ||
   currentCourse === 'noSelected' ||
   courseVideoUrl === 'notSelected'

   const activeTabSwitcher = (activeTab: ActiveTabs) => {
      dispatch(changeActiveTab({ activeTab }))
   }

   const handleUnsetSelectedCourse = () => {
      dispatch(setCurrentCourse({ courseModification: 'noSelected' }))
   }

   return (
      <MobileWrapper width={width}>
         <MobileButtonsWrapper>
            <MobileItemWrapper>
               <Link to='/'><Button
                  modifier='mobileNavigation'
                  onClick={() => activeTabSwitcher('main')}
                  active={activeTab === 'main'}
                  icon={<img src={mainIcon} />}
                  className='mobile__button'
               ></Button></Link>
            </MobileItemWrapper>
            <MobileItemWrapper>
               <Link to='/courses'> <Button
                  onClick={() => activeTabSwitcher('courses')}
                  modifier='mobileNavigation'
                  active={activeTab === 'courses'}
                  icon={<img src={coursesIcon} />}
                  className='mobile__button'
               ></Button> </Link>
            </MobileItemWrapper>
            <MobileItemWrapper>
               {activeTab === 'cabinet'
                  ? <Button
                     modifier='mobileNavigation'
                     active={true}
                     className='mobile__button'
                     onClick={handleUnsetSelectedCourse}
                     icon={courseNotSelected ? <img src={cabinetIcon} /> : <img src={backToCabinetIcon} />}
                  ></Button>
                  : <Link to={'/cabinet'}> <Button
                     onClick={() => activeTabSwitcher('cabinet')}
                     modifier='mobileNavigation'
                     active={false}
                     icon={<img src={cabinetIcon} />}
                     className='mobile__button'
                  ></Button> </Link>}
            </MobileItemWrapper>
         </MobileButtonsWrapper>
      </MobileWrapper>
   );
};

export default MobileNavigation;