import { NAVIGATION_WIDTH } from '../../../../constants/dimensions';
import { COLORS } from '../../../../constants/colors';
import styled from 'styled-components';

export const CourseSearchWrapper = styled.div<{ isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 20px;
   padding: 15px;
   width: ${({ isMobile }) => isMobile ? 'calc(100vw - 50px)' : `calc(100vw - ${NAVIGATION_WIDTH + 30}px)`};
   margin: 0px 30px 20px 0px;
   background-color: ${COLORS.white};
   border-radius: 10px;
`;

export const SearchInputWrapper = styled.div`
   width: 100%;
   height: 40px;
   border-radius: 5px;
   font-size: 16px;
   font-family: 'Montserrat';
   color: ${COLORS.urbanBlack};
   outline: none;
`;