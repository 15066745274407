import { LogosWrapper } from './styled';
import { CursesSections } from 'types/common';
import { getSectionsLogo } from '../../utils/courses/getSectionsLogo';

type Props = {
   name: CursesSections;
}

const Logo = ({ name }: Props) => {
   return <LogosWrapper src={getSectionsLogo(name)} />
}

export default Logo;