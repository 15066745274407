import noUserSelected from '../../../../assets/global/noUserSelected.png'

import { SubscriptionsUsersWrapper, NotSelectedWrapper, ControlSubtitle } from './styled'


const EmptyPlaceholder = () => {
   return (
      <SubscriptionsUsersWrapper>
         <NotSelectedWrapper>
            <img src={noUserSelected} alt="" />
            <h1>Выберите пользователя</h1>
            <ControlSubtitle>
               Сейчас никто из пользователей не выбран. Для управления пользователем выберети одного из списка слева.
            </ControlSubtitle>
         </NotSelectedWrapper>
      </SubscriptionsUsersWrapper>
   );
};

export default EmptyPlaceholder;