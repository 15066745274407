
import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const EventWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const CourseSubtitle = styled.div`
   color: ${COLORS.urbanBlack};
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   max-width: 500px;
`

export const CourseFooter = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 4rem;
`

