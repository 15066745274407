import { useState } from 'react';

import { RandomNumber, StepWrapper } from './style';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

type Props = {
   setNextStep: () => void,
   arrayOfNumbers: number[]
}

const RandomNumberStep = ({ arrayOfNumbers, setNextStep }: Props) => {
   const [currentNumber, setCurrentNumber] = useState(0);
   const newArrayOfNumbers = [...arrayOfNumbers, 'last']
   const isCurrentNumberLast = newArrayOfNumbers[currentNumber] === 'last'
   const { isMobile } = useMediaQuery();

   if (!isCurrentNumberLast) {
      setTimeout(() => {
         setCurrentNumber(currentNumber + 1)
      }, 1000)
   }

   if (isCurrentNumberLast) {
      setNextStep()
   }

   return (
      <StepWrapper isMobile={isMobile}>
         <RandomNumber isMobile={isMobile}>
            {arrayOfNumbers[currentNumber]}
         </RandomNumber>
      </StepWrapper>
   );
};

export default RandomNumberStep;