import { MouseEvent } from 'react';

import { SVGIconWrapper } from './styled';

export type SVGIconProps = {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
  icon: JSX.Element;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export const SVGIcon = ({ fill, width, height, icon, className, onClick }: SVGIconProps) => {
  return (
    <SVGIconWrapper
      width={width}
      height={height}
      fill={fill}
      className={className}
      onClick={onClick}
    >
      <div className='icon-wrapper'>{icon}</div>
    </SVGIconWrapper>
  );
};
