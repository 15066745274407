import styled from 'styled-components';

import { COLORS } from '../../../../../constants/colors';

export const DescriptionWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   justify-content: space-between;
   flex-direction: ${props => props.isMobile ? 'column' : 'row'};
   gap: 20px;
`;


export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const PersonalityWrapper = styled.div<{ color: string, isMobile: boolean }>`
   background-color: ${({ color }) => `${color}`};
   height: 300px;
   padding: 14px;
   border-radius: 10px;
   text-align: center;
   max-width: ${props => props.isMobile ? '100%' : '300px'};
`;

export const PersonalityProfilePhoto = styled.img`
   width: 132px;
   height: 150px;
   border-radius: 10px;
   text-align: center;
   align-self: center;
`;

export const PersonalityTitle = styled.div`
   font-weight: 700;
   font-size: 16px;
   margin-top: 12px;
   line-height: 141%;
   color: ${COLORS.blackLabel};
   min-height: 45px;
   margin-bottom: -23px;
`;

export const PersonalityDescriptionWrapper = styled.div`
   font-weight: 500;
   font-size: 12px;
   margin-top: 8px;
   line-height: 130%;
   min-height: 50px;
   opacity: 0.9;
   color: ${COLORS.urbanBlack};
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   max-width: 300px;
`;

export const PersonalityDecoration = styled.img`
   position: relative;
   width: 290px;
   height: 200px;
   top: -165px;
   left: -18px;
`;

export const IntroductionWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   padding: 20px;
   flex-direction: column;
   margin-left: ${props => props.isMobile ? '0' : '20px'};
   height: ${props => props.isMobile ? 'auto' : '300px'};
   width: 100%;
   background: linear-gradient(117.19deg, rgba(196, 196, 196, 0.07) 33.03%, rgba(196, 196, 196, 0) 70.44%), ${COLORS.darkUrban};
   border-radius: 10px;
`;

export const IntroductionTag = styled.div`
   font-weight: 700;
   font-size: 12px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   color: ${COLORS.white};
`;

export const IntroductionTitle = styled.h1`
   font-weight: 700;
   font-size: 24px;
   line-height: 130%;
   color: ${COLORS.white};
   margin-top: 8px;

   span {
     margin-left: 10px;
   }
`;

export const IntroductionSubtitle = styled.div`
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   color: ${COLORS.white};
   max-width: 900px;
`;

export const IntroductionQuote = styled.div<{color: string}>`
   border-left: 2px solid ${({ color }) => `${color}`};
   margin-top: 20px;

   p {
      max-width: 700px;
      margin-left: 10px;
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: ${COLORS.white};
   }
`;

export const QuoteAuthor = styled.div<{color: string}>`
   color: ${({ color }) => `${color}`};
   font-style: italic;
   font-weight: 700;
   font-size: 12px;
   line-height: 15px;
   margin-left: 10px;
   margin-top: 10px;
   text-transform: uppercase;
`;

export const DropDownTitle = styled.h1`
   color: ${COLORS.white};
   font-size: 16px;
   font-weight: 700;
`;

export const DropDownSubtitle = styled.div`
   color: ${COLORS.white};
   font-size: 14px;
   font-weight: 500;
   line-height: 130%;
`;