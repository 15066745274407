import { useTranslation } from 'react-i18next';
import { ChangeEvent} from 'react';


import { Button } from '../../UI/Button/index';
import { Input } from '../../UI/Input/Input';
import { SECTIONS } from '../../../constants/courses';
import gameSecondStep from '../../../assets/courses/mentalMath/gameSecondStep.png'

import { StepTitle, StepSubtitle, StepWrapper, InputHolder } from './style';
import { CursesSections } from 'types/common';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

type Props = {
   setNextStep: () => void,
   userAnswer: string,
   setUserAnswer: (e: string) => void
}


const UserInputStep = ({ setNextStep, userAnswer, setUserAnswer }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const onUserChange = (e:ChangeEvent<HTMLInputElement>) => {
      setUserAnswer(e.target.value)
   }

   return (
      <StepWrapper isMobile={isMobile}>
         <StepTitle>
            {t('course.mentalMath.gameSecondStepTitle')}
         </StepTitle>
         <img src={gameSecondStep} alt="" />
         <StepSubtitle isMobile={isMobile}>
            {t('course.mentalMath.gameSecondStepSubtitle')}
         </StepSubtitle>
         <InputHolder>
            <Input
               type='text'
               onChange={onUserChange}
               value={userAnswer}
               placeholder='Введите ответ'
               modifier='secondary'
               color={SECTIONS.kids as CursesSections}
            />
         </InputHolder>
         <Button
            modifier='primaryOutlined'
            color={SECTIONS.kids as CursesSections}
            onClick={setNextStep}>
            {t('course.mentalMath.button')}
         </Button>
      </StepWrapper>
   );
};

export default UserInputStep;