import styled from 'styled-components';

export const PaginatorContainer = styled.div`
   display: flex;
   align-items: center;
   max-width: 100px;
`;


export const PaginatorTextPale = styled.span`
    color: #707070;
`;

export const PaginatorTextBright = styled.span`
   opacity: 1;
   margin-right: 7px;
   color: #fff;
`;

export const PaginatorBtnGroup = styled.div`
   margin-left: auto;
   display: grid;
   grid-auto-flow: column;
   grid-gap: 10px;

   button {
      all: unset;
      background: #3C3C3C;
      border-radius: 60px;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      font-weight: 900;

      &:disabled {
         opacity: 0.5;
         cursor: default;
      }
   }
`;

