import { createSelector } from 'reselect';
import { RootState } from '../configureStore';

const selectBookReducer = (state: RootState) => state.book;

const selectCurrentPage = createSelector(selectBookReducer, (data) => data.currentPage);
const selectLeftMenuState = createSelector(selectBookReducer, (data) => data.isLeftMenuOpen);
const selectIsUserFirstTime = createSelector(selectBookReducer, (data) => data.isUserFirstTime);
const selectCurrentConfig = createSelector(selectBookReducer, (data) => data.currentConfig);

export { selectCurrentPage, selectLeftMenuState, selectIsUserFirstTime, selectCurrentConfig };
