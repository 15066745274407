/* eslint-disable max-len */
import { COURSES } from '../../constants/courses';

type CourseProgram = {
  title: string
  description?: string;
  materials?: string;
}

export const getCourseProgram = (courseModification: string): CourseProgram[] => {
  switch (courseModification) {
    case COURSES.oratorMastery:
      return [{
        title: `course.${COURSES.oratorMastery}.lessons.0.title`,
        description: `course.${COURSES.oratorMastery}.lessons.0.description`,
      }];
    case COURSES.kidsExercises:
      return [
        {
          title: `course.${COURSES.kidsExercises}.lessons.0.title`,
          description: `course.${COURSES.kidsExercises}.lessons.0.description`,
        },
        {
          title: `course.${COURSES.kidsExercises}.lessons.1.title`,
          description: `course.${COURSES.kidsExercises}.lessons.1.description`,
        },
        {
          title: `course.${COURSES.kidsExercises}.lessons.2.title`,
          description: `course.${COURSES.kidsExercises}.lessons.2.description`,
        },
        {
          title: `course.${COURSES.kidsExercises}.lessons.3.title`,
          description: `course.${COURSES.kidsExercises}.lessons.3.description`,
        },
        {
          title: `course.${COURSES.kidsExercises}.lessons.4.title`,
          description: `course.${COURSES.kidsExercises}.lessons.4.description`,
        },
      ];
    case COURSES.parentAndChild:
      return [
        {
          title: `course.${COURSES.parentAndChild}.lessons.0.title`,
          description: `course.${COURSES.parentAndChild}.lessons.0.description`,
        },
        {
          title: `course.${COURSES.parentAndChild}.lessons.1.title`,
          description: `course.${COURSES.parentAndChild}.lessons.1.description`,
        },
        {
          title: `course.${COURSES.parentAndChild}.lessons.2.title`,
          description: `course.${COURSES.parentAndChild}.lessons.2.description`,
        },
        {
          title: `course.${COURSES.parentAndChild}.lessons.3.title`,
          description: `course.${COURSES.parentAndChild}.lessons.3.description`,
        },
        {
          title: `course.${COURSES.parentAndChild}.lessons.4.title`,
          description: `course.${COURSES.parentAndChild}.lessons.4.description`,
        },
      ];
    case COURSES.kidsLessonsPrepare:
      return [
        {
          title: `course.${COURSES.kidsLessonsPrepare}.lessons.0.title`,
          description: `course.${COURSES.kidsLessonsPrepare}.lessons.0.description`,
          materials: 'https://drive.google.com/drive/folders/1cbvmMaNC9CfNZLZJIG9ejsNwN3ocYD1C?usp=share_link',
        },
        {
          title: `course.${COURSES.kidsLessonsPrepare}.lessons.1.title`,
          description: `course.${COURSES.kidsLessonsPrepare}.lessons.1.description`,
          materials: 'https://drive.google.com/drive/folders/1cbvmMaNC9CfNZLZJIG9ejsNwN3ocYD1C?usp=share_link',
        },
        {
          title: `course.${COURSES.kidsLessonsPrepare}.lessons.2.title`,
          description: `course.${COURSES.kidsLessonsPrepare}.lessons.2.description`,
          materials: 'https://drive.google.com/drive/folders/1cbvmMaNC9CfNZLZJIG9ejsNwN3ocYD1C?usp=share_link',
        }
      ];
    case COURSES.audioAscent:
      return [{
        title: `course.${COURSES.audioAscent}.lessons.0.title`,
        description: `course.${COURSES.audioAscent}.lessons.0.description`,
      }];
    case COURSES.audioSand:
      return [{
        title: `course.${COURSES.audioSand}.lessons.0.title`,
        description: `course.${COURSES.audioSand}.lessons.0.description`,
      }];
    case COURSES.audioForest:
      return [{
        title: `course.${COURSES.audioForest}.lessons.0.title`,
        description: `course.${COURSES.audioForest}.lessons.0.description`,
      }];
    case COURSES.workWithNegativeEmotions:
      return [{
        title: `course.${COURSES.workWithNegativeEmotions}.lessons.0.title`,
        description: `course.${COURSES.workWithNegativeEmotions}.lessons.0.description`,
      }];
    case COURSES.sandTherapyTechnique:
      return [{
        title: `course.${COURSES.sandTherapyTechnique}.lessons.0.title`,
        description: `course.${COURSES.sandTherapyTechnique}.lessons.0.description`,
      }];
    case COURSES.internetSecurity:
      return [{
        title: `course.${COURSES.internetSecurity}.lessons.0.title`,
        description: `course.${COURSES.internetSecurity}.lessons.0.description`,
      },
      {
        title: `course.${COURSES.internetSecurity}.lessons.1.title`,
        description: `course.${COURSES.internetSecurity}.lessons.1.description`,
      },
      {
        title: `course.${COURSES.internetSecurity}.lessons.2.title`,
        description: `course.${COURSES.internetSecurity}.lessons.2.description`,
      }];
    case COURSES.puzzleShow:
      return [{
        title: `course.${COURSES.puzzleShow}.lessons.0.title`,
        description: `course.${COURSES.puzzleShow}.lessons.0.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.1.title`,
        description: `course.${COURSES.puzzleShow}.lessons.1.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.2.title`,
        description: `course.${COURSES.puzzleShow}.lessons.3.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.3.title`,
        description: `course.${COURSES.puzzleShow}.lessons.3.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.4.title`,
        description: `course.${COURSES.puzzleShow}.lessons.4.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.5.title`,
        description: `course.${COURSES.puzzleShow}.lessons.5.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.6.title`,
        description: `course.${COURSES.puzzleShow}.lessons.6.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.7.title`,
        description: `course.${COURSES.puzzleShow}.lessons.7.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.8.title`,
        description: `course.${COURSES.puzzleShow}.lessons.8.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.9.title`,
        description: `course.${COURSES.puzzleShow}.lessons.9.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.10.title`,
        description: `course.${COURSES.puzzleShow}.lessons.10.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.11.title`,
        description: `course.${COURSES.puzzleShow}.lessons.11.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.12.title`,
        description: `course.${COURSES.puzzleShow}.lessons.12.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.13.title`,
        description: `course.${COURSES.puzzleShow}.lessons.13.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.14.title`,
        description: `course.${COURSES.puzzleShow}.lessons.14.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.15.title`,
        description: `course.${COURSES.puzzleShow}.lessons.15.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.16.title`,
        description: `course.${COURSES.puzzleShow}.lessons.16.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.17.title`,
        description: `course.${COURSES.puzzleShow}.lessons.17.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.18.title`,
        description: `course.${COURSES.puzzleShow}.lessons.18.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.19.title`,
        description: `course.${COURSES.puzzleShow}.lessons.19.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.20.title`,
        description: `course.${COURSES.puzzleShow}.lessons.20.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.21.title`,
        description: `course.${COURSES.puzzleShow}.lessons.21.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.22.title`,
        description: `course.${COURSES.puzzleShow}.lessons.22.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.23.title`,
        description: `course.${COURSES.puzzleShow}.lessons.23.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.24.title`,
        description: `course.${COURSES.puzzleShow}.lessons.24.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.25.title`,
        description: `course.${COURSES.puzzleShow}.lessons.25.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.26.title`,
        description: `course.${COURSES.puzzleShow}.lessons.26.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.27.title`,
        description: `course.${COURSES.puzzleShow}.lessons.27.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.28.title`,
        description: `course.${COURSES.puzzleShow}.lessons.28.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.29.title`,
        description: `course.${COURSES.puzzleShow}.lessons.29.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.30.title`,
        description: `course.${COURSES.puzzleShow}.lessons.30.description`,
      },
      {
        title: `course.${COURSES.puzzleShow}.lessons.31.title`,
        description: `course.${COURSES.puzzleShow}.lessons.31.description`,
      }];
    case COURSES.familyContext:
      return [{
        title: `course.${COURSES.familyContext}.lessons.0.title`,
        description: `course.${COURSES.familyContext}.lessons.0.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.1.title`,
        description: `course.${COURSES.familyContext}.lessons.1.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.2.title`,
        description: `course.${COURSES.familyContext}.lessons.2.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.3.title`,
        description: `course.${COURSES.familyContext}.lessons.3.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.4.title`,
        description: `course.${COURSES.familyContext}.lessons.4.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.5.title`,
        description: `course.${COURSES.familyContext}.lessons.5.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.6.title`,
        description: `course.${COURSES.familyContext}.lessons.6.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.7.title`,
        description: `course.${COURSES.familyContext}.lessons.7.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.8.title`,
        description: `course.${COURSES.familyContext}.lessons.8.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.9.title`,
        description: `course.${COURSES.familyContext}.lessons.9.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.10.title`,
        description: `course.${COURSES.familyContext}.lessons.10.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.11.title`,
        description: `course.${COURSES.familyContext}.lessons.11.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.12.title`,
        description: `course.${COURSES.familyContext}.lessons.12.description`,
      },
      {
        title: `course.${COURSES.familyContext}.lessons.13.title`,
        description: `course.${COURSES.familyContext}.lessons.13.description`,
      },
      ];
    case COURSES.urbanShow:
      return [{
        title: `course.${COURSES.urbanShow}.lessons.0.title`,
        description: `course.${COURSES.urbanShow}.lessons.0.description`,
      },
      {
        title: `course.${COURSES.urbanShow}.lessons.1.title`,
        description: `course.${COURSES.urbanShow}.lessons.1.description`,
      },]
    case COURSES.thematicActivity:
      return [{
        title: `course.${COURSES.thematicActivity}.lessons.0.title`,
        description: `course.${COURSES.thematicActivity}.lessons.0.description`,
        materials: '/cabinet/gameLab'
      }]
    case COURSES.restoreBrokenPremium:
      return [{
        title: `course.${COURSES.restoreBrokenPremium}.lessons.0.title`,
        description: `course.${COURSES.restoreBrokenPremium}.lessons.0.description`,
      },
      {
        title: `course.${COURSES.restoreBrokenPremium}.lessons.1.title`,
        description: `course.${COURSES.restoreBrokenPremium}.lessons.1.description`,
      },
      {
        title: `course.${COURSES.restoreBrokenPremium}.lessons.2.title`,
        description: `course.${COURSES.restoreBrokenPremium}.lessons.2.description`,
      },
      {
        title: `course.${COURSES.restoreBrokenPremium}.lessons.3.title`,
        description: `course.${COURSES.restoreBrokenPremium}.lessons.3.description`,
      },
      {
        title: `course.${COURSES.restoreBrokenPremium}.lessons.3.title`,
        description: `course.${COURSES.restoreBrokenPremium}.lessons.3.description`,
      }]
    default:
      return [{
        title: 'course.notFound',
      }];
  }
}