import { useSelector } from 'react-redux';
import maib from '../../../assets/global/maib.png';
import successGuy from '../../../assets/global/successGuy.png';
import errorGuy from '../../../assets/global/errorGuy.png';
import Tag from '../../Tags/Tag';
import { Button } from '../../UI/Button';
import { CursesSections } from 'types/common';
import { selectUserPaymentStatus } from '../../../store/selectors/user';


import {
   FirstStepHolderForm,
   FormTitle,
   WaitingTitle,
   SuccessSubtitle,
   ResponseImage,
   InfoToAccess,
   ButtonWrapper
} from '../styled'
import { Link } from 'react-router-dom';

const LastStepMaib = () => {
   const paymentStatus = useSelector(selectUserPaymentStatus);


   return <FirstStepHolderForm>
      <FormTitle>
         {paymentStatus === 'payed' ? <p>Успешная оплата</p> : <p>Оплата не прошла</p>}
         <div className='powered'>
            <div className='powered-text'>Powered by  </div> <img src={maib} alt="" />
         </div>
      </FormTitle>
      <ResponseImage src={paymentStatus === 'payed' ? successGuy : errorGuy} alt="" />
      {paymentStatus === 'payed' ? <WaitingTitle>
         Успешно
      </WaitingTitle> : <WaitingTitle>
         Ошибка
      </WaitingTitle>}
      {paymentStatus === 'payed' && <InfoToAccess>
         <div className='text'>
            Новая секция
         </div>
         <Tag name={'Premium' as CursesSections} customName='По подписке' />
      </InfoToAccess>}
      <ButtonWrapper>
         <Link to='/courses'>
            {paymentStatus === 'payed' && <Button
               modifier='primaryWhiteOutlined'
            >
               Открыть курс
            </Button>}
         </Link>
      </ButtonWrapper>
      {paymentStatus === 'payed' ? <SuccessSubtitle>
         Поздравляем! Теперь у вас безграничный доступ к приобретенному курсу.
         Вы можете просматривать его видеоуроки, читать материалы и выполнять задания в удобное для вас время.
      </SuccessSubtitle> : <SuccessSubtitle>
         К сожалению, ваша оплата не прошла успешно. Пожалуйста, проверьте введенные данные и попробуйте совершить оплату еще раз.
         Если проблема не устранится, обратитесь в службу поддержки платежной системы или курса для получения помощи.
      </SuccessSubtitle>}

   </FirstStepHolderForm>
};

export default LastStepMaib;