import styled from "styled-components";
import { COLORS } from '../../../constants/colors';

export const AdminPageContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
   background-color: ${COLORS.urbanBaGLight};
`;

export const UsersInteractWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   justify-content: space-between;
   margin-top: 20px;
   width: 100%;
`
