import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import fiveDirectionsBG from '../../../../assets/courses/previewCourses/fiveDirectionsBG.png';
import { CursesSections } from '../../../../types/common';
import coursePlan from '../../../../assets/courses/fiveDirections/coursePlan.png';
import oneLesson from '../../../../assets/courses/fiveDirections/oneLesson.png';
import chatDecoration from '../../../../assets/courses/prepareToSuccess/chatDecoration.png';
import elenaContulescu from '../../../../assets/courses/courseUsers/elenaContulescu.png';
import costelAnastasia from '../../../../assets/courses/courseUsers/costelAnastasia.png';
import urbanUser from '../../../../assets/courses/courseUsers/urbanUser.png';
import ContactsMap from '../CommonComponents/ContactsMap';
import CourseFeedback from '../CommonComponents/CourseFeedback';
import { Button } from '../../../UI/Button';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   RestoreWrapper,
   CourseTitle,
   CourseSubtitle,
   CourseFooter,
   CoursePlanWrapper,
   ChatDecorationWrapper,
   ChatTitle,
   ChatSubtitle,
   ButtonWrapper
} from './styled';

const FiveDirections = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   const courseFeedbackMessages = [{
      userImage: urbanUser,
      message: 'Что изменилось в вас после прохождения курса?',
      isLector: true
   },
   {
      userImage: elenaContulescu,
      // eslint-disable-next-line max-len
      message: 'Раньше мои действия были всегда необдуманными, решала все спонтанно и почти всегда неудачно. Теперь я стараюсь прежде всего думать, перед тем как принять решение.',
   },
   {
      userImage: urbanUser,
      message: 'Чему вы научились на курсах?',
      isLector: true
   },
   {
      userImage: elenaContulescu,
      // eslint-disable-next-line max-len
      message: 'Говорить простые слова «Спасибо», когда надо – «Прости» я была слишком гордой для этих слов. Курсы научили меня осознавать важность этих слов и то, как они влияют на мои отношения.'
   }]

   const courseFeedbackMessagesVitalii = [{
      userImage: urbanUser,
      message: 'Что изменилось в вас после прохождения курса? ',
      isLector: true
   },
   {
      userImage: costelAnastasia,
      // eslint-disable-next-line max-len
      message: 'Курс раскрыл полностью мой характер, я узнала себя получше, научилась сдерживаться в ссорах, также курс меня научил быть красивее т.е. хорошо ухаживать за собой, чтоб было приятно мне и другим людям.',
   },
   {
      userImage: urbanUser,
      message: 'Чему вы научились на курсах?',
      isLector: true
   },
   {
      userImage: costelAnastasia,
      // eslint-disable-next-line max-len
      message: 'Я научилась многому, от того, как знакомиться и общаться с людьми, до того, как следить за собой и своим питанием.'
   }]

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   const handleCourseRead = () => {
      return `/cabinet/${COURSES.fiveDirections}/reader`
   }

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={fiveDirectionsBG}
            sectionName={SECTIONS.teens as CursesSections}
            modification={COURSES.fiveDirections as CoursesModifications}
            button={CoursesButtonTypes.READ}
            buttonModification={'primaryOutlined'}
            customTag={'Free'}
            handleButtonClick={handleCourseRead}
         />
         <CourseTitle>
            {t('course.howCourse')}
         </CourseTitle>
         <CoursePlanWrapper isMobile={isMobile}>
            <img src={coursePlan} alt="course plan" />
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.fiveDirections}.lessonTitle`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.fiveDirections}.lessonSubtitle`)}
               </p>
            </div>
         </CoursePlanWrapper>
         <CoursePlanWrapper isMobile={isMobile}>
            <div>
               <CourseTitle>
                  {t(`course.${COURSES.fiveDirections}.lessonTitleTwo`)}
               </CourseTitle>
               <p>
                  {t(`course.${COURSES.fiveDirections}.lessonSubtitleTwo`)}
               </p>
            </div>
            <img src={oneLesson} alt="course plan" />
         </CoursePlanWrapper>
         <ChatDecorationWrapper isMobile={isMobile}>
            <ChatTitle isMobile={isMobile}>
               {t(`course.${COURSES.fiveDirections}.itIsFree`)}
            </ChatTitle>
            <ChatSubtitle isMobile={isMobile}>
               {t(`course.${COURSES.fiveDirections}.itIsFreeSubtitle`)}
            </ChatSubtitle>
            <img src={chatDecoration} alt="" />
            <ButtonWrapper isMobile={isMobile}>
               <Link to={`/cabinet/${COURSES.fiveDirections}/reader`}>
                  <Button onClick={handleCourseRead} modifier='primaryOutlined'>{t(`course.read`)}</Button>
               </Link>
            </ButtonWrapper>
         </ChatDecorationWrapper>
         <CourseTitle>
            {t('course.feedback')}
         </CourseTitle>
         <CourseSubtitle>
            {t('course.feedbackDescription')}
         </CourseSubtitle>
         <CourseFooter isMobile={isMobile}>
            <CourseFeedback
               userName={'Елена Концулеску '}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessages}
            />
            <CourseFeedback
               userName={'Костей Анастасия'}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessagesVitalii}
            />
         </CourseFooter>
         <ContactsMap handleButtonClick={handleCourseRead} />
      </RestoreWrapper>
   );
};

export default FiveDirections;