import { CursesSections } from 'types/common';

import artTherapyLogo from '../../assets/courses/sectionsLogos/artTherapyLogo.svg';
import teensLogo from '../../assets/courses/sectionsLogos/teensLogo.svg';
import eventLabLogo from '../../assets/courses/sectionsLogos/eventLabLogo.svg';
import showLogo from '../../assets/courses/sectionsLogos/showLogo.svg';
import youthLogo from '../../assets/courses/sectionsLogos/youthLogo.svg';
import kidsLogo from '../../assets/courses/sectionsLogos/kidsLogo.svg';
import { SECTIONS } from '../../constants/courses';

export const getSectionsLogo = (section: CursesSections): string => {
  switch (section) {
    case SECTIONS.artTherapy:
      return artTherapyLogo;
    case SECTIONS.kids:
      return kidsLogo;
    case SECTIONS.youth:
      return youthLogo;
    case SECTIONS.teens:
      return teensLogo;
    case SECTIONS.eventLab:
      return eventLabLogo;
    case SECTIONS.show:
      return showLogo;
    default:
      return '';
  }
}
