import { COLORS } from "../../../../constants/colors";
import styled from "styled-components";


export const UserSectionWrapper = styled.div`
   width: 100%;
   margin-top: 60px
   margin-bottom: 50px;
   padding-left: 30px;
   padding-right: 30px;
`;

export const UserSectionTitle = styled.h1`
   font-size: 24px;
   font-weight: 700;
   color: ${COLORS.urbanBlack};
   margin: 0;
`;

export const NotSelectedWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   
   img {
      width: 240px
   }

`

export const UserControlTitle = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   color: ${COLORS.white};
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;

export const UsersWrapper = styled.div`
   background-color: ${COLORS.urbanBlack};
   color: ${COLORS.white};
   padding: 5px 15px;
   margin-top: 20px;
   border-radius: 15px;
   max-height: 500px;
   overflow: scroll;
`;

export const OneUserWrapper = styled.div<{ isSelected: boolean, isAdmin: boolean }>`
   display: flex;
   align-items: center;
   justify-content: space-between;
   opacity: 0.8;
   padding: 0px 10px;
   border-radius: 20px;
   margin-bottom: 10px;
   color: ${({ isSelected }) => isSelected ? COLORS.darkUrban : COLORS.white};
   background: ${({ isSelected }) => isSelected ? COLORS.white : 'transparent'};

   &:hover {
      opacity: ${({ isAdmin }) => isAdmin ? '0.8' : '1'};
      cursor: ${({ isAdmin }) => isAdmin ? 'not-allowed' : 'pointer'};
   }
`;

export const UserImage = styled.img`
   width: 60px;
   height: 60px;
   border-radius: 50%;
   margin-right: 10px;
`;

export const UserInfoWrapper = styled.div< { isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   height: 80px;

   h3 {
      line-height: 0.5;
      margin-top: 22px;
      margin-bottom: 0px;
   }

   span {
      p {
         font-size: ${({ isMobile }) => isMobile ? '12px' : '14px'}
         opacity: 0.5;
      }
   }
`;

export const UserHolder = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   max-width: 380px;
`;

export const UserTagsWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   width: 180px;
   padding: 20px;
`;

export const PremiumCoursesWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 280px;
`;

export const AllPremiumCoursesWrapper = styled.div`
   background-color: ${COLORS.urbanBlack}
   border: 2px dashed ${COLORS.woodSmoke}
   width: 400px;
   height: 400px;
   margin-top: 10px;
   padding: 10px;
   border-radius: 10px;
`

export const SubscriptionsUsersWrapper = styled.div`
   background-color: ${COLORS.urbanBlack};
   color: ${COLORS.white};
   padding: 15px;
   margin-top: 20px;
   border-radius: 15px;
   height: 100%;
   max-width: 500px;
`;

export const SubscriptionsUserSectionWrapper = styled.div< { isMobile: boolean } >`
   width: 100%;
   margin-top: ${({ isMobile }) => isMobile ? '15px' : '60px'};
   margin-bottom: 50px;
   padding-left: 30px;
   padding-right: 30px;
`;

export const ControlWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 100%;
`;

export const ControlSubtitle = styled.span`
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   opacity: 0.5;
`

export const UserInfoRow = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
`

export const UserInfoSubWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   height: 80px;

   h1 {
      line-height: 0.1;
      margin-bottom: 0px;
   }

   span {
      p {
         opacity: 0.5;
      }
   }
`;