import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CoursesModifications } from 'types/courses/courses';

import {selectCurrentCourse, selectAllNotFilteredCourses, selectPremiumCourses} from '../../../../store/selectors/courses';
import { getCourseLector } from '../../../../utils/courses/getCourseLector';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import Tag from '../../../Tags/Tag';
import { CursesSections } from '../../../../types/common';
import { SECTIONS } from '../../../../constants/courses';
import { TagsBlock } from '../../..//Tabs/Courses/CommonComponents/styles';
import {
   LectorImage,
   LectorDescription,
   LectorDescriptionRow,
   VideoInfoWrapper,
   CourseDescriptionWrapper,
   TopRowWrapper,
} from './styled';


type Props = {
   courseModification: CoursesModifications;
}

const CourseDescription = ({ courseModification }: Props) => {
   const { t } = useTranslation()
   const currentCourse = useSelector(selectCurrentCourse);
   const courseLector = getCourseLector(currentCourse);
   const { isMobile } = useMediaQuery();

   const courses = useSelector(selectAllNotFilteredCourses);
   const premiumCourses = useSelector(selectPremiumCourses);
   const course = courses.find((i) => i.courseModification === currentCourse)
   const premiumCourse = premiumCourses.find((i) => i.courseModification === currentCourse)
   const currentCourseSection = course?.section || premiumCourse?.section;


   return (
      <CourseDescriptionWrapper isMobile={isMobile}>
         <TopRowWrapper>
            <LectorDescriptionRow>
               <LectorImage src={courseLector.photo} alt="lector photo" />
               <LectorDescription>
                  <h3>
                     {t(courseLector.name)}
                  </h3>
                  <p>
                     {t(courseLector.position)}
                  </p>
               </LectorDescription>
            </LectorDescriptionRow>
            {!isMobile && <TagsBlock>
               <Tag name={SECTIONS.custom as CursesSections} customName={`course.${courseModification}.tagUnlimited`} />
               <Tag name={SECTIONS.custom as CursesSections} customName={`course.${courseModification}.tagHours`} />
               <Tag name={currentCourseSection as CursesSections} />
            </TagsBlock>}
         </TopRowWrapper>
         <VideoInfoWrapper>
            <h3>
               {t(`course.${courseModification}.title`)}
            </h3>
            <p>
               {t(`course.${courseModification}.description`)}
            </p>
            <p>
               {t(`course.${courseModification}.longDescription`)}
            </p>
         </VideoInfoWrapper>
      </CourseDescriptionWrapper>
   );
};

export default CourseDescription;