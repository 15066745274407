import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const StepWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: left;
   width: 100%;
   height: 100%;
   padding: ${({ isMobile }) => isMobile ? '0px 0px' : '0px 20px'};
   color: ${COLORS.white}
   line-height: 1.2;
   font-size: 16px !important;
   overflow-y: scroll;

   h3 {
      font-weight: 700;
      font-size: 16px;¸
      opacity: 0.5;
      font-style: italic;
   }

`;

export const OneStepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: left;
   width: 100%;
   height: 100%;
   color: ${COLORS.white}
   line-height: 1.0;

   p {
      font-style: italic;
      font-weight: 700;
      opacity: 0.5;
      font-size: 13px;
   }
`;

export const LastStepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: top;
   width: 100%;
   color: ${COLORS.white}
   line-height: 1.0;
   margin-top: 70px;
   height: 150px;

   span {
      width: 150px !important;
      margin-top: 0px;
      margin-left: -125px;
   }

   img {
      text-align: center;
      width: 90px;
      height: 90px;
   }

   div {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 300px;
   }

   p {
      font-style: italic;
      font-weight: 700;
      opacity: 0.5;
      font-size: 13px;
   }
`;

export const InputHolder = styled.div<{ isNotDisplayed?: boolean }>`
   display: ${({ isNotDisplayed }) => isNotDisplayed ? 'none' : 'default'};
   margin-bottom: 4px;
   margin-top: 10px;
   width: auto;
`;

export const BottomRow = styled.div<{ isMobile?: boolean }>`
   position: absolute;
   bottom: 0;
   height: 80px;
   border-top: 1px solid ${COLORS.blackLabel};
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   justify-content: space-between;
   z-index: 1;
   background-color: ${COLORS.courseCardBackground};
   padding-right: ${({ isMobile }) => isMobile ? '10px' : '60px'};

   button {
      width: ${({ isMobile }) => isMobile ? '140px !important' : 'auto'};
   }

   .terms {
      max-width: 200px;
      width: ${({ isMobile }) => isMobile ? '200px' : 'auto'};
   }

   .terms-text {
      font-weight: 500;
      font-size: ${({ isMobile }) => isMobile ? '10px' : '12px'};
      line-height: 10% !important;
      opacity: 0.5;
      text-style: italic;


      .link {
         text-decoration: underline;
      }
   }
`;

export const FirstStepHolderForm = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: ${COLORS.woodSmoke};
   margin-top: 15px;
   margin-bottom: 15px;
   padding: 25px 20px;
   border-radius: 5px;

   .row-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 450px;
      margin-left: 15px;


      .modal-window {
         max-width: 350px;
         text-align: left;
      }
   }
`;

export const InputTitle = styled.div`
   margin-top: 15px;
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;


export const PurchaseInfoWrapper = styled.div`
   background-color: ${COLORS.woodSmoke};
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   margin-top: 15px;
   margin-bottom: 15px;
   padding: 25px 20px;
   

   h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 110%;
      color: ${COLORS.white};
      opacity: 1;
      font-style: normal;
   }

   p {
      font-weight: 500;
      font-size: 13px;
      line-height: 130%;
      opacity: 0.3;
   }
`;

export const InfoHeaderWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;

   .left-side {
      display: flex;
      align-items: center;
      gap: 10px;
   }

   img {
      width: 40px;
      height: 40px;
   }

   span {
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
   }
`;

export const FormTitle = styled.div<{ isMobile?: boolean }>`
   display: flex;
   justify-content: space-between;   
   align-items: center;
   margin-bottom: 10px;

   p {
      font-style: normal;
      font-weight: 700;
      font-size: ${({ isMobile }) => isMobile ? '10px' : '16px'};
      line-height: 130%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
   }

   .powered {
     
      display: flex;
      align-items: center;
      gap: 10px;

      .powered-text {
         font-size: ${({ isMobile }) => isMobile ? '8px' : '12px'};
         opacity: 0.5;
         font-weight: 700;
         font-style: italic;
      }

      img {
         opacity: 1;
      }
   }
`;

export const DoubleInputHolder = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 10px;
   max-width: 500px;
   margin-top: 15px;
`;

export const TermsWrapper = styled.div`
   text-align: center;
   font-weight: 500;
   font-size: 13px;
   line-height: 130%;
   opacity: 0.3;
   margin-top: 20px;
   padding: 0 20px;

   .link {
      text-decoration: underline;
   }
`;


export const ButtonTextHolder = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
   margin-top: 5px;

   .button-text {
      font-weight: 700;
      margin-top: -5px;
   }
`;

export const WaitingTitle = styled.h3`
   font-weight: 700;
   font-size: 16px;
   line-height: 110%;
   color: ${COLORS.white};
   margin-bottom: 10px;
   text-align: center;
   opacity: 1 !important;
`;

export const WaitingSubtitle = styled.p`
   font-weight: 500;
   font-size: 13px;
   line-height: 130%;
   opacity: 0.3;
   margin-bottom: 10px;
   margin-top: 30px;
   text-align: center;
`;

export const SuccessSubtitle = styled.p`
   font-weight: 500;
   font-size: 13px;
   line-height: 130%;
   margin-bottom: 10px;
   margin-top: 30px;
   text-align: center;
   opacity: 1 !important;
`;

export const ResponseImage = styled.img`
   width: 120px;
   margin-bottom: -10px;
   margin-top: -10px;
   align-self: center;   
`;

export const InfoToAccess = styled.div`
   display: flex;
   gap: 10px;
   margin: 0 auto;
   margin-top: 20px;
   border: 2px solid ${COLORS.blackLabel};
   padding: 10px 15px 5px 15px;
   border-radius: 10px;

   .text {
      opacity: 0.5;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 130%;
   }
`;

export const ButtonWrapper = styled.div`
   display: flex;
   justify-content: center;
   margin-top: 20px;

   .button {
      max-width: 200px;
   }
`;


export const InfoHolder = styled.div`
   display: flex;
   justify-content: space-between;
`;

export const LeftSideInfo = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   gap: 10px;
   font-size: ${({ isMobile }) => isMobile ? '10px' : '14px'};
`;

export const RightSideInfo = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   text-align: right;
   gap: 10px;
   font-size: ${({ isMobile }) => isMobile ? '10px' : '14px'};
   opacity: 0.5;
`;