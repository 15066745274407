import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
CoursePlanWrapper,
   PlanTitle,
   OneBlockWrapper,
   BlocksWrapper,
   BlockTitleCoursePlan,
   BlockLeftSide,
   BlockRightSide,
} from './styles';

type OnePlanPart = {
   count: string;
   title: string;
   img: string,
   description: string[];
}

type coursePlan = {
   coursePlan: OnePlanPart[]
}

const CoursePlan = ({ coursePlan }: coursePlan) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()

   return (
      <CoursePlanWrapper>
         <PlanTitle>
            {t('course.plan')}
         </PlanTitle>
         <BlocksWrapper>
            {coursePlan.map(({ count, title, description, img }) => {
               return <OneBlockWrapper key={title} bgImage={img} isMobile={isMobile}>
                  <BlockLeftSide isMobile={isMobile}>
                     <BlockTitleCoursePlan>
                        {t(count)}
                     </BlockTitleCoursePlan>
                     <BlockTitleCoursePlan>
                        {t(title)}
                     </BlockTitleCoursePlan>
                  </BlockLeftSide>
                  <BlockRightSide>
                     <ul>
                        {description.map((i) => {
                           return <ol key={i}>
                              {t(i)}
                           </ol>
                        })}
                     </ul>
                  </BlockRightSide>
               </OneBlockWrapper>
            })}
         </BlocksWrapper>
      </CoursePlanWrapper>
   );
};

export default CoursePlan;