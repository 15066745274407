
import styled from 'styled-components';
import { HEADER_HEIGHT, NAVIGATION_WIDTH } from '../../constants/dimensions';

export const ActiveTabWrapper = styled.div<{isMobile: boolean, width: number}>`
   position: absolute;
   top: ${HEADER_HEIGHT}px;
   left:  ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH }px;
   display: flex;
   flex-direction: column;
   align-items: baseline;
   overflow: ${({ isMobile }) => isMobile ? 'hidden' : 'auto'}};
   width:  ${({ isMobile, width }) => isMobile ? `${width}px` : `calc(100% - ${NAVIGATION_WIDTH}px)`};
   height: calc(100% - ${HEADER_HEIGHT}px);
   overflow: hidden;
`;
