import Course from "./Course/Course";
import { CoursesWrapper } from './styled';
import {OneCourse} from '../../types/courses/courses';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

type Props = {
   coursesConfig: OneCourse[];
}

const Courses = ({ coursesConfig }: Props) => {
   const { isMobile } = useMediaQuery();

   return <CoursesWrapper isMobile={isMobile}>
      {coursesConfig.map((course) => {
         return <Course
            key={course.courseModification}
            rating={course.rating}
            section={course.section}
            button={course.button}
            courseModification={course.courseModification}
         />
      })}
   </CoursesWrapper>
}

export default Courses;