import { useSelector } from 'react-redux';

import { selectLeftMenuState } from '../../../store/selectors/book';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   LeftMenuWrapper,
} from './styled';

type Props = {
   children: React.ReactNode;
}

const LeftMenu = ({ children }: Props) => {
   const isLeftMenuOpened = useSelector(selectLeftMenuState)
   const { isMobile, width } = useMediaQuery();

   return (
      <LeftMenuWrapper isMobile={isMobile} width={width} isOpened={isLeftMenuOpened}>
         {isLeftMenuOpened && <div>
            {children}
         </div>}
      </LeftMenuWrapper>
   );
};

export default LeftMenu;