import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const StepWrapper = styled.div<{ isMobile: boolean }>`
   margin: 20px;
   color: ${COLORS.white};

   img {
      height: ${({ isMobile }) => isMobile ? '90px' : 'auto'};
      width: 100%;
      margin-top: 20px;
   }
`

export const StepTitle = styled.div`
   font-style: normal;
   font-weight: 700;
   line-height: 130%;
   font-size: 24px;
`

export const RandomNumber = styled.h1<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '180px' : '280px'}};
   font-size: ${({ isMobile }) => isMobile ? '120px' : '220px'};
`

export const FinalNumber = styled.h1<{ isUserAnswerCorrect: boolean, isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '90px' : '220px'}};
   margin-bottom: ${({ isMobile }) => isMobile ? '50px' : '210px'}}};
   font-size: ${({ isMobile }) => isMobile ? '120px' : '220px'};
   color: ${({ isUserAnswerCorrect }) => isUserAnswerCorrect ? COLORS.green : COLORS.magenta};
   text-align: center;
`

export const StepSubtitle = styled.div<{ isMobile: boolean }>`
   font-weight: 500;
   font-size: 18px;
   line-height: 130%;
   margin-top: ${({ isMobile }) => isMobile ? '10px' : '50px'}};
   margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '30px'}};
`

export const InputHolder = styled.div`
   margin-bottom: 20px;
`

export const FinalStepWrapper = styled.div<{ isMobile: boolean }>`
   img {
      position: absolute;
      top: ${({ isMobile }) => isMobile ? '150px' : '220px'};
      width: 100%;
      left: 10px;
   }

   p {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
   }
`