import { BookAction, BOOK_ACTION_TYPES } from '../actions/bookActions';

interface BookState {
  currentPage: number;
  isLeftMenuOpen: boolean;
  isUserFirstTime: boolean;
  currentConfig: {
    name: string;
    file: string;
    materials?: string | string[];
    materialsNames?: string[];
    isForDownload?: boolean;
  }[]
}

const initialState: BookState = {
  currentPage: 0,
  isLeftMenuOpen: false,
  isUserFirstTime: true,
  currentConfig: [],
};

export default function book(state = initialState, action: BookAction): BookState {
  switch (action.type) {
    case BOOK_ACTION_TYPES.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
      };
    }

    case BOOK_ACTION_TYPES.SET_LEFT_MENU: {
      return {
        ...state,
        isLeftMenuOpen: action.isLeftMenuOpen,
      };
    }

    case BOOK_ACTION_TYPES.SET_USER_FIRST_TIME: {
      return {
        ...state,
        isUserFirstTime: action.isUserFirstTime,
      };
    }

    case BOOK_ACTION_TYPES.SET_CURRENT_BOOK_CONFIG: {
      return {
        ...state,
        currentConfig: [...action.currentConfig],
      };
    }

    default: {
      return state;
    }
  }
}
