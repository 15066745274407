
import ContentLoader from "react-content-loader"
import { COLORS } from '../../../../constants/colors';

const NewsLoading = () => (
      <ContentLoader
         speed={2}
         height={209}
         backgroundColor={COLORS.loader}
         foregroundColor={COLORS.loaderSecondary}
      >
         <rect x="11" y="4" rx="10" ry="10" width="70" height="27" />
         <rect x="812" y="4" rx="10" ry="10" width="26" height="27" />
         <rect x="11" y="51" rx="7" ry="7" width="827" height="16" />
         <rect x="11" y="76" rx="7" ry="7" width="260" height="16" />
         <rect x="11" y="112" rx="4" ry="4" width="551" height="6" />
         <rect x="11" y="124" rx="7" ry="7" width="580" height="6" />
         <rect x="11" y="141" rx="0" ry="0" width="71" height="18" />
         <rect x="95" y="142" rx="0" ry="0" width="71" height="18" />
      </ContentLoader>
)

export default NewsLoading
