type ValidationType = {
   callback: any,
   old_password: string,
   new_password: string,
   setValidationErrorFields: (value: string[]) => void
}

export const validationPasswordFields = ({
   callback,
   old_password,
   new_password,
   setValidationErrorFields,
}: ValidationType): boolean => {
   const russianSymbolsRegex = /[а-яА-ЯЁё]/;
   
   if (!old_password && !new_password) {
      callback("Please fill fields.", { variant: "warning" });
      setValidationErrorFields(['new_password', 'old_password'])
      return false;
   }

   if (old_password === new_password) {
      callback("Passwords needs be not the same.", { variant: "warning" });
      setValidationErrorFields(['new_password', 'old_password'])
      return false;
   }

   if(old_password?.includes(" ")) {
      callback("Please provide password without spaces", { variant: "warning" });
      setValidationErrorFields(['old_password'])
      return false;
   }

   if(new_password?.includes(" ")) {
      callback("Please provide password without spaces", { variant: "warning" });
      setValidationErrorFields(['new_password'])
      return false;
   }

   if (new_password.length <= 8) {
      callback("New password should be at least 8 characters long.", { variant: "warning" });
      setValidationErrorFields(['new_password'])
      return false;
   }

   if (russianSymbolsRegex.test(old_password)) {
      callback("Password should not contain Russian characters.", { variant: "warning" });
      setValidationErrorFields(['old_password'])
      return false;
   }


   if (russianSymbolsRegex.test(new_password)) {
      callback("Password should not contain Russian characters.", { variant: "warning" });
      setValidationErrorFields(['new_password'])
      return false;
    }

   return true
}