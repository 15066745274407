import successImg from '../../../assets/global/successImg.png';
import { Button } from '../../../components/UI/Button';
import { BottomRow } from '../../../components/InvoiceSteps/styled';

import {
   LastStepWrapper,
   FirstStepHolderForm
} from '../styled'


const SecondStep = () => {
   return <FirstStepHolderForm>
      <LastStepWrapper>
         <img src={successImg} alt="" />
         <div>
            <h1>Спасибо!</h1>
         </div>
         <div>Мы свяжемся с вами, когда наберется группа желающих!</div>
      </LastStepWrapper>
      <BottomRow>
         <Button
            htmlType="submit"
            modifier='primaryOutlined'
         >Готово</Button>
      </BottomRow>
   </FirstStepHolderForm>
};

export default SecondStep;