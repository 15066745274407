import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import { VideoPrepareWrapper, CourseVideoWrapper } from './styled';

const VideoPrepare = () => {
   const { isMobile } = useMediaQuery();
   const { t } = useTranslation();

   return (
      <VideoPrepareWrapper>
         <div className='one-element'>
            <h1>
               {t('course.prepareToSuccess.videoPrepare.title')}
            </h1>
            <div>
               {t('course.prepareToSuccess.videoPrepare.description')}
            </div>
            <CourseVideoWrapper isMobile={isMobile}>
               <div>
                  <iframe
                     src={"https://www.youtube.com/embed/H5xroQJzkdg"}
                     width={isMobile ? 370 : 620}
                     height={isMobile ? "200px" : "370px"}
                     title="YouTube video player"
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen={true}
                  ></iframe>
               </div>
            </CourseVideoWrapper>
         </div>
         <div className='one-element'>
            <CourseVideoWrapper isMobile={isMobile}>
               <div>
                  <iframe
                     src={"https://www.youtube.com/embed/H5xroQJzkdg"}
                     width={isMobile ? 370 : 620}
                     height={isMobile ? "200px" : "370px"}
                     title="YouTube video player"
                     frameBorder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen={true}
                  ></iframe>
               </div>
            </CourseVideoWrapper>
            <h1>
               {t('course.prepareToSuccess.videoPrepare.titleTwo')}
            </h1>
            <div>
               {t('course.prepareToSuccess.videoPrepare.descriptionTwo')}
            </div>
         </div>
      </VideoPrepareWrapper>
   );
};

export default VideoPrepare;