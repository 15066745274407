import errorPage from '../../../../assets/global/404.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PopularCoursesError = ({ errorTitle }: { errorTitle: string }) => {
   const { t } = useTranslation();

   return <ErrorPageWrapper>
      <img src={errorPage} alt="" />
      <div>
         <h2>{t(errorTitle)}</h2>
         <div>
            {t('errors.subtitle.default')}
         </div>
      </div>
   </ErrorPageWrapper>
}

export default PopularCoursesError;


const ErrorPageWrapper = styled.section`
   width: 800px;
   text-align: center;

   img {
      width: 400px;
   }

   h2 {
      margin-top: -5px;
   }
`;

