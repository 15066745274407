import { createSelector } from 'reselect';
import { RootState } from '../configureStore';

const selectCoursesReducer = (state: RootState) => state.app;

const selectAppIsFetching = createSelector(selectCoursesReducer, (data) => data.isFetching);
const selectPopularCourses = createSelector(selectCoursesReducer, (data) => data.popularCourses);
const selectPopularStatus = createSelector(selectCoursesReducer, (data) => data.popularCoursesStatus);
const selectPremiumCourses = createSelector(selectCoursesReducer, (data) => data.premiumCourses);
const selectPremiumStatus = createSelector(selectCoursesReducer, (data) => data.premiumCoursesStatus);
const selectAllCourses = createSelector(selectCoursesReducer, (data) => data.courses);
const selectAllNotFilteredCourses = createSelector(selectCoursesReducer, (data) => data.notFilteredCourses);
const selectCurrentCourse = createSelector(selectCoursesReducer, (data) => data.currentCourseModification);
const selectCurrentVideo = createSelector(selectCoursesReducer, (data) => data.currentCourseVideo);
const selectFilter = createSelector(selectCoursesReducer, (data) => data.filter);
const selectLabel = createSelector(selectCoursesReducer, (data) => data.label);

export {
   selectAppIsFetching,
   selectPopularCourses,
   selectPopularStatus,
   selectAllCourses,
   selectCurrentCourse,
   selectCurrentVideo,
   selectFilter,
   selectLabel,
   selectAllNotFilteredCourses,
   selectPremiumCourses,
   selectPremiumStatus
};
