import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ChangeWrapper, ChangeItem, VerticalSeparator } from './styled';
import { changeTranslation, TranslateType } from '../../../store/actions/translateAction';
import { selectCurrentLanguage } from '../../../store/selectors/translation';
import { selectUserProfile } from '../../../store/selectors/user';
import { updateTranslation } from '../../../store/actions/userActions';

export const Changer = () => {
   const { i18n } = useTranslation();
   const translation = useSelector(selectCurrentLanguage);
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const isUserLoggedIn = !!userProfile?.id;

   const changeLanguage = (lng: TranslateType) => {
      if (isUserLoggedIn) {
         dispatch(updateTranslation({ newLanguage: lng, i18n }) as any)

      } else {
         dispatch(changeTranslation({ newLanguage: lng }));
      }
   };

   return <ChangeWrapper>
      {/* <ChangeItem isLangSelected={translation === 'en'} onClick={() => changeLanguage('en')}>EN</ChangeItem>
      <VerticalSeparator /> */}
      {translation === 'ro' 
      ? <ChangeItem isLangSelected={true} onClick={() => changeLanguage('ru')}>RU</ChangeItem> 
      : <ChangeItem isLangSelected={true} onClick={() => changeLanguage('ro')}> RO</ChangeItem>}

   </ChangeWrapper>
}


