import styled from "styled-components";
import { useState } from "react";
import { Button } from "../../components/UI/Button";
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

type EndInfoProps = {
  resetGame: () => void;
  endGameText?: string;
};

const EndInfo = ({ resetGame, endGameText }: EndInfoProps) => {
  const [isFinished, setIsFinished] = useState(false);
  const gameEndSFX = new Audio("/audio/game-end.wav");
  const { isMobile } = useMediaQuery();

  return (
    <EndGameContainer>
      {isFinished ? (
        <p>Спасибо за игру!</p>
      ) : (
        <>
          <h2>Отлично!</h2>
          <p>{endGameText && 'Вы нашли все совпадающие карты'}.</p>
          <p>Хотели бы вы снова сыграть?</p>
          <EndGameButtonContainer isMobile={isMobile}>
            <Button modifier="primaryOutlined"  onClick={resetGame}>Да</Button>
            <Button
              modifier="primaryOutlined"
              onClick={() => {
                gameEndSFX.play();
                setIsFinished(true);
              }}
            >
              Нет
            </Button>
          </EndGameButtonContainer>
        </>
      )}
    </EndGameContainer>
  );
};

const EndGameContainer = styled.section`
  margin-top: auto;
  text-align: center;
  font-size: 1.3rem;
`;

const EndGameButtonContainer = styled.div<{isMobile:boolean}>`
  width: 70%;
  margin: 3rem auto 0 auto;
  display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
  justify-content: center;
  gap: 1rem;

  button {
    margin-top: 20px;
`;

export default EndInfo;
