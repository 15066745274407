import { LECTORS } from '../../constants/courses';

import volodimirUbeivolc from '../../assets/team/volodimirUbeivolc.png';
import olgaVoronova from '../../assets/team/olgaVoronova.png'
import sergheiMihailov from '../../assets/team/sergheiMihailov.png';
import iuliaUbeivolk from '../../assets/team/iuliaUbeivolk.png';
import veraCrapunarli from '../../assets/team/veraCrapunarli.png';
import sergheiSemaev from '../../assets/team/sergheiSemaev.png';
import vitaliiGrecov from '../../assets/team/vitaliiGrecov.png';
import polinaSozonova from '../../assets/team/polinaSozonova.png';
import annaVasiltsova from '../../assets/team/annaVasiltsova.png';
import ekaterinaVoronova from '../../assets/team/ekaterinaVoronova.png';
import annaUbeivolc from '../../assets/team/annaUbeivolc.png';
import sergheiProcopov from '../../assets/team/sergheiProcopov.png';
import innaCiriac from '../../assets/team/innaCiriac.png';

import { LectorsTypes } from 'types/courses/courses';

export type CourseLector = {
  name: string;
  position: string;
  longDescription: string;
  quote: string;
  experience: string;
  photo?: string;
}

export const getLectorImage = (lector: LectorsTypes): string => {
  switch (lector) {
    case LECTORS.volodimirUbeivolc:
      return volodimirUbeivolc;
    case LECTORS.innaCiriac:
      return innaCiriac;
    case LECTORS.olgaVoronova:
      return olgaVoronova
    case LECTORS.iuliaUbeivolk:
      return iuliaUbeivolk
    case LECTORS.annaUbeivolc:
      return annaUbeivolc
    case LECTORS.ekaterinaVoronova:
      return ekaterinaVoronova
    case LECTORS.annaVasiltsova:
      return annaVasiltsova
    case LECTORS.veraCrapunarli:
      return veraCrapunarli
    case LECTORS.sergheiSemaev:
      return sergheiSemaev
    case LECTORS.sergheiProcopov:
      return sergheiProcopov
    case LECTORS.polinaSozonova:
      return polinaSozonova
    case LECTORS.sergheiMihailov:
      return sergheiMihailov
    case LECTORS.vitaliiGrecov:
      return vitaliiGrecov
    default:
      return volodimirUbeivolc
  }
}