
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const CourseWrapper = styled.div`
   background-color: ${COLORS.courseCardBackground};
   border-radius: 10px;
   width: 250px;
   height: 360px;
   margin-right: 25px;
   padding: 14px;
`;

export const CourseImage = styled.img`
   width: 222px;
   height: 129px;
   border-radius: 10px;
`;

export const CourseTitle = styled.div`
   font-weight: 700;
   font-size: 16px;
   margin-top: 12px;
   line-height: 141%;
   color: ${COLORS.white};
   min-height: 45px;
`;

export const CourseDescription = styled.div`
   font-weight: 500;
   font-size: 12px;
   margin-top: 8px;
   line-height: 130%;
   min-height: 50px;
   opacity: 0.5;
   color: ${COLORS.white};
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
`;

export const CourseFooter = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 8px;
`;

export const CourseInfo = styled.div<{ color: string }>`
   display: flex;
   flex-direction: row;
   margin-bottom: 10px;
   justify-content: space-between;
   svg {
      color: ${props => props.color};
   }
`;

export const CourseActionsWrapper = styled.div<{ isUserLoggedIn: boolean }>`
   display: flex;
   flex-direction: row;
   gap: ${({ isUserLoggedIn }) => (isUserLoggedIn ? '10px' : '0px')};

   .actionButton {
      width: 170px;
   }

   .bookmark {
      width: 40px;

      .icon-bookmark {
         width: 20px;
         height: 20px;
         opacity: 0.5;

      }
      &:hover {
         .icon-bookmark {
            opacity: 1;
         }
      }
   }

   .disabled-link {
      pointer-events: none;
      button {
         .not-disabled-link {
            pointer-events: all;
         }
      }
   }
`;

export const CourseActionsBtn = styled.div<{ isUserLoggedIn: boolean }>`
   display: flex;
   flex-direction: row;
   gap: ${({ isUserLoggedIn }) => (isUserLoggedIn ? '10px' : '0px')};
`;