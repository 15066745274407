import styled, { css } from 'styled-components';
import { colors, screenSize } from '../../styles/theme';

type props = {
  fill?: string;
  width?: number;
  height?: number;
};

export const SVGIconWrapper = styled.div<props>(
  ({ width, height, fill }) => css`
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: ${`${width}px`};

      @media (min-width: ${screenSize.lg}) {
        height: ${`${height}px`};
      }
      svg {
        width: 100%;
        height: 100%;
        fill: ${fill};

        path {
          ${fill && ` fill: ${colors[fill as keyof typeof colors]};`};
        }
      }
    }
  `,
);
