import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tag from '../../Tags/Tag';
import maib from '../../../assets/global/maib.png';
import { CursesSections } from '../../../types/common';
import { Button } from '../../../components/UI/Button';
import { CoursesModifications } from '../../../types/courses/courses';
import { COLORS } from '../../../constants/colors';

import { selectTransactionId } from '../../../store/selectors/user';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   OneStepWrapper,
   InfoHolder,
   BottomRow,
   FirstStepHolderForm,
   FormTitle,
   ButtonTextHolder,
   LeftSideInfo,
   RightSideInfo
} from '../styled'

type Props = {
   handleNextStep: () => void;
   modification?: CursesSections;
   course: CoursesModifications;
}

const FirstStep = ({ handleNextStep, modification, course }: Props) => {
   const [checked, setChecked] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const date = new Date();
   const transactionId = useSelector(selectTransactionId)

   const handleButtonClick = () => {
      if (transactionId === '' || transactionId === undefined) {
         enqueueSnackbar("Ошибка при получении данных", { variant: 'error' })
         return;
      } else {
         window.open(`https://maib.ecommerce.md:443/ecomm01/ClientHandler?trans_id=${transactionId}`, '_blank');
         handleNextStep();
         enqueueSnackbar("Данные об оплате отправлены", { variant: 'success' })
      }
   }

   const isValidTransactionId = !transactionId?.includes('IP');

   return <>
      <FirstStepHolderForm>
         <FormTitle isMobile={isMobile}>
            <p>Информация о покупке</p>

            <div className='powered'>
               <div className='powered-text'>Powered by  </div> <img src={maib} alt="" />
            </div>
         </FormTitle>
         <OneStepWrapper>
            <InfoHolder>
               <LeftSideInfo isMobile={isMobile}>
                  <div>
                     Представитель:
                  </div>
                  <div>
                     Сайт:
                  </div>
                  <div>
                     Сумма:
                  </div>
                  <div>
                     Дата:
                  </div>
                  <div>
                     ID транзакции:
                  </div>
                  <div>
                     Описание:
                  </div>
               </LeftSideInfo>
               <RightSideInfo isMobile={isMobile}>
                  <div>
                     ООО «Начало Жизни»
                  </div>
                  <div>
                     app.urbancenter.md
                  </div>
                  <div>
                     500 MDL
                  </div>
                  <div>
                     {date.getDate()}.{date.getMonth()}.{date.getFullYear()}
                  </div>
                  <div>
                     {isValidTransactionId ? transactionId : <div>
                        ID не найден | Попробуйте еще раз
                     </div>}
                  </div>
                  <div>
                     {t(`course.${course}.title`)}
                  </div>
               </RightSideInfo>
            </InfoHolder>
         </OneStepWrapper>


      </FirstStepHolderForm>
      <BottomRow isMobile={isMobile}>
         <div className='terms'>
            <Checkbox
               color="primary"
               onChange={(e) => setChecked(e.target.checked)}
               checked={checked}
               sx={{
                  color: COLORS.white,
                  '&.Mui-checked': {
                     color: COLORS.magenta,
                  },
               }}

            /> <span className='terms-text'> Согласен с <Link className='link' to={'/urban_terms'}>условиями.</Link> </span>
         </div>
         <Button
            disabled={!checked || transactionId === '' || !isValidTransactionId}
            htmlType="submit"
            modifier='primaryOutlined'
            color={'Magenta' as CursesSections}
            onClick={handleButtonClick}
         > <ButtonTextHolder> <div className='button-text'>Готово</div>
               <Tag name={modification as CursesSections} customName='500 MDL' />
            </ButtonTextHolder></Button>
      </BottomRow>
   </>
};

export default FirstStep;