import { FC, memo } from 'react'
import { Button } from '../../components/UI/Button';
import arrowLeft from '../../assets/global/arrow-left.svg';
import arrowRight from '../../assets/global/arrow-right.svg';

import { PaginatorContainer, PaginatorTextBright, PaginatorTextPale, PaginatorBtnGroup } from './styled';

interface PaginationProps {
    onNext: VoidFunction
    onPrev: VoidFunction
    totalCount: number
    pageSize: number,
    currentPage: number
    hasNext?: boolean
    hasPrev?: boolean
    withText: boolean
}

const Pagination: FC<PaginationProps> = ({
    onPrev,
    onNext,
    totalCount,
    pageSize,
    currentPage,
    hasNext,
    hasPrev,
    withText = true
}) => {
    const min = currentPage * pageSize + 1
    const max = min + pageSize - 1

    return (
        <PaginatorContainer>
            {withText && <span>
                <PaginatorTextBright>{min}-{max}</PaginatorTextBright>
                <PaginatorTextPale>out of {totalCount}</PaginatorTextPale>
            </span>}
            <PaginatorBtnGroup>
                <Button modifier='circle' disabled={!hasPrev} onClick={onPrev}><img src={arrowLeft} /></Button>
                <Button modifier='circle' disabled={!hasNext} onClick={onNext}><img src={arrowRight} /></Button>
            </PaginatorBtnGroup>
        </PaginatorContainer>
    )
}

export default memo(Pagination)