
import { useSelector } from 'react-redux';
import { PROD_API } from '../../../constants/api';

import { selectUserProfile } from '../../../store/selectors/user';

import {
   UserWrapper,
   UserImage
} from './styled'

const User = ({ handleOpenDropdown }: { handleOpenDropdown: () => void }) => {
   const user = useSelector(selectUserProfile)

   return (
      <UserWrapper onClick={handleOpenDropdown}>
         <UserImage src={`${PROD_API}/${user?.profile_image}`} alt="user" />
      </UserWrapper>
   );
};

export default User;