import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';


import { CursesSections } from 'types/common';

import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { selectCurrentConfig, selectCurrentPage } from '../../../store/selectors/book';
import { setCurrentPage, setLeftMenu } from '../../../store/actions/bookActions';
import bookImage from '../../../assets/global/bookImage.svg';

import { selectCurrentCourse, selectAllCourses } from '../../../store/selectors/courses';
import { getCourseColor } from '../../../utils/courses/getCourseColor';

import { BookPlanHolder, BookPlanTitle, OneDescriptionElementWrapper, BookPlanWrapper, Divider } from './styled';


const BookPlan = () => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const currentPage = useSelector(selectCurrentPage)
   const dispatch = useDispatch();
   const currentConfig = useSelector(selectCurrentConfig)


   const handleChangePage = (page: number) => {
      dispatch(setCurrentPage({ currentPage: page }))
      dispatch(setLeftMenu({ isLeftMenuOpen: false }))
   }

   const materials = currentConfig[currentPage].materials;


   const currentCourse = useSelector(selectCurrentCourse);
   const courses = useSelector(selectAllCourses);
   const course = courses.find((i) => i.courseModification === currentCourse)
   const currentCourseSection = course?.section;
   const courseColor = getCourseColor(currentCourseSection as CursesSections)

   const oneProgramElement = (i: { name: string, file?: string }, index: number) => {
      return <>
         <OneDescriptionElementWrapper
            color={courseColor}
            isMobile={isMobile}
            isCurrent={currentPage === index}
            onClick={() => handleChangePage(index)}
            key={i.name}
         >
            {t(i.name)}
         </OneDescriptionElementWrapper>
      </>
   }


   return (
      <BookPlanHolder isMobile={isMobile}>
         <BookPlanTitle isMobile={isMobile}>
            <h1>
               {t('cabinet.courseProgram')}
            </h1>
            <div className='chapters'>
               <img src={bookImage} alt="" />  {currentConfig.length} лекций
            </div>
         </BookPlanTitle>
         <Divider isMobile={isMobile} />
         <BookPlanWrapper isMaterials={!!materials} isMobile={isMobile}>
            {currentConfig.map((i, index) => {
               return oneProgramElement(i, index)
            })}
         </BookPlanWrapper>
        
      </BookPlanHolder>
   );
};

export default BookPlan;