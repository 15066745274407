
import { useTranslation } from "react-i18next";

import BackButton from "../../UI/Button/BackButton";
import DropDown from "../..//UI/DropDown/DropDown";
import useMediaQuery from "../../../utils/breakpoints/useMediaQuery";

import { TermsWrapper, DropDownTitle, DropDownSubtitle } from "./styled";

const TermsPage = () => {
   const { isMobile } = useMediaQuery();
   const { t } = useTranslation();

   return (
      <TermsWrapper>
         <BackButton title='Условия и положения использования сайта' isMobile={isMobile} modifier={'circle'} />
         <div>
            <h2>{t(`terms.description.title`)}</h2>
            <p>{t(`terms.description.description`)}</p>
         </div>
         <DropDown
            contentSmall={<>
               <DropDownTitle>
                  {t(`terms.globalConcepts.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  1. {t(`terms.globalConcepts.pointOne`)}
                  <br />
                  <br />
                  2. {t(`terms.globalConcepts.pointTwo`)}
                  <br />
                  <br />
                  3. {t(`terms.globalConcepts.pointThree`)}
                  <br />
                  <br />
                  4. {t(`terms.globalConcepts.pointFour`)}
                  <br />
                  <br />
                  5. {t(`terms.globalConcepts.pointFive`)}
                  <br />
                  <br />
                  6. {t(`terms.globalConcepts.pointSix`)}
                  <br />
                  <br />
                  7. {t(`terms.globalConcepts.pointSeven`)}
               </DropDownSubtitle>
            </>}
            contentBig={<>
               <DropDownTitle>
                  {t(`terms.globalConcepts.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  1. {t(`terms.globalConcepts.pointOneShort`)}
               </DropDownSubtitle>
            </>}
         />
         <DropDown
            contentSmall={<>
               <DropDownTitle>
                  {t(`terms.confidence.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {t(`terms.confidence.description`)}
                  <br />
                  <br />
                  1. {t(`terms.confidence.pointOne`)}
                  <br />
                  <br />
                  2. {t(`terms.confidence.pointTwo`)}
                  <br />
                  <br />
                  3. {t(`terms.confidence.pointThree`)}
                  <br />
                  <br />
                  4. {t(`terms.confidence.pointFour`)}
                  <br />
                  <br />
                  5. {t(`terms.confidence.pointFive`)}
                  <br />
                  <br />
                  6. {t(`terms.confidence.pointSix`)}
                  <br />
                  <br />
                  7. {t(`terms.confidence.pointSeven`)}
               </DropDownSubtitle>
            </>}
            contentBig={<>
               <DropDownTitle>
                  {t(`terms.confidence.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {t(`terms.confidence.descriptionShort`)}
               </DropDownSubtitle>
            </>}
         />

         <DropDown
            contentSmall={<>
               <DropDownTitle>
                  {t(`terms.return.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {t(`terms.return.description`)}
                  <br />
                  <br />
                  1. {t(`terms.return.pointOne`)}
                  <br />
                  <br />
                  2. {t(`terms.return.pointTwo`)}
                  <br />
                  <br />
                  3. {t(`terms.return.pointThree`)}
                  <br />
                  <br />
                  4. {t(`terms.return.pointFour`)}
               </DropDownSubtitle>
            </>}
            contentBig={<>
               <DropDownTitle>
                  {t(`terms.return.title`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {t(`terms.return.descriptionShort`)}
               </DropDownSubtitle>
            </>}
         />

         <div>
            <h2>{t(`terms.payment.title`)}</h2>
            <p>{t(`terms.payment.description`)}</p>
            <p>{t(`terms.payment.description1`)}</p>
            <p>{t(`terms.payment.description2`)}</p>
         </div>
         <div>
            <h2>{t(`terms.returnPayment.title`)}</h2>
            <p>{t(`terms.returnPayment.description`)}</p>
         </div>
         <div>
            <h2>{t(`terms.protection.title`)}</h2>
            <p>{t(`terms.protection.description`)}</p>
         </div>

         <div>
            <h2>{t(`terms.contactInfo.title`)}</h2>
            <p>{t(`terms.contactInfo.description`)}</p>
         </div>
      </TermsWrapper>
   );
};

export default TermsPage;