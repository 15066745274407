import { createSelector } from 'reselect';
import { RootState } from '../configureStore';

const selectUserReducer = (state: RootState) => state.user;

const selectUserData = createSelector(selectUserReducer, (data) => data.userData);
const selectAuthTokens = createSelector(selectUserReducer, (data) => data.authTokens);
const selectUserProfile = createSelector(selectUserReducer, (data) => data.userProfile);
const selectUsersProfilesData = createSelector(selectUserReducer, (data) => data.usersProfilesData);
const selectAuthError = createSelector(selectUserReducer, (data) => data.authError);
const selectUserPaymentStatus = createSelector(selectUserReducer, (data) => data.paymentStatus);
const selectTransactionId = createSelector(selectUserReducer, (data) => data.transactionId);
const selectStartedCourses = createSelector(selectUserReducer, (data) => data.startedCourses);
const selectStartedCoursesStatus = createSelector(selectUserReducer, (data) => data.startedCoursesStatus);
const selectBookmarkedCoursesStatus = createSelector(selectUserReducer, (data) => data.bookmarkedCoursesStatus);

export {
   selectUserData,
   selectAuthTokens,
   selectUserProfile,
   selectUsersProfilesData,
   selectAuthError,
   selectUserPaymentStatus,
   selectTransactionId,
   selectStartedCourses,
   selectStartedCoursesStatus,
   selectBookmarkedCoursesStatus
};

