
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const NewsWrappers = styled.div<{isMobile: boolean}>`
   display: flex;
   position: relative;
   flex-direction: column;
   margin-top: ${({ isMobile }) => isMobile ? '20px' : '0px'};
   width: ${({ isMobile }) => isMobile ? 'calc(100% - 20px)' : '100%'};
`;

export const NewsWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: ${({ isMobile }) => isMobile ? '20px' : '0px'};
`;

export const OneNewsOverlay = styled.img`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: absolute;
   max-height: 190px;
   width: 100%;
   height: 100%;
   border-radius: 10px;
   object-fit: cover;
`;

export const OneNewsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: 10px;
   z-index: 1;
`;

export const OneSlideNewsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: 10px;
   cursor: pointer;

   .wrapper-news-content {
      z-index: 2;
      margin: 20px;
   }
`;

export const NewsTitleWrapper = styled.div`
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 28px;
   display: flex;
   align-items: flex-end;
   color: ${COLORS.white};
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
`;

export const NewsTopRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`;

export const NewsBottomRow = styled.div`
   justify-content: space-between;
   margin-top: 30px;

   p {
      margin: 0;
      color: ${COLORS.white};
      height: 50px;
      word-break: break-word;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
   }
`;

export const NewsFooter = styled.div`
   display: flex;
   flex-direction: row;
   height: 30px;
   align-items: center;
   color: ${COLORS.white};

   div {
      margin-right: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      opacity: 0.35;
      flex: none;
      order: 2;
      flex-grow: 0;
   }
`;

export const ShortInfoWrapper = styled.div`
   display: grid;
   grid-template-columns: auto auto;
   grid-template-rows: 70px 70px; 
   column-gap: 20px;
   row-gap: 10px;
`;


export const OneShortInfoWrapper = styled.div`
   border-radius: 10px;
   height: 70px;
   min-width: 154px;
   background-color: ${COLORS.courseCardBackground};
   color: ${COLORS.white};
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 20px;

   .number {
      font-weight: 700;
      font-size: 32px;
   }

   .text {
      font-weight: 700;
      font-size: 16px;
      line-height: 130%;
      text-transform: uppercase;
      margin-left: 10px;
   }

   @media (max-width: 1658px) {
      .number {
         font-weight: 700;
         font-size: 22px;
      }

       .text {
         font-weight: 700;
         font-size: 11px;
         line-height: 130%;
         text-transform: uppercase;
         margin-left: 10px;
      }
   }

`;