import { ChangeEvent, useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Input } from '../../UI/Input/Input';
import { CursesSections } from '../../../types/common';
import { Button } from '../../../components/UI/Button';
import { CoursesModifications } from '../../../types/courses/courses';

import {
   OneStepWrapper,
   InputHolder,
   BottomRow,
   FirstStepHolderForm,
   InputTitle
} from '../styled'

type Props = {
   handleNextStep: () => void;
   modification?: CursesSections;
   course?: CoursesModifications;
}

const FirsStep = ({ handleNextStep, modification, course }: Props) => {
   const [userName, setUserName] = useState('');
   const [userPhone, setUserPhone] = useState('');
   const [userEmail, setUserEmail] = useState('');
   const [isFormValid, setIsFormValid] = useState(false);

   const form = useRef();

   useEffect(() => {
      if (userName && userPhone && userEmail) {
         setIsFormValid(true)
         return
      }
      return setIsFormValid(false)
   }, [userName, userPhone, userEmail])

   const sendEmail = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      emailjs.sendForm('service_fd74e9z', 'template_x95jzuo', form.current, 'OaRJGXFEuMjJ6PmpV')
         .then((result) => {
            handleNextStep()
            console.log(result.text);
         }, (error) => {
            console.log(error.text);
         });
   };

   const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUserName(e.target.value)
   }

   const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUserPhone(e.target.value)
   }

   const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUserEmail(e.target.value)
   }

   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   /* @ts-ignore */
   return <FirstStepHolderForm ref={form} onSubmit={sendEmail}>
      <OneStepWrapper>
         <InputTitle>
            Введите имя
         </InputTitle>
         <InputHolder>
            <Input
               type='text'
               onChange={onNameChange}
               value={userName}
               name="user_first_name"
               placeholder='Введите имя'
               modifier='primary'
               color={modification}
            />
         </InputHolder>
         <InputTitle>
            Введите номер телефона
         </InputTitle>
         <InputHolder>
            <Input
               type='text'
               onChange={onPhoneChange}
               value={userPhone}
               name="user_last_name"
               placeholder='Введите номер телефона'
               modifier='primary'
               color={modification}
            />
         </InputHolder>
         <InputTitle>
            Введите email
         </InputTitle>
         <InputHolder>
            <Input
               type='email'
               onChange={onEmailChange}
               value={userEmail}
               placeholder='Введите email'
               name="user_email"
               modifier='primary'
               color={modification}
            />
         </InputHolder>
         <InputHolder isNotDisplayed={true}>
            <Input
               type='course'
               placeholder='Введите email'
               name="course_type"
               defaultValue={course}
            />
         </InputHolder>
      </OneStepWrapper>
      <BottomRow>
         <Button
            disabled={!isFormValid}
            htmlType="submit"
            modifier='primaryOutlined'
            color={modification}
         >Готово</Button>
      </BottomRow>
   </FirstStepHolderForm>
};

export default FirsStep;