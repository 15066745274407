import { Navigate } from 'react-router-dom';
import React from 'react';

interface PrivateRouteProps {
   isAuthenticated: boolean;
   children: React.ReactNode;
}

const ProtectedRoute = ({ isAuthenticated, children }: PrivateRouteProps) => {
   if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
   }

   return <React.Fragment>{children}</React.Fragment>;
}

export default ProtectedRoute;