import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCurrentPersonality } from '../../../../../store/selectors/personality';
import useMediaQuery from '../../../../../utils/breakpoints/useMediaQuery';

import {
   IntroductionWrapper,
   IntroductionTag,
   IntroductionTitle,
   IntroductionSubtitle,
   IntroductionQuote,
   QuoteAuthor,
} from './styled';

type Props = {
   getPersonalityCardColor: () => string;
}

const PersonalityIntroduction = ({ getPersonalityCardColor }: Props) => {
   const { t } = useTranslation();
   const currentPersonality = useSelector(selectCurrentPersonality);
   const {isMobile} = useMediaQuery();

   return (
      <IntroductionWrapper isMobile={isMobile}>
         <IntroductionTag>
            {t('course.personalities.button')}
         </IntroductionTag>
         <IntroductionTitle>
            {t('course.personalities.personality')}
            <span>
               «{t(`personality.${currentPersonality}.title`)}»
            </span>
         </IntroductionTitle>
         <IntroductionSubtitle>
            {t(`personality.${currentPersonality}.subtitle`)}
         </IntroductionSubtitle>
         <IntroductionQuote color={getPersonalityCardColor()}>
            <p>
               {t(`personality.${currentPersonality}.quote`)}
            </p>
            <QuoteAuthor color={getPersonalityCardColor()}>
               {t(`personality.${currentPersonality}.quoteAuthor`)}
            </QuoteAuthor>
         </IntroductionQuote>
      </IntroductionWrapper>
   );
};

export default PersonalityIntroduction;