import { LoadingStatuses } from './types/index';
import { NewsTypes } from '../../types/courses/news';
import { NewsAction, NEWS_ACTION_TYPES } from '../actions/newsActions';

interface NewsState {
  news: NewsTypes[];
  newsStatus: LoadingStatuses;
}

const initialState: NewsState = {
  news: [],
  newsStatus: 'idle',
};

export default function news(state = initialState, action: NewsAction): NewsState {
  switch (action.type) {
    case NEWS_ACTION_TYPES.GET_ALL_NEWS: {
      return {
        ...state,
        news: action.news,
      };
    }

    case NEWS_ACTION_TYPES.SET_POPULAR_NEWS_STATUS: {
      return {
        ...state,
        newsStatus: action.newsStatus,
      };
    }

    default: {
      return state;
    }
  }
}
