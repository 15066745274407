import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const MentalMathWrapper = styled.div`
   margin: 20px;
   color: ${COLORS.white}
`

export const MathTitle = styled.div`
   font-style: normal;
   font-weight: 700;
   line-height: 130%;
   font-size: 24px;
`