import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { XXLG_SCREEN } from '../../../../utils/breakpoints/constants';
import gameIcon from '../../../../assets/games/gameIcon.png';
import associativeIcon from '../../../../assets/games/associativeIcon.png';

import { GamesWrapper, GameOptions, OneCardGame } from './styles';
import { useDispatch } from 'react-redux';
import { setMemoryGameStatus, setAssociationsGameStatus } from '../../../../store/actions/gameActions';

const Games = () => {
   const { t } = useTranslation();
   const { width, isMobile } = useMediaQuery();
   const dispatch = useDispatch();

   const handleOpenMemoryGame = () => {
      dispatch(setMemoryGameStatus({ status: 'playing' }));
   };
   const handleOpenAssociationGame = () => {
      dispatch(setAssociationsGameStatus({ status: 'playing' }));
   };

   const isGamesDisplayed = width > XXLG_SCREEN;

   if (!isGamesDisplayed) {
      return null
   }

   return (
      <GamesWrapper isMobile={isMobile}>
         <h1>{t('games.title')}</h1>
         <GameOptions>
            <OneCardGame onClick={handleOpenMemoryGame}>
               <img src={gameIcon} alt="" />
               <div className='game-wrapper'>
                  <h3>{t('games.oneCardGame.title')}</h3>
                  <p>{t('games.oneCardGame.description')}</p>
               </div>
            </OneCardGame>
            <OneCardGame onClick={handleOpenAssociationGame}>
               <img className='associative-icon' src={associativeIcon} alt="" />
               <div className='game-wrapper'>
                  <h3>{t('games.responseFromMind.title')}</h3>
                  <p>{t('games.responseFromMind.description')}</p>
               </div>
            </OneCardGame>
         </GameOptions>
      </GamesWrapper>
   );
};

export default Games;