
import { useSelector } from 'react-redux';
import { useState } from 'react';

import Filters from '../../../UI/Filters/Filters';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import { CabinetFilters } from '../../../../store/reducers/types';
import CourseBox from '../../../CourseBox/CourseBox';
import Course from '../../../Courses/Course/Course';

import { selectStartedCourses, selectStartedCoursesStatus, selectUserProfile } from '../../../../store/selectors/user';
import { getCourseProgram } from '../../../../utils/courses/getCourseProgram';
import MyEducationLoading from './MyEducationLoading';
import MyEducationNoCourses from './MyEducationNoCourses';
import { selectAllCourses, selectPremiumCourses } from '../../../../store/selectors/courses';

import { CabinetPrivateWrapper, TitleRowWrapper, StartedCoursesWrapper, CabinetTopRow, CabinetBottomRow, BookmarkedCoursesWrapper, MyCoursesWrapper, OneCourseWrapper } from './styled';
import MyEducationNoBookmarks from './MyEducationNoBookmarks';
import { selectCurrentLanguage } from '../../../../store/selectors/translation';

const CabinetPrivate = () => {
   const { isMobile } = useMediaQuery();
   const userStartedCourses = useSelector(selectStartedCourses);
   const userStartedCoursesStatus = useSelector(selectStartedCoursesStatus);
   const isLoadingStartedCourses = userStartedCoursesStatus === 'loading';
   const [activeFilter, setActiveFilter] = useState<CabinetFilters>('started' as CabinetFilters);
   const userProfile = useSelector(selectUserProfile);
   const courses = useSelector(selectAllCourses)
   const currentLanguage = useSelector(selectCurrentLanguage);
   const premiumCourses = useSelector(selectPremiumCourses)
   const selectedLanguage = userProfile?.language || currentLanguage;
   const allCourses = [...courses, ...premiumCourses]
   const userBookmarkedCourses = allCourses?.filter(course => userProfile?.bookmarked_courses?.includes(course.courseModification))
   const userPremiumCourses = premiumCourses.filter(course => {
      return userProfile?.premium_courses.includes(course.courseModification);
   }).filter(course => course.language.includes(selectedLanguage as string));

   const filteredUserStartedCourses = userStartedCourses?.filter(course => {
      const currentCourseProgram = getCourseProgram(course.courseModification)
      const currentLesson = course.currentLesson
      const currentCourseProgramLength = currentCourseProgram.length - 1;
      let currentCourseProgress;

      if (currentCourseProgramLength === 0) {
         currentCourseProgress = 100;
      } else {
         currentCourseProgress = (currentLesson / currentCourseProgramLength) * 100;
      }
      if (activeFilter === 'all') {
         return true
      } else if (activeFilter === 'started') {
         return currentCourseProgress < 100
      } else if (activeFilter === 'finished') {
         return currentCourseProgress === 100
      }
   })


   const handleFilterCourse = (filter: CabinetFilters) => {
      setActiveFilter(filter)
   }

   const renderMyCourses = () => {
         return userPremiumCourses?.map(course => {
            return<OneCourseWrapper key={course.id}>
               <Course
                  rating={course.rating}
                  section={course.section}
                  button={course.button}
                  courseModification={course.courseModification}
               />
            </OneCourseWrapper>
         })
   }

   const renderStartedCourses = () => {
      if (isLoadingStartedCourses) {
         return <MyEducationLoading />
      } else if (filteredUserStartedCourses && filteredUserStartedCourses.length === 0) {
         return <MyEducationNoCourses />
      } else {
         return filteredUserStartedCourses?.map(course => {
            return <CourseBox
               key={course.courseModification}
               courseModification={course.courseModification}
               currentLesson={course.currentLesson}
               button={'Продолжить'}
            />
         })
      }
   }

   const renderBookmarkedCourses = () => {
      if (userBookmarkedCourses && userBookmarkedCourses.length === 0) {
         return <MyEducationNoBookmarks />
      } else {
         return userBookmarkedCourses?.map(course => {
            return <Course
               key={course.courseModification}
               courseModification={course.courseModification}
               button={course.button}
               rating={course.rating}
               section={course.section}
            />
         })
      }
   }

   const filters = [
      {
         label: 'Все',
         onClick: () => handleFilterCourse('all' as CabinetFilters),
         active: activeFilter === 'all'
      },
      {
         label: 'Начатые',
         onClick: () => handleFilterCourse('started' as CabinetFilters),
         active: activeFilter === 'started'
      },
      {
         label: 'Законченные',
         onClick: () => handleFilterCourse('finished' as CabinetFilters),
         active: activeFilter === 'finished'
      }
   ]

   return (
      <CabinetPrivateWrapper isMobile={isMobile}>
         <CabinetTopRow isMobile={isMobile}>
            <div>
               <TitleRowWrapper isMobile={isMobile}>
                  <h1>Мои курсы</h1>
               </TitleRowWrapper>
               <div>
                  <MyCoursesWrapper isMobile={isMobile}>
                     {renderMyCourses()}
                  </MyCoursesWrapper>
               </div>
            </div>
         </CabinetTopRow>
         <CabinetTopRow isMobile={isMobile}>
            <div>
               <TitleRowWrapper isMobile={isMobile}>
                  <h1>Мое обучение</h1>
                  <Filters filters={filters} />
               </TitleRowWrapper>
               <div>
                  <StartedCoursesWrapper isMobile={isMobile}>
                     {renderStartedCourses()}
                  </StartedCoursesWrapper>
               </div>
            </div>
         </CabinetTopRow>
        {/*  <CabinetBottomRow isMobile={isMobile}>
            <TitleRowWrapper isMobile={isMobile}>
               <h1>Мои закладки</h1>
            </TitleRowWrapper>
            <BookmarkedCoursesWrapper isMobile={isMobile}>
               {renderBookmarkedCourses()}
            </BookmarkedCoursesWrapper>
         </CabinetBottomRow> */}
      </CabinetPrivateWrapper>
   );
};

export default CabinetPrivate;