import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { changeActiveTab } from '../../../store/actions/appActions';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import UserInfoEditor from './UserInfoEditor/UserInfoEditor';

import {
   SettingsWrapper,
   UserSettingsRow,
   UserInfoBlock,
   PasswordResetBlock
} from './styled';
import UserPasswordReset from './UserPasswordReset/UserPasswordReset';

const Settings = () => {
   const dispatch = useDispatch();
   const { isMobile } = useMediaQuery()


   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'settings' }))
   }, []);

   return (
      <SettingsWrapper isMobile={isMobile}>
         <UserSettingsRow>
            <UserInfoBlock>
               <UserInfoEditor />
            </UserInfoBlock>
            <PasswordResetBlock>
               <UserPasswordReset />
            </PasswordResetBlock>
         </UserSettingsRow>
         {/* {!isMobile && <SettingsWelcomeImg src={settingsWelcome} alt="" />}
         <WelcomeMessageWrapper isMobile={isMobile}>
            <div style={{
               position: 'relative'
            }}>
               <h1>
                  Привет {user?.name}! ⚡️
               </h1>
               <SubtitleMessage>
                  Панель настроек кабинета в веб-приложении - это интерфейс,
                  предназначенный для управления и настройки параметров,
                  связанных с пользовательскими кабинетами или профилями.
               </SubtitleMessage>
            </div>
         </WelcomeMessageWrapper> */}
      </SettingsWrapper>
   );
};

export default Settings;