import { PersonalityTypes, PersonalitySections } from 'types/common';
import { PersonalityAction, PERSONALITY_ACTION_TYPES } from '../actions/personalityActions';
import { PERSONALITY } from '../../constants/courses';

interface PersonalityState {
  currentPersonality: PersonalityTypes;
  currentPersonalitySection: PersonalitySections;
}

const initialState: PersonalityState = {
  currentPersonality: PERSONALITY.noSelected as PersonalityTypes,
  currentPersonalitySection: 'notSelected',
};

export default function personality(state = initialState, action: PersonalityAction): PersonalityState {
  switch (action.type) {
    case PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY: {
      return {
        ...state,
        currentPersonality: action.currentPersonality,
      };
    }

    case PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY_SECTION: {
      return {
        ...state,
        currentPersonalitySection: action.currentPersonalitySection,
      };
    }

    default: {
      return state;
    }
  }
}
