
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const DirectionsWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 10px;
   min-width: 800px;

   h1 {
      margin: 0;
      padding: ${({ isMobile }) => isMobile ? '10px 0px' : '20px 0'};
      position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
      top: 0px;
      width: 100%;
      background-color: ${COLORS.urbanBaGLight};
      z-index: 2;
   }
`;

export const OneDirectionWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin: 10px 0;
   width: 100%;
   max-width: ${({ isMobile }) => isMobile ? '100%' : '800px'};
`;

export const OneDirectionCustomWrapper = styled.div<{isMobile: boolean, width:number}>`
   display: ${({ isMobile }) => isMobile ? 'block' : 'flex'}};
   flex-direction: row;
   align-items: center;
   margin: 10px 0;
   width: ${({ isMobile, width }) => isMobile ? `${width - 40}px` : '100%'};
   max-width: 800px;
`;

export const OneDirectionBg = styled.img`
   width: 100%;
   height: 100%;
   max-width: 800px;
   max-height: 422px;
   object-fit: cover;
   border-radius: 10px;
`;

export const OneDirectionBgCustom = styled.img`
   width: 100%;
   height: 100%;
   max-width: 522px;
   max-height: 422px;
   object-fit: cover;
   border-radius: 10px;
`;

export const DisclaimerWrapper = styled.div`
   display: flex;
   background-color: ${COLORS.courseCardBackground};
   padding: 16px;
   margin-left: 28px;
   border-radius: 10px;
   max-width: 250px;
   min-height: 211px;

   h3 {
      color: ${COLORS.white};
   }

   p {
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: ${COLORS.white};
      opacity: 0.5;
      margin-bottom: 30px;
   }

   button {
      margin: 0 auto;
      z-index: 1;
   }
`;

export const DirectionContent = styled.div<{ image: string, isMobile:boolean, width:number }>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   background-image: ${({ image }) => `url(${image})`};
   width: ${({ isMobile, width }) => isMobile ? `${width - 40}px` : '522px'};
   border-radius: 10px;
   padding: 20px;
   height: ${({ isMobile }) => isMobile ? '250px' : '210px'};
   background-repeat: no-repeat;
   background-size: cover;

   h1 {
      background-color: transparent;
      color: ${COLORS.white};
      padding: 0;
      position: relative;
      width: 100%;
      z-index: 1;
   }

   p {
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: ${COLORS.white};
      opacity: 0.5;
      margin-bottom: 0px;
   }
`;

export const DirectionContentBig = styled.div<{ image: string, isMobile: boolean, width: number }>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   background-image: ${({ image }) => `url(${image})`};
   width: ${({ isMobile, width }) => isMobile ? `${width - 40}px` : '800px'};
   border-radius: 10px;
   padding: 20px;
   height: ${({ isMobile }) => isMobile ? '270px' : '210px'};
   background-repeat: no-repeat;
   background-size: cover;

   .description {
      height: 100px;
   }


   h1 {
      background-color: transparent;
      color: ${COLORS.white};
      padding: 0;
      position: relative;
      width: 100%;
      z-index: 1;
   }

   p {
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: ${COLORS.white};
      opacity: 0.5;
      margin-bottom: 0px;
      max-width: 400px;
      min-width: 200px;
   }
`;

export const DirectionContentCustomBottom = styled.div<{isMobile:boolean}>`
   max-height: 100px;
   margin-top: 50px;
   display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
   align-items: flex-end;
   justify-content: space-between;
   width: 100%;

   .description-small {
      max-width: 200px;
   }

   button {
      margin-top: 20px;
   }
`;