import { AssociativeCardGameWrapper } from './styled';
import { useState } from 'react';
import { negativeCardsArray, positiveCardsArray } from "./libs/cards";
import { AssociativeCardObj } from './AssociativeCard';
import StartComponent from './Steps/StartComponent';
import StepWithNegativeCards from './Steps/StepWithNegativeCards';
import StepSelectedNegativeCard from './Steps/StepSelectedNegativeCard';
import StepPositiveCardDescription from './Steps/StepPositiveCardDescription';
import StepWithPositiveCards from './Steps/StepWithPositiveCards';
import StepSelectedPositiveCards from './Steps/StepSelectedPositiveCards';
import StepPositiveCardExplanation from './Steps/StepPositiveCardExplanation';
import FinishComponent from './Steps/FinishComponent';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

const AssociativeGame = () => {
   const [isPlaying, setIsPlaying] = useState<boolean>(false);
   const [negativeSelectedCard, setNegativeSelectedCard] = useState<AssociativeCardObj>();
   const [positiveSelectedCard, setPositiveSelectedCard] = useState<AssociativeCardObj[]>([]);
   const [negativeCurrentGameCards, setNegativeCurrentGameCards] = useState<AssociativeCardObj[] | null>(null);
   const [positiveCurrentGameCards, setPositiveCurrentGameCards] = useState<AssociativeCardObj[] | null>([]);
   const negativeCards = negativeCardsArray();
   const positiveCards = positiveCardsArray();
   const [currentStep, setCurrentStep] = useState<number>(0);
   const { isMobile } = useMediaQuery()

   const handleClickNegative = (card: AssociativeCardObj) => {
      if (isPlaying) {
         setNegativeSelectedCard(card);
         setNextStep();
      }
   };


   const setFlippedStatus = (cardIndex: number) => {
      if (positiveCurrentGameCards) {
         if (
            positiveCurrentGameCards[cardIndex].flipped === true
         ) {
            return;
         }

         const newState = positiveCurrentGameCards.map((card, index) => {
            if (index === cardIndex) {
               return { ...card, flipped: true };
            }
            return card;
         });
         setPositiveCurrentGameCards(newState);
      }
   };

   const handleClickPositive = (card: AssociativeCardObj) => {
      if (isPlaying) {
         const newState = positiveSelectedCard;
         newState.push(card);
         setPositiveSelectedCard(newState);
         setFlippedStatus(card.id - 1);

         if (positiveSelectedCard?.length === 2) {
            setTimeout(() => {
               setNextStep();
            }, 1000);
         }
         if (positiveSelectedCard?.length === 4) {
            setTimeout(() => {
               setNextStep();
            }, 1000);
         }
      }
   };

   const setNextStep = () => {
      setCurrentStep(currentStep + 1);
   };

   const setPreviousStep = () => {
      setCurrentStep(currentStep - 1);
      setPositiveSelectedCard([]);
      setPositiveCurrentGameCards(positiveCards);
   };

   const setFlippedCards = () => {
      if (negativeCurrentGameCards) {
         const newFlippedCards = negativeCurrentGameCards;
         for (let i = 0; i < negativeCurrentGameCards.length; i++) {
            setTimeout(() => {
               newFlippedCards[i].flipped = true;
               setNegativeCurrentGameCards([...newFlippedCards]);
            }, 100 * i);
         }
      }
   };


   const resetGame = () => {
      setIsPlaying(false);
      setNegativeSelectedCard(undefined);
      setPositiveSelectedCard([]);
      setNegativeCurrentGameCards(null);
      setPositiveCurrentGameCards([]);
      setCurrentStep(0);
   };

   const steps = [{
      description: 'games.responseFromMind.steps.stepOne',
      component: <StepWithNegativeCards
         cards={negativeCurrentGameCards}
         handleClick={handleClickNegative}
         setNextStep={setNextStep}
         setFlippedCards={setFlippedCards}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepTwo',
      component: <StepSelectedNegativeCard
         selectedCard={negativeSelectedCard}
         setPreviousStep={setPreviousStep}
         setNextStep={setNextStep}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepThree',
      component: <StepPositiveCardDescription
         setNextStep={setNextStep}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepThree',
      component: <StepWithPositiveCards
         cards={positiveCurrentGameCards}
         handleClick={handleClickPositive}
         setNextStep={setNextStep}
         setFlippedCards={setFlippedCards}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepTwo',
      component: <StepSelectedPositiveCards
         selectedCard={positiveSelectedCard}
         setPreviousStep={setPreviousStep}
         setNextStep={setNextStep}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepThree',
      component: <StepPositiveCardExplanation
         setNextStep={setNextStep}
      />,
   },{
      description: 'games.responseFromMind.steps.stepThree',
      component: <StepWithPositiveCards
         cards={positiveCurrentGameCards}
         handleClick={handleClickPositive}
         setNextStep={setNextStep}
         setFlippedCards={setFlippedCards}
      />,
   }, {
      description: 'games.responseFromMind.steps.stepThree',
      component: <FinishComponent
         endGame={resetGame}
         negativeSelectedCard={negativeSelectedCard}
         positiveSelectedCard={positiveSelectedCard}
      />,
   }]

   const startGame = () => {
      setNegativeCurrentGameCards(negativeCards);
      setPositiveCurrentGameCards(positiveCards);
      setIsPlaying(true);
   };

   return (
      <AssociativeCardGameWrapper isMobile={isMobile}>
         {isPlaying ? <div> {steps[currentStep].component} </div> : <StartComponent startGame={startGame} />}
      </AssociativeCardGameWrapper>
   );
};

export default AssociativeGame;