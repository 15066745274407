import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const StepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: left;
   width: 100%;
   height: 100%;
   padding: 0 20px;
   color: ${COLORS.white}
   line-height: 1.2;
   font-size: 16px !important;

   h3 {
      font-weight: 700;
      font-size: 16px;
      opacity: 0.5;
      font-style: italic;
   }

`;

export const OneStepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: left;
   width: 100%;
   height: 100%;
   color: ${COLORS.white}
   line-height: 1.0;

   p {
      font-style: italic;
      font-weight: 700;
      opacity: 0.5;
      font-size: 13px;
   }
`;

export const LastStepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: top;
   width: 100%;
   height: 100%;
   color: ${COLORS.white}
   line-height: 1.0;
   margin-top: 70px;

   img {
      text-align: center;
      width: 90px;
      height: 90px;
   }

   div {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 300px;
   }

   p {
      font-style: italic;
      font-weight: 700;
      opacity: 0.5;
      font-size: 13px;
   }
`;

export const InputHolder = styled.div<{ isNotDisplayed?: boolean }>`
   display: ${({ isNotDisplayed }) => isNotDisplayed ? 'none' : 'default'};
   margin-bottom: 4px;
   margin-top: 10px;
   width: auto;
`;

export const BottomRow = styled.div`
   position: absolute;
   bottom: 0;
   height: 80px;
   border-top: 1px solid ${COLORS.blackLabel};
   width: 87%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   justify-content: space-between;
`;

export const FirstStepHolderForm = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
`;

export const InputTitle = styled.div`
   margin-top: 10px;
   font-weight: 700;
   font-size: 11px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
`;