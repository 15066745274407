import { useTranslation } from 'react-i18next';

import { CursesSections } from 'types/common';

import { CourseLector } from '../../../../utils/courses/getCourseLector';
import Tag from '../../../Tags/Tag';
import { SECTIONS } from '../../../../constants/courses';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   LectorWrapper,
   BlockTitle,
   BlockSubtitle,
   LectorImage,
   LeftBlockHolder,
   RightBlockHolder,
   TagsWrapper,
   BlockDescription
} from './styles';

type Props = {
   lector: CourseLector,
   tags: string[]
}

const Lector = ({ lector, tags }: Props) => {

   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <LectorWrapper isMobile={isMobile}>
         <LeftBlockHolder>
            <div>
               <div>
                  <BlockTitle>
                     {t(`${lector.name}`)}
                  </BlockTitle>
                  <BlockSubtitle>
                     {t(`${lector.position}`)}
                  </BlockSubtitle>
               </div>

            </div>
            <BlockDescription>
               {t(`${lector.longDescription}`)}
            </BlockDescription>
         </LeftBlockHolder>
         <RightBlockHolder isMobile={isMobile}>
            <TagsWrapper isMobile={isMobile}>
               {tags.map((tag) => {
                  return <Tag key={tag} customName={tag} name={SECTIONS.custom as CursesSections} />
               })}
            </TagsWrapper>
            {lector.photo && <LectorImage src={lector.photo} alt='lector' />}
         </RightBlockHolder>
      </LectorWrapper>
   );
};

export default Lector;