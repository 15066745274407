import { PERSONALITY } from '../../constants/courses';
import { PersonalityTypes } from '../../types/common';

import strategic from '../../assets/courses/personalities/strategic.png'
import logician from '../../assets/courses/personalities/logician.png'
import commander from '../../assets/courses/personalities/commander.png'
import debater from '../../assets/courses/personalities/debater.png'
import advocate from '../../assets/courses/personalities/advocate.png'
import mediator from '../../assets/courses/personalities/mediator.png'
import protagonist from '../../assets/courses/personalities/protagonist.png'
import campaigner from '../../assets/courses/personalities/campaigner.png'
import logistician from '../../assets/courses/personalities/logistician.png'
import defender from '../../assets/courses/personalities/defender.png'
import executive from '../../assets/courses/personalities/executive.png'
import consul from '../../assets/courses/personalities/consul.png'
import virtuoso from '../../assets/courses/personalities/virtuoso.png'
import adventurer from '../../assets/courses/personalities/adventurer.png'
import entrepreneur from '../../assets/courses/personalities/entrepreneur.png'
import entertainer from '../../assets/courses/personalities/entertainer.png'

export const getPersonalitiesAvatar = (personality: PersonalityTypes): string => {
  switch (personality) {
    case PERSONALITY.strategic:
      return strategic;
    case PERSONALITY.logician:
      return logician;
    case PERSONALITY.commander:
      return commander;
    case PERSONALITY.debater:
      return debater;
    case PERSONALITY.advocate:
      return advocate;
    case PERSONALITY.mediator:
      return mediator;
    case PERSONALITY.protagonist:
      return protagonist;
    case PERSONALITY.campaigner:
      return campaigner;
    case PERSONALITY.logistician:
      return logistician;
    case PERSONALITY.defender:
      return defender;
    case PERSONALITY.executive:
      return executive;
    case PERSONALITY.consul:
      return consul;
    case PERSONALITY.virtuoso:
      return virtuoso;
    case PERSONALITY.adventurer:
      return adventurer;
    case PERSONALITY.entrepreneur:
      return entrepreneur;
    case PERSONALITY.entertainer:
      return entertainer;
    default:
      return strategic;
  }
}
