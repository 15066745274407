import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const NavigationLabelsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   gap: 10px;
   align-items: flex-start;
   margin: 15px;
`;

export const HashTag = styled.div`
   font-weight: 700;
   font-size: 12px;
   line-height: 15px;
   align-items: center;
   text-align: center;
   letter-spacing: -0.017em;
   color: ${COLORS.white};
   opacity: 0.3;
   margin-top: 7px;
`;

export const HashTagWrapper = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
   margin-bottom: 15px;
   height: 15px;

   img {
      width: 8px;
      margin-left: 10px;
      cursor: pointer;
      opacity: 0.3;

      &:hover {
         opacity: 0.7;
      }
   }
`