import { useState } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyIcon from '@mui/icons-material/Key';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Tag from '../../Tags/Tag';
import maib from '../../../assets/global/maib.png';
import { Input } from '../../UI/Input/Input';
import { CursesSections } from '../../../types/common';
import { Button } from '../../../components/UI/Button';
import { CoursesModifications } from '../../../types/courses/courses';
import { validationFields } from './utils';
import { COLORS } from '../../../constants/colors';

import {
   OneStepWrapper,
   InputHolder,
   BottomRow,
   FirstStepHolderForm,
   InputTitle,
   FormTitle,
   ButtonTextHolder,
   DoubleInputHolder
} from '../styled'
import { setPaymentStatus } from '../../../store/actions/userActions';


type Props = {
   handleNextStep: () => void;
   modification?: CursesSections;
   course: CoursesModifications;
}

const FirstStep = ({ handleNextStep, modification, course }: Props) => {
   const [checked, setChecked] = useState(false);
   const [validationErrorFields, setValidationErrorFields] = useState<string[]>([])
   const { enqueueSnackbar } = useSnackbar();
   const dispatch = useDispatch();

   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { user_name, cvv, date, card_number } = e.target as HTMLFormElement;

      if (!validationFields({
         callback: enqueueSnackbar,
         user_name: user_name.value,
         cvv: cvv.value,
         date: date.value,
         card_number: card_number.value,
         setValidationErrorFields
      })) { 
         return;
      }
      dispatch(setPaymentStatus({paymentStatus: 'loading'}))
      handleNextStep();
      enqueueSnackbar("Данные об оплате отправлены", { variant: 'success' })
   }

   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   /* @ts-ignore */
   return <FirstStepHolderForm onSubmit={handleOnSubmit}>
      <FormTitle>
         <p>Покупка картой</p>

         <div className='powered'>
            <div className='powered-text'>Powered by  </div> <img src={maib} alt="" />
         </div>
      </FormTitle>
      <OneStepWrapper>
         <InputHolder>
            <Input
               type='number'
               name="card_number"
               placeholder='1234 1234 1234 1234 1234'
               modifier='primary'
               isError={validationErrorFields.includes('card_number')}
               color={modification}
               icon={<CreditCardIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
            />
            <DoubleInputHolder>
               <Input
                  type='number'
                  name="date"
                  placeholder='MM/YY'
                  isError={validationErrorFields.includes('date')}
                  modifier='primary'
                  color={modification}
                  icon={<CalendarMonthIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
               <Input
                  type='number'
                  name="cvv"
                  placeholder='CVV'
                  modifier='primary'
                  color={modification}
                  isError={validationErrorFields.includes('cvv')}
                  icon={<KeyIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
            </DoubleInputHolder>
         </InputHolder>
         <InputTitle>
            Введите имя владельца карты
         </InputTitle>
         <InputHolder>
            <Input
               type='text'
               name="user_name"
               placeholder='Введите имя владельца карты'
               modifier='primary'
               isError={validationErrorFields.includes('user_name')}
               color={modification}
               icon={<PersonIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
            />
         </InputHolder>
      </OneStepWrapper>

      <BottomRow>
         <div className='terms'>
            <Checkbox
               color="primary"
               onChange={(e) => setChecked(e.target.checked)}
               checked={checked}
               sx={{
                  color: COLORS.white,
                  '&.Mui-checked': {
                     color: COLORS.magenta,
                  },
               }}

            /> <span className='terms-text'> Согласен с <Link className='link' to={'/urban_terms'}>условиями.</Link> </span>
         </div>
         <Button
            disabled={!checked}
            htmlType="submit"
            modifier='primaryOutlined'
            color={'Magenta' as CursesSections}
         > <ButtonTextHolder> <div className='button-text'>Готово</div>
               <Tag name={modification as CursesSections} customName='500 MDL' />
            </ButtonTextHolder></Button>
      </BottomRow>
   </FirstStepHolderForm>
};

export default FirstStep;