import { useState } from 'react';
import { Link } from 'react-router-dom';

import LastStep from './Steps/LastStep';
import FirstStep from './Steps/FirstStep';
import { default as FirstStepMaib } from './StepsMaib/FirstStep';
import { default as SecondStepMaib } from './StepsMaib/SecondStep';
import SecondStep from './Steps/SecondStep';
import { CursesSections } from '../../types/common';
import { CoursesModifications } from 'types/courses/courses';
import PurchaseInfo from './PurchaseInfo';
import LastStepMaib from './StepsMaib/LastStep';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';


import {
   StepWrapper,
   TermsWrapper
} from './styled'


type Props = {
   handleCloseModal: () => void;
   modification: CursesSections;
   course: CoursesModifications;
   isMaib: boolean;
}

const PaymentSteps = ({ handleCloseModal, modification, course, isMaib }: Props) => {
   const [activeStep, setActiveStep] = useState(0);
   const { isMobile } = useMediaQuery();

   const handleNextStep = () => {
      if (isLastStep) {
         handleCloseModal()
         return;
      }
      setActiveStep(activeStep + 1);
   };

   const handleNextSecondStep = () => {
      setActiveStep(activeStep + 1);
   }

   const stepsMaib = [
      {
         components: <FirstStepMaib handleNextStep={handleNextStep} modification={modification} course={course} />,
      },
      {
         components: <SecondStepMaib handleNextSecondStep={handleNextSecondStep} course_to_update={course} />,
      },
      {
         components: <LastStepMaib />,
      }
   ];

   const steps = [
      {
         components: <FirstStep handleNextStep={handleNextStep} modification={modification} course={course} />,
      },
      {
         components: <SecondStep  />,
      },
      {
         components: <LastStep />,
      }
   ];
   const isLastStep = activeStep === steps.length - 1;

   return (
      <StepWrapper isMobile={isMobile}>
         <PurchaseInfo course={course} modification={modification} />
         {isMaib ? stepsMaib[activeStep].components : steps[activeStep].components}
         <TermsWrapper>
            Покупка  курсов Urban  быстрая, простая и безопасная.
            Действуют <Link className='link' to={'/urban_terms'} >условия и положения</Link>.
            Не стесняйтесь обращаться в службу поддержки покупок, если вам нужна помощь.
         </TermsWrapper>
      </StepWrapper>
   );
};

export default PaymentSteps;