
import styled from 'styled-components';

import { COLORS } from '../../../../constants/colors';

export const RestoreWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const CourseSubtitle = styled.div`
   color: ${COLORS.urbanBlack};
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   max-width: 500px;
`

export const CourseFooter = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   gap: 4rem;
`

export const CoursePlanWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   margin: ${({ isMobile }) => isMobile ? '20px 0' : '50px'};
   align-items: ${({ isMobile }) => isMobile ? 'flex-start' : 'center'}};
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   gap: ${({ isMobile }) => isMobile ? '2rem' : '10rem'};
   text-align: left;

   p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 400px;
   }

   img {
      filter: ${({ isMobile }) => isMobile ? 'none' : 'drop-shadow(12px 12px 27px rgba(0, 0, 0, 0.25))'};
      width: ${({ isMobile }) => isMobile ? '100%' : 'auto'};
   }
`

export const ChatDecorationWrapper = styled.div<{ isMobile: boolean }>`

   background: ${({ isMobile }) => isMobile ? COLORS.urbanBlack : ''};
   padding: ${({ isMobile }) => isMobile ? '10px' : '0'};
   border-radius: ${({ isMobile }) => isMobile ? '10px' : '0'};

   img {
      width: 100%;
      display: ${({ isMobile }) => isMobile ? 'none' : 'block'};
   }
`

export const ChatTitle = styled.h1<{ isMobile: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: 120px;
   left: 30px;
`

export const ChatSubtitle = styled.p<{ isMobile: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: 120px;
   left: 30px;
`

export const ButtonWrapper = styled.div<{ isMobile: boolean }>`
   color: ${COLORS.white};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'relative'};
   top: -120px;
   left: 30px;
`