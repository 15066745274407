import { FilterWrapper } from './styled';


export type FilterProps = {
   onClick?: () => void;
   text: string;
   active?: boolean;
};

export const Filter = ({
   text,
   active,
   onClick
}: FilterProps) => {

   return (
      <FilterWrapper
         onClick={onClick}
         className={`${active ? 'active' : ''}`}
      >
         {text}
      </FilterWrapper>
   );
};
