import Personality from "./Personality/Personality";

import {
   personalitiesAnalyticConfig,
   PersonalityType,
   personalitiesDiplomatConfig,
   personalitiesKeeperConfig,
   personalitiesFinderConfig
} from "./constants";

import { CoursesWrapper } from './styled';


const CardPersonalities = () => {
   return <div>
      <CoursesWrapper>
         {personalitiesAnalyticConfig.map((course: PersonalityType) => {
            return <Personality
               key={course.personalityType}
               personalitySection={course.personalitySection}
               section={course.section}
               personalityType={course.personalityType}
               miniType={course.miniType}
            />
         })}
      </CoursesWrapper>
      <CoursesWrapper>
         {personalitiesDiplomatConfig.map((course: PersonalityType) => {
            return <Personality
               key={course.personalityType}
               personalitySection={course.personalitySection}
               section={course.section}
               personalityType={course.personalityType}
               miniType={course.miniType}
            />
         })}
      </CoursesWrapper>
      <CoursesWrapper>
         {personalitiesKeeperConfig.map((course: PersonalityType) => {
            return <Personality
               key={course.personalityType}
               personalitySection={course.personalitySection}
               section={course.section}
               personalityType={course.personalityType}
               miniType={course.miniType}
            />
         })}
      </CoursesWrapper>
      <CoursesWrapper>
         {personalitiesFinderConfig.map((course: PersonalityType) => {
            return <Personality
               key={course.personalityType}
               personalitySection={course.personalitySection}
               section={course.section}
               miniType={course.miniType}
               personalityType={course.personalityType}
            />
         })}
      </CoursesWrapper>
   </div>
}

export default CardPersonalities;