import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { Button } from '../../../UI/Button/index';

import {
   MapWrapper,
   ContactFormWrapper,
   PhoneNumber,
   MapHolder,
   ButtonByWrapper
} from './styles'

type Props = {
   handleButtonClick: () => void
   isButtonDisabled?: boolean
}

const ContactsMap = ({ handleButtonClick, isButtonDisabled }: Props) => {
   const { isMobile } = useMediaQuery();

   return (
      <MapWrapper isMobile={isMobile}>
         <ContactFormWrapper>
            <h1>
               Обратная связь
            </h1>
            <p>
               Оставьте заявку, звоните по номеру или приходите в офис для уточнения текущего набора на курсы.
            </p>
            <PhoneNumber>
               + 373 (68) 281555
               <span>
                  в любое время
               </span>
            </PhoneNumber>
            <PhoneNumber>
               urban.md.center@gmail.com
               <span>
                  в любое время
               </span>
            </PhoneNumber>
            <ButtonByWrapper>
               {!isButtonDisabled && <Button modifier='primaryOutlined' onClick={handleButtonClick}>
                  Оставить заявку
               </Button>}
            </ButtonByWrapper>
         </ContactFormWrapper>

         <MapHolder>
            <iframe
               src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9979.361455053302!2d28.828488960022984!3d47.00798804748376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x306abbce760b8dbd!2sUrban%20Center!5e0!3m2!1sen!2s!4v1673517516954!5m2!1sen!2s"
               style={{ border: 0, width: '100%', height: '200px' }}
               loading="lazy"
               referrerPolicy="no-referrer-when-downgrade"></iframe>
         </MapHolder>
      </MapWrapper>
   );
};

export default ContactsMap;