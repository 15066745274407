
import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const ArtWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const OneCardWrapper = styled.div<{isHidden: boolean}>`
   width: 267px;
   height: 263px;
   margin-right: 10px;
   margin-bottom: 20px;
   cursor: pointer;

   .parallax-card-layers {
      background-color: transparent !important;
   }

   .parallax-card-shadow {
      display: none;
   }

   .card_background {
       display: ${({isHidden}) => isHidden ? 'block' : 'none'};
   }

   .card_front {
      display: ${({isHidden}) => isHidden ? 'none' : 'block'};
   }

   img {
      width: 267px;
      height: 263px;
   }
`;

export const WrapperCards = styled.div`
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   gap: 30px;
   margin-top: 40px;
`;