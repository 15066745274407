
import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const AppLoadingWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   width: 100%;
   position: absolute;
   z-index: 100;
   background-color: ${COLORS.white};
   overflow: hidden;

   img {
      width: 300px;
   }
`

export const CloudWrapper = styled.div`
   z-index: 0;
   div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      img {
         width: 60px;
      }
   }
`
export const MediumCloudWrapper = styled.div`
   z-index: 1;

   img {
      width: 140px;
   }
`