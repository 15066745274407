
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { setSetAdminPageStatus, filterCourses, labelCourses } from '../../../store/actions/appActions';
import { selectUserProfile } from '../../../store/selectors/user';
import { getAllUsers, getUserInfo } from '../../../store/actions/userActions';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import { Users } from './Users/Users';
import AdminHeader from './AdminHeader/AdminHeader';
import { UsersInfo } from './Users/UsersInfo';

import { AdminPageContainer , UsersInteractWrapper} from './styled';

const AdminPage = () => {
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const { isMobile } = useMediaQuery();

   useEffect(() => {
      dispatch(setSetAdminPageStatus({ isAdminPageOpen: true }))
      dispatch(filterCourses({ filter: 'all' }))
      dispatch(labelCourses({ label: 'none' }))
      dispatch(getAllUsers() as any)
      dispatch(getUserInfo() as any)
      return () => {
         dispatch(setSetAdminPageStatus({ isAdminPageOpen: false }))
      }
   }, [])

   if (!userProfile?.is_admin) {
      return null
   }

   return (
      <AdminPageContainer>
         <AdminHeader />
         <UsersInteractWrapper isMobile={isMobile}>
            <Users />
            <UsersInfo />
         </UsersInteractWrapper>
      </AdminPageContainer>
   );
};

export default AdminPage;