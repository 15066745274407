
import styled from 'styled-components';
import { NAVIGATION_WIDTH } from '../../../constants/dimensions';
import { COLORS } from '../../../constants/colors';

export const OneElementWrapper = styled.div`
   width: ${NAVIGATION_WIDTH}px;
   display: flex;
   justify-content: center;
   margin-bottom: 12px;
`;


export const NavigationItemsWrapper = styled.div`
   display: flex;
   flex-direction: column;
`;

export const TagWrapper = styled.div`
   display: flex;
   margin-left: 12px;
   font-weight: 700;
   font-size: 12px;
   line-height: 15px;
   display: flex;
   align-items: center;
   text-align: center;
   letter-spacing: -0.017em;
   color: ${COLORS.white};
   opacity: 0.3;
`;

export const NavButtonsWrapper = styled.div`
   margin-top: 22px;
`;

export const IconWrapper = styled.div`
  transform: rotate(180deg);
  margin-bottom: 3px;
`;