import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContactsMap from '../Courses/CommonComponents/ContactsMap';
import DirectionPreview from '../common/DirectionPreview';
import { SECTIONS, COURSES } from '../../../constants/courses';
import { CoursesButtonTypes, CoursesModifications } from '../../../types/courses/courses';
import teensBanner from '../../../assets/directions/teensBanner.png';
import { CursesSections } from '../../../types/common';
import Lector from '../Courses/CommonComponents/Lector';
import { getCourseLector } from '../../../utils/courses/getCourseLector';
import MaterialModal from '../../../components/UI/Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';
import InvoiceSteps from '../../../components/InvoiceSteps/InvoiceSteps';

import { ArtWrapper, CourseTitle } from './styles';


const Teens = () => {
   const { t } = useTranslation();
   const [isModalOpen, setIsModalOpen] = useState(false);

   const handleDirectionAction = () => {
      setIsModalOpen(!isModalOpen);
   }

   const lectorHigh = getCourseLector(COURSES.urbanHigh as CoursesModifications);
   const lectorTop = getCourseLector(COURSES.urbanTop as CoursesModifications);

   return (
      <ArtWrapper>
         <DirectionPreview
            bgImage={teensBanner}
            sectionName={SECTIONS.teens as CursesSections}
            button={CoursesButtonTypes.SUBSCRIBE}
            buttonModification={'primaryOutlined'}
            customTag={'Мероприятия'}
            handleDirectionAction={handleDirectionAction}
         />
         <CourseTitle>
            {t(`directions.${SECTIONS.teens}.titleOne`)}
         </CourseTitle>
         <Lector lector={lectorHigh} tags={['Более 20 лет лектор', 'Более 9 лет с нами', 'Более 20 лет в профессии']} />
         <CourseTitle>
            {t(`directions.${SECTIONS.teens}.titleTwo`)}
         </CourseTitle>
         <Lector lector={lectorTop} tags={['Более 20 лет лектор', 'Более 2 лет с нами', 'Более 20 лет в профессии']} />
         <ContactsMap handleButtonClick={handleDirectionAction} />
         <MaterialModal
            modification={SECTIONS.teens as CursesSections}
            open={isModalOpen}
            handleClose={handleDirectionAction}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.teens}.title`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.teens as CursesSections}
               handleCloseModal={handleDirectionAction}
               course={COURSES.urbanHigh as CoursesModifications}
               invoiceDescription={t(`course.${COURSES.urbanHigh}.invoiceDescription`)}
            />
         </MaterialModal>
      </ArtWrapper>
   );
};

export default Teens;