import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoursesModifications } from 'types/courses/courses';

import Tag from '../Tags/Tag';
import paymentLogo from '../../assets/global/paymentLogo.png';

import { PurchaseInfoWrapper, InfoHeaderWrapper } from './styled';
import { CursesSections } from 'types/common';

type PurchaseInfoProps = {
   course: CoursesModifications;
   modification: CursesSections;
}

const PurchaseInfo = ({ course, modification }: PurchaseInfoProps) => {
   const { t } = useTranslation();

   return (
      <PurchaseInfoWrapper>
         <InfoHeaderWrapper>
            <div className='left-side'>
               <img src={paymentLogo} alt="" />
               <span>
                  Вы покупаете
               </span>
            </div>
            {modification !== 'NotSelected' && <Tag name={modification} />}
         </InfoHeaderWrapper>
         <h3>Ожидание оплаты
         </h3>
         <p>{t(`course.${course}.purchaseInfo`)}</p>
      </PurchaseInfoWrapper>
   );
};

export default PurchaseInfo;