import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { DirectionsTypeCustom } from 'components/Tabs/Main/Directions/Directions';
import { Button } from '../../../../components/UI/Button';
import Tag from '../../../Tags/Tag';

import MaterialModal from '../../../../components/UI/Modal/MaterialModal';
import { COLORS } from '../../../../constants/colors';
import { OneDirectionCustomWrapper, DirectionContentCustomBottom, DisclaimerWrapper, DirectionContent } from './styled';
import { SECTIONS } from '../../../../constants/courses';
import { CursesSections } from '../../../../types/common';
import InvoiceSteps from '../../../InvoiceSteps/InvoiceSteps';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import {CoursesModifications} from 'types/courses/courses';

const OneDirectionCustom = ({ title, description, image, modification }: DirectionsTypeCustom) => {
   const { t } = useTranslation();
   const [isModalOpened, setIsModalOpened] = useState(false);
   const { isMobile , width} = useMediaQuery();

   const handleOpenModal = () => {
      setIsModalOpened(!isModalOpened);
   };

   return (
      <OneDirectionCustomWrapper isMobile={isMobile} width={width}>
         <DirectionContent image={image} width={width} isMobile={isMobile}>
            <Tag name={'Event-lab'} />
            <DirectionContentCustomBottom isMobile={isMobile}>
               <div className='description-small'>
                  <h1>{title}</h1>
                  <p>{description}</p>
               </div>
               <Link to={`/${modification}`}>
                  <Button modifier='primaryOutlined' color={'Event-lab'}>{t('directions.about')}</Button>
               </Link>
            </DirectionContentCustomBottom>
         </DirectionContent>
         {!isMobile && <DisclaimerWrapper>
            <div>
               <h3>{t('directions.whyUs')}</h3>
               <p>{t('directions.whyUsSubtitle')}</p>
               <Button modifier='primaryOutlined' onClick={handleOpenModal}> {t('directions.proposal')} </Button>
            </div>
         </DisclaimerWrapper>}
         <MaterialModal
            modification={SECTIONS.eventLab as CursesSections}
            open={isModalOpened}
            handleClose={handleOpenModal}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.eventLab}.title`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.eventLab as CursesSections}
               handleCloseModal={handleOpenModal}
               course={SECTIONS.eventLab as CoursesModifications}
               invoiceDescription={t(`directions.${SECTIONS.eventLab}.invoiceDescription`)}
            />
         </MaterialModal>
      </OneDirectionCustomWrapper>
   );
}

export default OneDirectionCustom;