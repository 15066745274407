
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { hexToRGB } from '../../utils/colorConverter';

export const AssociativeCardGameWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
`;

export const StartContainer = styled.div`
   text-align: left;
   font-size: 2rem;
   align-items: center;
   color: ${COLORS.white};
   padding: 20px;
   justify-content: flex-start;

   button {
      margin: 0 auto;
   }
`;

export const FinishContainer = styled.div<{ isMobile: boolean }>`
   text-align: left;
   margin-top: auto;
   font-size: 2rem;
   align-items: center;
   color: ${COLORS.white};
   padding: 20px;
   height: ${({ isMobile }) => isMobile ? '440px' : '672px'};
   overflow-y: scroll;
   overflow-x: hidden;

   button {
      margin: 0 auto;
   }
`;

export const IntroText = styled.h3<{ isMobile: boolean }>`
   font-size: ${({ isMobile }) => isMobile ? '1rem' : '1.5rem'};
   line-height: ${({ isMobile }) => isMobile ? '0.5rem' : '1rem'};
`;


export const DescriptionText = styled.p<{ isMobile: boolean }>`
   font-size: ${({ isMobile }) => isMobile ? '0.8rem' : '1rem'};
   line-height: ${({ isMobile }) => isMobile ? '0.9rem' : '1rem'};
   margin: 0 auto;
`;

export const StepOneWrapper = styled.div<{ isMobile: boolean }>`
   max-width: 600px;
   font-size: ${({ isMobile }) => isMobile ? '0.6rem' : '1rem'};
   line-height: ${({ isMobile }) => isMobile ? '0.9rem' : '1.3rem'};
   margin: ${({ isMobile }) => isMobile ? '15px auto' : '35px auto'};
   font-weight: 500;
`;

export const CardsWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: ${props => props.isMobile ? 'column' : 'row'};
   justify-content: space-between;
   align-items: center;
   gap: 20px;
   padding: 15px 20px;
`;

export const FinishCardWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 0 auto;
`;

export const AnnotationText = styled.p<{ isMobile: boolean }>`
   font-size: ${({ isMobile }) => isMobile ? '12px' : '14px'};
   max-width: 600px;
   margin: ${({ isMobile }) => isMobile ? '15px 5px' : '15px auto'};
   line-height: ${({ isMobile }) => isMobile ? '0.9rem' : '1rem'};
   font-style: italic;
   color: ${COLORS.white};

   opacity: 0.8;
`;

export const AssociativeStepOneImg = styled.img<{ isMobile: boolean }>`
   width: 100%;
   max-width: ${({ isMobile }) => isMobile ? '250px' : '600px'};
   margin: 0 auto;
`;

export const BottomOneButtonWrapper = styled.div<{ isMobile: boolean }>`
   padding-top: ${({ isMobile }) => isMobile ? '10px' : '15px'}};
   display: flex;
   justify-content: center;
   border-top: 1px solid ${hexToRGB(COLORS.white, 0.1)};;
   width: ${({ isMobile }) => isMobile ? '350px' : '550px'};

   button {
      width: 140px !important;
   }
`;

export const GlobalStepWrapper = styled.div<{ isMobile: boolean }>`
   text-align: left;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   height: ${({ isMobile }) => isMobile ? '440px' : '672px'};
`;

export const BottomButtonsWrapper = styled.div<{ isMobile: boolean }>`
   padding-top: ${({ isMobile }) => isMobile ? '10px' : '15px'};
   display: flex;
   justify-content: space-between;
   border-top: 1px solid ${hexToRGB(COLORS.white, 0.1)};;
   width: ${({ isMobile }) => isMobile ? '350px' : '550px'};

   button {
      width: 120px !important;
   }
`;


export const StepWrapper = styled.div<{ isMobile: boolean }>`
   text-align: left;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   height: ${({ isMobile }) => isMobile ? '440px' : '672px'};
`;