import styled from "styled-components";

import { COLORS } from "../../../constants/colors";


export const TermsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   padding: 20px;
   width: 100%;
   overflow-x: hidden;
   overflow-y: scroll;
`;

export const DropDownTitle = styled.h1`
   display: flex;
   flex-direction: row;
   gap: 10px;
   color: ${COLORS.white};
`;

export const DropDownSubtitle = styled.div`
   display: flex;
   flex-direction: row;
   gap: 10px;
   color: ${COLORS.white};
`;