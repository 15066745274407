import smallLogo from '../../../assets/global/smallLogo.png';

import { Button } from '../Button';

import { SideModalHeaderWrapper } from './styled';


type Props = {
   onClose: () => void;
   width: number;
}

const SideModalHeader = ({ onClose, width }: Props) => {
   return (
      <SideModalHeaderWrapper width={width}>
        <span><img src={smallLogo} alt="" />  <p>urban</p> </span> 
         <div className='left-side'>
            <Button modifier='transparent' onClick={onClose}>x</Button>
         </div>
      </SideModalHeaderWrapper>
   );
};

export default SideModalHeader;