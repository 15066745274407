import { UserDataType, UserOneProfileType, UserPaymentStatusType, UserStartedCourses } from '../../types/user/user';
import { USER_ACTION_TYPES, UserAction } from '../actions/userActions';
import { LoadingStatuses } from './types';

interface UserState {
  userData: UserDataType;
  authTokens: {
    access: string;
    refresh: string;
  } | null;
  userProfile: UserOneProfileType,
  usersProfilesData: UserOneProfileType[],
  authError: string | null;
  paymentStatus: UserPaymentStatusType;
  transactionId: string,
  startedCourses: UserStartedCourses[],
  startedCoursesStatus: LoadingStatuses,
  bookmarkedCoursesStatus: LoadingStatuses
}

const initialState: UserState = {
  userData: null,
  authTokens: null,
  userProfile: null,
  usersProfilesData: [],
  authError: null,
  paymentStatus: 'notPayed',
  transactionId: '',
  startedCourses: [],
  startedCoursesStatus: 'idle',
  bookmarkedCoursesStatus: 'idle'
};

export default function user(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER_DATA: {
      return {
        ...state,
        userData: action.userData,
      };
    }

    case USER_ACTION_TYPES.SET_AUTH_TOKENS: {
      return {
        ...state,
        authTokens: action.authTokens,
      };
    }

    case USER_ACTION_TYPES.SET_USER_INFO: {
      return {
        ...state,
        userProfile: action.userProfile,
      };
    }

    case USER_ACTION_TYPES.SET_ALL_USERS: {
      return {
        ...state,
        usersProfilesData: action.usersProfilesData,
      };
    }

    case USER_ACTION_TYPES.SET_AUTH_ERROR: {
      return {
        ...state,
        authError: action.authError
      }
    }

    case USER_ACTION_TYPES.SET_TRANZACTION_ID: {
      return {
        ...state,
        transactionId: action.id
      }
    }

    case USER_ACTION_TYPES.SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: action.paymentStatus
      }
    }

    case USER_ACTION_TYPES.SET_STARTED_COURSES: {
      return {
        ...state,
        startedCourses: action.startedCourses
      }
    }

    case USER_ACTION_TYPES.SET_STARTED_COURSES_STATUS: {
      return {
        ...state,
        startedCoursesStatus: action.startedCoursesStatus
      }
    }

    case USER_ACTION_TYPES.SET_BOOKMARKED_COURSES_STATUS: {
      return {
        ...state,
        bookmarkedCoursesStatus: action.bookmarkedCoursesStatus
      }
    }
    
    default: {
      return state;
    }
  }
}
