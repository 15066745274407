
import styled from 'styled-components';

import { COLORS } from '../../../../constants/colors';

export const RestoreWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`

export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};
`

export const CourseSubtitle = styled.div`
   color: ${COLORS.urbanBlack};
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   max-width: 500px;
`

export const CourseFooter = styled.div<{ isMobile: boolean}>`
   display: flex;
   justify-content: space-between;
   flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
   gap: 4rem;
`

export const CoursePlanWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 10rem;
   text-align: left;
   margin-bottom: 40px;
   margin-top: 40px;

   p {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      max-width: 500px;
   }

   img {
      height: 40%;
      width: 40%;
   }
`
