import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { COLORS } from '../../constants/colors';
import { LabelWrapper } from './styled';

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 1 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <LabelWrapper>
        <Typography variant="body2" color={COLORS.whiteLight}>{`Завершено на ${Math.round(
          props.value,
        )}%`}</Typography>
      </LabelWrapper>
    </Box>
  );
}