import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../UI/Button';
import { getCourseImage } from '../../utils/courses/getCourseImage';
import { getCourseColor } from '../../utils/courses/getCourseColor';
import { setCurrentCourse, setCurrentVideo } from '../../store/actions/appActions';
import { COURSES } from '../../constants/courses';
import { setCurrentPage } from '../../store/actions/bookActions';
import {
   CoursesModifications,
   CoursesButtonTypes,
} from '../../types/courses/courses';
import { getCourseVideo } from '../../utils/courses/getCourseVideo';
import { getCourseLector } from '../../utils/courses/getCourseLector';
import { LinearProgressWithLabel } from './LinearProgress';
import { getCourseProgram } from '../../utils/courses/getCourseProgram';
import { selectAllCourses, selectPremiumCourses } from '../../store/selectors/courses';
import { CursesSections } from 'types/common';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

import {
   CourseWrapper,
   CourseImage,
   CourseTitle,
   CourseDescription,
   CourseRightSide,
   CourseInfo,
   CourseActions,
   CourseDescriptionWrapper,
   CourseContent,
   CourseLector,
   StoppedOnWrapper,
   CurrentStopTitle,
} from './styled';



type Props = {
   courseModification: CoursesModifications;
   button: string;
   currentLesson: number;
}

const CourseBox = ({ courseModification, button, currentLesson }: Props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const { isMobile, width } = useMediaQuery();
   const courseLector = getCourseLector(courseModification);
   const courses = useSelector(selectAllCourses)
   const premiumCourses = useSelector(selectPremiumCourses)
   const allCourses = [...courses, ...premiumCourses]
   const section = allCourses.find(course => course.courseModification === courseModification)?.section || 'Magenta' as CursesSections
   const setCurrentCourseModification = (courseModification: CoursesModifications) => {
      dispatch(setCurrentCourse({ courseModification }));
   }
   const currentCourse = allCourses.find(course => course.courseModification === courseModification)
   const currentCourseProgram = getCourseProgram(currentCourse?.courseModification as CoursesModifications)
   const currentCourseProgramLength = currentCourseProgram.length - 1;
   let currentCourseProgress;

   if (currentCourseProgramLength === 0) {
      currentCourseProgress = 100;
   } else {
      currentCourseProgress = (currentLesson / currentCourseProgramLength) * 100;
   }

   const isDesktop = width > 768 &&  width < 1500;

   const getToLink = (button: string): string => {
      switch (button) {
         case CoursesButtonTypes.DESCRIPTION:
            if (courseModification === COURSES.urbanHigh || courseModification === COURSES.urbanTop) {
               return `/Teens`;
            }
            return `/cabinet/${courseModification}`;
         case CoursesButtonTypes.PINTEREST:
            return getCourseVideo(courseModification) as string;
         case CoursesButtonTypes.SEE:
            return "/cabinet"
         case CoursesButtonTypes.SOON:
            return '';
         case CoursesButtonTypes.READ: {
            return `/cabinet/${courseModification}/reader`;
         }
         default:
            return "/cabinet"
      }
   }

   const handleButtonClick = () => {
      if (button === CoursesButtonTypes.PINTEREST) {
         location.href = getToLink(button)
      } else {
         setCurrentCourseModification(courseModification)
         dispatch(setCurrentPage({ currentPage: 0 }));
         dispatch(setCurrentVideo({ courseVideo: 0 }))
      }
   }


   return <CourseWrapper isMobile={isMobile} isDesktop={isDesktop}>
      <div>
         <CourseImage src={getCourseImage(courseModification)} alt="" />
      </div>
      <CourseContent isMobile={isMobile} isDesktop={isDesktop}>
         <CourseDescriptionWrapper color={getCourseColor(section)}>
            <CourseTitle>
               {t(`course.${courseModification}.title`)}
            </CourseTitle>
            <CourseDescription>
               {t(`course.${courseModification}.description`)}
            </CourseDescription>
            <CourseLector>
               {t(courseLector.name)}
            </CourseLector>
            <LinearProgressWithLabel variant='determinate' color='secondary' value={currentCourseProgress} />
         </CourseDescriptionWrapper>

         <CourseRightSide  isMobile={isMobile} isDesktop={isDesktop}>
            <CourseInfo color={getCourseColor(section)}>
               <StoppedOnWrapper>
                  Вы остановились на:
               </StoppedOnWrapper>
               <CurrentStopTitle color={getCourseColor(section)}>
                  {t(getCourseProgram(courseModification)[currentLesson]?.title)}
               </CurrentStopTitle>
            </CourseInfo>
            <CourseActions>
               {button === CoursesButtonTypes.PINTEREST ?
                  <Button
                     modifier={'primaryOutlined'}
                     onClick={() => location.href = getToLink(button)}>
                     {t(button)}
                  </Button> :
                  <Link to={getToLink(button)}>
                     <Button
                        disabled={button === CoursesButtonTypes.SOON}
                        modifier={button === CoursesButtonTypes.SOON ? 'primary' : 'primaryOutlined'}
                        onClick={handleButtonClick}
                     >
                        {t(button)}
                     </Button>
                  </Link>
               }
            </CourseActions>
         </CourseRightSide>
      </CourseContent>
   </CourseWrapper>
}

export default CourseBox;