
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const TeamWrapper = styled.div<{isMobile: boolean}>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 20px;
   max-width: 385px;
   min-width: 385px;

   h1 {
      margin: 0;
      padding: ${({ isMobile }) => isMobile ? '10px 0px' : '20px 0'};
      position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
      top: 0px;
      width: 100%;
      background-color: ${COLORS.urbanBaGLight};
      z-index: 2;
   }
`;

export const TeamContainer = styled.div`
   display: flex;
   flex-direction: column;
   background: ${COLORS.teamBg};;
   border-radius: 8px;
   padding-left: 20px;
`;

export const TeamSubtitle = styled.div`
   font-weight: 700;
   font-size: 12px;
   line-height: 130%;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   color: ${COLORS.white};
   top: 0px;
   background: ${COLORS.teamBg};
   padding-top: 20px;
   margin-right: 30px;

   h2 {
      margin-bottom: 20px;
      font-size: 14px;
      width: 320px;
   }

   span {
      color: ${COLORS.eventLab};
   }

`;

export const TeamMembersWrapper = styled.div`
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   color: ${COLORS.white};

   .member {
      max-height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      .memberInfo {
         max-height: 87px;
         

         h3 {
            line-height: 1px;
         }

         p {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: ${COLORS.white};
            opacity: 0.5;
         }
      }

      img {
         width: 70px;
         height: 70px;
         margin-right: 20px;
      }  

   }
`;