import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Card from 'react-animated-3d-card';
import { AssociativeCardObj } from './AssociativeCard';

type Props = {
  selectedCard?: AssociativeCardObj | null;
  children?: JSX.Element;
  height?: string;
  width?: string;
};

const SelectedCard = ({
  selectedCard,
  children,
  height = "300px",
  width = "450px",
}: Props): JSX.Element => {

  return (
    <CardWrapper>
      {children}
      <Card
        cursorPointer={false}
        shineStrength={0.25}
        style={{
          background: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
          width: width,
          height:  height,
          cursor: "pointer !important",
        }}
      >
        {selectedCard && <div>
          <CardFlippedImage src={selectedCard.image} alt="Alt text" />
        </div>}
      </Card>
    </CardWrapper>

  );
};

const CardFlippedImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.1rem;
  cursor: pointer;
`;

const CardWrapper = styled.div`
  display: contents;
  justify-content: center;
`;

export default SelectedCard;
