import {TranslateActions, TranslateType, TRANSLATE_ACTION_TYPES} from '../actions/translateAction';

interface TranslationsState {
  translation: TranslateType;
}

const initialState: TranslationsState = {
  translation: 'ru',
};

export default function translation(state = initialState, action: TranslateActions): TranslationsState {
  switch (action.type) {
    case TRANSLATE_ACTION_TYPES.CHANGE_TRANSLATE: {
      return {
        translation: action.translate,
      };
    }

    default: {
      return state;
    }
  }
}
