
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const PreviewWrapper = styled.div<{ bgImage: string }>`
   background: ${({ bgImage }) => `url(${bgImage}) no-repeat`};
   padding: 20px;
   color: ${COLORS.white};
   border-radius: 5px;
   background-size: cover;
`;

export const TitleWrapper = styled.h1<{isMobile: boolean}>`
   margin-bottom: 50px;
   font-size: ${({ isMobile }) => isMobile ? '28px !important' : '36px !important'};
   line-height: ${({ isMobile }) => isMobile ? '28px !important' : '36px !important'};
`;

export const SubRowWrapper = styled.div`
   display: flex;
`;

export const SubTitleWrapper = styled.h3`
   font-size: 24px;
`;

export const DescriptionWrapper = styled.p`
   max-width: 600px;
   font-size: 18px;
   overflow-y: hidden;
`;

export const TopRowWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

export const BottomRowWrapper = styled.div<{isMobile: boolean}>`
   display: ${({ isMobile }) => isMobile ? 'block' : 'flex'};
   justify-content: space-between;
`

export const LeftActions = styled.div`
   display: block;
   margin-top: 60px;
`

export const TagsBlock = styled.div`
   display: flex;
   text-align: right;
   align-items: flex-end;
   justify-content: flex-end;
   gap: 10px;
   margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
   margin-left: 60px;
`

export const CoursePlanWrapper = styled.div`
   color: ${COLORS.urbanBlack};
`

export const PlanTitle = styled.h1`
   font-size: 36px;
`

export const OneBlockWrapper = styled.div<{ bgImage: string }>`
   background: ${({ bgImage }) => `url(${bgImage}) no-repeat top center`};
   min-height: 100px;
   margin-bottom: 20px;
   border-radius: 5px;
   color: ${COLORS.white};
   display: flex;
   justify-content: space-between;
`

export const BlocksWrapper = styled.div`
   display: flex;
   flex-direction: column;
`

export const BlockTitle = styled.h2`
`

export const BlockTitleCoursePlan = styled.h2`
   margin: 20px;
`

export const BlockLeftSide = styled.div`
   display: flex;
   flex-direction: row;
`

export const BlockRightSide = styled.div`
   margin: 20px;
   text-decoration: dotted;
   text-align: left;
   width: 400px;
`

export const LectorWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   padding: 10px;
   background: linear-gradient(180deg, rgba(107, 110, 114, 0.8) 0%, rgba(26, 29, 34, 0.8) 100%);
   color: ${COLORS.white};
   border-radius: 5px;
`

export const BlockSubtitle = styled.div`
   color: ${COLORS.white};
   font-size: 18px;
`

export const BlockDescription = styled.div`
   margin-top: 20px;
   color: ${COLORS.white};
   font-size: 14px;
   margin-bottom: 20px;
`

export const LectorImage = styled.img`
   width: 170px;
   height: 170px;
   margin-top: 20px;
`

export const LeftBlockHolder = styled.div`
   margin-left: 20px;
`

export const RightBlockHolder = styled.div`
   text-align: right;
   margin-right: 20px;
`

export const TagsWrapper = styled.div`
   min-width: 500px;
   display: flex;
   justify-content: space-around;
   gap: 1rem;
   margin: 10px 5px;
`

// Feedback Messages UI

export const LectorMessageWrapper = styled.div<{ color: string }>`
   background-color: ${props => props.color};
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
   border-bottom-left-radius: 2px;
   border-bottom-right-radius: 25px;
   height: fit-content;
   padding: 8px 15px;
   color: ${COLORS.white};
   max-width: 300px;
`

export const UserMessageWrapper = styled.div`
   background-color: ${COLORS.white};
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
   border-bottom-left-radius: 25px;
   border-bottom-right-radius: 2px;
   height: fit-content;
   padding: 15px 15px;
   margin-left: 200px;
   margin-right: 10px;
`

export const MessageWrapper = styled.div`
   display: flex;
   align-items: center;
   font-size: 13px;
   line-height: 15px;
`

export const UserImage = styled.img`
   width: 80px;
   height: 80px;
   margin-left: 10px;
`

export const LectorChatImage = styled.img`
   width: 80px;
   height: 80px;
   margin-right: 20px;
`

export const FeedbackWrapper = styled.div`
   margin-top: 20px;
   width: fit-content;
`

export const UserNameWrapper = styled.div`
   text-align: right;
   margin-top: 20px;
`

export const User = styled.div`
   font-weight: 700;
   font-size: 14px;
   line-height: 171%;
   font-style: italic;
`

export const UserDescription = styled.div`
   font-weight: 400;
   font-size: 12px;
   line-height: 12px;
`

// Contact Map styles

export const  MapWrapper = styled.div`
   display: flex;
   justify-content: space-between;
   margin: 60px 0px;
`

export const  ContactFormWrapper = styled.div`
   font-weight: 500;
   font-size: 14px;
   line-height: 130%;
`

export const  PhoneNumber = styled.div`
   font-weight: 500;
   font-size: 14px;
   line-height: 130%;
   padding-right: 2px;

   span {
      font-weight: 500;
      font-size: 9px;
      line-height: 130%;
      opacity: 0.3;
      padding-left: 2px;
   }
`

export const MapHolder = styled.div`
   width: 100%;
   height: 200px;
`

export const ButtonByWrapper = styled.div`
   width: max-content;
   margin-top: 20px;
`