import { useTranslation } from 'react-i18next';

import noCourses from '../../../../assets/global/noCourses.png';

import { NotSelectedWrapper, TitleNotSelected } from './styled';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

const CabinetNotSelected = () => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <NotSelectedWrapper isMobile={isMobile}>
         <img src={noCourses} alt="" />

         <TitleNotSelected>
            {t('cabinet.notSelected')}
         </TitleNotSelected>
         <div>
            <p>
               {t('cabinet.notSelectedSubtitle')}
            </p>
         </div>
      </NotSelectedWrapper>
   );
};

export default CabinetNotSelected;