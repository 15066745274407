import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';

import { OneCourse } from "types/courses/courses";

import { COLORS } from "../../../../constants/colors";
import { updateUserPremiumCourses } from "../../../../store/actions/userActions";
import { selectAdminSubscriptionSelectedUser } from "../../../../store/selectors/app";
import { setAdminSubscriptionSelectedUser } from "../../../../store/actions/appActions";
import { useTranslation } from "react-i18next";

type PremiumCoursesProps = {
  premiumCourses: OneCourse[]
}

function PremiumCourses({ premiumCourses }: PremiumCoursesProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedAdminSubscriptionUser = useSelector(selectAdminSubscriptionSelectedUser)

  const handleCheckboxChange = (event: any) => {
    const selectedCourse = event.target.value;
    const updatedCourses = selectedAdminSubscriptionUser?.premium_courses;
    if (updatedCourses?.includes(selectedCourse)) {
      const index = updatedCourses.indexOf(selectedCourse);
      if (index > -1) {
        updatedCourses.splice(index, 1);
      }
    } else {
      updatedCourses?.push(selectedCourse)
    }
    selectedAdminSubscriptionUser && dispatch(updateUserPremiumCourses({
      userId: selectedAdminSubscriptionUser.id,
      premium_courses: updatedCourses as any
    }) as any);
    dispatch(setAdminSubscriptionSelectedUser({
      user: {
        ...selectedAdminSubscriptionUser,
        premium_courses: updatedCourses as any
      }
    } as any))
  };

  return (
    <div>
      {premiumCourses?.length > 0 && premiumCourses.map((course) => (
        <div key={course.id}>
          <Checkbox
            value={course?.courseModification}
            checked={selectedAdminSubscriptionUser?.premium_courses?.includes(course?.courseModification)}
            onChange={handleCheckboxChange}
            color="primary"
            sx={{
              color: COLORS.white,
              '&.Mui-checked': {
                color: COLORS.magenta,
              },
            }}
          />
          <label>{t(`course.${course?.courseModification}.title`)}</label>
        </div>
      ))}
    </div>
  );
}

export default PremiumCourses;
