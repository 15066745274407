import styled from 'styled-components';

import { COLORS } from '../../../../constants/colors';

export const PersonalitiesWrapper = styled.div`
   margin: 20px;
   justify-content: flex-start;
   align-self: stretch;
   overflow-x: hidden;
   overflow-y: scroll;
`;


export const CourseTitle = styled.h1`
   color: ${COLORS.urbanBlack};

   img {
      width: 10px;
      height: 10px;
      margin-top: 4px;
      margin-left: 10px;
      margin-bottom: 2px;
      cursor: pointer;
   }
`