import styled from "styled-components";
import { COLORS } from "../../../constants/colors";

export const TermsAndConditionsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: auto; 
   margin-bottom: 20px;
`;

export const TermsText = styled.div`
   font-size: 10px;
   line-height: 12px;
   opacity: 0.3;
   font-weight: 600;
   color: ${COLORS.white};
   text-align: center;
   margin-bottom: 10px;
   padding: 0 10px;

   .link {
      color: ${COLORS.white};
      text-decoration: underline;
   }
`;

export const PaymentLogo = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   margin-bottom: 50px;
   gap: 6px;

   img {
      width: 20px;
      height: 17px;
      object-fit: contain;
   }
`;