import { TagsWrapper } from './styled';
import { getCourseColor } from '../../utils/courses/getCourseColor';
import { CursesSections } from 'types/common';
import { useTranslation } from 'react-i18next';

type Props = {
   name: CursesSections;
   customName?: string;
}

const Tag = ({ name, customName }: Props) => {

   const { t } = useTranslation();

   return <TagsWrapper color={getCourseColor(name)}>
      {customName ? t(customName) : name}
   </TagsWrapper>
}

export default Tag;