import { useState } from 'react';

import dropDownArrow from '../../../assets/dropDown/dropDownArrow.png';
import dropDownExit from '../../../assets/dropDown/dropDownExit.png';

import {
   DropDownWrapper,
   DropDownContent,
   TopRow,
   DropDownAction
} from './styled'

type Props = {
   contentSmall: JSX.Element;
   contentBig: JSX.Element;
}

const DropDown = ({ contentSmall, contentBig }: Props) => {
   const [isExpanded, setIsExpanded] = useState(true);
   const toggleDropDown = () => {
      setIsExpanded(!isExpanded);
   }

   const renderContent = () => {
      if (isExpanded) {
         return contentSmall
      } else {
         return contentBig
      }
   }

   return (
      <DropDownWrapper isExpanded={isExpanded}>
         <TopRow>
            <DropDownContent>
               {renderContent()}
            </DropDownContent>
            <DropDownAction >
               {isExpanded ? <img src={dropDownExit} onClick={toggleDropDown} alt="" /> : <img src={dropDownArrow} onClick={toggleDropDown} alt="" />}
            </DropDownAction>
         </TopRow>
      </DropDownWrapper >
   );

}

export default DropDown;

