export type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
  toString: () => string;
};

export const objectToRGBA = ({ r, g, b, a }: RGBA) => `rgba(${r}, ${g}, ${b}, ${a})`;

export const rgbaStringToObject = (color: string): RGBA => {
  const [red, green, blue, alpha] = color.split('(')[1].split(')')[0].split(',');
  return { r: +red, g: +green, b: +blue, a: +alpha };
};

export const hexToRGB = (hex: string, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbaToString = (rgba: RGBA) => {
  const { r, g, b, a } = rgba;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
