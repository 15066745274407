import Tag from '../../../components/Tags/Tag';
import { ModalHeaderWrapper } from './styled';

import { CursesSections } from 'types/common';
import { Button } from '../Button';

type Props = {
   title: string;
   onClose: () => void;
   width: number;
   modification: CursesSections;
}

const ModalHeader = ({ title, modification, onClose, width }: Props) => {
   return (
      <ModalHeaderWrapper width={width}>
         <h3>{title}</h3>
         <div className='left-side'>
            {modification !== 'NotSelected' && <Tag name={modification} />}
            <Button modifier='transparent' onClick={onClose}>x</Button>
         </div>
      </ModalHeaderWrapper>
   );
};

export default ModalHeader;