import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../UI/Button';
import { COLORS } from '../../../constants/colors';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import { AnnotationText, GlobalStepWrapper, BottomOneButtonWrapper } from '../styled';

type Props = {
   setNextStep: () => void;
}

const StepPositiveCardExplanation = ({ setNextStep }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()

   return (
      <GlobalStepWrapper isMobile={isMobile}>
         <SelectedCardWrapper isMobile={isMobile}>
            <StepWrapper isMobile={isMobile}>
               <SelectedCardTitle isMobile={isMobile}>
                  Теперь выберите другие 2 карты из набора “позитив”, в закрытую.
               </SelectedCardTitle>
               <CardImageWrapper isMobile={isMobile}>
                  <CardImage src="/images/positiveCards/positiveCardClosed.png" alt="Alt text" />
                  <CardImage src="/images/positiveCards/positiveCardClosed.png" alt="Alt text" />
               </CardImageWrapper>
               <SelectedCardSubtitle isMobile={isMobile} >
                  * Эти карты являются решением ваших проблем. Напишите как вы себя чувствуете, когда вы видите эти карты.
               </SelectedCardSubtitle>
               <SelectedCardSubtitle isMobile={isMobile}>
                  * Это может быть слово, фраза, предложение, а может быть и картинка.
               </SelectedCardSubtitle>
               <AnnotationText isMobile={isMobile}>
                  Если вы согласны с выбором продолжайте дальше, если нет, то вернитесь на предыдущий шаг.
               </AnnotationText>
            </StepWrapper>
         </SelectedCardWrapper>
         <BottomOneButtonWrapper isMobile={isMobile}>
            <Button modifier='primaryOutlined' onClick={setNextStep}>{t("navigation.next")}</Button>
         </BottomOneButtonWrapper>
      </GlobalStepWrapper>
   );
};

export default StepPositiveCardExplanation;

const StepWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   padding: ${props => props.isMobile ? "1rem" : "1rem"};
`;

const SelectedCardWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: ${props => props.isMobile ? "1rem" : "1rem"};
`;

const SelectedCardTitle = styled.h3<{ isMobile: boolean }>`
   font-size: ${props => props.isMobile ? "0.8rem" : "1.5rem"};
   line-height: ${props => props.isMobile ? "1rem" : "2rem"};
   margin: 10px 0px;
   padding-bottom: ${props => props.isMobile ? "0.5rem" : "1rem"};
   color: ${COLORS.white};
   text-align: left;
`;


const CardImage = styled.img`
  width: 230px;
  height: 100%;
  border-radius: 0.4rem;
  object-fit: cover;
`;

const CardImageWrapper = styled.div<{ isMobile: boolean }>`
   width: ${props => props.isMobile ? '100%' : '500px'};
   display: flex;
   flex-direction: row;
   margin-bottom: 30px;
   justify-content: space-around;
   gap: 20px;
   align-items: center;

   img {
      width: ${props => props.isMobile ? '100px' : '230px'};
   }

`;

const SelectedCardSubtitle = styled.p<{ isMobile: boolean }>`
   font-size: ${props => props.isMobile ? "0.8rem" : "1rem"}};
   line-height: ${props => props.isMobile ? "1rem" : "1.5rem"}};
   margin: .4rem 0;
   text-align: left;
   color: ${COLORS.white};
`;