
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { NAVIGATION_WIDTH } from '../../../constants/dimensions';

export const BoorReaderWrapper = styled.div<{ isMobile: boolean, width: number }>`
   width:  ${({ isMobile, width }) => isMobile ? `${width - 20}px` : `calc(${width}px - ${NAVIGATION_WIDTH + 30}px)`};
   text-align: center;
   overflow: hidden;
   position: absolute;
   top: 0px;
   left: ${({ isMobile }) => isMobile ? '0px' : `10px`};
   margin: 80px 0px 10px 10px;
`;

export const OpenMenuIcon = styled.img<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '6px' : '15px'}};
   width: ${({ isMobile }) => isMobile ? '4px' : '8px'}};
   margin-left: -5px;
   height: ${({ isMobile }) => isMobile ? '14px' : '27px'}};
`

export const CloseMenuIcon = styled.img<{ isMobile: boolean }>`
   margin-top: ${({ isMobile }) => isMobile ? '6px' : '15px'}};
   width: ${({ isMobile }) => isMobile ? '4px' : '8px'}};
   margin-left: ${({ isMobile }) => isMobile ? '-2px' : '-5px'}};
   height: ${({ isMobile }) => isMobile ? '14px' : '27px'}};
   transform: rotate(180deg);
`

export const BookContentHolder = styled.div<{ isMobile: boolean }>`
   overflow: hidden;
   display: flex;
   justify-content: center;

   .react-pdf__Document {
      overflow-y: scroll;
      height: calc(100vh - 140px);
      .react-pdf__message {
         background-color: ${COLORS.white};
         height: ${({ isMobile }) => isMobile ? '85.9vh' : '93.9vh'} !important;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 20px;
         font-weight: 700;
      }
   }

   .react-pdf__message--error {
      display: none !important;
   }
   
  

   .react-pdf__Page__textContent {
      display: none;
   }

   .react-pdf__Page__annotations {
      display: none;
   }
`
export const PageFlipper = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 10px;
   gap: 10px;
   width: 500px;
`

export const BookActions = styled.div`
   display: flex;
   justify-content: right;
   align-items: center;
   gap: 10px;
   margin-right: 10px;
   margin-bottom: 10px;
   margin-top: 10px;
   margin-left: 10px;
   width: 300px;

   color: ${COLORS.urbanBlack};
   font-size: 16px;
   font-weight: 700;
   font-family: 'Montserrat';
`

export const PrevPage = styled.div<{ isAvailable: boolean, isMobile: boolean }>`
   height: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   width: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   align-items: center;
   text-align: center;
   border-right: 1px solid ${COLORS.whiteLight};
   border-top-left-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   border-bottom-left-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   background-color: ${({ isAvailable }) => isAvailable ? COLORS.white : COLORS.whiteLight};

   &:hover {
      background-color: ${COLORS.whiteLight};
      cursor: ${({ isAvailable }) => isAvailable ? 'pointer' : 'not-allowed'};
   }

`

export const NextPage = styled.div<{ isAvailable: boolean, isMobile: boolean }>`
   height: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   width: ${({ isMobile }) => isMobile ? '25px' : '55px'}};
   align-items: center;
   text-align: center;
   border-top-right-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   border-bottom-right-radius: ${({ isMobile }) => isMobile ? '5px' : '10px'}};
   background-color: ${({ isAvailable }) => isAvailable ? COLORS.white : COLORS.whiteLight};

   &:hover {
      background-color: ${COLORS.whiteLight};
      cursor: ${({ isAvailable }) => isAvailable ? 'pointer' : 'not-allowed'};

   }
`

export const BookPlanHolder = styled.div<{ isMobile: boolean }>`
   overflow: hidden;
`;

export const BookPlanTitle = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   align-items: center;

   .chapters {
      color: ${COLORS.blackLabel};
      opacity: 0.5;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: fit-content;

      img {
         margin-right: 5px;
      }
   }

   h1 {
      margin-left: 20px;
      margin-top: 20px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
      color: ${COLORS.blackLabel};
   }
`;

export const OneDescriptionElementWrapper = styled.div<{ isCurrent: boolean, isMobile: boolean, color: string }>`
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 171%;
   padding: 10px;
   background-color:${({ isCurrent, color }) => isCurrent ? color : COLORS.darkUrban};
   color: ${({ isCurrent }) => isCurrent ? COLORS.white : COLORS.white};
   border: ${({ isCurrent, color }) => isCurrent ? `2px solid ${color}` : `2px solid ${COLORS.blackLabel}`};
   cursor: pointer;
   margin-left: ${({ isMobile }) => isMobile ? '20px' : '20px'};
   margin-top: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   border-radius: 5px;
   transition: all 0.2s ease-in-out;
   text-align: left;

   &:hover {
      ${({ isMobile }) => isMobile ? '' : `background-color: ${COLORS.blackLabel} `}; 
      color: ${COLORS.white};
      border: 2px solid ${COLORS.white};
      cursor: pointer;
   }

`;

export const BookPlanWrapper = styled.div<{ isMobile: boolean, isMaterials: boolean }>`
   overflow-x: hidden;
   margin-bottom: 10px;
   height: ${({ isMobile }) => isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 210px)'};
   overflow-y: auto;  /* Only show vertical scrollbar when content exceeds the height */
`;

export const Divider = styled.div<{ isMobile: boolean }>`
   width: ${({ isMobile }) => isMobile ? 'calc(100vw - 40px)' : '305px'};
   height: 1px;
   background-color: ${COLORS.white};
   margin-bottom: 4px;
   margin-left: 20px;
   margin-bottom: 5px;
   opacity: 0.1;
`;


export const BookDownload = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   color: ${COLORS.white};
`;

export const ScaleWrapper = styled.div`
   display: flex;
   justify-content: left;
   align-items: center;
   gap: 10px;
   margin: 10px;
   margin-bottom: 10px;
   color: ${COLORS.urbanBlack};
   font-size: 14px;
   font-weight: 700;
   font-family: 'Montserrat';
   width: 300px;

   .scale {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${COLORS.whiteGray};
      border-radius: 5px;
      width: 60px;
      height: 32px;
      color: ${COLORS.urbanBlack
   }
`;