import { motion } from "framer-motion"

import ballon from '../../assets/global/ballon.png';
import urbanPng from '../../assets/global/urban.png';
import center from '../../assets/global/center.png';

import { AppLoadingWrapper, CloudWrapper, MediumCloudWrapper } from './styled';


const AppLoading = () => {
   return (
      <AppLoadingWrapper>
         <MediumCloudWrapper>
            <motion.div
               initial={{ x: 50 }}
               animate={{ x: -10 }}
               transition={{
                  duration: 1.7
               }}
            >
               <div>
                  <img src={ballon} alt="" />
               </div>
            </motion.div>
         </MediumCloudWrapper>
         <CloudWrapper>
            <motion.div
               initial={{ x: -50 }}
               animate={{ x: -20 }}
               transition={{
                  duration: 1.7
               }}
            >
               <div>
                  <img src={urbanPng} alt='' />
                  <img src={center} alt='' />
               </div>
            </motion.div>
         </CloudWrapper>
      </AppLoadingWrapper>
   );
};

export default AppLoading;