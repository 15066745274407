import styled from 'styled-components';
import { hexToRGB } from '../../../utils/colorConverter';
import { theme } from '../../../styles/theme';
import { COLORS } from '../../../constants/colors';

export const LabelWrapper = styled.div`
    width: auto;
    height: auto;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    align-items: left;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 100px;
    color: ${COLORS.white};
    padding: 3px 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
      background: ${COLORS.buttonSecondaryBGActive};

      &.color-Event-lab {
        background: ${COLORS.eventLab};
      }

      &.color-Art-therapy {
        background: ${COLORS.artTherapy};
      }

      &.color-Kids {
        background: ${COLORS.kids};
      }

      &.color-Teens {
        background: ${COLORS.teens};
      }

      &.color-Youth {
        background: ${COLORS.youth};
      }

      &.color-Show {
        background: ${COLORS.show};
      }
    }

    &:hover {
      border-color: ${COLORS.white};

      &.color-Event-lab {
        background: ${COLORS.eventLab};
      }

      &.color-Art-therapy {
        background: ${COLORS.artTherapy};
      }

      &.color-Kids {
        background: ${COLORS.kids};
      }

      &.color-Teens {
        background: ${COLORS.teens};
      }

      &.color-Youth {
        background: ${COLORS.youth};
      }

      &.color-Show {
        background: ${COLORS.show};
      }
    }

    &:disabled,
    [disabled] {
      background-color: ${hexToRGB(theme.colors.grey, 0.1)};
      border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      color: ${hexToRGB(theme.colors.grey, 0.5)};
      pointer-events: none;

      &:hover {
        box-shadow: none;
        border-color: ${hexToRGB(theme.colors.grey, 0.1)};
      }
    }
`;