import useWindowBreakpoint from './useWindowBreakpoint';

import { LG_SCREEN, MD_SCREEN, XLG_SCREEN } from './constants';

function useMediaQuery() {
  const breakpoint = useWindowBreakpoint();

  const mediumParsed =  MD_SCREEN;
  const largeParsed = LG_SCREEN;
  const xLargeParsed =  XLG_SCREEN;

  const isMobile = (width: number) => width < mediumParsed;
  const isTablet = (width: number) => width >= mediumParsed;
  const isDesktop = (width: number) => width >= largeParsed;
  const isDesktopLarge = (width: number) => width >= xLargeParsed;

  return {
    isMobile: isMobile(breakpoint),
    isTablet: isTablet(breakpoint),
    isDesktop: isDesktop(breakpoint),
    isDesktopLarge: isDesktopLarge(breakpoint),
    width: breakpoint,
  };
}

export default useMediaQuery;
