import { PersonalitySections, CursesSections, PersonalityTypes } from '../../types/common';
import { PERSONALITIES, PERSONALITY, SECTIONS } from '../../constants/courses';

export type PersonalityType = {
   personalitySection: PersonalitySections;
   section: CursesSections;
   personalityType: PersonalityTypes;
   miniType: string;
}


export const personalitiesAnalyticConfig: PersonalityType[] = [
   {
      personalitySection: PERSONALITIES.analytic as PersonalitySections,
      section: SECTIONS.eventLab as CursesSections,
      personalityType: PERSONALITY.strategic as PersonalityTypes,
      miniType: 'INTJ-A / INTJ-T'
   },
   {
      personalitySection: PERSONALITIES.analytic as PersonalitySections,
      section: SECTIONS.eventLab as CursesSections,
      personalityType: PERSONALITY.logician as PersonalityTypes,
      miniType: 'INTP-A / INTP-T'
   },
   {
      personalitySection: PERSONALITIES.analytic as PersonalitySections,
      section: SECTIONS.eventLab as CursesSections,
      personalityType: PERSONALITY.commander as PersonalityTypes,
      miniType: 'ENTJ-A / ENTJ-T'
   },
   {
      personalitySection: PERSONALITIES.analytic as PersonalitySections,
      section: SECTIONS.eventLab as CursesSections,
      personalityType: PERSONALITY.debater as PersonalityTypes,
      miniType: 'ENTP-A / ENTP-T'
   }
]

export const personalitiesDiplomatConfig: PersonalityType[] = [
   {
      personalitySection: PERSONALITIES.diplomat as PersonalitySections,
      section: SECTIONS.kids as CursesSections,
      personalityType: PERSONALITY.advocate as PersonalityTypes,
      miniType: 'INFJ-A / INFJ-T'
   },
   {
      personalitySection: PERSONALITIES.diplomat as PersonalitySections,
      section: SECTIONS.kids as CursesSections,
      personalityType: PERSONALITY.mediator as PersonalityTypes,
      miniType: 'INFP-A / INFP-T'
   },
   {
      personalitySection: PERSONALITIES.diplomat as PersonalitySections,
      section: SECTIONS.kids as CursesSections,
      personalityType: PERSONALITY.protagonist as PersonalityTypes,
      miniType: 'ENFJ-A / ENFJ-T'
   },
   {
      personalitySection: PERSONALITIES.diplomat as PersonalitySections,
      section: SECTIONS.kids as CursesSections,
      personalityType: PERSONALITY.campaigner as PersonalityTypes,
      miniType: 'ENFP-A / ENFP-T'
   }
]


export const personalitiesKeeperConfig: PersonalityType[] = [
   {
      personalitySection: PERSONALITIES.keeper as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.logistician as PersonalityTypes,
      miniType: 'ISTJ-A / ISTJ-T'
   },
   {
      personalitySection: PERSONALITIES.keeper as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.defender as PersonalityTypes,
      miniType: 'ISFJ-A / ISFJ-T'
   },
   {
      personalitySection: PERSONALITIES.keeper as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.executive as PersonalityTypes,
      miniType: 'ESTJ-A / ESTJ-T'
   },
   {
      personalitySection: PERSONALITIES.keeper as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.consul as PersonalityTypes,
      miniType: 'ESFJ-A / ESFJ-T'
   }
]

export const personalitiesFinderConfig: PersonalityType[] = [
   {
      personalitySection: PERSONALITIES.finder as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.virtuoso as PersonalityTypes,
      miniType: 'ISTP-A / ISTP-T'
   },
   {
      personalitySection: PERSONALITIES.finder as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.adventurer as PersonalityTypes,
      miniType: 'ISFP-A / ISFP-T'
   },
   {
      personalitySection: PERSONALITIES.finder as PersonalitySections,
      section: SECTIONS.youth as CursesSections,
      personalityType: PERSONALITY.entrepreneur as PersonalityTypes,
      miniType: 'ESTP-A / ESTP-T'
   },
   {
      personalitySection: PERSONALITIES.finder as PersonalitySections,
      section: SECTIONS.artTherapy as CursesSections,
      personalityType: PERSONALITY.entertainer as PersonalityTypes,
      miniType: 'ESFP-A / ESFP-T'
   }
]
