import styled from "styled-components";

export const MiniGamesWrapper = styled.div`
  display: flex;
`;

export const OneMiniGamesWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;

  .associations {
    width: 38px !important;
  }

  img {
    margin-top: 8px;
    width: 30px !important;
    height: 30px !important;
  }
`;