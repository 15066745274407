import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';

import LeftMenu from '../../UI/LeftMenu/LeftMenu';
import { selectCurrentPage, selectCurrentConfig, selectLeftMenuState } from '../../../store/selectors/book';
import { setCurrentBookConfig, setLeftMenu, setUserFirstTime } from '../../../store/actions/bookActions';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import FirstTimeTooltip from '../../UI/BookComponents/FirstTimeTooltip';
import BookPlan from './BookPlan';
import { selectCurrentCourse } from '../../../store/selectors/courses';
import { getCourseBookConfig } from '../../../utils/courses/getCourseBookConfig';
import { COURSES } from '../../../constants/courses';
import { CoursesModifications } from '../../../types/courses/courses';
import { setCurrentCourse } from '../../../store/actions/appActions';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

import {
   BoorReaderWrapper as BookReaderWrapper,
   BookContentHolder,
   PageFlipper,
   ScaleWrapper,
   BookActions,
   BookDownload,
} from './styled';
import { selectCurrentLanguage } from '../../../store/selectors/translation';
import { selectUserProfile } from '../../../store/selectors/user';
import TopBar from '../../UI/TopBar/TopBar';
import { Button } from '../../UI/Button';
import { downloadMaterials } from '../../../utils/courses/downloadMaterials';

type Props = {
   modification: CoursesModifications;
};

const BookReader = ({ modification }: Props) => {
   const dispatch = useDispatch();
   const [scale, setScale] = useState(1.5);
   const currentPage = useSelector(selectCurrentPage);
   const currentConfig = useSelector(selectCurrentConfig);
   const currentCourse = useSelector(selectCurrentCourse);
   const [numPages, setNumPages] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const isFirstPage = pageNumber > 1;
   const { isMobile, width } = useMediaQuery();
   const isSmallScreen = width < 1200;
   const navigate = useNavigate();
   const search = useLocation().search;
   const searchParams = new URLSearchParams(search);
   const materialsModification = searchParams.get('materials') as string;
   const userProfile = useSelector(selectUserProfile);
   const currentLanguage = useSelector(selectCurrentLanguage);
   const bookLanguage = userProfile?.language || currentLanguage;
   const isLastPage = isSmallScreen ? pageNumber < numPages : pageNumber < numPages - 1;
   const isLeftMenuOpened = useSelector(selectLeftMenuState)
   const [isDownloading, setIsDownloading] = useState(false);
   const [isDownloadingDone, setIsDownloadDone] = useState(false);


   const handleCloseLeftMenu = () => {
      dispatch(setLeftMenu({ isLeftMenuOpen: false }));
   };

   const toggleLeftMenu = () => {
      dispatch(setLeftMenu({ isLeftMenuOpen: !isLeftMenuOpened }))
      dispatch(setUserFirstTime({ isUserFirstTime: false }))
   }
   const materials = currentConfig[currentPage]?.materials;

   const [hasOpenedModal, setHasOpenedModal] = useState(false);

   useEffect(() => {
      if (materialsModification && !hasOpenedModal) {
         setHasOpenedModal(true); // Update state to indicate that modal has been opened
      }
   }, [materialsModification, hasOpenedModal]);

   useEffect(() => {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   }, []);

   useEffect(() => {
      setPageNumber(1);
      dispatch(setCurrentCourse({ courseModification: modification }));
   }, [currentPage]);

   useEffect(() => {
      dispatch(
         setCurrentBookConfig({
            currentConfig: getCourseBookConfig(currentCourse, materialsModification, bookLanguage),
         }),
      );
   }, [currentCourse, bookLanguage]);

   const nextPage = () => {
      handleCloseLeftMenu();
      document.getElementById('react-pdf__Page__canvas');
      if (isLastPage) {
         setPageNumber(pageNumber + 1);
      }
   };

   const prevPage = () => {
      handleCloseLeftMenu();
      if (isFirstPage) {
         setPageNumber(pageNumber - 1);
      }
   };

   function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
      setNumPages(numPages);
   }

   const increaseScale = () => {
      setScale(prevScale => Math.min(prevScale + 0.1, 2)); 
   };

   const decreaseScale = () => {
      setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
   };

   if (currentCourse === COURSES.notSelected) {
      navigate('/cabinet');
      return <div>Выберите курс</div>;
   }

   const materialsNames = currentConfig[currentPage]?.materialsNames;
   const isForDownload = currentConfig[currentPage]?.isForDownload;

   const handleMaterialsClick = () => {
      if (isForDownload && Array.isArray(materials) && Array.isArray(materialsNames)) {
         setIsDownloading(true);
         return downloadMaterials(materials, materialsNames, () => {
            setIsDownloading(false)
            setIsDownloadDone(true)
         })
      }
      if (!Array.isArray(materials)) {
         return window.open(materials, '_blank')
      }
   }

   const renderDownloadButton = () => {
      if (isDownloading) {
         return <CircularProgress size={20} color='secondary' />
      }
      if (isDownloadingDone) {
         return <DownloadDoneIcon fontSize='small' />
      } else {
         return <FileDownloadIcon fontSize='small' />
      }
   }


   return (
      <>
         <TopBar>
            <ScaleWrapper>
               <Button modifier='square-primary' onClick={increaseScale}>+</Button>
               <div className='scale'>
                  <span>{Math.round(scale * 100)}%</span>
               </div>
               <Button modifier='square-primary' onClick={decreaseScale}>-</Button>
            </ScaleWrapper>
            <PageFlipper isMobile={isMobile}>
               <Button modifier='square-primary' onClick={prevPage}>
                  <ArrowCircleLeftIcon />
               </Button>
               <Button modifier='square-primary' onClick={nextPage}>
                  <ArrowCircleRightIcon />
               </Button>
            </PageFlipper>
            <BookActions>
               {materials && <>
                  <BookDownload isMobile={isMobile}>
                     <Button
                        modifier='square-text-primary'
                        onClick={handleMaterialsClick}
                        disabled={isDownloading}
                     >
                       {!isMobile && "Материалы"} {renderDownloadButton()}
                     </Button>
                  </BookDownload>
               </>}
               <Button modifier='square-text-primary' onClick={toggleLeftMenu}>
                  {!isMobile && "Оглавление"}  {isLeftMenuOpened ? <MenuBookIcon /> : <ImportContactsIcon />}
               </Button>
            </BookActions>
         </TopBar>
         <BookReaderWrapper isMobile={isMobile} width={width}>
            <BookContentHolder isMobile={isMobile}>
               <Document
                  file={currentConfig[currentPage]?.file}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error='Не получилось загрузить учебник'
                  loading={<CircularProgress color='secondary' />}
                  renderMode='svg'
               >
                  <Page pageNumber={pageNumber} scale={scale} />
               </Document>
            </BookContentHolder>
         </BookReaderWrapper>
         <LeftMenu>
            <BookPlan />
         </LeftMenu>
      </>
   );
};


export default BookReader;
