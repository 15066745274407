import Modal from '@mui/material/Modal';
import ModalHeader from './ModalHeader';
import { ModalWrapperBox } from './styled';
import { CursesSections } from 'types/common';



type Props = {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  width?: number;
  height?: number;
  bgcolor: string;
  modification: CursesSections;
  title: string;
}

const MaterialModal = ({ children, open, handleClose, width = 600, height = 750, bgcolor, modification, title }: Props) => {

  const renderBody = () => {
    return (
      <ModalWrapperBox width={width} bgcolor={bgcolor}  height={height}>
        <ModalHeader onClose={handleClose} title={title} modification={modification} width={width}  />
        {children}
      </ModalWrapperBox>

    )
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {renderBody()}
      </Modal>
    </div>
  );
}

export default MaterialModal;