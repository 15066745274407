import useMediaQuery from "../../../../utils/breakpoints/useMediaQuery";
import WeekCalendar from "./WeekCalendar";

import { CalendarWrapper } from "./styled";

const Calendar = () => {
   const { isMobile } = useMediaQuery();

   return <CalendarWrapper isMobile={isMobile}>
      <WeekCalendar />
   </CalendarWrapper>
}
 
export default Calendar;