import Modal from '@mui/material/Modal';
import SideModalHeader from './SideModalHeader';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import { SideModalWrapperBox } from './styled';

type Props = {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  modalWidth?: number;
  height?: number;
  bgcolor: string;
}

const SideModal = ({ children, open, handleClose, modalWidth = 600, height = 750, bgcolor }: Props) => {

  const { isMobile, width } = useMediaQuery();

  const renderBody = () => {
    return (
      <SideModalWrapperBox width={isMobile ? width : modalWidth} bgcolor={bgcolor}  height={height}>
        <SideModalHeader onClose={handleClose} width={isMobile ? width : modalWidth}  />
        {children}
      </SideModalWrapperBox>
    )
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {renderBody()}
      </Modal>
    </div>
  );
}

export default SideModal;