import { useState } from 'react';

import StartStep from './Steps/StartStep';
import RandomNumberStep from './Steps/RandomNumberStep';
import FinalStep from './Steps/FinalStep';
import UserInputStep from './Steps/UserInputStep';

import { sumOfArray } from '../../utils/sumOfArray';
import { getRandomArray } from '../../utils/getRandomArray';

import {
   MentalMathWrapper,
} from './style'

type Props = {
   closeModal: () => void
}

const MentalMathGame = ({closeModal}: Props) => {
   const [currentStep, setCurrentStep] = useState<number>(0);
   const [userAnswer, setUserAnswer] = useState('');
   const arrayOfNumbers = getRandomArray({length: 10, max: 9, min: 0});
   const currentNumberSum = sumOfArray(arrayOfNumbers)
   const isUserAnswerCorrect = currentNumberSum === Number(userAnswer);

   const setNextStep = () => {
      if (currentStep === 3) return
      setCurrentStep(currentStep + 1)
   }

   const steps = [{
      description: 'games.responseFromMind.steps.stepOne',
      component: <StartStep
         setNextStep={setNextStep}
      />,
   },
   {
      description: 'games.responseFromMind.steps.stepOne',
      component: <RandomNumberStep arrayOfNumbers={arrayOfNumbers} setNextStep={setNextStep} />,
   },
   {
      description: 'games.responseFromMind.steps.stepOne',
      component: <UserInputStep userAnswer={userAnswer} setUserAnswer={setUserAnswer} setNextStep={setNextStep} />,
   },
   {
      description: 'games.responseFromMind.steps.stepOne',
      component: <FinalStep closeModal={closeModal} isUserAnswerCorrect={isUserAnswerCorrect} correctSum={currentNumberSum} />,
   },]

   return (
      <MentalMathWrapper>
         {steps[currentStep].component}
      </MentalMathWrapper>
   );
};

export default MentalMathGame;