
export enum BOOK_ACTION_TYPES {
  SET_CURRENT_PAGE = 'BOOK::SET_CURRENT_PAGE',
  SET_LEFT_MENU = 'BOOK::SET_LEFT_MENU',
  SET_USER_FIRST_TIME = 'BOOK::SET_USER_FIRST_TIME',
  SET_CURRENT_BOOK_CONFIG = 'BOOK::SET_CURRENT_BOOK_CONFIG',
}

export type BookAction =
  | { type: BOOK_ACTION_TYPES.SET_CURRENT_PAGE; currentPage: number }
  | { type: BOOK_ACTION_TYPES.SET_LEFT_MENU; isLeftMenuOpen: boolean }
  | { type: BOOK_ACTION_TYPES.SET_USER_FIRST_TIME; isUserFirstTime: boolean }
  | {
    type: BOOK_ACTION_TYPES.SET_CURRENT_BOOK_CONFIG; currentConfig: {
      name: string;
      file: string;
      materials?: string | string[];
      materialsNames?:  string[];
      isForDownload?: boolean;
    }[]
  };

export const setCurrentPage = ({ currentPage }: { currentPage: number }): BookAction => {
  return { type: BOOK_ACTION_TYPES.SET_CURRENT_PAGE, currentPage };
};

export const setLeftMenu = ({ isLeftMenuOpen }: { isLeftMenuOpen: boolean }): BookAction => {
  return { type: BOOK_ACTION_TYPES.SET_LEFT_MENU, isLeftMenuOpen };
};

export const setUserFirstTime = ({ isUserFirstTime }: { isUserFirstTime: boolean }): BookAction => {
  return { type: BOOK_ACTION_TYPES.SET_USER_FIRST_TIME, isUserFirstTime };
}

export const setCurrentBookConfig = ({ currentConfig }: {
  currentConfig: {
    name: string;
    file: string;
    materials?: string | string[];
    materialsNames?:  string[];
    isForDownload?: boolean;
  }[]
}): BookAction => {
  return { type: BOOK_ACTION_TYPES.SET_CURRENT_BOOK_CONFIG, currentConfig };
}
