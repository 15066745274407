import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ContactsMap from '../Courses/CommonComponents/ContactsMap';
import DirectionPreview from '../common/DirectionPreview';
import { SECTIONS } from '../../../constants/courses';
import { CoursesButtonTypes, CoursesModifications } from '../../../types/courses/courses';
import eventLabDescriptionBG from '../../../assets/directions/artDescriptionBG.png';
import { CursesSections } from '../../../types/common';
import MaterialModal from '../../../components/UI/Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';
import InvoiceSteps from '../../../components/InvoiceSteps/InvoiceSteps';

import { EventWrapper } from './styles';

const EventLab = () => {
   const { t } = useTranslation();
   const [isModalOpened, setIsModalOpened] = useState(false);

   const handleDirectionAction = () => {
      setIsModalOpened(!isModalOpened);
   }

   return (
      <EventWrapper>
         <DirectionPreview
            bgImage={eventLabDescriptionBG}
            sectionName={SECTIONS.eventLab as CursesSections}
            button={CoursesButtonTypes.SUBSCRIBE}
            buttonModification={'primaryOutlined'}
            customTag={'Мероприятия'}
            handleDirectionAction={handleDirectionAction}
         />
         <ContactsMap handleButtonClick={handleDirectionAction} />
         <MaterialModal
            modification={SECTIONS.eventLab as CursesSections}
            open={isModalOpened}
            handleClose={handleDirectionAction}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.eventLab}.title`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.eventLab as CursesSections}
               handleCloseModal={handleDirectionAction}
               course={SECTIONS.eventLab as CoursesModifications}
               invoiceDescription={t(`directions.${SECTIONS.eventLab}.invoiceDescription`)}
            />
         </MaterialModal>
      </EventWrapper>
   );
};

export default EventLab;