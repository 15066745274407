import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const LeftMenuWrapper = styled.div<{ isOpened: boolean, width: number, isMobile: boolean }>`
   display: flex;
   justify-content: start;
   width: ${({ isOpened, width, isMobile }) => isOpened ? `${isMobile ? width : 338}px` : '0px'};
   position: absolute;
   top: 80px;
   right: 0px;
   background-color: ${({ isMobile, isOpened }) => isMobile ? `${isOpened ? COLORS.white: 'transparent' }` : COLORS.white };
   align-items: center;
   text-align: center;
   overflow: hidden;
   border-radius: 10px;
   z-index: 2;
   transition: width 0.5s;
   margin-right: ${({ isMobile }) => isMobile ? '0px' : '10px'};
   margin-bottom: ${({ isMobile }) => isMobile ? '0px' : '10px'};
`

export const LeftMenuControllerWrapper = styled.div<{ isMobile?: boolean, isLeftMenuOpened: boolean }>`
   width: 60px;
   height: 100vh;
   z-index: 2;
   user-select: none;
   border-right: ${({ isLeftMenuOpened }) => isLeftMenuOpened ? '1px solid rgba(166, 166, 166, 0.13)' : 'none'};
   min-width: 50px;

   &:hover {
      cursor: pointer;
   }
`

export const OpenMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
`

export const CloseMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
   transform: rotate(180deg);
`
