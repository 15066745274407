import axios from "axios";
import { Dispatch } from "redux";
import { NewsTypes } from "../../types/courses/news";
import { LoadingStatuses } from '../reducers/types/index';
import { API_NEWS } from '../../constants/news';

export enum NEWS_ACTION_TYPES {
   GET_ALL_NEWS = 'NEWS::GET_ALL_NEWS',
   SET_POPULAR_NEWS_STATUS = "NEWS::SET_POPULAR_NEWS_STATUS",
}

export type NewsAction =
   | { type: NEWS_ACTION_TYPES.GET_ALL_NEWS; news: NewsTypes[] }
   | { type: NEWS_ACTION_TYPES.SET_POPULAR_NEWS_STATUS; newsStatus: LoadingStatuses };

export const setAllNews = ({ news }: { news: NewsTypes[] }): NewsAction => {
   return { type: NEWS_ACTION_TYPES.GET_ALL_NEWS, news };
};

export const setNewsStatus = ({ newsStatus }: { newsStatus: LoadingStatuses }): NewsAction => {
   return { type: NEWS_ACTION_TYPES.SET_POPULAR_NEWS_STATUS, newsStatus };
};


export const getAllNews = () => {
   return (dispatch: Dispatch) => {
      dispatch(setNewsStatus({ newsStatus: "loading" }));
      return axios.get(API_NEWS).then((response) => {
         dispatch(setNewsStatus({ newsStatus: "idle" }));
         return dispatch(setAllNews({ news: response.data }))
      })
         .catch(() => {
            dispatch(setNewsStatus({ newsStatus: "error" }));
            return dispatch(setAllNews({ news: [] }))
         })
   };
};
