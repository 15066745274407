import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CursesSections } from '../../types/common';
import { CoursesModifications } from 'types/courses/courses';
import PurchaseInfo from './PurchaseInfo';
import LastStepModal from './StepsMaib/LastStepModal';


import {
   StepWrapper,
   TermsWrapper
} from './styled'
import SecondStepModal from './StepsMaib/SecondStepModal';


type Props = {
   modification: CursesSections;
   course: CoursesModifications;
}

const PaymentStepsCallback = ({ modification, course }: Props) => {
   const [activeStep, setActiveStep] = useState(0);

   const handleNextSecondStep = () => {
      setActiveStep(activeStep + 1);
   }

   const stepsMaib = [
      {
         components: <SecondStepModal handleNextSecondStep={handleNextSecondStep} course_to_update={course} />,
      },
      {
         components: <LastStepModal />,
      }
   ];

   return (
      <StepWrapper isMobile={true}>
         <PurchaseInfo course={course} modification={modification} />
         {stepsMaib[activeStep].components}
         <TermsWrapper>
            Покупка  курсов Urban  быстрая, простая и безопасная.
            Действуют <Link className='link' to={'/urban_terms'} >условия и положения</Link>.
            Не стесняйтесь обращаться в службу поддержки покупок, если вам нужна помощь.
         </TermsWrapper>
      </StepWrapper>
   );
};

export default PaymentStepsCallback;