import { useTranslation } from 'react-i18next';
import { CursesSections } from 'types/common';

import OneDirection from './OneDirection';
import OneDirectionCustom from './OneDirectionCustom';
import { SECTIONS } from '../../../../constants/courses';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import artBanner from '../../../../assets/directions/artBanner.png';
import eventLabBanner from '../../../../assets/directions/eventLabBanner.png';
import teensBanner from '../../../../assets/directions/teensBanner.png';

import { DirectionsWrapper } from './styled';

export type DirectionsType = {
   title: string;
   description: string;
   image: string;
   modification: CursesSections;
};

export type DirectionsTypeCustom = {
   title: string;
   description: string;
   image: string;
   modification: CursesSections;
};


const Directions = () => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   const directions: DirectionsType[] = [
      {
         title: t(`directions.${SECTIONS.teens}.title`),
         description: t(`directions.${SECTIONS.teens}.description`),
         image: teensBanner,
         modification: SECTIONS.teens as CursesSections,
      },
      {
         title: t(`directions.${SECTIONS.artTherapy}.title`),
         description: t(`directions.${SECTIONS.artTherapy}.description`),
         image: artBanner,
         modification: SECTIONS.artTherapy as CursesSections,
      },
   ]

   return (
      <DirectionsWrapper isMobile={isMobile}>
         <h1>{t('directions.title')}</h1>
         <OneDirectionCustom
            modification={SECTIONS.eventLab as CursesSections}
            title={t(`directions.${SECTIONS.eventLab}.title`)}
            description={t(`directions.${SECTIONS.eventLab}.description`)}
            image={eventLabBanner} />
         {directions.map((direction, index) => (
            <OneDirection
               key={index}
               title={direction.title}
               description={direction.description}
               image={direction.image}
               modification={direction.modification}
            />
         ))}
      </DirectionsWrapper>
   );
}

export default Directions;