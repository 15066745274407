import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoginIcon from '@mui/icons-material/Login';
import { Button } from '../../UI/Button/index';

import { logoutUser, updateTranslation } from '../../../store/actions/userActions';
import { selectUserProfile } from '../../../store/selectors/user';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import {
   UserInfoWrapper,
   DropDownWrapper,
   ButtonUserWrapper,
   ButtonWrapper
} from './styled';
import User from './User';
import { selectCurrentLanguage } from '../../../store/selectors/translation';
import { TranslateType, changeTranslation } from '../../../store/actions/translateAction';

const UserInfo = () => {
   const [openDropdown, setOpenDropdown] = useState(false);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const userProfile = useSelector(selectUserProfile);
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()
   const { i18n } = useTranslation();
   const translation = useSelector(selectCurrentLanguage);
   const isUserLoggedIn = !!userProfile?.id;

   const handleOpenDropdown = () => {
      setOpenDropdown(!openDropdown);
   };

   const handleLoginClick = () => {
      dispatch(logoutUser() as any);
   };

   const handleAdmin = () => {
      navigate('/admin');
   };



   const changeLanguage = (lng: TranslateType) => {
      if (isUserLoggedIn) {
         dispatch(updateTranslation({ newLanguage: lng, i18n }) as any)

      } else {
         dispatch(changeTranslation({ newLanguage: lng }));
      }
   };

   const buttonsConfig = [
      {
         title: `${translation === 'ru' ? 'Romanian' : 'Русский'}`,
         onClick: () => translation === 'ru' ? changeLanguage('ro') : changeLanguage('ru'),
         show: isMobile
      },
      {
         title: 'Панель',
         onClick: () => handleAdmin(),
         show: userProfile?.is_admin // add a show property to conditionally render the button
      },
      {
         title: 'Выйти',
         className: 'logout',
         onClick: () => handleLoginClick()
      }
   ].filter(button => button.show !== false);


   return (
      <UserInfoWrapper>
         {userProfile && <div><User handleOpenDropdown={handleOpenDropdown} /></div>}
         {openDropdown && userProfile ? (
            <DropDownWrapper isMobile={isMobile}>
               {buttonsConfig.map((button, index) => (
                  <ButtonUserWrapper key={index} className={button.className} onClick={button.onClick}>{button.title}</ButtonUserWrapper>
               ))}
            </DropDownWrapper>
         ) : null}
         <ButtonWrapper isMobile={isMobile}>
            {!userProfile && <Button
               modifier='login'
               onClick={() => navigate('/login')}
            >
               {isMobile ? <LoginIcon /> : t('header.login')}
            </Button>}
         </ButtonWrapper>
      </UserInfoWrapper>
   );
};

export default UserInfo;