import { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { getAllCourses, getPremiumCourses } from './store/actions/appActions';
import { AppWrapper } from './App.styled';
import './translation/translation';
import { GlobalStyle } from './styles/globalStyles';
import { ThemeProvider, MuiThemeProvider } from '@material-ui/core';
import { materialTheme, theme } from './styles/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLoading from './components/AppLoading/AppLoading';
import useMediaQuery from './utils/breakpoints/useMediaQuery';
import Pages from './components/Pages/Pages';
import { setAuthTokens, updateTokens, getUserInfo } from './store/actions/userActions';
import { selectAuthTokens, selectUserProfile } from './store/selectors/user';
import { useTranslation } from 'react-i18next';
import { selectCurrentLanguage } from './store/selectors/translation';

const TOKEN_UPDATE_INTERVAL = 1000 * 60 * 4;

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { isMobile } = useMediaQuery();
  const authTokens = useSelector(selectAuthTokens);
  const authTokensCookie = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
  const userProfile = useSelector(selectUserProfile);
  const translation = useSelector(selectCurrentLanguage);

  useEffect(() => {
    if (userProfile?.id && userProfile?.language) {
      i18n.changeLanguage(userProfile.language);
    } else {
      i18n.changeLanguage(translation);
    }
  }, [userProfile?.language, translation]);

  useEffect(() => {
    dispatch(getAllCourses() as any);
    dispatch(getPremiumCourses() as any)

    authTokensCookie && dispatch(setAuthTokens({ authTokens: authTokensCookie }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserInfo() as any);
  }, [authTokens]);

  useEffect(() => {
    if (isAppLoading) {
      dispatch(updateTokens() as any)
    }
  }, [isAppLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (authTokens) {
        dispatch(updateTokens() as any)
      }
    }, TOKEN_UPDATE_INTERVAL);
    return () => clearInterval(interval);

  }, [authTokens, isAppLoading]);

  useEffect(() => {
    setTimeout(() => {
      setIsAppLoading(false);
    }, 2000);
  }, []);

  return (
    <div data-test='component-app'>
      <AppWrapper isMobile={isMobile}>
        <Router>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={materialTheme}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                autoHideDuration={3500}
                classes={{
                  variantSuccess: 'snackbar-success',
                  variantError: 'snackbar-error',
                  variantWarning: 'snackbar-warning',
                  variantInfo: 'snackbar-info',
                }}
              >
                <GlobalStyle />
                {isAppLoading && <AppLoading />}
                <Pages />
              </SnackbarProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </Router>
      </AppWrapper>
    </div>
  );
}

export default App;
