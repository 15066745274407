
import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const CabinetItemWrapper = styled.div`
   width: 200px;
   height: 100px;
`;

export const CabinetPlanHolder = styled.div<{ isMobile: boolean }>`
   border-radius: ${({ isMobile }) => isMobile ? '0px' : '15px'};
   margin: ${({ isMobile }) => isMobile ? '0px 10px 0px 0px' : '20px 0px'};
   height: ${({ isMobile }) => isMobile ? 'auto' : '470px'};
   width: ${({ isMobile }) => isMobile ? '100%' : '440px'};
   background-color: ${COLORS.blackLabel};
`;

export const CabinetPlanWrapper = styled.div<{ isMobile: boolean }>`
   overflow: scroll;
   overflow-x: hidden;
   height: ${({ isMobile }) => isMobile ? '200px' : '350px'};
   margin-bottom: 10px;
`;

export const CourseMaterials = styled.div<{ isMobile: boolean }>`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-right: 30px;

   .chapters {
      color: ${COLORS.white};
      opacity: 0.5;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: fit-content;

      img {
         margin-right: 5px;
      }
   }

   h1 {
      margin-left: 20px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
   }
`;

export const CourseDownload = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   button {
      width: 40px !important;
      height: 40px !important;
      margin-right: 20px;
      margin-bottom: 10px;
   }

   h1 {
      margin-left: 20px;
      margin-top: 9px;
      font-size: 14px;
      line-height: ${({ isMobile }) => isMobile ? '12px' : '28px'};
   }
`;

export const CourseRecommendedWrapper = styled.div`
   display: flex;
   margin-right: 20px;
   margin-top: -20px;
   flex-direction: column;
   min-width: 450px;
   max-width: 450px;
   height: calc(100vh - 550px);
   overflow: scroll;
`;

export const TopRowWrapperRecommended = styled.div`
   display: flex;
   justify-content: space-between;
   position: sticky;
   margin-right: 20px;
   left: 0;

   h1 {
      line-height: 20px;
      position: sticky;
      left: 0;
      font-size: 18px;
      color: ${COLORS.blackLabel};
   }
`;

export const VideoInfoWrapper = styled.div`
   color: ${COLORS.urbanBlack} ;
   display: flex;
   flex-direction: column;
   align-items: left;
   margin-left: 20px;
   height: 100%;
   width: 90%;

   h3 {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 0px;
   }

   p{
      font-size: 14px;
      line-height: 19px;
   }

`;

export const OneDescriptionElementWrapper = styled.div<{ isCurrent: boolean, isMobile: boolean, color: string }>`
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 171%;
   padding: 10px;
   background-color:${({ color }) => color};
   color: ${({ isCurrent }) => isCurrent ? COLORS.white : COLORS.white};
   border: ${({ isCurrent }) => isCurrent ? `2px solid ${COLORS.white}` : `2px solid ${COLORS.blackLabel}`};
   cursor: pointer;
   margin-left: ${({ isMobile }) => isMobile ? '20px' : '20px'};
   margin-right: ${({ isMobile }) => isMobile ? '20px' : '20px'};
   margin-top: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '10px'};
   border-radius: 5px;
   transition: all 0.2s ease-in-out;
   display: flex;
   justify-content: space-between;
   align-items: center;

   &.disabled {
      opacity: 0.5;
      .text {
         text-decoration: line-through;
      }
   }

   &:hover {
      ${({ isMobile, isCurrent, color }) => isMobile ? '' : `background-color: ${isCurrent ? color : COLORS.buttonSecondaryBGActive} `}; 
      color: ${COLORS.white};
      border: 2px solid ${COLORS.white};
      cursor: pointer;
   }

`;

export const VideoDescriptionWrapper = styled.div`
   font-weight: 500;
   font-size: 12px;
   line-height: 131%;
   padding: 4px 10px;
   background-color: ${COLORS.buttonSecondaryBG};
   color: #FFFFFF;
   cursor: pointer;
   margin-left: 20px;
   margin-right: 20px;
   margin-top: 10px;
   margin-bottom: 10px;
   border-radius: 5px;
`

export const LectorImage = styled.img`
   width: 80px;
   height: 80px;
   margin-right: 10px;
`

export const LectorDescriptionRow = styled.div`
   margin-left: 20px;
   display: flex;
   flex-direction: row;
`

export const LectorDescription = styled.div`
   display: flex;
   flex-direction: column;
   color: ${COLORS.urbanBlack};

   h3 {
      line-height: 2px;
   }

   p {
      line-height: 2px;
      font-size: 14px;
   }
`

export const LectorExperienceRow = styled.div`
   margin-top: 10px;
   margin-left: 20px;
   width: 60%;
   font-size: 12px;
   line-height: 16px;
`

export const LectorQuoteRow = styled.div`
   display: flex;
   align-items: center;
   margin-top: 20px;
   margin-left: 20px;
   text-align: center;
   font-style: italic;
   width: 60%;
   font-size: 10px;
   line-height: 14px;
`

export const NotSelectedWrapper = styled.div<{ isMobile: boolean }>`
   text-align: center;
   align-items: center;
   width: 100%;

   img {
      height: ${({ isMobile }) => isMobile ? '200px' : '400px'};
   }

   h1 {
      text-align: center !important;
      margin: 0 auto;
   }

`

export const TitleNotSelected = styled.div`
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 171%;
`;

export const CourseVideoWrapper = styled.div<{ isMobile: boolean }>`
   margin: ${({ isMobile }) => isMobile ? '0px' : '20px'};
   border-radius: ${({ isMobile }) => isMobile ? '0px' : '15px'};
   display: flex;
   align-items: flex-start;
   justify-content: space-between;

   div {
      overflow: hidden;
      border-radius: ${({ isMobile }) => isMobile ? '0px' : '15px'};
   }
`

export const Divider = styled.div`
   width: 395px;
   height: 1px;
   background-color: ${COLORS.white};
   margin-bottom: 4px;
   margin-left: 20px;
   margin-bottom: 5px;
   opacity: 0.1;
`;

export const CourseDescriptionWrapper = styled.div<{ isMobile: boolean }>`
   width: ${({ isMobile }) => isMobile ? '300px' : '100%'};
   height: 300px;
   overflow: scroll;
`;

export const CoursesRecommendedHolder = styled.div`
   display: flex;
`;

export const TopRowWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;