
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import { useSnackbar } from 'notistack';

import { setSetLoginPageStatus, filterCourses, labelCourses } from '../../../store/actions/appActions';
import { registerUser, setAuthError } from '../../../store/actions/userActions';
import { selectAuthError, selectUserData } from '../../../store/selectors/user';
import logo from '../../../assets/header/logo-urban.svg';
import loginPageBg from '../../../assets/global/loginPageBg.png';
import { Input } from '../../UI/Input/Input';
import { Button } from '../../UI/Button/index';
import { COLORS } from '../../../constants/colors';
import closeButtonWhite from '../../../assets/courses/personalities/closeButtonWhite.png';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { validationFields } from './utils';

import {
   LoginPageContainer,
   LoginRightSide,
   LoginLeftSide,
   LoginTitle,
   HolderForm,
   InputTitle,
   InputSubtitle,
   VerticalSeparator,
   RegisterSubtitle,
   LogoRow,
   RowInput
} from './styled';

const RegistrationPage = () => {
   const dispatch = useDispatch();
   const user = useSelector(selectUserData);
   const authError = useSelector(selectAuthError);
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { isMobile, width } = useMediaQuery()
   const { enqueueSnackbar } = useSnackbar();
   const [validationErrorFields, setValidationErrorFields] = useState<string[]>([])

   useEffect(() => {
      if (authError) {
         enqueueSnackbar(authError, { variant: "error" })
         setTimeout(() => {
            dispatch(setAuthError({ authError: null }))
         }, 1000)
      }
   }, [authError])

   useEffect(() => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: true }))
      dispatch(filterCourses({ filter: 'all' }))
      dispatch(labelCourses({ label: 'none' }))

      return () => {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      }
   }, [])

   useEffect(() => {
      if (user) {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
         navigate('/')
      }
   }, [user])

   const handleRegistration = ({ first_name, last_name, email, username, password, password2 }: {
      first_name: string,
      last_name: string;
      email: string,
      username: string;
      password: string;
      password2: string,
   }) => {
      dispatch(registerUser({ first_name, last_name, email, username, password, password2 }) as any)
   }

   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { first_name, last_name, username, password, password2, email } = e.target as HTMLFormElement;

      if (!validationFields({
         callback: enqueueSnackbar,
         first_name: first_name.value,
         last_name: last_name.value,
         username: username.value,
         password: password.value,
         password2: password2.value,
         email: email.value,
         setValidationErrorFields
      })) {
         return;
      }

      handleRegistration({
         last_name: last_name.value,
         first_name: first_name.value,
         email: email.value,
         username: username.value,
         password: password.value,
         password2: password2.value,
      })
      enqueueSnackbar("Успешная регистрация", { variant: 'success' })
   }

   const handleCloseLoginPage = () => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      navigate('/')
   }

   return (
      <LoginPageContainer>
         <LoginLeftSide isMobile={isMobile}>
            <LogoRow>
               <img className='logo' src={logo} alt="" />
               <img onClick={handleCloseLoginPage} className='close' src={closeButtonWhite} alt="" />
            </LogoRow>
            <LoginTitle isMobile={isMobile}>{t('login.registration')}</LoginTitle>
            <HolderForm onSubmit={handleOnSubmit}>
               <InputTitle>
                  {t('login.login')}
               </InputTitle>
               <Input
                  type='text'
                  name="username"
                  placeholder={t('login.enterLogin')}
                  modifier='primary'
                  isError={validationErrorFields.includes('username')}
                  icon={<PersonIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
               <RowInput width={width}>
                  <div>
                     <InputTitle>
                        {t('login.last_name')}
                     </InputTitle>
                     <Input
                        type='text'
                        name="last_name"
                        placeholder={t('login.enterLastName')}
                        isError={validationErrorFields.includes('last_name')}
                        modifier='primary'
                        icon={<BadgeIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                     />
                  </div>
                  <div className='first-name'>
                     <InputTitle>
                        {t('login.first_name')}
                     </InputTitle>
                     <Input
                        type='text'
                        name="first_name"
                        placeholder={t('login.enterFirstName')}
                        isError={validationErrorFields.includes('first_name')}
                        modifier='primary'
                        icon={<BadgeIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                     />
                  </div>
               </RowInput>
               <InputTitle>
                  {t('login.email')}
               </InputTitle>
               <Input
                  type='email'
                  name="email"
                  placeholder={t('login.enterEmail')}
                  isError={validationErrorFields.includes('email')}
                  modifier='primary'
                  icon={<AlternateEmailIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />

               <RowInput width={width}>
                  <div>
                     <InputTitle>
                        {t('login.password')}
                     </InputTitle>
                     <Input
                        type='password'
                        name="password"
                        placeholder={t('login.enterPassword')}
                        isError={validationErrorFields.includes('password')}
                        modifier='primary'
                        icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                     />
                  </div>
                  <div className='first-name'>
                     <InputTitle>
                        {t('login.passwordRepeat')}
                     </InputTitle>
                     <Input
                        type='password'
                        name="password2"
                        placeholder={t('login.enterPasswordRepeat')}
                        isError={validationErrorFields.includes('password2')}
                        modifier='primary'
                        icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                     />
                  </div>
               </RowInput>
               <InputSubtitle>
                  {''}
               </InputSubtitle>
               <Button
                  modifier='primaryWhiteOutlined'
                  htmlType='submit'
               >
                  {t('login.registration')}
               </Button>
               <VerticalSeparator />
            </HolderForm>
            <Link to={'/login'}>
               <RegisterSubtitle>
                  {t('login.exist')}
               </RegisterSubtitle>
            </Link>
         </LoginLeftSide>
         {!isMobile && <LoginRightSide>
            <img src={loginPageBg} alt="" />
         </LoginRightSide>}
      </LoginPageContainer>
   );
};

export default RegistrationPage;