import styled from "styled-components";
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

export interface AssociativeCardObj {
  id: number;
  name: string;
  image: string;
  flipped: boolean;
  modification?: 'positive' | 'negative';
}

type AssociativeCardProps = {
  flippedImage: string;
  card: AssociativeCardObj;
  handleClick: (card: AssociativeCardObj) => void;
  modification?: 'positive' | 'negative';
};

type CardFlippedImgProps = {
  found?: boolean;
  isMobile: boolean;
};

const AssociativeCard = ({
  flippedImage,
  handleClick,
  card,
  modification
}: AssociativeCardProps): JSX.Element => {
  const { isMobile } = useMediaQuery();

  return (
    <CardContainer
      onClick={() => {
        handleClick(card);
      }}
      isMobile={isMobile}
    >
      {card.flipped ? (
        <CardFlippedImage src={flippedImage} alt="Alt text" />
      ) : (
        <CardImage src={`/images/${modification}Cards/${modification}CardClosed.png`} alt="Alt text" />
      )}
    </CardContainer>
  );
};

// styled components
const CardContainer = styled.div<CardFlippedImgProps>`
  box-sizing: border-box;
  border: ${(props) =>
    props.found
      ? "4px inset var(--violet-blue-crayola)"
      : "1px inset var(--oxford-blue)"};
  border-radius: 0.5rem;
  width: ${(props) => (props.isMobile ? "min(18vw, 6rem)" : "min(22vw, 8rem)")}};
  height: ${(props) => (props.isMobile ? "min(14vw, 6rem)" : "min(18vw, 6rem)")}}};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  object-fit: cover;
`;

const CardFlippedImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
`;

export default AssociativeCard;
