import { useTranslation } from 'react-i18next';
import Tag from '../../../components/Tags/Tag';
import { CursesSections } from '../../../types/common';
import { CoursesButtonTypes } from '../../../types/courses/courses';
import Logo from '../../Tags/Logos';
import { SECTIONS } from '../../../constants/courses';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import { ButtonModifiers } from '../../../components/UI/Button/index';
import { Button } from '../../../components/UI/Button';
import { ButtonWrapper } from '../../../components/Tabs/Courses/CommonComponents/styles';

import {
   PreviewWrapper,
   TitleWrapper,
   SubRowWrapper,
   SubTitleWrapper,
   DescriptionWrapper,
   TopRowWrapper,
   BottomRowWrapper,
   LeftActions,
   TagsBlock,
} from './styles';
import { Link } from 'react-router-dom';

type Props = {
   bgImage: string;
   sectionName: CursesSections;
   button?: CoursesButtonTypes;
   buttonModification?: ButtonModifiers;
   customTag: string;
   isButtonDisabled?: boolean;
   handleDirectionAction: () => void;
}

const DirectionPreview = ({
   bgImage,
   sectionName,
   customTag = 'Free',
   button,
   isButtonDisabled,
   handleDirectionAction,
   buttonModification
}: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   return (
      <PreviewWrapper bgImage={bgImage}>
         <TopRowWrapper>
            <TitleWrapper isMobile={isMobile}>
               {t('directions.title')} «{t(`directions.${sectionName}.title`)}»
            </TitleWrapper>
            <Logo name={sectionName} />
         </TopRowWrapper>
         <SubRowWrapper>
            <Tag name={sectionName} />
         </SubRowWrapper>
         <SubTitleWrapper>
            {t(`directions.${sectionName}.subtitle`)}
         </SubTitleWrapper>
         <BottomRowWrapper isMobile={isMobile}>
            <DescriptionWrapper>
               {t(`directions.${sectionName}.description`)}
            </DescriptionWrapper>
            <LeftActions>
               <TagsBlock>
                  <Tag name={SECTIONS.custom as CursesSections} customName={`directions.${sectionName}.tagUnlimited`} />
                  <Tag name={SECTIONS.custom as CursesSections} customName={`directions.${sectionName}.tagHours`} />
                  <Tag name={sectionName} customName={customTag} />
               </TagsBlock>
               {!isButtonDisabled && <ButtonWrapper>
                  <Button onClick={handleDirectionAction} modifier={buttonModification}>
                     {button && t(button)}
                  </Button>
               </ButtonWrapper>}
            </LeftActions>
         </BottomRowWrapper>
      </PreviewWrapper>
   );
};

export default DirectionPreview;