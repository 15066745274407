import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { Button, ButtonModifiers } from '.';

import { GoBackButtonWrapper, BackButtonWrapper, TitleWrapper, IconWrapper } from './styled';

type BackButtonProps = {
   title: string,
   isMobile: boolean,
   handleGoBackClick?: () => void,
   modifier?: ButtonModifiers,
};

const BackButton = ({ title, isMobile, handleGoBackClick, modifier }: BackButtonProps) => {

   const handleGoBackClickDefault = () => {
      window.history.back();
   };

   return (
      <BackButtonWrapper>
         <GoBackButtonWrapper isMobile={isMobile}>
            <Button
               modifier={modifier || 'primaryOutlined'}
               onClick={handleGoBackClick || handleGoBackClickDefault}
            >
               <IconWrapper>
                  <DoubleArrowIcon />
               </IconWrapper>
            </Button>
         </GoBackButtonWrapper>
         <TitleWrapper>
            {title}
         </TitleWrapper>
      </BackButtonWrapper >

   );
};

export default BackButton;