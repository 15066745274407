import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CursesSections } from 'types/common';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ForwardIcon from '@mui/icons-material/Forward';
import CheckIcon from '@mui/icons-material/Check';

import { getCourseProgram } from '../../../../utils/courses/getCourseProgram';
import { selectCurrentCourse, selectCurrentVideo, selectAllNotFilteredCourses, selectPremiumCourses } from '../../../../store/selectors/courses';
import { setCurrentVideo } from '../../../../store/actions/appActions';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { getCourseColor } from '../../../../utils/courses/getCourseColor';
import { Button } from '../../../UI/Button/index';
import bookImage from '../../../../assets/global/bookImage.svg';
import { updateUserStartedCourses } from '../../../../store/actions/userActions';

import {
   CabinetPlanHolder,
   CourseMaterials,
   OneDescriptionElementWrapper,
   VideoDescriptionWrapper,
   CabinetPlanWrapper,
   Divider,
   CourseDownload
} from './styled';
import { selectStartedCourses, selectUserProfile } from '../../../../store/selectors/user';

const CoursePlan = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch()
   const currentCourse = useSelector(selectCurrentCourse);
   const startedCourses = useSelector(selectStartedCourses);
   const currentStartedCourseLesson = startedCourses.find((i) => i.courseModification === currentCourse)?.currentLesson;
   const currentVideo = useSelector(selectCurrentVideo);
   const { isMobile } = useMediaQuery();
   const courseProgram = getCourseProgram(currentCourse);
   const materials = courseProgram[currentVideo]?.materials;
   const user = useSelector(selectUserProfile);
   const isUserLoggedIn = user?.username !== undefined && currentStartedCourseLesson !== undefined
   const containerRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (containerRef.current) {
         const currentChild = containerRef.current.querySelector('.current');
         if (currentChild) {
            currentChild.scrollIntoView({ behavior: 'smooth', block: 'center' });
         }
      }
   }, [currentVideo]);

   useEffect(() => {
      if (isUserLoggedIn) {
         dispatch(setCurrentVideo({ courseVideo: currentStartedCourseLesson }))
      }
   }, [])

   useEffect(() => {
      if (isUserLoggedIn && currentStartedCourseLesson > currentVideo) return
      dispatch(updateUserStartedCourses({ courseModification: currentCourse, currentLesson: currentVideo }) as any)
   }, [currentVideo])

   const setCurrentStep = (video: number) => {
      if (isUserLoggedIn && currentStartedCourseLesson < currentVideo) {
         dispatch(updateUserStartedCourses({ courseModification: currentCourse, currentLesson: video }) as any)
      }
      dispatch(setCurrentVideo({ courseVideo: video }))
   }

   const courses = useSelector(selectAllNotFilteredCourses);
   const premiumCourses = useSelector(selectPremiumCourses);
   const course = courses.find((i) => i.courseModification === currentCourse)
   const premiumCourse = premiumCourses.find((i) => i.courseModification === currentCourse)
   const currentCourseSection = course?.section || premiumCourse?.section;
   const courseColor = getCourseColor(currentCourseSection as CursesSections)


   const oneProgramElement = (i: { title: string, description?: string }, index: number) => {
      return <div>
         <OneDescriptionElementWrapper
            color={courseColor}
            isMobile={isMobile}
            isCurrent={currentVideo === index}
            onClick={() => setCurrentStep(index)}
            className={`${currentVideo === index ? 'current' : ''} ${isUserLoggedIn && currentStartedCourseLesson > index ? 'disabled' : ''}`}
         >
           <div className='text'>
               <span>{index + 1}:</span> {t(i.title)} 
            </div> 
            <div>
               {isUserLoggedIn && currentStartedCourseLesson > index &&  <CheckIcon fontSize='small' />}
            </div>
         </OneDescriptionElementWrapper>
         {currentVideo === index && <VideoDescriptionWrapper>
            <p>
               {i.description && t(i.description)}
            </p>
         </VideoDescriptionWrapper>}
      </div>
   }

   return (
      <CabinetPlanHolder isMobile={isMobile}>
         <CourseMaterials isMobile={isMobile}>
            <h1>
               {t('cabinet.courseProgram')}
            </h1>
            <div className='chapters'>
               <img src={bookImage} alt="" />  {courseProgram.length} лекций
            </div>
         </CourseMaterials>
         <Divider />
         <CabinetPlanWrapper ref={containerRef} isMobile={isMobile}>
            {courseProgram.map((i, index) => {
               return oneProgramElement(i, index)
            })}
         </CabinetPlanWrapper>
         {materials && <>
            <Divider />
            <CourseDownload isMobile={isMobile}>
               <h1>
                  {currentCourse === 'thematicActivity' ? t('cabinet.goToCourse') : t('cabinet.downloadCourse')}
               </h1>
               <Button
                  modifier='primaryOutlined'
                  onClick={() => window.open(materials, '_blank')}
               >
                  {currentCourse === 'thematicActivity' ? <ForwardIcon fontSize='small' /> : <FileDownloadIcon fontSize='small' />}
               </Button>
            </CourseDownload>
         </>}
      </CabinetPlanHolder>
   );
};

export default CoursePlan;