export const downloadMaterials = (urls: string | string[], filenames: string[], setIsDownloading: () => void) => {
   // If urls and filenames are not arrays, convert them to arrays
   urls = Array.isArray(urls) ? urls : [urls];

   // Loop through the array of URLs
   urls.forEach((url, index) => {
      fetch(url)
         .then(response => response.blob())
         .then(blob => {
            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = filenames[index];

            // Append the download link to the DOM and trigger the download
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setIsDownloading();
         })
         // eslint-disable-next-line no-console
         .catch(error => console.error(error));
   });
};