import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const TagsWrapper = styled.div<{ color: string }>`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   background-color: ${props => props.color};
   height: 22px;
   font-style: normal;
   font-weight: bold;
   font-size: 12px;
   line-height: 130%;
   color: ${COLORS.white};
   padding: 3px 8px;
   border-radius: 100px;
   width: fit-content;
   margin: 0 5px 5px 0;
`;

export const LogosWrapper = styled.img`
   width: 28px;
   height: 28px;
`;