import styled from "styled-components";

import { COLORS } from "../../../../constants/colors";

export const CalendarWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 20px;

   h1 {
      margin: 0;
      padding: ${({ isMobile }) => isMobile ? '10px 0px' : '20px 0'};
      position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
      top: 0px;
      width: 100%;
      background-color: ${COLORS.urbanBaGLight};
      z-index: 2;
   }
`;

export const CurrentMonth = styled.h1<{ isMobile: boolean }>`
   margin: 0;
   padding: ${({ isMobile }) => isMobile ? '10px 0px' : '20px 0'};
   position: ${({ isMobile }) => isMobile ? 'unset' : 'sticky'};
   top: 0px;
   width: 100%;
   background-color: ${COLORS.urbanBaGLight};
   z-index: 2;
   text-transform: capitalize;
`;


export const DaysWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   padding-top: 15px;
   padding-bottom: 15px;
`;

export const DayWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 21px;
   font-weight: 700;
   color: ${COLORS.darkUrban};
   padding: 10px;
   margin-right: 10px;
   border-radius: 5px;
   border: 1px solid ${COLORS.whiteLight};
   opacity: 0.5;
   background-color: ${COLORS.whiteLight};
   height: 80px;


   &.current-day-label {
      color: ${COLORS.magenta};
   }

   &.current-day {
      color: ${COLORS.darkUrban};
      opacity: 1;
      box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.35);

      .current-day-marker {
         background-color: ${COLORS.darkUrban};
      }
   }

   .current-day-marker {
      height: 5px;
      width: 5px;
      background-color: ${COLORS.magenta};
      border-radius: 50%;
      margin-left: 10px;
      margin-top: 5px;
   }

   .number {
      font-size: 21px;
      text-align: center;
      align-self: center;
   }

   .text {
      font-size: 14px;
      text-align: center;
      align-self: center;
   }

   &:hover {
      cursor: pointer;
   }
`;

export const CalendarEventsWrapper = styled.div<{ isMobile: boolean }>`
   border-top: 1px solid ${COLORS.whiteLight};
   padding-top: 15px;
   margin-top: 10px;
`;

export const CalendarEventWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-around;
   padding: 10px;
   margin-bottom: 10px;
   border-radius: 5px;
   border: 1px solid ${COLORS.whiteLight};
   background-color: ${COLORS.courseCardBackground};
   height: 80px;
   color: ${COLORS.white};
`;

export const DescriptionWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: left;
   justify-content: space-between;
`;

export const EventTitle = styled.div`
   font-size: 16px;
   font-weight: 700;
`;

export const EventDescription = styled.div`
   font-size: 12px;
   font-weight: 400;
   line-height: 1.4;
   width: 240px;
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
`;

export const EventTime = styled.div`
   font-size: 12px;
   font-weight: 400;
`;