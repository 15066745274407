import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const DropDownWrapper = styled.div<{ isExpanded: boolean }>`
   width: 100%;
   background-color: ${COLORS.urbanBlack};
   padding: 20px;
   color: ${COLORS.white};
   border-radius: 10px;
   margin-top: 20px;
   height: ${({ isExpanded }) => (isExpanded ? 'auto' : '130px')};
`;

export const TopRow = styled.div`
   display: flex;
   justify-content: space-between;
`;

export const DropDownContent = styled.div`
`;



export const DropDownAction = styled.div`
   margin-top: 20px;

   img {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 20px;
   }
`;