
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentPersonality } from '../../../../../store/selectors/personality';
import useMediaQuery from '../../../../../utils/breakpoints/useMediaQuery';

import {
   StrengthsItem,
   AllWrapper,
   StrengthsWrapper,
   WeaknessesItem,
} from './styled';

const StrengthsAndWeaknesses = () => {
   const currentPersonality = useSelector(selectCurrentPersonality);
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();

   const renderStrengths = () => {
      return [1, 2, 3, 4, 5].map((item) => {
         return <StrengthsItem key={item}>
            <strong> {t(`personality.${currentPersonality}.strength${item}`).split('—')[0]}</strong> -
            {t(`personality.${currentPersonality}.strength${item}`).split('—')[1]}
         </StrengthsItem>
      })
   }

   const renderWeaknesses = () => {
      return [1, 2, 3, 4, 5].map((item) => {
         return <WeaknessesItem key={item}>
            <strong> {t(`personality.${currentPersonality}.weaknesses${item}`).split('—')[0]}</strong> -
            {t(`personality.${currentPersonality}.weaknesses${item}`).split('—')[1]}
         </WeaknessesItem>
      })
   }

   return (
      <AllWrapper isMobile={isMobile}>
         <StrengthsWrapper>
            {renderStrengths()}
         </StrengthsWrapper>
         <StrengthsWrapper>
            {renderWeaknesses()}
         </StrengthsWrapper>
      </AllWrapper>
   );
};

export default StrengthsAndWeaknesses;