import { useEffect, useState } from 'react';

function useWindowBreakpoint() {
  const getWindowWidth = () => {
    if (typeof window !== 'undefined') {
      // Try using documentElement.clientWidth as a fallback
      return  document.documentElement.clientWidth || 0;
    }
    return 0;
  };
  
  const [breakpoint, setBreakpoint] = useState(getWindowWidth);

  useEffect(() => {
    function handleResize() {
      const newWidth = getWindowWidth();
      setBreakpoint(newWidth);  // Update state with new width
    }

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
}

export default useWindowBreakpoint;