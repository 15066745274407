import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import {
   TopMenuWrapper,
} from './styled';

type Props = {
   children: React.ReactNode
}

const TopBar = ({ children }: Props) => {
   const { isMobile } = useMediaQuery();

   return (
      <TopMenuWrapper isMobile={isMobile}>
         {children}
      </TopMenuWrapper>
   );
};

export default TopBar;