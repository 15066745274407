import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonModifiers } from 'components/UI/Button/index';

import Tag from '../../../../components/Tags/Tag';
import { CursesSections } from '../../../../types/common';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import Logo from '../../../Tags/Logos';
import { Button } from '../../../UI/Button';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   PreviewWrapper,
   TitleWrapper,
   SubRowWrapper,
   SubTitleWrapper,
   DescriptionWrapper,
   TopRowWrapper,
   BottomRowWrapper,
   LeftActions,
   TagsBlock,
   ButtonWrapper,
} from './styles';
import { CircularProgress } from '@material-ui/core';


type Props = {
   bgImage: string;
   sectionName: CursesSections;
   modification: CoursesModifications
   button: CoursesButtonTypes;
   buttonModification: string;
   customTag: string;
   isButtonDisabled?: boolean;
   handleButtonClick?: () => void;
   isLoading?: boolean;
}

const CoursePreview = ({
   bgImage,
   sectionName,
   modification,
   button,
   buttonModification,
   isButtonDisabled,
   customTag = 'Free',
   handleButtonClick,
   isLoading
}: Props) => {

   const { t } = useTranslation();
   const { isMobile } = useMediaQuery();
   const getToLink = (button: string): string => {
      switch (button) {
         case CoursesButtonTypes.READ: {
            if (modification === COURSES.buckleUpProposal) {
               return `/cabinet/buckleUp/reader`;
            }
            if (modification === COURSES.enterAltitudeProposal) {
               return `/cabinet/enterAltitude/reader`;
            }
            if (modification === COURSES.moveUpwardsProposal) {
               return `/cabinet/moveUpwards/reader`;
            }
            if (modification === COURSES.chooseDirectionProposal) {
               return `/cabinet/chooseDirection/reader`;
            }
            if (modification === COURSES.maintainBalanceProposal) {
               return `/cabinet/maintainBalance/reader`;
            }
            return `/cabinet/${modification}/reader`;
         }
         case CoursesButtonTypes.SUBSCRIBE: {
            return `/cabinet/${modification}`;
         }
         case CoursesButtonTypes.SEE: {
            if (modification === COURSES.restoreBroken ) {
               return `/cabinet`;
            } else {
               return `/cabinet/${modification}`;
            }
         }
         default:
            return `/cabinet/${modification}`;
      }
   }

   const renderPaymentInProgressButton = ({ button }: { button: string }) => {
      if (isLoading) {
         return <CircularProgress size={20} color='secondary' />
      } else {
         return t(button) 
      }
   }


   return (
      <PreviewWrapper bgImage={bgImage}>
         <TopRowWrapper>
            <TitleWrapper isMobile={isMobile}>
               {
                  modification === COURSES.buckleUpProposal 
                  || modification === COURSES.enterAltitudeProposal 
                  || modification === COURSES.moveUpwardsProposal
                  || modification === COURSES.chooseDirectionProposal
                  || modification === COURSES.maintainBalanceProposal
                  ? t(`course.${modification}.title`) : `${t('course.course')} «${t(`course.${modification}.title`)}»`
               }
            </TitleWrapper>
            <Logo name={sectionName} />
         </TopRowWrapper>
         <SubRowWrapper>
            <Tag name={sectionName} />
         </SubRowWrapper>
         <SubTitleWrapper>
            {t(`course.${modification}.subtitle`)}
         </SubTitleWrapper>
         <BottomRowWrapper isMobile={isMobile}>
            <DescriptionWrapper>
               {t(`course.${modification}.description`)}
            </DescriptionWrapper>
            <LeftActions>
               {<TagsBlock>
                  <Tag name={SECTIONS.custom as CursesSections} customName={`course.${modification}.tagUnlimited`} />
                  <Tag name={SECTIONS.custom as CursesSections} customName={`course.${modification}.tagHours`} />
                  <Tag name={sectionName} customName={customTag} />
               </TagsBlock>}
               {!isButtonDisabled && <ButtonWrapper>
                  <Link to={getToLink(button)}>
                     <Button onClick={handleButtonClick} modifier={buttonModification as ButtonModifiers}>
                        {renderPaymentInProgressButton({ button })}
                     </Button>
                  </Link>
               </ButtonWrapper>}
            </LeftActions>
         </BottomRowWrapper>
      </PreviewWrapper>
   );
};

export default CoursePreview;