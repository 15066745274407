interface Card {
  id: number;
  name: string;
  image: string;
  flipped: boolean;
  found: boolean;
}

const cards: Card[] = [
  {
    id: 1,
    name: "map",
    image: "/images/mapCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 2,
    name: "bottle",
    image: "/images/bottleCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 3,
    name: "binoculars",
    image: "/images/binocularsCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 4,
    name: "compass",
    image: "/images/compassCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 5,
    name: "coin",
    image: "/images/coinCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 6,
    name: "squirrel",
    image: "/images/squirrelCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 7,
    name: "jam",
    image: "/images/jamCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 8,
    name: "socks",
    image: "/images/socksCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 9,
    name: "map",
    image: "/images/mapCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 10,
    name: "bottle",
    image: "/images/bottleCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 11,
    name: "binoculars",
    image: "/images/binocularsCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 12,
    name: "compass",
    image: "/images/compassCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 13,
    name: "coin",
    image: "/images/coinCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 14,
    name: "squirrel",
    image: "/images/squirrelCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 15,
    name: "jam",
    image: "/images/jamCard.png",
    flipped: false,
    found: false,
  },
  {
    id: 16,
    name: "socks",
    image: "/images/socksCard.png",
    flipped: false,
    found: false,
  },
];

export default cards;
