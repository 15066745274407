import { useTranslation } from 'react-i18next';
import { Rating } from '@material-ui/lab';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Tag from '../../Tags/Tag';
import { getCourseImage } from '../../../utils/courses/getCourseImage';
import { getCourseColor } from '../../../utils/courses/getCourseColor';
import { CursesSections } from '../../../types/common';

import {
   CoursesModifications,
} from '../../../types/courses/courses';

import {
   CourseWrapper,
   CourseImage,
   CourseTitle,
   CourseDescription,
   CourseFooter,
   CourseInfo,
} from './styled';
import CourseActions from './CourseActions';

type Props = {
   courseModification: CoursesModifications;
   rating: number;
   section: CursesSections;
   button: string;
}

const Course = ({ courseModification, rating, section, button }: Props) => {
   const { t } = useTranslation();

   return <CourseWrapper>
      <CourseImage src={getCourseImage(courseModification)} alt="" />
      <CourseTitle>
         {t(`course.${courseModification}.title`)}
      </CourseTitle>
      <CourseDescription>
         {t(`course.${courseModification}.description`)}
      </CourseDescription>
      <CourseFooter>
         <CourseInfo color={getCourseColor(section)}>
            <Tag name={section} />
         </CourseInfo>
         <CourseActions courseModification={courseModification} button={button} />
      </CourseFooter>
   </CourseWrapper>
}

export default Course;