
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PasswordIcon from '@mui/icons-material/Password';
import { useSnackbar } from 'notistack';

import { setSetLoginPageStatus, filterCourses, labelCourses } from '../../../store/actions/appActions';
import { resetConfirmUserPassword } from '../../../store/actions/userActions';
import { selectUserProfile } from '../../../store/selectors/user';
import logo from '../../../assets/header/logo-urban.svg';
import loginPageBg from '../../../assets/global/loginPageBg.png';
import { Input } from '../../UI/Input/Input';
import { Button } from '../../UI/Button/index';
import { COLORS } from '../../../constants/colors';
import closeButtonWhite from '../../../assets/courses/personalities/closeButtonWhite.png';
import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';
import { validationFields } from './utils';

import {
   LoginPageContainer,
   LoginRightSide,
   LoginLeftSide,
   LoginTitle,
   HolderForm,
   InputTitle,
   InputSubtitle,
   VerticalSeparator,
   RegisterSubtitle,
   LogoRow,
} from './styled';

const PasswordResetPage = () => {
   const { uidb64, token } = useParams<{ uidb64: string, token: string }>();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const user = useSelector(selectUserProfile)
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()
   const { enqueueSnackbar } = useSnackbar();
   const [validationErrorFields, setValidationErrorFields] = useState<string[]>([])

   useEffect(() => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: true }))
      dispatch(filterCourses({ filter: 'all' }))
      dispatch(labelCourses({ label: 'none' }))

      return () => {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      }
   }, [])

   useEffect(() => {
      if (user) {
         dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
         navigate('/')
      }
   }, [user])

   const handleRegistration = ({ password }: {
      password: string;
   }) => {
      dispatch(resetConfirmUserPassword({
         uidb64,
         token,
         password,
         callback: () => enqueueSnackbar("Успешная смена пароля", { variant: 'success' }),
         callbackError: () => enqueueSnackbar("Общибка попробуйте еще раз", { variant: 'success' })
      }) as any)
   }

   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { password, password2 } = e.target as HTMLFormElement;

      if (!validationFields({
         callback: enqueueSnackbar,
         password: password.value,
         password2: password2.value,
         setValidationErrorFields
      })) {
         return;
      }

      handleRegistration({
         password: password.value,
      })
   }

   const handleCloseLoginPage = () => {
      dispatch(setSetLoginPageStatus({ isLoginPageOpen: false }))
      navigate('/')
   }

   return (
      <LoginPageContainer>
         <LoginLeftSide isMobile={isMobile}>
            <LogoRow>
               <img className='logo' src={logo} alt="" />
               <img onClick={handleCloseLoginPage} className='close' src={closeButtonWhite} alt="" />
            </LogoRow>
            <LoginTitle isMobile={isMobile}>{t('login.reset')}</LoginTitle>
            <HolderForm onSubmit={handleOnSubmit}>
               <div>
                  <InputTitle>
                     {t('login.new_password')}
                  </InputTitle>
                  <Input
                     type='password'
                     name="password"
                     placeholder={t('login.enterPassword')}
                     isError={validationErrorFields.includes('password')}
                     modifier='primary'
                     icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  />
               </div>
               <div className='first-name'>
                  <InputTitle>
                     {t('login.passwordRepeat')}
                  </InputTitle>
                  <Input
                     type='password'
                     name="password2"
                     placeholder={t('login.enterPasswordRepeat')}
                     isError={validationErrorFields.includes('password2')}
                     modifier='primary'
                     icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  />
               </div>
               <InputSubtitle>
                  {''}
               </InputSubtitle>
               <Button
                  modifier='primaryWhiteOutlined'
                  htmlType='submit'
               >
                  {t('login.reset')}
               </Button>
               <VerticalSeparator />
            </HolderForm>
            <Link to={'/login'}>
               <RegisterSubtitle>
                  {t('login.exist')}
               </RegisterSubtitle>
            </Link>
         </LoginLeftSide>
         {!isMobile && <LoginRightSide>
            <img src={loginPageBg} alt="" />
         </LoginRightSide>}
      </LoginPageContainer>
   );
};

export default PasswordResetPage;