import { COURSES } from '../../constants/courses';

export const getCourseVideo = (courseModification: string ): string | string[] => {
  switch (courseModification) {
    case COURSES.oratorMastery:
      return 'https://www.youtube.com/embed/C3PPW5ppV8I';
    case COURSES.kidsExercises:
      return [
        'https://www.youtube.com/embed/8VMCvtNbC30',
        'https://www.youtube.com/embed/W6xDILY_SrY', 
        'https://www.youtube.com/embed/7Ma4TJR-zyw',
        'https://www.youtube.com/embed/BB5ka-5aneA',
        'https://www.youtube.com/embed/6s_a-cAVcP8',
      ];
    case COURSES.parentAndChild:
      return [
        'https://www.youtube.com/embed/mIr__fFXdMI',
        'https://www.youtube.com/embed/331DWYhBsp0',
        'https://www.youtube.com/embed/rhuh9FlgJ34',
        'https://www.youtube.com/embed/RZJgTApQgEo',
        'https://www.youtube.com/embed/wnM1bGLnm2U'
      ];
    case COURSES.kidsLessonsPrepare:
      return [
        'https://www.youtube.com/embed/03h5jOXZPz0',
        'https://www.youtube.com/embed/2UgiKGEj3cc',
        'https://www.youtube.com/embed/hMxWDetWFfo'
      ];
    case COURSES.artKidsExercises:
      return 'https://www.pinterest.com/urbancentermd/urban-kids/';
    case COURSES.mentalMath:
      return 'https://www.youtube.com/embed/_BEEBIyM76k';
    case COURSES.audioAscent:
      return 'https://www.youtube.com/embed/p9QiZUdM8Vs';
    case COURSES.audioSand:
      return 'https://www.youtube.com/embed/2ckd-tlIJy0';
    case COURSES.audioForest:
      return 'https://www.youtube.com/embed/Fp9PEE2H6P4';
    case COURSES.workWithNegativeEmotions:
      return 'https://www.youtube.com/embed/s8o2HcQmXJc';
    case COURSES.sandTherapyTechnique:
      return 'https://www.youtube.com/embed/iDz8i5oe8uQ';
    case COURSES.internetSecurity:
      return [
        'https://www.youtube.com/embed/HA1ALYp8GlE',
        'https://www.youtube.com/embed/78J3Cti1zVc',
        'https://www.youtube.com/embed/q1DMj0tamtw',
      ];
    case COURSES.puzzleShow:
      return [
        'https://www.youtube.com/embed/B86XUnQuKYM',
        'https://www.youtube.com/embed/nwI8avCDQ5A',
        'https://www.youtube.com/embed/Dv1txJMOWcE',
        'https://www.youtube.com/embed/1tT38BD31c8',
        'https://www.youtube.com/embed/W7nIytfDHio',
        'https://www.youtube.com/embed/_l9N2wngk_I',
        'https://www.youtube.com/embed/wK_B2K58Bmk',
        'https://www.youtube.com/embed/P2W18539Niw',
        'https://www.youtube.com/embed/t3x9Z5SBAVs',
        'https://www.youtube.com/embed/HqSpEqpQzo',
        'https://www.youtube.com/embed/ARDN8dDl0Zk',
        'https://www.youtube.com/embed/Jq1zB1Xj-H8',
        'https://www.youtube.com/embed/a9mu5GTrB4c',
        'https://www.youtube.com/embed/N3xTYwsCijA',
        'https://www.youtube.com/embed/FoKzIVJNnk8',
        'https://www.youtube.com/embed/3gEHUhPkV-I',
        'https://www.youtube.com/embed/dXMPr0zlb0s',
        'https://www.youtube.com/embed/QejGp5QD8SQ',
        'https://www.youtube.com/embed/yvzem6wZX9w',
        'https://www.youtube.com/embed/9gf3Wr5q8Ig',
        'https://www.youtube.com/embed/m8emxWLaNRI',
        'https://www.youtube.com/embed/XlG5P2Vdgiw',
        'https://www.youtube.com/embed/jbSybEqHhlo',
        'https://www.youtube.com/embed/cGDG2lDAKeQ',
        'https://www.youtube.com/embed/Zu4IIvlXrog',
        'https://www.youtube.com/embed/f8wPlzslPmA',
        'https://www.youtube.com/embed/SkSAadV1u3o',
        'https://www.youtube.com/embed/j-Hu6VPX9AU',
        'https://www.youtube.com/embed/yXkojM-Ed2o',
        'https://www.youtube.com/embed/9EK5_aS2IN4',
        'https://www.youtube.com/embed/pS94zrBgSNA',
        'https://www.youtube.com/embed/0iRl7MDaAQQ',
      ]
    case COURSES.familyContext:
      return [
        'https://www.youtube.com/embed/uXMV2_TcDfI',
        'https://www.youtube.com/embed/Hm2Dow1sEfk',
        'https://www.youtube.com/embed/gnINCb_qHFM',
        'https://www.youtube.com/embed/nOHngMlSl5U',
        'https://www.youtube.com/embed/etULTMNH0Bw',
        'https://www.youtube.com/embed/fcLPIrwxh5Q',
        'https://www.youtube.com/embed/W8k8iQ84n8c',
        'https://www.youtube.com/embed/BSHOnZ8Lxl4',
        'https://www.youtube.com/embed/sJgmvYP2-qs',
        'https://www.youtube.com/embed/Kvfi90IlnSM',
        'https://www.youtube.com/embed/rW4PbWgCXXs',
        'https://www.youtube.com/embed/e2r9NtHbm-s',
        'https://www.youtube.com/embed/msia7ZJ4-Hc',
        'https://www.youtube.com/embed/5iPVOWuvWgE',
      ]
    case COURSES.urbanShow:
      return [
        'https://www.youtube.com/embed/U7jTzsCDdDY',
        'https://www.youtube.com/embed/3CE6YcjVnzY',
      ]
    case COURSES.thematicActivity:
      return [
        'https://www.youtube.com/embed/aLbTlelrlzY',
      ]
    case COURSES.restoreBrokenPremium: 
      return [
        'https://www.youtube.com/embed/fgN_BXFCJJI',
        'https://www.youtube.com/embed/iHjY9xM-YhQ',
        'https://www.youtube.com/embed/tyGpYscMuDc',
        'https://www.youtube.com/embed/T6Tqh5NM0_Y',
        'https://www.youtube.com/embed/ItROKSJQZl8',
      ]
    default:
      return 'notSelected';
  }
}
