import styled from 'styled-components';

import {COLORS} from '../../../../constants/colors';

export const RestoreWrapper = styled.div`
  margin: 20px;
  justify-content: flex-start;
  align-self: stretch;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const CourseTitle = styled.h1`
  color: ${COLORS.urbanBlack};
  margin-top: 40px;
  line-height: 23px !important;
`;

export const CourseSubtitle = styled.div`
  color: ${COLORS.urbanBlack};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  max-width: 500px;
`;

export const PuzzleContainer = styled.div<{isMobile: boolean}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobile ? 'repeat(2, 1fr)' : 'repeat(1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 1fr)'};
  grid-template-rows: ${(props) => (props.isMobile ? 'repeat(0.1fr 1fr 1fr 1fr 1fr)' : '1fr 1fr')};
  grid-template-areas: ${(props) =>
    props.isMobile
      ? '"descr descr" "puzl1 one" "two puzl2" " puzl3 three" "four puzl4"'
      : '"descr descr puzl2 puzl2 three three three puzl4" "puzl1 one one two two puzl3 puzl3 four"'};
  gap: 10px;
`;

export const GridItem1 = styled.div<{isMobile: boolean}>`
  font-size: 20px;
  margin:${(props) => (props.isMobile ? '0 0 20px 0' : '0')}
  grid-area: descr;
  align-self: center;

`;
export const GridItem2 = styled.div`
  grid-area: puzl2;
  justify-self: center;
  align-self: center;
`;

export const GridItemPuzzle = styled.div<{decoration: string}>`
  grid-area: ${({decoration}) => decoration};
`;

export const GridItem4 = styled.div<{isMobile: boolean}>`
  transform: ${(props) => (props.isMobile ? 'rotate(60deg)' : 'rotate(0)')} !important;
  grid-area: puzl4;
  justify-self: center;
  align-self: center;
`;
export const GridItem5 = styled.div`
  grid-area: puzl1;
  justify-self: center;
  align-self: center;
`;

export const GridItem8 = styled.div`
  grid-area: puzl3;
  justify-self: start;
  align-self: center;
`;

export const CourseParagraphTitle = styled.h2`
  color: ${COLORS.urbanBlack};
  margin: 0 0 0.8em 0 !important;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
  max-width: 500px;
`;

export const PuzzleImg = styled.img<{isMobile: boolean}>`
  width: ${(props) => (props.isMobile ? '130px' : '180px')};
`;

export const PuzzleText = styled.div`
  font-size: 14px;
`;

export const CourseFooter = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  gap: 4rem;
`;

export const CoursePlanWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.isMobile ? '0' : '10rem')};
  justify-content: space-between;
  text-align: left;
  margin-top: 20px;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    max-width: 600px;
  }

  img {
    height: 200px;
  }
`;

export const ChatDecorationWrapper = styled.div<{isMobile: boolean}>`
  background: ${(props) => (props.isMobile ? COLORS.urbanBlack : '')};
  padding: ${(props) => (props.isMobile ? '20px' : '0px')};
  margin-top: ${(props) => (props.isMobile ? '20px' : '0px')};
  border-radius: ${(props) => (props.isMobile ? '10px' : '0px')};

  img {
    width: 100%;
    display: ${(props) => (props.isMobile ? 'none' : 'block')};
  }
`;

export const ChatTitle = styled.h1<{isMobile: boolean}>`
  color: ${COLORS.white};
  position: ${(props) => (props.isMobile ? 'unset' : 'relative')};
  top: 220px;
  left: 50%;
  font-size: ${(props) => (props.isMobile ? '24px' : '28px')} !important;
  line-height: ${(props) => (props.isMobile ? '28px' : '36px')} !important;
`;

export const ChatSubtitle = styled.p<{isMobile: boolean}>`
   color: ${COLORS.white};
   position: ${(props) => (props.isMobile ? 'unset' : 'relative')}};
   top: 220px;
   left: 50%;
   max-width: 300px;
`;

export const ButtonWrapper = styled.div<{isMobile: boolean}>`
   color: ${COLORS.white};
   position: ${(props) => (props.isMobile ? 'unset' : 'relative')}};
   top: -120px;
   left: 50%;
`;
