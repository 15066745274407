
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const PersonalityWrapper = styled.div<{color: string}>`
   background-color: ${({ color }) => `${color}`};
   height: 300px;
   padding: 14px;
   border-top-left-radius: 10px;
   text-align: center;
   border-top-right-radius: 10px;
`;

export const PersonalityHolder = styled.div`
   margin-right: 25px;
   margin-bottom: 20px;
`;

export const PersonalityProfilePhoto = styled.img`
   width: 132px;
   height: 150px;
   border-radius: 10px;
   text-align: center;
   align-self: center;
`;

export const PersonalityTitle = styled.div`
   font-weight: 700;
   font-size: 16px;
   margin-top: 12px;
   line-height: 141%;
   color: ${COLORS.blackLabel};
   min-height: 45px;
   margin-bottom: -23px;
`;

export const PersonalityDescription = styled.div`
   font-weight: 500;
   font-size: 12px;
   margin-top: 8px;
   line-height: 130%;
   min-height: 50px;
   opacity: 0.9;
   color: ${COLORS.urbanBlack};
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   max-width: 300px;
`;

export const PersonalityFooter = styled.div<{ image: string }>`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 20px;
   height: 60px;
   background-image: ${({ image }) => `url(${image})`};
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
`;

export const PersonalityDecoration = styled.img`
   position: relative;
   width: 290px;
   height: 200px;
   top: -195px;
   left: -18px;
`;

export const PersonalityActions = styled.div`
   display: flex;
   flex-direction: row;

   .personality__button {
      width: 150px !important;
   }

   .disabled-link {
      pointer-events: none;
      button {
         width: 50px !important;
         .not-disabled-link {
            pointer-events: all;
         }
      }
   }
`;

export const TagWrapper = styled.div`
   text-transform: uppercase;
   width: max-content;
   margin-bottom: 10px;
`;