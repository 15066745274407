import { useState } from "react";
import { HoveredImageWrapper } from "./styled";


export const HoveredImage = ({ imageOne, imageTwo }: {imageOne: string, imageTwo: string}) => {
   const [isHovered, setIsHovered] = useState(false);

   return (
      <HoveredImageWrapper onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
         <img src={isHovered ? imageTwo : imageOne} alt="hovered"/>
      </HoveredImageWrapper>
   )
}