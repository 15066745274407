import AssociativeCardGrid from '../AssociativeCardGrid';
import { AssociativeCardObj } from '../AssociativeCard';
import styled from 'styled-components';
import { useEffect } from 'react';

type Props = {
   cards: AssociativeCardObj[] | null;
   handleClick: (card: AssociativeCardObj) => void;
   setNextStep: () => void;
   setFlippedCards: () => void;
}

const StepWithNegativeCards = ({ cards, handleClick, setFlippedCards }: Props) => {

   useEffect(() => {
      setFlippedCards();
   }, []);

   return (
      <StepWrapper >
         <AssociativeCardGrid cards={cards} handleClick={handleClick} />
      </StepWrapper>
   );
};

export default StepWithNegativeCards;

const StepWrapper = styled.div`
   display: flex;
   flex-direction: column;
   text-align: right;
   align-items: end;
   padding: 1rem;
`;
