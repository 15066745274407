type ValidationType = {
   callback: any,
   first_name: string,
   last_name: string,
   username: string,
   password: string,
   password2: string,
   email: string,
   setValidationErrorFields: (value: string[]) => void
}

export const validationFields = ({
   callback,
   first_name,
   last_name,
   username,
   password,
   password2,
   email,
   setValidationErrorFields
}: ValidationType): boolean => {
   const russianSymbolsRegex = /[а-яА-ЯЁё]/;


   if (!username || !password || !password2 || !email || !first_name || !last_name) {
      callback("Please fill in all the required fields.", { variant: "warning" });
      setValidationErrorFields(['username', 'password', 'password2', 'email', 'first_name', 'last_name'])
      return false;
   }

   if (username.length < 5) {
      callback("Username should be at least 5 characters long.", { variant: "warning" });
      setValidationErrorFields(['username'])
      return false;
   }

   if (username.includes(" ")) {
      callback("Username should not contain spaces.", { variant: "warning" });
      setValidationErrorFields(['username'])
      return false;
   }

   if (russianSymbolsRegex.test(username)) {
      callback("Username should not contain spaces.", { variant: "warning" });
      setValidationErrorFields(['username'])
      return false;
   }


   if (password.includes(" ")) {
      callback("Password should not contain spaces.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (password.length <= 8) {
      callback("Password should be at least 8 characters long.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (russianSymbolsRegex.test(password)) {
      callback("Password should not contain Russian characters.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (password !== password2) {
      callback("Passwords do not match.", { variant: "warning" });
      setValidationErrorFields(['password', 'password2'])
      return false;
   }

   return true
}