import { Input } from '../../../../components/UI/Input/Input'
import { SearchInputWrapper } from './styled'
import icon_search from '../../../../assets/icons/icon-search.svg';

const SearchInput = ({
   setSearchValue,
   searchValue
}: {
   setSearchValue?: (value: string) => void
   searchValue?: string
}) => {
   return (
      <SearchInputWrapper>
         <Input value={searchValue} onChange={
            (e) => {
               setSearchValue && setSearchValue(e.target.value)
            }
         } modifier='search-secondary' type="text" placeholder="Поиск..." icon={<img src={icon_search} />} />
      </SearchInputWrapper>
   )
}

export default SearchInput