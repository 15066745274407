import { useTranslation } from 'react-i18next';

import { CursesSections } from 'types/common';
import { getCourseColor } from '../../../../utils/courses/getCourseColor';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   LectorMessageWrapper,
   UserMessageWrapper,
   MessageWrapper,
   FeedbackWrapper,
   UserNameWrapper,
   User,
   UserDescription,
   UserImage,
   LectorChatImage
} from './styles';

type Message = {
   userImage?: string,
   message: string,
   isLector?: boolean
}

type Props = {
   section: CursesSections,
   userName: string,
   messages: Message[]
}

const CourseFeedback = ({ section, messages, userName }: Props) => {
   const color = getCourseColor(section)
   const { t } = useTranslation()
   const { isMobile } = useMediaQuery()

   const renderMessage = ({ message }: { message: Message }) => {
      if (message.isLector) {
         return <MessageWrapper isMobile={isMobile}>
            <LectorChatImage src={message.userImage} alt="" />
            <LectorMessageWrapper color={color}>
               {message.message}
            </LectorMessageWrapper>
         </MessageWrapper>
      }

      return <MessageWrapper isMobile={isMobile}>
         <UserMessageWrapper isMobile={isMobile}>
            {message.message}
         </UserMessageWrapper>
         <UserImage src={message.userImage} alt="" />
      </MessageWrapper>
   }

   return (
      <FeedbackWrapper>
         {messages.map((message) => {
            return renderMessage({ message })
         })}
         <UserNameWrapper>
            <User>
               @{userName}
            </User>
            <UserDescription>
               {t('course.user')}
            </UserDescription>
         </UserNameWrapper>
      </FeedbackWrapper>
   );
};

export default CourseFeedback;