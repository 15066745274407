import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import gameLabBG from '../../../../assets/courses/previewCourses/gameLabBG.png';
import { CursesSections } from '../../../../types/common';
import planOne from '../../../../assets/courses/gameLab/planOne.png';
import planTwo from '../../../../assets/courses/gameLab/planTwo.png';
import planThree from '../../../../assets/courses/gameLab/planThree.png';
import personOneTransformed from '../../../../assets/courses/gameLab/personOneTransformed.png';
import personTwo from '../../../../assets/courses/gameLab/personTwo.png';
import personTwoTransformed from '../../../../assets/courses/gameLab/personTwoTransformed.png';
import personOne from '../../../../assets/courses/gameLab/personOne.png';
import personThreeTransformed from '../../../../assets/courses/gameLab/personThreeTransformed.png';
import personThree from '../../../../assets/courses/gameLab/personThree.png';
import chatDecoration from '../../../../assets/courses/prepareToSuccess/chatDecoration.png';
import urbanUser from '../../../../assets/courses/courseUsers/urbanUser.png';
import ContactsMap from '../CommonComponents/ContactsMap';
import CourseFeedback from '../CommonComponents/CourseFeedback';
import { Button } from '../../../UI/Button';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import MaterialModal from '../../../UI/Modal/MaterialModal';
import InvoiceSteps from '../../../InvoiceSteps/InvoiceSteps';
import { COLORS } from '../../../../constants/colors';

import {
   RestoreWrapper,
   CourseTitle,
   CourseSubtitle,
   CourseFooter,
   CoursePlanWrapper,
   ChatDecorationWrapper,
   ChatTitle,
   ChatSubtitle,
   ButtonWrapper,
   ImagesWrapper
} from './styled';
import CoursePlan from '../CommonComponents/CoursePlan';
import { HoveredImage } from './HoveredImage';
import { getCourseLector } from '../../../../utils/courses/getCourseLector';
import Lector from '../CommonComponents/Lector';


const GameLab = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { isMobile, width } = useMediaQuery();
   const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
   const sectionName = SECTIONS.youth as CursesSections;
   const lector = getCourseLector(COURSES.gameLab as CoursesModifications)

   const handleOpenInvoice = () => {
      setIsInvoiceOpen(!isInvoiceOpen);
   };

   const courseFeedbackMessages = [{
      userImage: lector.photo,
      message: 'Как вам курс?',
      isLector: true
   },
   {
      userImage: urbanUser,
      // eslint-disable-next-line max-len
      message: 'Во время тренинга все настолько касалось меня и давало внутренний отзыв. ',
   },
   {
      userImage: lector.photo,
      message: 'Можно подробнее?',
      isLector: true
   },
   {
      userImage: urbanUser,
      // eslint-disable-next-line max-len
      message: 'Во время тренинга все казалось настолько личным и релевантным для меня, я получила множество внутренних ответов. Спасибо'
   }]

   const courseFeedbackMessagesVitalii = [{
      userImage: lector.photo,
      message: 'Что изменилось в вас после прохождения курса? ',
      isLector: true
   },
   {
      userImage: urbanUser,
      message: 'Большое спасибо за отличный тренинг, игра "Короли пустыни" подарила столько эмоций.',
   },
   {
      userImage: lector.photo,
      message: 'Чему вы научились на курсах?',
      isLector: true
   },
   {
      userImage: urbanUser,
      message: 'Я многое получила из вашего тренинга для своей работы и служения.'
   }]

   const coursePlan = [{
      count: 'course.plans.0',
      title: 'course.gameLab.plan.0.title',
      img: planOne,
      description: [
         'course.gameLab.plan.0.descriptionOne',
         'course.gameLab.plan.0.descriptionTwo',
         'course.gameLab.plan.0.descriptionThree',
         'course.gameLab.plan.0.descriptionFour'
      ]
   },
   {
      count: 'course.plans.1',
      title: 'course.gameLab.plan.1.title',
      img: planTwo,
      description: [
         'course.gameLab.plan.1.descriptionOne',
         'course.gameLab.plan.1.descriptionTwo',
      ]
   },
   {
      count: 'course.plans.2',
      title: 'course.gameLab.plan.2.title',
      img: planThree,
      description: [
         'course.gameLab.plan.2.descriptionOne',
         'course.gameLab.plan.2.descriptionTwo',
         'course.gameLab.plan.2.descriptionThree',
      ]
   }]

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={gameLabBG}
            sectionName={SECTIONS.youth as CursesSections}
            modification={COURSES.gameLab as CoursesModifications}
            button={CoursesButtonTypes.SUBSCRIBE}
            buttonModification={'primaryOutlined'}
            customTag={'50$ / 800 MDL'}
            handleButtonClick={handleOpenInvoice}
         />
         <CoursePlan coursePlan={coursePlan} />
         <CoursePlanWrapper isMobile={isMobile}>
            <ImagesWrapper isMobile={isMobile}>
               <HoveredImage imageOne={personOne} imageTwo={personOneTransformed} />
               <HoveredImage imageOne={personThree} imageTwo={personThreeTransformed} />
               <HoveredImage imageOne={personTwo} imageTwo={personTwoTransformed} />
            </ImagesWrapper>
            <div>
               <h1>{t(`course.gameLab.whatTitle`)}</h1>
               <p>{t(`course.gameLab.whatIsDescription`)}</p>
            </div>
         </CoursePlanWrapper>
         <Lector lector={lector} tags={['Более 8 лет лектор', 'Более 8 лет в разработке', 'Более 8 лет в ивентах']} />
         <ChatDecorationWrapper isMobile={isMobile} isSmallScreen={width < 1400}>
            <ChatTitle isMobile={isMobile} isSmallScreen={width < 1400}>
               {t(`course.${COURSES.gameLab}.includedTitle`)}
            </ChatTitle>
            <ChatSubtitle isMobile={isMobile} isSmallScreen={width < 1400}>
               <div>
                  1: {t(`course.${COURSES.gameLab}.includedDescriptionOne`)}
               </div>
               <div>
                  2: {t(`course.${COURSES.gameLab}.includedDescriptionTwo`)}
               </div>
               <div>
                  3: {t(`course.${COURSES.gameLab}.includedDescriptionThree`)}
               </div>
               <div>
                  4: {t(`course.${COURSES.gameLab}.includedDescriptionFour`)}
               </div>
               <div>
                  5: {t(`course.${COURSES.gameLab}.includedDescriptionFive`)}
               </div>
            </ChatSubtitle>
            <img src={chatDecoration} alt="" />
            <ButtonWrapper isMobile={isMobile} isSmallScreen={width < 1400}>
               <Button onClick={handleOpenInvoice} modifier='primaryOutlined'>{t(`course.subscribe`)}</Button>
            </ButtonWrapper>
         </ChatDecorationWrapper>
         <CourseTitle>
            {t('course.feedback')}
         </CourseTitle>
         <CourseSubtitle>
            {t('course.feedbackDescription')}
         </CourseSubtitle>
         <CourseFooter isMobile={isMobile}>
            <CourseFeedback
               userName={'Асель Байзакова'}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessages}
            />
            <CourseFeedback
               userName={'Ажара Иманалиева'}
               section={SECTIONS.youth as CursesSections}
               messages={courseFeedbackMessagesVitalii}
            />
         </CourseFooter>
         <ContactsMap handleButtonClick={handleOpenInvoice} />
         <MaterialModal
            modification={sectionName}
            open={isInvoiceOpen}
            handleClose={handleOpenInvoice}
            bgcolor={COLORS.courseCardBackground}
            title={t('course.gameLab.title')}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.youth as CursesSections}
               course={COURSES.gameLab as CoursesModifications}
               handleCloseModal={handleOpenInvoice}
               invoiceDescription={t(`course.${COURSES.gameLab}.invoiceDescription`)}
            />
         </MaterialModal>
      </RestoreWrapper>
   );
};

export default GameLab;