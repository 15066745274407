interface AssociativeCard {
  id: number;
  name: string;
  image: string;
  flipped: boolean;
  selected: boolean;
  modification?: 'positive' | 'negative';
}

export const negativeCardsArray = () => {
  const negativeCards: AssociativeCard[] = [];
  for (let i = 1; i < 61; i++) {
    negativeCards.push({
      id: i,
      name: `negativeCard-${i}`,
      image: `/images/negativeCards/negativeCard-${i}.png`,
      flipped: false,
      selected: false,
      modification: 'negative',
    });
  }
  return negativeCards;
}

export const positiveCardsArray = () => {
  const positiveCards: AssociativeCard[] = [];
  for (let i = 1; i < 66; i++) {
    positiveCards.push({
      id: i,
      name: `positiveCard-${i}`,
      image: `/images/positiveCards/positiveCard-${i}.png`,
      flipped: false,
      selected: false,
      modification: 'positive',
    });
  }
  return positiveCards;
}
