
import { Link } from 'react-router-dom';
import { TermsAndConditionsWrapper, TermsText, PaymentLogo } from './styled';
import visa from '../../../assets/global/visa.png';
import mastercard from '../../../assets/global/mastercard.png';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
   const { t } = useTranslation();
   return (
      <TermsAndConditionsWrapper>
         <TermsText>
            {t('navigation.book.terms')} <Link className='link' to={'/urban_terms'} >{t('navigation.book.termsLink')}</Link>  
         </TermsText>
         <PaymentLogo>
            <img src={visa} alt="" />
            <img src={mastercard} alt="" />
         </PaymentLogo>
      </TermsAndConditionsWrapper>
   );
};

export default TermsAndConditions;
