import { useState } from 'react';

import ContactsMap from '../Courses/CommonComponents/ContactsMap';
import DirectionPreview from '../common/DirectionPreview';
import { SECTIONS } from '../../../constants/courses';
import youthDescriptionBG from '../../../assets/directions/youthDescriptionBG.png';
import { CursesSections } from '../../../types/common';

import { EventWrapper } from './styles';

const Youth = () => {
   const [isModalOpened, setIsModalOpened] = useState(false);

   const handleDirectionAction = () => {
      setIsModalOpened(!isModalOpened);
   }

   return (
      <EventWrapper>
         <DirectionPreview
            bgImage={youthDescriptionBG}
            sectionName={SECTIONS.youth as CursesSections}
            customTag={'Интервью'}
            handleDirectionAction={handleDirectionAction}
            isButtonDisabled={true}
         />
         <ContactsMap handleButtonClick={handleDirectionAction} />
      </EventWrapper>
   );
};

export default Youth;