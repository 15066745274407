import { CourseSearchWrapper } from './styled'
import SearchInput from './SearchInput'
import Filters from '../../../../components/UI/Filters/Filters'
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery'

const CourseSearch = ({
   filters,
   setSearchValue,
   searchValue
}: {
   filters: { label: string, onClick: () => void, active?: boolean }[]
   setSearchValue?: (value: string) => void
   searchValue?: string
}) => {

   const { isMobile } = useMediaQuery();

   return (
      <CourseSearchWrapper isMobile={isMobile}>
         <SearchInput setSearchValue={setSearchValue} searchValue={searchValue} />
         <Filters filters={filters} />
      </CourseSearchWrapper>
   )
}

export default CourseSearch