import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { COURSES, SECTIONS, PERSONALITY } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import personalitiesBG from '../../../../assets/courses/previewCourses/personalitiesBG.png';
import closeButtonBlack from '../../../../assets/courses/personalities/closeButtonBlack.png';

import { CursesSections, PersonalityTypes } from '../../../../types/common';
import { PersonalitiesWrapper, CourseTitle } from './styled';
import CardPersonalities from '../../../CardPersonalities/CardPersonalities';
import { selectCurrentPersonality } from '../../../../store/selectors/personality';
import PersonalityDescription from './PersonalityDescription/PersonalityDescription';
import StrengthsAndWeaknesses from './StrengthsAndWeaknesses/StrengthsAndWeaknesses';
import ContactsMap from '../CommonComponents/ContactsMap';
import { setCurrentPersonality } from '../../../../store/actions/personalityActions';

const Personalities = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const currentPersonality = useSelector(selectCurrentPersonality);
   const isPersonalitySelected = currentPersonality !== PERSONALITY.noSelected;

   const handleResetPersonality = () => {
      dispatch(setCurrentPersonality({ personality: PERSONALITY.noSelected as PersonalityTypes }))
   }

   return (
      <PersonalitiesWrapper>
         <CoursePreview
            bgImage={personalitiesBG}
            sectionName={SECTIONS.youth as CursesSections}
            modification={COURSES.personalities as CoursesModifications}
            button={CoursesButtonTypes.READ}
            buttonModification={'primaryOutlined'}
            customTag={'Free'}
            isButtonDisabled={true}
         />
         <CourseTitle id='personality_photo'>
            {t('course.personalities.title')}
            {isPersonalitySelected && <div onClick={handleResetPersonality}>
               <img src={closeButtonBlack} alt="" />
            </div>}
         </CourseTitle>
         {isPersonalitySelected ? <><PersonalityDescription />
            <CourseTitle>
               {t('personality.strengthsAndWeaknesses')}
            </CourseTitle>
            <StrengthsAndWeaknesses />
         </> : <CardPersonalities />}
         <ContactsMap handleButtonClick={() => null} isButtonDisabled={true} />
      </PersonalitiesWrapper>
   );
};

export default Personalities;