
import { COLORS } from './constants/colors';
import styled from 'styled-components';

export const AppWrapper = styled.div<{isMobile: boolean}>`
  font-family: 'Montserrat', sans-serif;
  background-color: ${COLORS.white};
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: ${({ isMobile }) => isMobile ? '16px' : '24px'};
    line-height: ${({ isMobile }) => isMobile ? '13px' : '29px'};
    display: flex;
    align-items: center;
    letter-spacing: -0.017em;
  }
`;

export const HolderTicket = styled.div`
  display: flex;
  min-height: 305px;
  max-width: 964px;
  align-items: flex-start;
`;

export const CheckBoxWrapper = styled.div`
  min-height: 305px;
  align-self: flex-start;
  height: 100%;
  border-right: 1px solid ${COLORS.whiteLight};
  background-color: ${COLORS.whiteSea};
`;
