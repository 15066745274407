import ContentLoader from "react-content-loader"

const MyEducationLoading = () => {
   return (
      <ContentLoader
         speed={2}
         width={'100%'}
         height={160}
         viewBox="0 0 700 100"
         backgroundColor="#f3f3f3"
         foregroundColor="#dedede"
      >
         <rect x="5" y="17" rx="0" ry="0" width="172" height="112" />
         <rect x="187" y="18" rx="0" ry="0" width="155" height="16" />
         <rect x="187" y="40" rx="0" ry="0" width="193" height="8" />
         <rect x="187" y="55" rx="0" ry="0" width="200" height="8" />
         <rect x="187" y="120" rx="0" ry="0" width="286" height="7" />
         <rect x="503" y="18" rx="0" ry="0" width="105" height="20" />
         <rect x="503" y="91" rx="0" ry="0" width="105" height="36" />
      </ContentLoader>
   );
};

export default MyEducationLoading;