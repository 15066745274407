import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import mentalMathBG from '../../../../assets/courses/previewCourses/mentalMathBG.png';
import { CursesSections } from '../../../../types/common';
import Puzzle1 from '../../../../assets/courses/mentalMath/pazzle1.svg';
import Puzzle2 from '../../../../assets/courses/mentalMath/pazzle2.svg';
import Puzzle3 from '../../../../assets/courses/mentalMath/pazzle3.svg';
import Puzzle4 from '../../../../assets/courses/mentalMath/pazzle4.svg';
import whatIsMentalImg from '../../../../assets/courses/mentalMath/whatIsMentalImg.png';
import chatMath from '../../../../assets/courses/mentalMath/chatMath.png';
import urbanUser from '../../../../assets/courses/courseUsers/urbanUser.png';
import { getCourseLector } from '../../../../utils/courses/getCourseLector';
import Lector from '../CommonComponents/Lector';
import ContactsMap from '../CommonComponents/ContactsMap';
import CourseFeedback from '../CommonComponents/CourseFeedback';
import { Button } from '../../../UI/Button';
import MaterialModal from '../../../UI/Modal/MaterialModal';
import { COLORS } from '../../../../constants/colors';
import MentalMathGame from '../../../MentalMathGame/MentalMathGame';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import InvoiceSteps from '../../../InvoiceSteps/InvoiceSteps';

import {
  RestoreWrapper,
  CourseTitle,
  CourseSubtitle,
  CourseFooter,
  CoursePlanWrapper,
  ChatDecorationWrapper,
  ChatTitle,
  ChatSubtitle,
  PuzzleContainer,
  GridItem1,
  GridItem2,
  GridItemPuzzle,
  GridItem4,
  GridItem5,
  GridItem8,
  CourseParagraphTitle,
  ButtonWrapper,
  PuzzleText,
  PuzzleImg,
} from './styles';

const MentalMath = () => {
  const dispatch = useDispatch();
  const sectionName = SECTIONS.kids as CursesSections;
  const courseName = COURSES.mentalMath as CoursesModifications;
  const { t } = useTranslation();
  const lectorFirst = getCourseLector(COURSES.parentAndChild as CoursesModifications);
  const lectorSecond = getCourseLector(COURSES.mentalMath as CoursesModifications);
  const [isTestOpened, setIsTestOpened] = useState(false);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const { isMobile } = useMediaQuery();

  const handleOpenInvoice = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };

  const handleOpenTest = () => {
    setIsTestOpened(!isTestOpened);
  };

  const courseFeedbackMessages = [
    {
      userImage: lectorFirst.photo,
      message: 'Раскажите свою историю как вы попали на курс ментальной арифметики?',
      isLector: true,
    },
    {
      userImage: urbanUser,
      message:
        // eslint-disable-next-line max-len
        'Изначально мы много думали, записывать ли Мариуса на данный курс. Тем не менее, мы решили попробовать, поэтому я взяла телефон и начала звонить. Первый центр, второй и так далее… Удивились и расстроились, когда один за другим нам отказали… Я с самого начала пыталась быть честной и говорила о том, что мой ребенок носитель кохлеарного импланта, что мы находимся в периоде слухово-вербальное восстановления… Но я думаю, новый опыт был превыше всего и наконец мы нашли центр, который с радостью нас принял «Urban Mental Academy»!',
    },
    {
      userImage: lectorFirst.photo,
      message: 'Как вам организация курса, презентации, структура?',
      isLector: true,
    },
    {
      userImage: urbanUser,
      message:
        // eslint-disable-next-line max-len
        'Вот тут и начался наш веселый опыт, но таким же образом такое полезное время для нас. У Мариуса были необычайно хорошие учителя-понимающие люди, которые заботились и старались объяснить каждому шаг, который нужно было сделать!',
    },
  ];

  const courseFeedbackMessagesVitalii = [
    {
      userImage: lectorSecond.photo,
      message: 'Как вам курс?',
      isLector: true,
    },
    {
      userImage: urbanUser,
      message:
        // eslint-disable-next-line max-len
        'После нескольких месяцев посещения курса ментальной арифметике, наблюдаю позитивные изменения в моём ребёнке. Он стал внимательным, усидчивым и самое главное увеличились успехи в учёбе в школе.',
    },
    {
      userImage: lectorSecond.photo,
      message: 'Как долго ваш ребёнок обучается ментальной арифметике?',
      isLector: true,
    },
    {
      userImage: urbanUser,
      message:
        // eslint-disable-next-line max-len
        'Мой сын уже 2 год обучается этой методике.  Сегодня он изучает, как можно умножать и делить на абакусе и ментально. Я всегда поражалась тому, как это у него это получается и на сколько потенциал мозга велик.',
    },
  ];

  useEffect(() => {
    dispatch(changeActiveTab({ activeTab: 'cabinet' }));
    dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }));
  }, []);

  const handleCourseRead = () => {
    return `/cabinet/${courseName}/reader`;
  };

  const PuzzleComponent = ({ title, subtitle, decoration }: { title: string; subtitle: string; decoration: string }) => {
    return (
      <GridItemPuzzle decoration={decoration}>
        <CourseParagraphTitle>{t(`course.${courseName}.${title}`)}</CourseParagraphTitle>
        <CourseSubtitle>{t(`course.${courseName}.${subtitle}`)}</CourseSubtitle>
      </GridItemPuzzle>
    );
  };

  return (
    <RestoreWrapper>
      <CoursePreview
        bgImage={mentalMathBG}
        sectionName={sectionName}
        modification={courseName}
        button={CoursesButtonTypes.SUBSCRIBE}
        buttonModification={'primaryOutlined'}
        customTag={'Pазвитие'}
        handleButtonClick={handleOpenInvoice}
      />
      <CourseTitle>{t(`course.${courseName}.whatIs`)}</CourseTitle>
      <CoursePlanWrapper isMobile={isMobile}>
        <p>{t(`course.${courseName}.whatIsDescription`)}</p>
        <img src={whatIsMentalImg} alt='' />
      </CoursePlanWrapper>
      <ChatDecorationWrapper isMobile={isMobile}>
        <ChatTitle isMobile={isMobile}>Начните курс в любое время — это бесплатно</ChatTitle>
        <ChatSubtitle isMobile={isMobile}>
          Цель курса – гармоничное развитие обоих полушарий мозга за счёт мысленной визуализации арифметических
          вычислений на счётах абакус.
        </ChatSubtitle>
        <img src={chatMath} alt='' />
        <ButtonWrapper isMobile={isMobile}>
          <Button modifier='primaryOutlined' color={sectionName} onClick={handleOpenTest}>
            Пройти быстрый тест
          </Button>
        </ButtonWrapper>
      </ChatDecorationWrapper>
      <div>
        <CourseTitle>{t(`course.${courseName}.buildFutureTitle`)}</CourseTitle>
        <PuzzleContainer isMobile={isMobile}>
          <GridItem1 isMobile={isMobile}>
            <PuzzleText>{t(`course.${courseName}.buildFutureSubtitle`)}</PuzzleText>
          </GridItem1>
          <GridItem2>
            <PuzzleImg src={Puzzle2} isMobile={isMobile}></PuzzleImg>
          </GridItem2>
          <PuzzleComponent title='thirdPuzleTitle' subtitle='thirdPuzleDescription' decoration='three' />
          <GridItem4 isMobile={isMobile}>
            <PuzzleImg src={Puzzle4} isMobile={isMobile}></PuzzleImg>
          </GridItem4>
          <GridItem5>
            <PuzzleImg src={Puzzle1} isMobile={isMobile}></PuzzleImg>
          </GridItem5>
          <PuzzleComponent title='firstPuzleTitle' subtitle='firstPuzleDescription' decoration='one' />
          <PuzzleComponent title='secondPuzleTitle' subtitle='secondPuzleDescription' decoration='two' />
          <GridItem8>
            <PuzzleImg src={Puzzle3} isMobile={isMobile}></PuzzleImg>
          </GridItem8>
          <PuzzleComponent title='fourthPuzleTitle' subtitle='fourthPuzleDescription' decoration='four' />
        </PuzzleContainer>
      </div>
      <CourseTitle>{t('course.lectors')}</CourseTitle>
      <Lector lector={lectorFirst} tags={['Более 20 лет лектор', 'Более 15 лет с в профессии', 'Более 10 лет с нами']} />
      <br />
      <Lector lector={lectorSecond} tags={['Более 5 лет лектор', 'Более 2 лет в профессии', 'Более 5 лет с нами']} />
      <CourseTitle>{t('course.feedback')}</CourseTitle>
      <CourseSubtitle>{t('course.feedbackDescription')}</CourseSubtitle>
      <CourseFooter isMobile={isMobile}>
        <CourseFeedback userName={'Cupceanu Elena'} section={sectionName} messages={courseFeedbackMessages} />
        <CourseFeedback userName={'Евпак Ольга'} section={sectionName} messages={courseFeedbackMessagesVitalii} />
      </CourseFooter>
      <ContactsMap handleButtonClick={handleCourseRead} />

      <MaterialModal
        modification={sectionName}
        open={isTestOpened}
        handleClose={handleOpenTest}
        bgcolor={COLORS.courseCardBackground}
        title={t('course.mentalMath.title')}
        width={isMobile ? 400 : undefined}
        height={isMobile ? 500 : undefined}
      >
        <MentalMathGame closeModal={handleOpenTest} />
      </MaterialModal>
      <MaterialModal
        modification={sectionName}
        open={isInvoiceOpen}
        handleClose={handleOpenInvoice}
        bgcolor={COLORS.courseCardBackground}
        title={t('course.mentalMath.title')}
        width={400}
        height={500}
      >
        <InvoiceSteps
          modification={SECTIONS.kids as CursesSections}
          course={COURSES.mentalMath as CoursesModifications}
          handleCloseModal={handleOpenInvoice}
          invoiceDescription={t(`course.${COURSES.mentalMath}.invoiceDescription`)}
        />
      </MaterialModal>
    </RestoreWrapper>
  );
};

export default MentalMath;
