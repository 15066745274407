
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const SettingsWrapper = styled.div<{ isMobile: boolean }>`
   top: 0;
   width: 100%;
   height: 100%;
   padding: 20px;
   color: ${COLORS.white};
   height: calc(100vh - ${({ isMobile }) => isMobile ? '140px' : '40px'});
   overflow-y: scroll;
`;

export const SettingsWelcomeImg = styled.img`
   max-width: 1000px;
   margin-top: -40px;
`;

export const WelcomeMessageWrapper = styled.div<{isMobile: boolean}>`
   position: ${({isMobile}) => isMobile ? 'unset' : 'absolute'};
   display: flex;
   margin-top: 22px;
   margin-left: 20px;
   z-index: 1;
   background: ${({isMobile}) => isMobile ? COLORS.blackLabel : 'transparent' };
   padding:  ${({isMobile}) => isMobile ? '20px' : '' };
   margin-bottom:  ${({isMobile}) => isMobile ? '20px' : '' };
`;

export const SubtitleMessage = styled.div`
   position: absolute;
   max-width: 700px;
   font-size: 13px;
   line-height: 16px
`;

export const UserSettingsRow = styled.div`
   display: flex;
   gap: 10px;
`;

export const UserInfoBlock = styled.div`
   background-color: ${COLORS.blackLabel};
   padding: 20px;
   border-radius: 10px;
`;

export const PasswordResetBlock = styled.div`
   background-color: ${COLORS.blackLabel};
   padding: 20px;
   border-radius: 10px;
   height: fit-content;
   width: 320px;
`;

