import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useSnackbar } from 'notistack';

import { PROD_API } from '../../../../constants/api';
import { selectUserData, selectUserProfile } from '../../../../store/selectors/user';
import { updateUserProfileBio, updateUserProfilePhoto } from '../../../../store/actions/userActions';
import { Button } from '../../../UI/Button';
import { COLORS } from '../../../../constants/colors';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import {
   UserInfoEditorWrapper,
   UserImage,
   UserTopRow,
   UserNameWrapper,
   Divider,
   UserBottomRow,
   HolderForm,
   InputTitle,
   VerticalSeparator
} from './styled'
import { Input } from '../../../UI/Input/Input';
import { validationFields } from './utils';

const UserInfoEditor = () => {
   const user = useSelector(selectUserProfile)
   const userData = useSelector(selectUserData)
   const fileInputRef = useRef<HTMLInputElement>(null);
   const dispatch = useDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar();
   const [validationErrorFields, setValidationErrorFields] = useState<string[]>([])
   const { isMobile } = useMediaQuery()

   const handleFileSelect = () => {
      if (fileInputRef.current) {
         fileInputRef.current.click();
      }
   };

   const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
         const file = event.target.files[0];
         dispatch(updateUserProfilePhoto({ photo: file }) as any);
      }
   };


   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { first_name, last_name, email } = e.target as HTMLFormElement;

      if(!validationFields({
         callback: enqueueSnackbar,
         first_name: first_name.value,
         last_name: last_name.value,
         email: email.value,
         setValidationErrorFields,
      })) {
         return
      }

      dispatch(updateUserProfileBio({ 
         first_name: first_name.value ? first_name.value :  user?.name?.split(" ")[0], 
         last_name: last_name.value ? last_name.value :  user?.name?.split(" ")[1], 
         email: email.value ? email.value : user?.email, 
         callback: () => enqueueSnackbar("Успешно внесены изменения", { variant: 'success'}),
         callbackError: () => enqueueSnackbar("Произошла ошибка", { variant: 'error'})
      }) as any);
      
      setValidationErrorFields([])
   }

   return (
      <UserInfoEditorWrapper>
         <UserTopRow>
            <div>
               <UserImage src={`${PROD_API}/${user?.profile_image}`} alt="user" />
            </div>
            <UserNameWrapper>
               <h1>
                  {user?.name}
               </h1>
               <span>
                  @{userData?.username} | {user?.email}
               </span>
               {/* <Button
                  modifier='primaryOutlined'
                  onClick={handleFileSelect}
               >
                  Изменить фото
               </Button> */}
               <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
               />
            </UserNameWrapper>
         </UserTopRow>
         <Divider />
         <UserBottomRow>
            <HolderForm isMobile={isMobile} onSubmit={handleOnSubmit}>
               <InputTitle>
                  {t('login.first_name')}
               </InputTitle>
               <Input
                  type='text'
                  name="first_name"
                  placeholder={user?.name?.split(" ")[0]}
                  modifier='primary'
                  isError={validationErrorFields.includes("first_name")}
                  icon={<PersonIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
               <InputTitle>
                  {t('login.last_name')}
               </InputTitle>
               <Input
                  type='text'
                  name="last_name"
                  placeholder={user?.name?.split(" ")[1]}
                  modifier='primary'
                  icon={<PersonIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  isError={validationErrorFields.includes("last_name")}
               />
               <InputTitle>
                  {t('login.email')}
               </InputTitle>
               <Input
                  type='text'
                  name="email"
                  placeholder={user?.email}
                  modifier='primary'
                  icon={<AlternateEmailIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
                  isError={validationErrorFields.includes("email")}
               />
               <Divider />
               <VerticalSeparator />
               <Button
                  modifier='primaryWhiteOutlined'
                  htmlType='submit'
               >
                  {t('login.save')}
               </Button>
            </HolderForm>
         </UserBottomRow>
      </UserInfoEditorWrapper>
   );
};

export default UserInfoEditor;