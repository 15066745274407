
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { hexToRGB } from '../../utils/colorConverter';

export const CourseWrapper = styled.div<{ isMobile: boolean, isDesktop: boolean }>`
   background-color: ${COLORS.courseCardBackground};
   border-radius: 10px;
   width: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? '260px' : '100%'};
   height: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? 'auto' : '160px'};
   margin-right: 25px;
   padding: 14px;
   display: flex;
   flex-direction: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? 'column' : 'row'};
`;

export const CourseImage = styled.img`
   width: 222px;
   height: 129px;
   border-radius: 10px;
`;

export const CourseTitle = styled.div`
   font-weight: 700;
   font-size: 16px;
   line-height: 41%;
   margin-top: -17px;
   color: ${COLORS.white};
   line-height: 120%;
`;

export const CourseDescription = styled.div`
   font-weight: 500;
   font-size: 12px;
   margin-top: 8px;
   line-height: 140%;
   min-height: 50px;
   opacity: 0.5;
   color: ${COLORS.white};
   word-break: break-word;
   display: -webkit-box;
   overflow: hidden;
   max-width: 300px;
   text-overflow: ellipsis;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
`;

export const CourseDescriptionWrapper = styled.div<{ color: string }>`
   font-weight: 500;
   font-size: 12px;
   margin-top: 8px;
   line-height: 130%;
   color: ${COLORS.white};
   display: flex;
   flex-direction: column;


   .MuiLinearProgress-root {
      border-radius: 100px;
      background-color: ${({ color }) => hexToRGB(color, 0.2)};
   }

   .MuiLinearProgress-bar {
      background-color: ${({ color }) => color};
   }
`;

export const CourseRightSide = styled.div<{ isMobile: boolean, isDesktop: boolean }>`
   display: flex;
   flex-direction: column;
   margin-top: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? '10px' : '-10px'};
`;

export const CourseInfo = styled.div<{ color: string }>`
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   justify-content: space-between;
   svg {
      color: ${props => props.color};
   }
`;

export const CourseActions = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-end;
   height: 100%;
   text-align: right;
   justify-content: flex-end;


   .disabled-link {
      pointer-events: none;
      
      button {
         .not-disabled-link {
            pointer-events: all;
         }
      }
   }
`;

export const CourseContent = styled.div<{ isMobile: boolean, isDesktop: boolean } >`
   display: flex;
   margin: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? '10px 0 0 0' : '10px'}};
   justify-content: space-between;
   flex-direction: ${({ isDesktop, isMobile }) => isDesktop || isMobile ? 'column' : 'row'};
   width: 100%;
`;

export const LabelWrapper = styled.div`
   opacity: 0.5;
   font-weight: 500;
   font-style: italic;

   p {
      font-family: 'Montserrat', sans-serif !important;
      font-size: 12px;
      margin-top: 5px;
   }
`;

export const CourseLector = styled.div`
   display: flex;
   opacity: 0.5;
   font-weight: 500;
   font-style: italic;
   font-size: 14px;
   margin-top: 5px;
`;

export const StoppedOnWrapper = styled.div`
   display: flex;
   flex-direction: column;
   text-transform: uppercase;
   opacity: 0.2;
   letter-spacing: 0.08em;
   font-style: italic;
   font-weight: 700;
   font-size: 10px;
   line-height: 130%;
   text-align: right;
`;

export const CurrentStopTitle = styled.div<{ color: string }>`
   font-weight: 700;
   font-size: 18px;
   background: ${props => props.color};
   color: ${COLORS.white};
   font-style: normal;
   font-weight: 700;
   font-size: 10px;
   line-height: 130%;
   padding: 4px;
   border-radius: 5px;
   margin-top: 5px;
   width: fit-content;
   text-align: right;
   align-self: flex-end;
`;