import { useState, useEffect } from 'react';

import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

import { CurrentMonth, DayWrapper, DaysWrapper } from './styled';
import CalendarEvents from './CalendarEvents';

function WeekCalendar() {
   const daysOfWeek: string[] = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
   const [currentDayOfWeek, setCurrentDayOfWeek] = useState<number>(new Date().getDay());
   const [currentWeek, setCurrentWeek] = useState<Date[]>([]);
   const { isMobile } = useMediaQuery();
   const today: Date = new Date();

   useEffect(() => {
      const startOfWeek: Date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const endOfWeek: Date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);
      const days: Date[] = [];
      for (let date = startOfWeek; date <= endOfWeek; date.setDate(date.getDate() + 1)) {
         days.push(new Date(date));
      }
      setCurrentWeek(days);
   }, []);

   const handleClick = (dayOfWeek: number) => {
      setCurrentDayOfWeek(dayOfWeek);
   };

   return (
      <div>
         <CurrentMonth isMobile={isMobile}>
            {currentWeek.length > 0 && currentWeek[0].toLocaleString('ru-RU', { month: 'short', year: 'numeric' })}
         </CurrentMonth>

         <DaysWrapper
            isMobile={isMobile}
         >
            {currentWeek.map((date) => (
               <DayWrapper
                  isMobile={isMobile}
                  key={date.toString()}
                  className={`${date.getDay() === currentDayOfWeek ? 'current-day' : ''} ${date.getDay() === today.getDay() && 'current-day-label'}`}
                  onClick={() => handleClick(date.getDay())}
               >
                  <div className='text'>{daysOfWeek[date.getDay()]}</div>

                  <div className='number'>{date.toLocaleString('default', { day: 'numeric' })}</div>
                  {date.getDay() === today.getDay() && <div className='current-day-marker'></div>}
               </DayWrapper>
            ))}
         </DaysWrapper>
         <div>
            <CalendarEvents 
               currentDayOfWeek={currentDayOfWeek} 
            />
         </div>
      </div>
   );
}

export default WeekCalendar;
