import { useDispatch, useSelector } from "react-redux";

import Tag from "../../../Tags/Tag";
import { CursesSections } from "types/common";
import { PROD_API } from "../../../../constants/api";

import {
   OneUserWrapper,
   UserImage,
   UserInfoWrapper,
   UserHolder,
   UserTagsWrapper,
} from "./styled";
import { setAdminSubscriptionSelectedUser } from "../../../../store/actions/appActions";
import { selectAdminSubscriptionSelectedUser } from "../../../../store/selectors/app";
import useMediaQuery from "../../../../utils/breakpoints/useMediaQuery";
import { CoursesModifications } from "types/courses/courses";
import { TranslateType } from "../../../../store/actions/translateAction";


type Props = {
   id: string,
   name: string,
   username: string,
   email: string,
   is_admin: boolean,
   profile_image: string,
   premium_courses: string[],
   isEmailChecked: boolean
   bookmarked_courses: CoursesModifications[],
   language: TranslateType
}


export const OneUser = ({
   id,
   name,
   username,
   email,
   is_admin,
   profile_image,
   premium_courses,
   isEmailChecked,
   bookmarked_courses,
   language
}: Props) => {
   const dispatch = useDispatch();
   const selectedAdminSubscriptionUser = useSelector(selectAdminSubscriptionSelectedUser)
   const { isMobile } = useMediaQuery();

   const handleSetUserInfo = () => {
      if (is_admin) {
         return;
      }
      dispatch(setAdminSubscriptionSelectedUser({
         user: {
            id,
            name,
            username,
            email,
            is_admin,
            profile_image,
            premium_courses,
            isEmailChecked,
            bookmarked_courses,
            language
         }
      }))
   }

   return <OneUserWrapper isSelected={selectedAdminSubscriptionUser?.id === id} isAdmin={is_admin} onClick={handleSetUserInfo} key={id}>
      <UserHolder>
         <UserImage src={`${PROD_API}/${profile_image}`} alt="user" />
         <UserInfoWrapper isMobile={isMobile}>
            <div>
               <h3>{name}</h3>
            </div>
            <span>
               <p> {email}</p>
            </span>
         </UserInfoWrapper>
      </UserHolder>
      <UserTagsWrapper>
         {is_admin && <Tag customName="Super user" name={'Magenta' as CursesSections} />}
      </UserTagsWrapper>
   </OneUserWrapper>
}
