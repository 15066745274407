import { GAME_ACTION_TYPES, GameAction } from '../actions/gameActions';
import { GameStatus } from 'types/courses/game';

interface GameState {
  memoryGameStatus: GameStatus;
  associationGameStatus: GameStatus;
}

const initialState: GameState = {
  memoryGameStatus: 'finished',
  associationGameStatus: 'finished',
};

export default function game(state = initialState, action: GameAction): GameState {
  switch (action.type) {
    case GAME_ACTION_TYPES.SET_ASSOCIATION_GAME_STATUS: {
      return {
        ...state,
        associationGameStatus: action.associationGameStatus,
      };
    }

    case GAME_ACTION_TYPES.SET_MEMORY_GAME_STATUS: {
      return {
        ...state,
        memoryGameStatus: action.memoryGameStatus,
      };
    }

    default: {
      return state;
    }
  }
}
