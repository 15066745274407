import { ChangeEvent, CSSProperties } from 'react';

import { Input as InputWrapper } from './styled';
import { CursesSections } from 'types/common';

export type InputProps = {
   onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
   labelId?: string;
   labelText?: string;
   placeholder?: string;
   value?: string;
   modifier?: 'primary' | 'secondary' | 'search' | 'search-secondary';
   type?: string;
   disabled?: boolean;
   prefix?: string | JSX.Element;
   suffix?: string | JSX.Element;
   className?: string;
   onClick?: () => void;
   style?: CSSProperties;
   color?: CursesSections;
   name?: string;
   defaultValue?: string;
   icon?: JSX.Element;
   isError?: boolean;
};

export const Input = (
   {
      onClick,
      className,
      prefix,
      suffix,
      modifier = 'primary',
      type = 'text',
      disabled,
      labelId,
      labelText,
      color,
      name,
      defaultValue,
      icon,
      isError,
      ...rest
   }: InputProps,
) => {
   return (
      <InputWrapper
         onClick={onClick}
         className={className}
         prefixContent={!!prefix}
         suffixContent={!!suffix}
         isIcon={!!icon}
      >
         <i className="icon">{icon}</i>
         {labelText ? <label htmlFor={labelId}>{labelText}</label> : null}
         <input
            autoComplete='off'
            type={type}
            className={`${modifier} color-${color} ${isError && 'error'}`}
            readOnly={disabled}
            name={name}
            defaultValue={defaultValue}
            {...rest}
         />
         <div className='prefix'>{prefix}</div>
         <div className='suffix'>{suffix}</div>
      </InputWrapper>
   );
}

