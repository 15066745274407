import { CursesSections } from 'types/common';
import { LabelWrapper } from './styled';
import { labelCourses, filterCourses, changeActiveTab } from '../../../store/actions/appActions';
import { ActiveLabels } from '../../../store/reducers/types';
import { useSelector, useDispatch } from 'react-redux';
import { selectLabel } from '../../../store/selectors/courses';
import { useNavigate } from 'react-router-dom';

export type LabelProps = {
   onClick?: () => void;
   section?: CursesSections;
   text: string;
};

export const Label = ({
   text,
   section,
}: LabelProps) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const label = useSelector(selectLabel)
   const currentLabel = useSelector(selectLabel)

   const handleLabelCourse = (label: ActiveLabels) => {
      if (label === currentLabel) {
         dispatch(filterCourses({ filter: 'all' }))
         dispatch(labelCourses({ label: 'none' as ActiveLabels }))
         return;
      }
      dispatch(changeActiveTab({ activeTab: 'courses' }))
      navigate('/courses')
      dispatch(filterCourses({ filter: 'all' }))
      dispatch(labelCourses({ label: label as ActiveLabels }))
   }

   return (
      <LabelWrapper
         onClick={() => handleLabelCourse(section as ActiveLabels)}
         className={`color-${section} ${label === section ? 'active' : ''}`}
      >
         {text}
      </LabelWrapper>
   );
};
