import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import parentsSchoolBG from '../../../../assets/courses/previewCourses/parentsSchoolBG.png';
import { CursesSections } from '../../../../types/common';
import courseFirst from '../../../../assets/courses/parentsSchool/courseFirst.png';
import courseSecond from '../../../../assets/courses/parentsSchool/courseSecond.png';
import ContactsMap from '../CommonComponents/ContactsMap';
import MaterialModal from '../../../../components/UI/Modal/MaterialModal';
import {COLORS} from '../../../../constants/colors';
import InvoiceSteps from '../../../../components/InvoiceSteps/InvoiceSteps';

import {
   RestoreWrapper,
   CourseTitle,
   CoursePlanWrapper,
} from './styles';


const ParentsSchool = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

   const handleOpenInvoice = () => {
      setIsInvoiceOpen(!isInvoiceOpen);
   }

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   const handleCourseRead = () => {
      return `/cabinet/${COURSES.prepareToSuccess}/reader`
   }

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={parentsSchoolBG}
            sectionName={SECTIONS.artTherapy as CursesSections}
            modification={COURSES.parentsSchool as CoursesModifications}
            button={CoursesButtonTypes.SUBSCRIBE}
            buttonModification={'primaryOutlined'}
            customTag={'Free'}
            handleButtonClick={handleOpenInvoice}
         />
         <CourseTitle>
            {t('course.parentsSchool.titleOne')}
         </CourseTitle>
         <CoursePlanWrapper>
            <div>
               <p>
                  {t(`course.${COURSES.parentsSchool}.subtitleOne`)}

               </p>
               <p>
                  {t(`course.${COURSES.parentsSchool}.subtitleTwo`)}
               </p>
            </div>
            <img src={courseFirst} alt="course plan" />
         </CoursePlanWrapper>
         <CourseTitle>
            {t('course.parentsSchool.titleTwo')}
         </CourseTitle>
         <CoursePlanWrapper>
            <img src={courseSecond} alt="course plan" />
            <div>
               <p>
                  {t(`course.${COURSES.parentsSchool}.subtitleThree`)}
               </p>
               <p>
                  {t(`course.${COURSES.parentsSchool}.subtitleFour`)}
               </p>
            </div>
         </CoursePlanWrapper>
         {/* <CourseTitle>
            {t('course.feedback')}
         </CourseTitle> */}
        {/*  <CourseSubtitle>
            {t('course.feedbackDescription')}
         </CourseSubtitle> */}
         <ContactsMap handleButtonClick={handleCourseRead} />
         <MaterialModal
            modification={SECTIONS.artTherapy as CursesSections}
            open={isInvoiceOpen}
            handleClose={handleOpenInvoice}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.artTherapy}.title`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.artTherapy as CursesSections}
               course={COURSES.parentsSchool as CoursesModifications}
               handleCloseModal={handleOpenInvoice}
               invoiceDescription={t(`course.${COURSES.parentsSchool}.invoiceDescription`)}
            />
         </MaterialModal>
      </RestoreWrapper>
   );
};

export default ParentsSchool;