import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import cardDecoration from '../../../../../assets/courses/personalities/cardDecoration.png';
import { getPersonalitiesAvatar } from '../../../../../utils/courses/getPersonalitiesAvatar';

import DropDown from '../../../../UI/DropDown/DropDown';
import { COLORS } from '../../../../../constants/colors';
import { PERSONALITIES } from '../../../../../constants/courses';
import { selectCurrentPersonality, selectCurrentPersonalitySection } from '../../../../../store/selectors/personality';
import PersonalityIntroduction from './PersonalityIntroduction';
import useMediaQuery from '../../../../../utils/breakpoints/useMediaQuery';

import {
   PersonalityWrapper,
   PersonalityProfilePhoto,
   PersonalityTitle,
   PersonalityDescriptionWrapper,
   PersonalityDecoration,
   DescriptionWrapper,
   DropDownTitle,
   DropDownSubtitle,
} from './styled';


const PersonalityDescription = () => {
   const { t } = useTranslation();
   const currentPersonality = useSelector(selectCurrentPersonality);
   const currentPersonalitySection = useSelector(selectCurrentPersonalitySection);
   const element = document.getElementById('personality_photo');
   const { isMobile } = useMediaQuery();

   useEffect(() => {
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   }, [element, currentPersonality]);

   const getPersonalityCardColor = () => {
      switch (currentPersonalitySection) {
         case PERSONALITIES.analytic:
            return COLORS.eventLabOpacity;
         case PERSONALITIES.diplomat:
            return COLORS.kidsOpacity;
         case PERSONALITIES.keeper:
            return COLORS.youthOpacity;
         case PERSONALITIES.finder:
            return COLORS.artTherapyOpacity;
         default:
            return COLORS.eventLab;
      }
   };

   const renderDropDowns = () => {
     return [1, 2, 3].map((item) => {
         return <DropDown
            key={item}
            contentSmall={<>
               <DropDownTitle>
                  {t(`personality.${currentPersonality}.personalityBlockTitle${item}`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {/* eslint-disable-next-line newline-per-chained-call */}
                  {t(`personality.${currentPersonality}.personalityRow${item}`).split('/n').map(line => <p key={line}>{line}</p>)}
               </DropDownSubtitle>
            </>}
            contentBig={<>
               <DropDownTitle>
                  {t(`personality.${currentPersonality}.personalityBlockTitle${item}`)}
               </DropDownTitle>
               <DropDownSubtitle>
                  {t(`personality.${currentPersonality}.personalityShort${item}`)}
               </DropDownSubtitle>
            </>}
         />
      })
   }
   return (
      <div>
         <DescriptionWrapper isMobile={isMobile}>
            <PersonalityWrapper isMobile={isMobile} color={getPersonalityCardColor()}>
               <PersonalityProfilePhoto  src={getPersonalitiesAvatar(currentPersonality)} alt="" />
               <PersonalityTitle>
                  {t(`personality.${currentPersonality}.title`)}
               </PersonalityTitle>
               <PersonalityDescriptionWrapper>
                  {t(`personality.${currentPersonality}.description`)}
               </PersonalityDescriptionWrapper>
               <PersonalityDecoration src={cardDecoration} alt="" />
            </PersonalityWrapper>
            <PersonalityIntroduction getPersonalityCardColor={getPersonalityCardColor} />
         </DescriptionWrapper>
         <div>
            {renderDropDowns()}
         </div>
      </div>

   );
};

export default PersonalityDescription;