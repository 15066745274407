type ValidationType = {
   callback: any,
   password: string,
   password2: string,
   setValidationErrorFields: (value: string[]) => void
}

export const validationFields = ({
   callback,
   password,
   password2,
   setValidationErrorFields
}: ValidationType): boolean => {
   const russianSymbolsRegex = /[а-яА-ЯЁё]/;


   if (!password || !password2 ) {
      callback("Please fill in all the required fields.", { variant: "warning" });
      setValidationErrorFields(['password', 'password2'])
      return false;
   }

   if (password.length < 8) {
      callback("Username should be at least 5 characters long.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (password.includes(" ")) {
      callback("Username should not contain spaces.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (russianSymbolsRegex.test(password)) {
      callback("Password should not contain Russian characters.", { variant: "warning" });
      setValidationErrorFields(['password'])
      return false;
   }

   if (password !== password2) {
      callback("Passwords do not match.", { variant: "warning" });
      setValidationErrorFields(['password', 'password2'])
      return false;
   }

   return true
}