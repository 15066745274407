import { PersonalityTypes, PersonalitySections } from 'types/common';

export enum PERSONALITY_ACTION_TYPES {
  SET_CURRENT_PERSONALITY = 'PERSONALITY::SET_CURRENT_PERSONALITY',
  SET_CURRENT_PERSONALITY_SECTION = 'PERSONALITY::SET_CURRENT_PERSONALITY_SECTION',
}

export type PersonalityAction =
  | { type: PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY; currentPersonality: PersonalityTypes }
  | { type: PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY_SECTION; currentPersonalitySection: PersonalitySections };

export const setCurrentPersonality = ({ personality }: { personality: PersonalityTypes }): PersonalityAction => {
  return { type: PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY, currentPersonality: personality };
};

export const setCurrentPersonalitySection = ({ section }: { section: PersonalitySections }): PersonalityAction => {
  return { type: PERSONALITY_ACTION_TYPES.SET_CURRENT_PERSONALITY_SECTION, currentPersonalitySection: section };
}
