import ContentLoader from "react-content-loader"
import { COLORS } from '../../../../constants/colors';

const PopularCourseLoader = () => (
  <ContentLoader 
    speed={2}
    width={800}
    height={229}
    viewBox="0 0 8s00 429"
    backgroundColor={COLORS.loader}
    foregroundColor={COLORS.loaderSecondary}
  >
    <rect x="11" y="5" rx="11" ry="11" width="222" height="129" /> 
    <rect x="11" y="274" rx="18" ry="18" width="222" height="39" /> 
    <rect x="11" y="149" rx="8" ry="8" width="222" height="28" /> 
    <rect x="11" y="220" rx="0" ry="0" width="130" height="6" /> 
    <rect x="11" y="190" rx="0" ry="0" width="221" height="6" /> 
    <rect x="11" y="205" rx="0" ry="0" width="221" height="6" /> 
    <rect x="11" y="237" rx="14" ry="14" width="99" height="23" /> 
    <rect x="270" y="3" rx="11" ry="11" width="222" height="129" /> 
    <rect x="270" y="272" rx="18" ry="18" width="222" height="39" /> 
    <rect x="270" y="147" rx="8" ry="8" width="222" height="28" /> 
    <rect x="270" y="218" rx="0" ry="0" width="130" height="6" /> 
    <rect x="270" y="188" rx="0" ry="0" width="221" height="6" /> 
    <rect x="270" y="203" rx="0" ry="0" width="221" height="6" /> 
    <rect x="270" y="235" rx="14" ry="14" width="99" height="23" /> 
    <rect x="531" y="3" rx="11" ry="11" width="222" height="129" /> 
    <rect x="531" y="272" rx="18" ry="18" width="222" height="39" /> 
    <rect x="531" y="147" rx="8" ry="8" width="222" height="28" /> 
    <rect x="531" y="218" rx="0" ry="0" width="130" height="6" /> 
    <rect x="531" y="188" rx="0" ry="0" width="221" height="6" /> 
    <rect x="531" y="203" rx="0" ry="0" width="221" height="6" /> 
    <rect x="531" y="235" rx="14" ry="14" width="99" height="23" />
  </ContentLoader>
)

export default PopularCourseLoader;

