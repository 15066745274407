import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { SECTIONS } from '../../../constants/courses';
import { CursesSections } from '../../../types/common';
import { Label } from '../../../components/UI/Label/Label';


import closeButtonWhite from '../../../assets/courses/personalities/closeButtonWhite.png';
import { selectLabel } from '../../../store/selectors/courses';
import { labelCourses } from '../../../store/actions/appActions';

import { NavigationLabelsContainer, HashTag, HashTagWrapper } from './styled';

const NavigationLabels = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch()
   const currentLabel = useSelector(selectLabel)
   const isLabelActive = currentLabel !== 'none'

   const labels = [
      {
         label: 'Art-Therapy',
         direction: SECTIONS.artTherapy,
      },
      {
         label: 'Teens',
         direction: SECTIONS.teens,
      },
      {
         label: 'Youth',
         direction: SECTIONS.youth,
      },
      {
         label: 'Kids',
         direction: SECTIONS.kids,
      },
      {
         label: 'Show',
         direction: SECTIONS.show,
      }
   ]

   const handleResetLabel = () => {
      dispatch(labelCourses({ label: 'none' }))
   }

   const renderContent = () => {
      return labels.map(({ direction, label }, index) => {
         return <Label key={index} section={direction as CursesSections} text={label} />
      })
   }

   return (
      <NavigationLabelsContainer>
         <HashTagWrapper>
            <HashTag>
               #{t('navigation.labels')}
            </HashTag>
            {isLabelActive && <div onClick={handleResetLabel}>
               <img src={closeButtonWhite} alt="" />
            </div>}
         </HashTagWrapper>
         {renderContent()}
      </NavigationLabelsContainer>
   );
};

export default NavigationLabels;