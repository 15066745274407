import CardGrid from "./CardGrid";
import PlayerInfo from "./PlayerInfo";
import { useEffect, useState } from "react";
import styled from "styled-components";
import EndInfo from "./EndInfo";
import cards from "./libs/cards";
import { COLORS } from '../../constants/colors';
import { Button } from "../../components/UI/Button";
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

export interface CardObj {
  id: number;
  name: string;
  image: string;
  flipped: boolean;
  found: boolean;
}

const MemoryGame = (): JSX.Element => {
  const [randomCards, setRandomCards] = useState<CardObj[] | null>(null);
  const [score, setScore] = useState<number>(0);
  const [turns, setTurns] = useState<number>(0);
  const [selectedCards, setSelectedCards] = useState<CardObj[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { isMobile } = useMediaQuery()
  const [gameFinished, setGameFinished] = useState<boolean>(false);
  const pairsToFind = 8;

  const randomizeCards = () => {
    const randomOrderArr = [];
    let cardsArr = cards;
    for (let i = cardsArr.length; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * i);
      randomOrderArr.push(cardsArr[randomIndex]);
      cardsArr = [
        ...cardsArr.slice(0, randomIndex),
        ...cardsArr.slice(randomIndex + 1),
      ];
    }
    return randomOrderArr;
  };

  useEffect(() => {
    if (score === pairsToFind) {
      setTimeout(() => {
        setRandomCards(null);
        setGameFinished(true);
      }, 3000);
    }
  }, [score]);

  useEffect(() => {
    if (selectedCards.length === 2) {
      if (randomCards) {
        setTurns(turns + 1);

        let newState: CardObj[] = [];
        if (selectedCards[0].name === selectedCards[1].name) {
          setScore(score + 1);
          newState = randomCards.map((card) => {
            if (card.name === selectedCards[0].name) {
              return { ...card, found: true, flipped: false };
            }
            return card;
          });
        } else {
          newState = randomCards.map((card) => {
            if (
              card.name === selectedCards[0].name ||
              card.name === selectedCards[1].name
            ) {
              return { ...card, flipped: false };
            }
            return card;
          });
        }
        setTimeout(() => {
          setRandomCards(newState);
          setSelectedCards([]);
        }, 1000);
      }
    }
  }, [selectedCards]);

  const handleClick = (cardIndex: number, card: CardObj) => {
    if (isPlaying) {
      if (selectedCards.length < 2) {
        setFlippedStatus(cardIndex);
        updateSelectedCards(card);
      }
    }
  };

  const setFlippedStatus = (cardIndex: number) => {
    if (randomCards) {
      if (
        randomCards[cardIndex].flipped === true ||
        randomCards[cardIndex].found === true
      ) {
        return;
      }

      const newState = randomCards.map((card, index) => {
        if (index === cardIndex) {
          return { ...card, flipped: true };
        }
        return card;
      });
      setRandomCards(newState);
    }
  };

  const updateSelectedCards = (card: CardObj) => {
    if (card.flipped === true) {
      return;
    }
    if (card.found === true) {
      return;
    }
    setSelectedCards([...selectedCards, card]);
  };

  const resetGame = () => {
    setScore(0);
    setTurns(0);
    setSelectedCards([]);
    setGameFinished(false);
    setIsPlaying(false);
    startGame();
  };

  const startGame = () => {
    const newState = randomizeCards();
    setRandomCards(newState);
    setIsPlaying(true);
  };

  return (
    <GameContainer isMobile={isMobile}>
      {gameFinished && <EndInfo resetGame={resetGame} endGameText={'You found all of the matching cards'} />}
      {isPlaying ? (
        <CardGrid randomCards={randomCards} handleClick={handleClick} />
      ) : (
        <StartContainer>
          <IntroText>Найдите все совпадающие пары карт!</IntroText>
          <Button modifier="primaryOutlined" onClick={startGame}>Начать</Button>
        </StartContainer>
      )}
      <PlayerInfo turns={turns} score={score} />
    </GameContainer>
  );
};

const GameContainer = styled.main<{isMobile: boolean}>`
  width: min(99%, 1000px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ isMobile }) => isMobile ? '100vh' : '84vh'};
  border-radius: 0.5rem;
  padding: 2rem 0 0 0;
  overflow: ${({ isMobile }) => isMobile ? 'scroll' : 'hidden'};
  color: ${COLORS.white};
`;

const StartContainer = styled.div`
  text-align: center;
  margin-top: auto;
  font-size: 2rem;
  align-items: center;

  button {
    margin: 0 auto;
  }
`;

const IntroText = styled.h3`
  font-size: 1.5rem;
`;

export default MemoryGame;
