import { useTranslation } from "react-i18next";
import styled from 'styled-components'
import { CursesSections } from "types/common";

import { ThemeProvider } from '@material-ui/core';
import { themeBtn } from "../../styles/theme";
import logo from '../../assets/header/logo.svg';
import MiniGames from './MiniGames/MiniGames';
import MaterialModal from '../../components/UI/Modal/MaterialModal';
import MemoryGame from '../../components/MemoryGame/Game';
import { COLORS } from '../../constants/colors';
import AssociativeGame from '../../components/AssociativeGame/AssociativeGame';
import { useSelector, useDispatch } from 'react-redux';
import { selectAssociationGame, selectMemoryGame } from "../../store/selectors/game";
import { setAssociationsGameStatus, setMemoryGameStatus } from '../../store/actions/gameActions';
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';
import UserInfo from "./UserInfo/UserInfo";
import Tag from "../../components/Tags/Tag";
import { selectUserProfile } from "../../store/selectors/user";
import { Changer } from "./Changer/Changer";
import { HEADER_HEIGHT, NAVIGATION_WIDTH } from "../../constants/dimensions";

const Header = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const userProfile = useSelector(selectUserProfile);
   const { isMobile } = useMediaQuery();

   const isPlayingMemoryGame = useSelector(selectMemoryGame);
   const openMemoryGame = isPlayingMemoryGame === 'playing';
   const handleCloseMemoryGame = () => {
      dispatch(setMemoryGameStatus({ status: 'finished' }));
   };

   const isPlayingAssociationGame = useSelector(selectAssociationGame);
   const openAssociationGame = isPlayingAssociationGame === 'playing';
   const handleCloseAssociationGame = () => {
      dispatch(setAssociationsGameStatus({ status: 'finished' }));
   };

   return <HeaderWrapper isMobile={isMobile}>
      <img src={logo} alt="" />
      <ThemeProvider theme={themeBtn}>
         <HeaderLeft>
            {/* TODO add all languages in locals to use */}
            {!isMobile && <Changer />}
            <MiniGames />
            <UserInfo />
            <MaterialModal
               modification='Event-lab'
               open={openMemoryGame}
               handleClose={handleCloseMemoryGame}
               bgcolor={COLORS.courseCardBackground}
               title={t('games.oneCardGame.title')}
               width={isMobile ? 400 : undefined}
               height={isMobile ? 500 : undefined}
            >
               <MemoryGame />
            </MaterialModal>
            <MaterialModal
               modification='Art-therapy'
               open={openAssociationGame}
               handleClose={handleCloseAssociationGame}
               bgcolor={COLORS.courseCardBackground}
               title={t('games.responseFromMind.title')}
               width={isMobile ? 400 : undefined}
               height={isMobile ? 500 : undefined}
            >
               <AssociativeGame />
            </MaterialModal>
         </HeaderLeft>
      </ThemeProvider>
   </HeaderWrapper>
}

export default Header;

const HeaderWrapper = styled.div<{ isMobile?: boolean }>`
   display: flex;
   position: absolute;
   top: 0;
   left: ${({ isMobile }) => isMobile ? 0 : NAVIGATION_WIDTH + 10}px;
   justify-content: space-between;
   align-items: center;
   margin: 10px;
   height: ${HEADER_HEIGHT}px;
   width: ${({ isMobile }) => isMobile ? `calc(100% - 20px)` : `calc(100% - ${NAVIGATION_WIDTH + 30}px)`};
   border-radius: 10px;
   background: radial-gradient(100% 57600% at 0% 0%, #121212 0%, #282828 100%);

   img {
     margin-left: 10px;
   }
`;

const HeaderLeft = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 60px;
`;

