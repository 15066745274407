import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { changeActiveTab, setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES, SECTIONS } from '../../../../constants/courses';
import { CoursesModifications, CoursesButtonTypes } from '../../../../types/courses/courses';
import CoursePreview from '../CommonComponents/CoursePreview';
import CoursePlan from '../CommonComponents/CoursePlan';
import restoreBrokenBg from '../../../../assets/courses/previewCourses/restoreBrokenBg.png';
import { CursesSections } from '../../../../types/common';
import planOne from '../../../../assets/courses/restoreBroken/planOne.png';
import planTwo from '../../../../assets/courses/restoreBroken/planTwo.png';
import planThree from '../../../../assets/courses/restoreBroken/planThree.png';
import elenaVakulovschi from '../../../../assets/courses/courseUsers/elenaVakulovschi.png';
import vasileFarakas from '../../../../assets/courses/courseUsers/vasileFarakas.png';
import { getCourseLector } from '../../../../utils/courses/getCourseLector';
import Lector from '../CommonComponents/Lector';
import ContactsMap from '../CommonComponents/ContactsMap';
import CourseFeedback from '../CommonComponents/CourseFeedback';
import SideModal from '../../../UI/Modal/SideModal';
import { COLORS } from '../../../../constants/colors';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import PaymentSteps from '../../../PaymentSteps/PaymentSteps';
import { selectUserProfile } from '../../../../store/selectors/user';

import { RestoreWrapper, CourseTitle, CourseSubtitle, CourseFooter } from './styles';
import { getTransactionId } from '../../../../store/actions/userActions';

const COURSE_PAYMENT_AMOUNT = 80000;

const RestoreBroken = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const navigate = useNavigate();
   const lector = getCourseLector(COURSES.restoreBroken as CoursesModifications)
   const { isMobile } = useMediaQuery()
   const userProfileInfo = useSelector(selectUserProfile)
   const { enqueueSnackbar } = useSnackbar();
   const hasUserPaid = userProfileInfo?.premium_courses?.includes(COURSES.restoreBrokenPremium)


   const handleButtonClick = () => {
      if (hasUserPaid) {
         dispatch(changeActiveTab({ activeTab: 'cabinet' }))
         dispatch(setCurrentCourse({ courseModification: COURSES.restoreBrokenPremium as CoursesModifications }))
         navigate('/cabinet')
      } else {
         handleOpenModal()
      }
   }

   const handleOpenModal = () => {
      if (userProfileInfo?.email) {
         dispatch(getTransactionId({ amount: COURSE_PAYMENT_AMOUNT}) as any)
         setIsModalOpen(!isModalOpen);
      } else {
         enqueueSnackbar(t('course.restoreBroken.modalError'), {
            variant: 'warning', anchorOrigin: {
               vertical: 'top',
               horizontal: 'left'
            }
         })
      }
   }

   const handleCloseModal = () => {
      setIsModalOpen(false);
   }

   const coursePlan = [{
      count: 'course.plans.0',
      title: 'course.restoreBroken.plan.0.title',
      img: planOne,
      description: [
         'course.restoreBroken.plan.0.descriptionOne',
         'course.restoreBroken.plan.0.descriptionTwo',
         'course.restoreBroken.plan.0.descriptionThree',
         'course.restoreBroken.plan.0.descriptionFour'
      ]
   },
   {
      count: 'course.plans.1',
      title: 'course.restoreBroken.plan.1.title',
      img: planTwo,
      description: [
         'course.restoreBroken.plan.1.descriptionOne',
         'course.restoreBroken.plan.1.descriptionTwo',
         'course.restoreBroken.plan.1.descriptionThree',
         'course.restoreBroken.plan.1.descriptionFour'
      ]
   },
   {
      count: 'course.plans.2',
      title: 'course.restoreBroken.plan.2.title',
      img: planThree,
      description: [
         'course.restoreBroken.plan.2.descriptionOne',
         'course.restoreBroken.plan.2.descriptionTwo',
         'course.restoreBroken.plan.2.descriptionThree',
      ]
   }]

   const courseFeedbackMessages = [{
      userImage: lector.photo,
      message: 'Как вам курс, что нового смогли взять для себя?',
      isLector: true
   },
   {
      userImage: elenaVakulovschi,
      message: 'Мне очень понравился курс, особенно практическая часть курса со всеми её упражнениями и заданиями.',
   },
   {
      userImage: lector.photo,
      message: 'Как вам организация курса, презентации, структура?',
      isLector: true
   },
   {
      userImage: elenaVakulovschi,
      message: 'Вся предоставленная информация была доступной, очень хорошо структурирована! Также я смогла больше узнать себя!'
   }]

   const courseFeedbackMessagesVitalii = [{
      userImage: lector.photo,
      message: 'Как вам курс, что нового смогли взять для себя?',
      isLector: true
   },
   {
      userImage: vasileFarakas,
      // eslint-disable-next-line max-len
      message: 'Для меня этот курс был важным и полезным, потому что я глубже осознал что в служение с разными людьми, имеющие разные проблемы, важно иметь знание и специальный подход.',
   },
   {
      userImage: lector.photo,
      message: 'Что самое важное вынесли для себя из курса?',
      isLector: true
   },
   {
      userImage: vasileFarakas,
      // eslint-disable-next-line max-len
      message: 'Важно для меня было также понять, что я не должен и не правильно рвануться разрешать проблему пострадавшего и спасать его, а важно то, чтобы я помог таким людям найти ресурсы для восстановления. Для меня курс был хорошим опытом и уверен, что послужит в дальнейшем работе и служении. Спасибо большое!'
   }]

   useEffect(() => {
      dispatch(changeActiveTab({ activeTab: 'cabinet' }))
      if (hasUserPaid) {
         dispatch(setCurrentCourse({ courseModification: COURSES.restoreBrokenPremium as CoursesModifications }))
         return
      } 
      dispatch(setCurrentCourse({ courseModification: COURSES.notSelected as CoursesModifications }))
   }, []);

   return (
      <RestoreWrapper>
         <CoursePreview
            bgImage={restoreBrokenBg}
            sectionName={SECTIONS.artTherapy as CursesSections}
            modification={COURSES.restoreBroken as CoursesModifications}
            button={hasUserPaid ? CoursesButtonTypes.SEE : CoursesButtonTypes.BUY}
            buttonModification={'primaryOutlined'}
            customTag={'50$ / 800 MDL'}
            handleButtonClick={handleButtonClick}
         />
         <CoursePlan coursePlan={coursePlan} />
         <CourseTitle>
            {t('course.lector')}
         </CourseTitle>
         <Lector lector={lector} tags={['Более 20 лет лектор', 'Более 20 лет с нами', 'Более 20 лет в профессии']} />
         <CourseTitle>
            {t('course.feedback')}
         </CourseTitle>
         <CourseSubtitle>
            {t('course.feedbackDescription')}
         </CourseSubtitle>
         <CourseFooter isMobile={isMobile}>
            <CourseFeedback
               userName={'Елена Вакуловски'}
               section={SECTIONS.artTherapy as CursesSections}
               messages={courseFeedbackMessages}
            />
            <CourseFeedback
               userName={'Василе Фаркас'}
               section={SECTIONS.artTherapy as CursesSections}
               messages={courseFeedbackMessagesVitalii}
            />
         </CourseFooter>
         <ContactsMap handleButtonClick={handleOpenModal} />
         <SideModal
            open={isModalOpen}
            handleClose={handleCloseModal}
            bgcolor={COLORS.courseCardBackground}
            modalWidth={500}
            height={500}
         >
            <PaymentSteps
               isMaib={true}
               modification={SECTIONS.artTherapy as CursesSections}
               course={COURSES.restoreBroken as CoursesModifications}
               handleCloseModal={handleCloseModal}
            />
         </SideModal>
      </RestoreWrapper>
   );
};

export default RestoreBroken;