import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Tag from '../../../Tags/Tag';
import buckleUp from '../../../../assets/recommended/buckleUp.png';
import { setCurrentCourse } from '../../../../store/actions/appActions';
import { COURSES } from '../../../../constants/courses';
import { CoursesModifications } from '../../../../types/courses/courses';

import { RestoreWrapper, TagsWrapper, RestoreImage, RestoreContent, RestoreBottom } from './styled';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';

const BuckleUpProgressio = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const { isMobile } = useMediaQuery();

   const handleSelectCourse = () => {
      dispatch(setCurrentCourse({ courseModification: COURSES.buckleUpProposal as CoursesModifications }));
   }

   return (
      <Link to={`/cabinet/${COURSES.buckleUpProposal}`} >
         <RestoreWrapper onClick={handleSelectCourse} isMobile={isMobile}>
            <RestoreImage isMobile={isMobile} src={buckleUp} />
            <RestoreContent isMobile={isMobile} >
               <TagsWrapper>
                  <Tag name="Kids" />
               </TagsWrapper>
               <RestoreBottom isMobile={isMobile}> 
                  <h1>{t('course.buckleUp.title')}</h1>
                  <p>{t('course.buckleUp.description')}</p>
            </RestoreBottom>
            </RestoreContent>
         </RestoreWrapper>
      </Link>
   );
};

export default BuckleUpProgressio;