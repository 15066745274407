import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FirsStep from './Steps/FirsStep';
import SecondStep from './Steps/SecondStep';
import { CursesSections } from '../../types/common';
import { CoursesModifications } from 'types/courses/courses';

import {
   StepWrapper,
} from './styled'

type Props = {
   handleCloseModal: () => void;
   modification?: CursesSections;
   invoiceDescription?: string;
   course?: CoursesModifications;
}

const InvoiceSteps = ({ handleCloseModal, modification, invoiceDescription, course }: Props) => {
   const [activeStep, setActiveStep] = useState(0);
   const { t } = useTranslation()

   const handleNextStep = () => {
      if (isLastStep) {
         handleCloseModal()
         return;
      }
      setActiveStep(activeStep + 1);
   };

   const steps = [
      {
         title: 'Start',
         components: <FirsStep handleNextStep={handleNextStep} modification={modification} course={course} />,
      },
      {
         title: 'Middle',
         components: <SecondStep />,
      },
   ];
   const isLastStep = activeStep === steps.length - 1;

   return (
      <StepWrapper>
         {!isLastStep && <h3>{t(`${invoiceDescription}`)}</h3>}
         {steps[activeStep].components}
      </StepWrapper>
   );
};

export default InvoiceSteps;