import styled from "styled-components";
import AssociativeCard, { AssociativeCardObj } from "./AssociativeCard";
import useMediaQuery from '../../utils/breakpoints/useMediaQuery';

type CardGridProps = {
  cards: AssociativeCardObj[] | null;
  handleClick: (card: AssociativeCardObj) => void;
};

const AssociativeCardGrid = ({ cards, handleClick }: CardGridProps): JSX.Element => {
  const { isMobile } = useMediaQuery();

  return (
    <Wrapper isMobile={isMobile}>
      {cards &&
        cards.map((card) => (
          <AssociativeCard
            handleClick={() => handleClick(card)}
            card={card}
            flippedImage={card.image}
            key={card.id}
            modification={card.modification}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.section<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  row-gap: 0.8rem;
  column-gap: 0.9rem;
  width: ${({ isMobile }) => (isMobile ? "100%" : "560px")};
  height: ${({ isMobile }) => (isMobile ? "400px" : "670px")};
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default AssociativeCardGrid;
