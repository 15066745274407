import axios from "axios";
import { Dispatch } from "redux";
import { OneCourse, CoursesModifications } from 'types/courses/courses';
import { ActiveLabels, ActiveTabs } from "../reducers/types";
import { LoadingStatuses, ActiveFilters } from '../reducers/types/index';
import { API_ALL_COURSES, API_POPULAR_COURSES, API_PREMIUM_COURSES } from '../../constants/courses';
import { UserOneProfileType } from "types/user/user";

export enum APP_ACTION_TYPES {
  GET_ALL_COURSES = 'APP::GET_ALL_TICKETS',
  CHANGE_STATUS = 'APP::CHANGE_STATUS',
  CHANGE_ACTIVE_TAB = 'APP::CHANGE_ACTIVE_TAB',
  GET_POPULAR_COURSES = 'APP::GET_POPULAR_COURSES',
  GET_PREMIUM_COURSES = 'APP::GET_PREMIUM_COURSES',
  GET_POPULAR_COURSES_STATUS = "APP::GET_POPULAR_COURSES_STATUS",
  SET_CURRENT_COURSE_MODIFICATION = "APP::SET_CURRENT_COURSE_MODIFICATION",
  SET_CURRENT_COURSE_VIDEO = "APP::SET_CURRENT_COURSE_VIDEO",
  FILTER_COURSES = "APP::FILTER_COURSES",
  FILTER_LABELS = "APP::FILTER_LABELS",
  SET_LOGIN_PAGE_STATUS = "APP::SET_LOGIN_PAGE_STATUS",
  SET_ADMIN_PAGE_STATUS = "APP::SET_ADMIN_PAGE_STATUS",
  GET_PREMIUM_COURSES_STATUS = "APP::GET_PREMIUM_COURSES_STATUS",
  SET_ADMIN_SUBSCRIPTION_SELECTED_USER = "APP::SET_ADMIN_SUBSCRIPTION_SELECTED_USER"
}

export type AppAction =
  | { type: APP_ACTION_TYPES.GET_ALL_COURSES; courses: OneCourse[] }
  | { type: APP_ACTION_TYPES.CHANGE_ACTIVE_TAB; activeTab: ActiveTabs }
  | { type: APP_ACTION_TYPES.GET_POPULAR_COURSES; popularCourses: OneCourse[] }
  | { type: APP_ACTION_TYPES.GET_PREMIUM_COURSES; premiumCourses: OneCourse[] }
  | { type: APP_ACTION_TYPES.GET_PREMIUM_COURSES_STATUS; premiumCoursesStatus: LoadingStatuses }
  | { type: APP_ACTION_TYPES.GET_POPULAR_COURSES_STATUS; popularCoursesStatus: LoadingStatuses }
  | { type: APP_ACTION_TYPES.SET_CURRENT_COURSE_MODIFICATION; courseModification: CoursesModifications }
  | { type: APP_ACTION_TYPES.SET_CURRENT_COURSE_VIDEO; courseVideo: number }
  | { type: APP_ACTION_TYPES.FILTER_COURSES; filter: ActiveFilters }
  | { type: APP_ACTION_TYPES.FILTER_LABELS; label: ActiveLabels }
  | { type: APP_ACTION_TYPES.SET_ADMIN_PAGE_STATUS; isAdminPageOpen: boolean }
  | { type: APP_ACTION_TYPES.SET_LOGIN_PAGE_STATUS; isLoginPageOpen: boolean }
  | { type: APP_ACTION_TYPES.SET_ADMIN_SUBSCRIPTION_SELECTED_USER; user: UserOneProfileType }

export const setSetAdminPageStatus = ({ isAdminPageOpen }: { isAdminPageOpen: boolean }): AppAction => {
  return { type: APP_ACTION_TYPES.SET_ADMIN_PAGE_STATUS, isAdminPageOpen };
};

export const setSetLoginPageStatus = ({ isLoginPageOpen }: { isLoginPageOpen: boolean }): AppAction => {
  return { type: APP_ACTION_TYPES.SET_LOGIN_PAGE_STATUS, isLoginPageOpen };
};

export const setAllCourses = ({ courses }: { courses: OneCourse[] }): AppAction => {
  return { type: APP_ACTION_TYPES.GET_ALL_COURSES, courses };
};

export const setCurrentVideo = ({ courseVideo }: { courseVideo: number }): AppAction => {
  return { type: APP_ACTION_TYPES.SET_CURRENT_COURSE_VIDEO, courseVideo };
};

export const setPopularCourses = ({ popularCourses }: { popularCourses: OneCourse[] }): AppAction => {
  return { type: APP_ACTION_TYPES.GET_POPULAR_COURSES, popularCourses };
};

export const setPopularCoursesStatus = ({ popularCoursesStatus }: { popularCoursesStatus: LoadingStatuses }): AppAction => {
  return { type: APP_ACTION_TYPES.GET_POPULAR_COURSES_STATUS, popularCoursesStatus };
};

export const setPremiumCoursesStatus = ({ premiumCoursesStatus }: { premiumCoursesStatus: LoadingStatuses }): AppAction => {
  return { type: APP_ACTION_TYPES.GET_PREMIUM_COURSES_STATUS, premiumCoursesStatus };
};

export const setPremiumCourses = ({ premiumCourses }: { premiumCourses: OneCourse[] }): AppAction => {
  return { type: APP_ACTION_TYPES.GET_PREMIUM_COURSES, premiumCourses };
};

export const setCurrentCourse = ({ courseModification }: { courseModification: CoursesModifications }): AppAction => {
  return { type: APP_ACTION_TYPES.SET_CURRENT_COURSE_MODIFICATION, courseModification };
};

export const changeActiveTab = ({ activeTab }: { activeTab: ActiveTabs }): AppAction => {
  return { type: APP_ACTION_TYPES.CHANGE_ACTIVE_TAB, activeTab };
};

export const filterCourses = ({ filter }: { filter: ActiveFilters }): AppAction => {
  return { type: APP_ACTION_TYPES.FILTER_COURSES, filter };
};

export const labelCourses = ({ label }: { label: ActiveLabels }): AppAction => {
  return { type: APP_ACTION_TYPES.FILTER_LABELS, label };
};

export const setAdminSubscriptionSelectedUser = ({ user }: { user: UserOneProfileType }): AppAction => {
  return { type: APP_ACTION_TYPES.SET_ADMIN_SUBSCRIPTION_SELECTED_USER, user };
};

export const getAllCourses = () => {
  return (dispatch: Dispatch) => {
    return axios.get(API_ALL_COURSES).then((response) => {
      return dispatch(setAllCourses({ courses: response.data }))
    })
  };
};

export const getPopularCourses = () => {
  return (dispatch: Dispatch) => {
    dispatch(setPopularCoursesStatus({ popularCoursesStatus: "loading" }));
    return axios.get(API_POPULAR_COURSES).then((response) => {
      dispatch(setPopularCoursesStatus({ popularCoursesStatus: "idle" }));
      return dispatch(setPopularCourses({ popularCourses: response.data }))
    })
      .catch(() => {
        dispatch(setPopularCoursesStatus({ popularCoursesStatus: "error" }));
        return dispatch(setPopularCourses({ popularCourses: [] }))
      })
  };
};

export const getPremiumCourses = () => {
  return (dispatch: Dispatch) => {
    dispatch(setPremiumCoursesStatus({ premiumCoursesStatus: "loading" }));
    return axios.get(API_PREMIUM_COURSES).then((response) => {
      dispatch(setPremiumCoursesStatus({ premiumCoursesStatus: "idle" }));
      return dispatch(setPremiumCourses({ premiumCourses: response.data }))
    })
      .catch(() => {
        dispatch(setPremiumCoursesStatus({ premiumCoursesStatus: "error" }));
        return dispatch(setPremiumCourses({ premiumCourses: [] }))
      })
  };
};

