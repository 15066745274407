import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import personalitiesFooter from '../../../assets/courses/personalities/personalityFooter.png';
import cardDecoration from '../../../assets/courses/personalities/cardDecoration.png';
import Tag from '../../Tags/Tag';
import { Button } from '../../UI/Button';
import { CursesSections, PersonalitySections, PersonalityTypes } from '../../../types/common';
import { COLORS } from '../../../constants/colors';
import { PERSONALITIES, SECTIONS } from '../../../constants/courses';
import { getPersonalitiesAvatar } from '../../../utils/courses/getPersonalitiesAvatar';
import {setCurrentPersonality, setCurrentPersonalitySection} from '../../../store/actions/personalityActions';

import {
   PersonalityWrapper,
   PersonalityProfilePhoto,
   PersonalityTitle,
   PersonalityDescription,
   PersonalityFooter,
   PersonalityDecoration,
   PersonalityActions,
   PersonalityHolder,
   TagWrapper,
} from './styled';

type Props = {
   personalitySection: PersonalitySections;
   section: CursesSections;
   personalityType: PersonalityTypes;
   miniType?: string;
}

const Personality = ({ personalityType, section, personalitySection, miniType }: Props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();

   const handleSelectPersonality = () => {
      dispatch(setCurrentPersonality({ personality: personalityType as PersonalityTypes}));
      dispatch(setCurrentPersonalitySection({ section: personalitySection as PersonalitySections}));
      
   }

   const getPersonalityCardColor = (personalitySection: PersonalitySections) => {
      switch (personalitySection) {
         case PERSONALITIES.analytic:
            return COLORS.eventLabOpacity;
         case PERSONALITIES.diplomat:
            return COLORS.kidsOpacity;
         case PERSONALITIES.keeper:
            return COLORS.youthOpacity;
         case PERSONALITIES.finder:
            return COLORS.artTherapyOpacity;
         default:
            return COLORS.eventLab;
      }
   };

   return <PersonalityHolder>
      <PersonalityWrapper color={getPersonalityCardColor(personalitySection)}>
         <TagWrapper>
            <Tag name={section} customName={personalitySection} />
         </TagWrapper>
         <PersonalityProfilePhoto src={getPersonalitiesAvatar(personalityType)} alt="" />
         <PersonalityTitle>
            {t(`personality.${personalityType}.title`)}
         </PersonalityTitle>
         <PersonalityDescription>
            {t(`personality.${personalityType}.description`)}
         </PersonalityDescription>
         <PersonalityDecoration src={cardDecoration} alt="" />
      </PersonalityWrapper>
      <PersonalityFooter image={personalitiesFooter}>
         <PersonalityActions>
            <Button
               modifier={'primaryWhiteOutlined'}
               onClick={handleSelectPersonality}
               className="personality__button"
            >
               {t('course.personalities.button')}
            </Button>

         </PersonalityActions>
         <Tag name={SECTIONS.custom as CursesSections} customName={miniType} />
      </PersonalityFooter>
   </PersonalityHolder>
}

export default Personality;