import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ContactsMap from '../Courses/CommonComponents/ContactsMap';
import DirectionPreview from '../common/DirectionPreview';
import { SECTIONS } from '../../../constants/courses';
import { CoursesButtonTypes, CoursesModifications } from '../../../types/courses/courses';
import artDescriptionBG from '../../../assets/directions/artDescriptionBG.png';
import { CursesSections } from '../../../types/common';
import Techniques from './Techniques';
import MaterialModal from '../../../components/UI/Modal/MaterialModal';
import { COLORS } from '../../../constants/colors';
import InvoiceSteps from '../../../components/InvoiceSteps/InvoiceSteps';

import { ArtWrapper, CourseTitle } from './styles';


const Art = () => {
   const { t } = useTranslation();
   const [isModalOpened, setIsModalOpened] = useState(false);

   const handleDirectionAction = () => {
      setIsModalOpened(!isModalOpened);
   }

   return (
      <ArtWrapper>
         <DirectionPreview
            bgImage={artDescriptionBG}
            sectionName={SECTIONS.artTherapy as CursesSections}
            button={CoursesButtonTypes.SUBSCRIBE}
            buttonModification={'primaryOutlined'}
            customTag={'Мероприятия'}
            handleDirectionAction={handleDirectionAction}
         />
         <CourseTitle>
            {t(`directions.${SECTIONS.artTherapy}.technique`)}
         </CourseTitle>
         <Techniques />
         <ContactsMap handleButtonClick={handleDirectionAction} />
         <MaterialModal
            modification={SECTIONS.artTherapy as CursesSections}
            open={isModalOpened}
            handleClose={handleDirectionAction}
            bgcolor={COLORS.courseCardBackground}
            title={t(`directions.${SECTIONS.artTherapy}.title`)}
            width={400}
            height={500}
         >
            <InvoiceSteps
               modification={SECTIONS.artTherapy as CursesSections}
               handleCloseModal={handleDirectionAction}
               course={SECTIONS.artTherapy as CoursesModifications}
               invoiceDescription={t(`directions.${SECTIONS.artTherapy}.invoiceDescription`)}
            />
         </MaterialModal>
      </ArtWrapper>
   );
};

export default Art;