import { AppAction, APP_ACTION_TYPES } from '../actions/appActions';
import {ActiveTabs, LoadingStatuses, ActiveFilters, ActiveLabels} from './types/index';
import { OneCourse } from '../../types/courses/courses';
import { CoursesModifications } from 'types/courses/courses';
import { UserOneProfileType } from 'types/user/user';

interface AppState {
  courses: OneCourse[];
  notFilteredCourses: OneCourse[];
  isFetching: boolean;
  activeTab: ActiveTabs;
  popularCourses: OneCourse[] | [];
  premiumCourses: OneCourse[] | [];
  popularCoursesStatus: LoadingStatuses;
  premiumCoursesStatus: LoadingStatuses;
  currentCourseModification: CoursesModifications;
  currentCourseVideo: number;
  filter: ActiveFilters;
  label: ActiveLabels;
  isLoginPageOpen: boolean;
  isAdminPageOpen: boolean;
  currentAdminSelectedSubscription: UserOneProfileType
}

const initialState: AppState = {
  courses: [],
  notFilteredCourses: [],
  isFetching: false,
  activeTab: 'main',
  popularCourses: [],
  premiumCourses: [],
  popularCoursesStatus: 'idle',
  premiumCoursesStatus: 'idle',
  currentCourseModification: 'noSelected',
  currentCourseVideo: 0,
  filter: 'all',
  label: 'none',
  isLoginPageOpen: false,
  isAdminPageOpen: false,
  currentAdminSelectedSubscription: null
};

export default function app(state = initialState, action: AppAction): AppState {
  switch (action.type) {
    case APP_ACTION_TYPES.SET_ADMIN_PAGE_STATUS: {
      return {
        ...state,
        isAdminPageOpen: action.isAdminPageOpen,
      };
    }

    case APP_ACTION_TYPES.SET_LOGIN_PAGE_STATUS: {
      return {
        ...state,
        isLoginPageOpen: action.isLoginPageOpen,
      };
    }

    case APP_ACTION_TYPES.GET_ALL_COURSES: {
      return {
        ...state,
        courses: action.courses,
        notFilteredCourses: action.courses,
      };
    }

    case APP_ACTION_TYPES.CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab,
      };
    }

    case APP_ACTION_TYPES.GET_POPULAR_COURSES: {
      return {
        ...state,
        popularCourses: action.popularCourses,
      };
    }

    case APP_ACTION_TYPES.GET_POPULAR_COURSES_STATUS: {
      return {
        ...state,
        popularCoursesStatus: action.popularCoursesStatus,
      };
    }

    case APP_ACTION_TYPES.GET_PREMIUM_COURSES: {
      return {
        ...state,
        premiumCourses: action.premiumCourses,
      };
    }

    case APP_ACTION_TYPES.GET_PREMIUM_COURSES_STATUS: {
      return {
        ...state,
        premiumCoursesStatus: action.premiumCoursesStatus
      }
    }

    case APP_ACTION_TYPES.SET_CURRENT_COURSE_MODIFICATION: {
      return {
        ...state,
        currentCourseModification: action.courseModification,
        currentCourseVideo: 0,
      }
    }

    case APP_ACTION_TYPES.SET_CURRENT_COURSE_VIDEO: {
      return {
        ...state,
        currentCourseVideo: action.courseVideo,
      }
    }

    case APP_ACTION_TYPES.FILTER_COURSES: {
      const currentCourses = state.notFilteredCourses;

      if (action.filter === 'all') {
        return {
          ...state,
          courses: state.notFilteredCourses,
          filter: 'all',
        }
      } else if (action.filter === 'paid') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.category === 'paid'),
          filter: 'paid',
        }
      } else if (action.filter === 'online') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.category === 'online'),
          filter: 'online',
        }
      } else if (action.filter === 'offline') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.category === 'offline'),
          filter: 'offline',
        }
      } else if (action.filter === 'audio') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.category === 'audio'),
          filter: 'audio',
        }
      } else if (action.filter === 'book') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.category === 'book'),
          filter: 'book',
        }
      }

      return {
        ...state,
        courses: state.courses,
      }
    }

    case APP_ACTION_TYPES.FILTER_LABELS: {
      const currentCourses = state.notFilteredCourses;

      if (action.label === 'none') {
        return {
          ...state,
          courses: state.notFilteredCourses,
          label: 'none',
        }
      } else if (action.label === 'Art-therapy') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Art-therapy'),
          label: 'Art-therapy',
        }
      } else if (action.label === 'Event-lab') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Event-lab'),
          label: 'Event-lab',
        }
      } else if (action.label === 'Show') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Show'),
          label: 'Show',
        }
      } else if (action.label === 'Teens') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Teens'),
          label: 'Teens',
        }
      } else if (action.label === 'Youth') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Youth'),
          label: 'Youth',
        }
      } else if (action.label === 'Kids') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Kids'),
          label: 'Kids',
        }
      } else if (action.label === 'Development') {
        return {
          ...state,
          courses: currentCourses.filter((course) => course.section === 'Youth' && course.category === 'online'),
          label: 'Development',
        }
      }
      return {
        ...state,
        courses: state.courses,
      }
    }

    case APP_ACTION_TYPES.SET_ADMIN_SUBSCRIPTION_SELECTED_USER: {
      return {
        ...state,
        currentAdminSelectedSubscription: action.user,
      }
    }

    default: {
      return state;
    }
  }
}
