import { COLORS } from "../../../constants/colors";
import styled from "styled-components";

export const UserProfileInfoWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   width: 100%;
   color: ${COLORS.white};
   margin-top: 20px;
   margin-bottom: 20px;
   gap: 5px;
   font-size: 20px;
   font-weight: 500;
   text-transform: uppercase;
`;

export const NoUserProfileInfoWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: ${COLORS.urbanLoginDark};
   color: ${COLORS.white};
   margin: 10px;
   font-size: 20px;
   font-weight: 500;
   border-radius: 10px; 
   padding: 4px;

   button {
      margin-top: 0px;
      margin-bottom: 5px;
      width: 120px !important;
      background-color: ${COLORS.eventLab} !important;
      color: ${COLORS.white} !important;
      border: none !important;
   }
`;

export const NoUserProfileInfTitle = styled.div`
   margin-top: 10px;
   margin-bottom: 10px;
   font-size: 13px;
   font-weight: bold;
   color: ${COLORS.white};
   text-align: center;
`;


export const NoUserProfileInfSubtitle = styled.div`
   margin-bottom: 10px;
   font-size: 10px;
   font-weight: regular;
   text-align: center;
   color: ${COLORS.white};
   line-height: 130%;
   opacity: 0.5;
`;

export const UserPhotoWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   color: ${COLORS.white};
   font-size: 20px;
   font-weight: 500;
   text-transform: uppercase;
   border-radius: 50%;
   padding: 5px;
   background-color: ${COLORS.magenta};
`;

export const UserImage = styled.img`
   width: 100px;
   height: 100px;
   border-radius: 50%;
`;

export const UserPROTag = styled.div`
   position: absolute;
   background-color: ${COLORS.magenta};
   color: ${COLORS.white};
   padding: 5px;
   border-radius: 50px;
   font-size: 10px;
   font-weight: bold;
   bottom: -14px;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 17px;

   img {
      margin-right: 5px;
      width: 9px;
      height: 9px;
   }
`;

export const UserName = styled.div`
   font-size: 20px;
   font-weight: bold;
   color: ${COLORS.white};
   margin-top: 20px;
`;

export const UserEmail = styled.div`
   font-size: 10px;
   font-weight: 500;
   color: ${COLORS.white};
   opacity: 0.5;
`;