import styled from "styled-components";
import { COLORS } from '../../../constants/colors';

export const ChangeWrapper = styled.div`
    display: flex;
    color: ${COLORS.white};
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
`;

export const ChangeItem = styled.div<{isLangSelected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-weight: ${(props) => props.isLangSelected ? 'bold' : 'normal'};
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 1px;
  background-color: ${COLORS.white};
`;