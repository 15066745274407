import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsersProfilesData } from '../../../../store/selectors/user';
import { OneUser } from './OneUser';
import { UserSectionWrapper, UserSectionTitle, UsersWrapper } from './styled';
import { Input } from '../../../../components/UI/Input/Input';

export const Users = () => {
   const allUsers = useSelector(selectUsersProfilesData);
   const [searchTerm, setSearchTerm] = useState('');

   const handleSearchChange = (e: any) => {
      setSearchTerm(e.target.value);
   };

   const filteredUsers = allUsers?.filter(user => 
      user?.name.toLowerCase().includes(searchTerm.toLowerCase())
   );

   return (
      <UserSectionWrapper>
         <UserSectionTitle>Управление пользователями</UserSectionTitle>
         <br />
         <Input 
            type="text" 
            value={searchTerm} 
            onChange={handleSearchChange} 
            placeholder="Search by name"
            modifier='search'
         />
         <UsersWrapper>
            {filteredUsers?.map((user, index) => 
               user && <OneUser key={user.id} {...user} />
            )}
         </UsersWrapper>
      </UserSectionWrapper>
   );
};