import styled from 'styled-components';

import { COLORS } from '../../../constants/colors';

export const TopMenuWrapper = styled.div<{ isMobile: boolean}>`
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   position: absolute;
   top: 20px;
   left: ${({ isMobile }) => isMobile ? '10px' : '20px'};
   width: calc(100% - ${({ isMobile }) => isMobile ? '20px' : '30px'});
   background-color: ${COLORS.white};
   border-radius: 10px;
   align-items: center;
   text-align: center;
   overflow: hidden;
   z-index: 2;
   transition: width 0.5s;
`

export const TopMenuControllerWrapper = styled.div<{ isMobile?: boolean, isLeftMenuOpened: boolean }>`
   width: 60px;
   height: 100vh;
   z-index: 2;
   user-select: none;
   border-right: ${({ isLeftMenuOpened }) => isLeftMenuOpened ? '1px solid rgba(166, 166, 166, 0.13)' : 'none'};
   min-width: 50px;

   &:hover {
      cursor: pointer;
   }
`

export const OpenMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
`

export const CloseMenuIcon = styled.img`
   width: 20px;
   margin-right: 4px;
   margin-left: 4px;
   margin-top: 50vh;
   height: 20px;
   transform: rotate(180deg);
`

export const ContentWrapper = styled.div<{ isMobile?: boolean }>`
   width: 86%;
   background-color: ${COLORS.urbanBlack};
`