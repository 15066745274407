import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PasswordIcon from '@mui/icons-material/Password';

import { updateUserPassword } from '../../../../store/actions/userActions';
import { Button } from '../../../UI/Button';
import useMediaQuery from '../../../../utils/breakpoints/useMediaQuery';
import { Input } from '../../../UI/Input/Input';
import { validationPasswordFields } from './utils';
import { COLORS } from '../../../../constants/colors';

import {
   UserInfoEditorWrapper,
   Divider,
   UserBottomRow,
   HolderForm,
   InputTitle,
   VerticalSeparator,
   UserNameWrapper
} from './styled'


const UserPasswordReset = () => {
   const dispatch = useDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar();
   const [validationErrorFields, setValidationErrorFields] = useState<string[]>([])
   const { isMobile } = useMediaQuery()

   const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { old_password, new_password } = e.target as HTMLFormElement;

      if (!validationPasswordFields({
         callback: enqueueSnackbar,
         old_password: old_password.value,
         new_password: new_password.value,
         setValidationErrorFields,
      })) {
         return
      }

      dispatch(updateUserPassword({
         old_password: old_password.value,
         new_password: new_password.value,
         callback: () => enqueueSnackbar("Успешно изменен пароль", { variant: 'success' }),
         callbackError: () => enqueueSnackbar('Неверный старый пароль', { variant: 'error' }),
      }) as any);

      setValidationErrorFields([])
   }

   return (
      <UserInfoEditorWrapper>
         <UserBottomRow>
            <UserNameWrapper>
               <h1>
                  {t('login.changePass')}
               </h1>
               <span>
                  {t('login.changePassDescription')}
               </span>
            </UserNameWrapper>
            <HolderForm isMobile={isMobile} onSubmit={handleOnSubmit}>
               <InputTitle>
                  {t('login.old_password')}
               </InputTitle>
               <Input
                  type='text'
                  name="old_password"
                  placeholder={t('login.old_password')}
                  modifier='primary'
                  isError={validationErrorFields.includes("old_password")}
                  icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
               <InputTitle>
                  {t('login.new_password')}
               </InputTitle>
               <Input
                  type='text'
                  name="new_password"
                  placeholder={t('login.new_password')}
                  modifier='primary'
                  isError={validationErrorFields.includes("new_password")}
                  icon={<PasswordIcon style={{ color: COLORS.white, fontSize: 25, opacity: 0.5 }} />}
               />
               <Divider />
               <VerticalSeparator />
               <Button
                  modifier='primaryWhiteOutlined'
                  htmlType='submit'
               >
                  {t('login.change')}
               </Button>
            </HolderForm>
         </UserBottomRow>
      </UserInfoEditorWrapper>
   );
};

export default UserPasswordReset;