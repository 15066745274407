import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SelectedCard from '../SelectedCard';
import { AssociativeCardObj } from '../AssociativeCard';
import { Button } from '../../UI/Button';
import { COLORS } from '../../../constants/colors';


import useMediaQuery from '../../../utils/breakpoints/useMediaQuery';

import { AnnotationText, GlobalStepWrapper, BottomButtonsWrapper } from '../styled';

type Props = {
   selectedCard?: AssociativeCardObj[] | null;
   setPreviousStep: () => void;
   setNextStep: () => void;
}

const StepSelectedPositiveCards = ({ selectedCard, setPreviousStep, setNextStep }: Props) => {
   const { t } = useTranslation();
   const { isMobile } = useMediaQuery()

   return (
      <GlobalStepWrapper isMobile={isMobile}>
         <SelectedCardWrapper isMobile={isMobile}>
            <StepWrapper isMobile={isMobile}>
               <SelectedCardTitle isMobile={isMobile}>
                  Вы выбрали 2 карты из набора “позитив“:
               </SelectedCardTitle>
               <div className='cards-wrapper'>
                  {selectedCard &&
                     <SelectedCard
                        width={isMobile ? "150px" : '230px'} height={isMobile ? "70px" : '130px'} selectedCard={selectedCard[0]} />}
                  {selectedCard &&
                     <SelectedCard
                        width={isMobile ? "150px" : '230px'} height={isMobile ? "70px" : '130px'} selectedCard={selectedCard[1]} />}
               </div>
               <SelectedCardSubtitle isMobile={isMobile}>
                  * Из написанных слов “существительных, глаголов или прилагательных“ сформулируйте предложение, которое
                  может служить ответом на выбранный запрос “желательно не добавлять каких-то ненаписанных слов.“
               </SelectedCardSubtitle>
               <AnnotationText isMobile={isMobile}>
                  Если вы согласны с выбором продолжайте дальше, если нет, то вернитесь на предыдущий шаг.
               </AnnotationText>
            </StepWrapper>
         </SelectedCardWrapper>
         <BottomButtonsWrapper isMobile={isMobile}>
            <Button modifier='primaryOutlined' onClick={setPreviousStep}>Назад</Button>
            <Button modifier='primaryOutlined' onClick={setNextStep}>{t("navigation.next")}</Button>
         </BottomButtonsWrapper>
      </GlobalStepWrapper>

   );
};

export default StepSelectedPositiveCards;

const StepWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   padding: ${props => props.isMobile ? "1rem" : "1rem"};

   .cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: 20px;
   }
`;

const SelectedCardWrapper = styled.div<{ isMobile: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: ${props => props.isMobile ? "1rem" : "1rem"};
`;

const SelectedCardTitle = styled.h3<{ isMobile: boolean }>`
   font-size: ${props => props.isMobile ? "0.8rem" : "1.5rem"};
   line-height: ${props => props.isMobile ? "1rem" : "2rem"};
   margin: 10px 0px;
   padding-bottom: ${props => props.isMobile ? "0.5rem" : "1rem"};
   color: ${COLORS.white};
   text-align: left;
`;


const SelectedCardSubtitle = styled.p<{ isMobile: boolean }>`
   font-size: ${props => props.isMobile ? "0.8rem" : "1rem"}};
   line-height: ${props => props.isMobile ? "1rem" : "1.5rem"}};
   margin: .4rem 0;
   text-align: left;
   color: ${COLORS.white};
`;