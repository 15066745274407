import { useTranslation } from 'react-i18next';
import { ShortInfoWrapper , OneShortInfoWrapper} from './styled';

const ShortInfoBlock = () => {
   const { t } = useTranslation();

   const renderOneInfoBlock = ({number, text}: {number: string, text: string}) => {
      return <OneShortInfoWrapper>
         <div className='number'>{number}</div>
         <div className='text'>{text}</div>
      </OneShortInfoWrapper>
   }

   return (
      <ShortInfoWrapper>
         {renderOneInfoBlock({ number: '5', text: `${t('news.developedParts')}` })}
         {renderOneInfoBlock({ number: '20+', text: `${t('news.yearsWork')}` })}
         {renderOneInfoBlock({ number: '20+', text: `${t('news.coursesDone')}` })}
         {renderOneInfoBlock({ number: '4+', text: `${t('news.booksAndGames')}` })}
      </ShortInfoWrapper>
   );
}

export default ShortInfoBlock;